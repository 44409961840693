//Temporary Inbound
export const SCAN_RETURN_FOR_PRODUCT_CODE_ADD = 'SCAN_RETURN_FOR_PRODUCT_CODE_ADD';
export const SCAN_RETURN_FOR_PACKAGE_CODE_ADD = 'SCAN_RETURN_FOR_PACKAGE_CODE_ADD';
export const SCAN_RETURN_COMPLETED = 'SCAN_RETURN_COMPLETED';

//Storing
export const SCAN_STORING_FOR_PRODUCT_CODE_ADD = 'SCAN_STORING_FOR_PRODUCT_CODE_ADD';
export const SCAN_STORING_FOR_PACKAGE_CODE_ADD = 'SCAN_STORING_FOR_PACKAGE_CODE_ADD';
export const SCAN_STORING_COMPLETED = 'SCAN_STORING_COMPLETED';

//Inbound control
export const SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD = 'SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD';
export const SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD = 'SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD';
export const SCAN_INBOUND_CONTROL_COMPLETED = 'SCAN_INBOUND_CONTROL_COMPLETED';

//Vas
export const SCAN_VAS_FOR_PRODUCT_CODE_ADD = 'SCAN_VAS_FOR_PRODUCT_CODE_ADD';
export const SCAN_VAS_COMPLETED = 'SCAN_VAS_COMPLETED';
//Scan
export const TYPE_SCAN = 'TYPE_SCAN';

//ProductionOrder
export const SCAN_PRODUCTION_ORDER_FOR_PRODUCT_CODE_ADD = 'SCAN_PRODUCTION_ORDER_FOR_PRODUCT_CODE_ADD';
export const SCAN_PRODUCTION_ORDER_COMPLETED = 'SCAN_PRODUCTION_ORDER_COMPLETED';
export const PRODUCTION_ORDER_PRODUCT = 'PRODUCTION_ORDER_PRODUCT';
