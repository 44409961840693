import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Modal, InputNumber, Input, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import { ProFormSelect } from '@ant-design/pro-form';
import './style.less';
// import _ from 'lodash';
// import Helper from 'utils/helpers';
// import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
import { ProFormText } from '@ant-design/pro-form';
import { getAllProudctLocator } from './production-order.service';
import { productList } from 'pages/dashboard/inventory/inventory-management/product.service';
// import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';

const ProducttionInternal = ({ item, onCallBack, setIsEditModal, isEditModal }) => {
  // console.log(item)
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const lang = getLanguages(t);
  const [locatorSelected, setLocatorSeleted] = useState('')


  const initValues = {
    internalProduct: item?.map(product => ({
      code3A: product?.masterProduct?.code3A,
      locatorCode: product?.locator?.locator,
      // locatorId: product?.locator?.locator,
      locatorCodeTo: product?.locatorTo?.locator,
      // total: product?.quantityInCarton * product.tinPerCarton - product?.pickingQuantity || 0,
      warehouseId: product?.warehouseId,
      lot: product?.lot,
      expireDate: product?.expireDate,
      name: product?.masterProduct?.nameEN,
      shortName: product?.masterProduct?.shortName,
      id: product?.id,
      tinPerCarton: product?.masterProduct?.tinPerCarton
    })),
  };
  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const onCancel = () => {
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${lang.btnCreate} đơn ${lang.EXPORT_BRANCH}`}
      onCancel={onCancel}
      afterClose={() => {
        form.resetFields();
        form.setFieldsValue({ internalProduct: undefined });
      }}
      width={1200}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        onFinish={ (values) => {
          const data = values?.internalProduct.filter((item) => (
            item?.quantityInCarton > 0 || item?.quantityTin > 0
          ));
          data.forEach((rs, index) => rs.index = index + new Date().getTime())
          onCallBack(data);
          setIsEditModal(false);
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col span={24}>
            <Form.List name="internalProduct" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name }) => (
                    <>
                      <Row gutter={[16, 16]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'code3A']}
                            wrapperCol={24}
                            label={lang.defaultCode}
                          >
                            <Input style={{ width: '100%' }} placeholder={lang.defaultCode} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            wrapperCol={24}
                            name={[name, 'shortName']}
                            label={lang.SHORTNAME}
                          >
                            <Input style={{ width: '100%' }} placeholder={lang.SHORTNAME} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <ProFormText
                            name={[name, 'name']}
                            label={lang.productName}
                            placeholder={lang.productName}
                            disabled
                          />
                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                          <ProFormSelect style={{ fontWeight: 600 }}
                            name={[name, 'locatorId']}
                            label={lang.Locator}
                            rules={[
                              {
                                required: true,
                                message: lang.requiredLocator,
                              },
                            ]}
                           params={{code3A: form.getFieldValue(['internalProduct', name, 'code3A'])}}
                           request={async (params) => {
                            const listProductInventory = await productList({ warehouseId: currentWarehouse?.id, isAvailableQauntity: true});
                            let listProductLocator = listProductInventory?.data?.find(item => item?.code3A === params?.code3A)?.masterProductsLocators;
                            // console.log(listProductLocator)
                            const listProductSelectedMulti = []; 
                            for (let i = 0; i < listProductLocator.length; i++) { 
                              let obj = listProductLocator[i];
                              const { totalQuantityCarttonForSale, totalQuantityForSale, locators } = obj;
                              const foundObj = listProductSelectedMulti.find((item) => (item?.locators?.id === locators?.id ));
                              
                              if (foundObj) {
                                foundObj.totalQuantityCarttonForSale += totalQuantityCarttonForSale; 
                                foundObj.totalQuantityForSale += totalQuantityForSale;
                              } else { 
                                listProductSelectedMulti.push(listProductLocator[i]);
                              }
                            }

                            return listProductSelectedMulti?.filter(proItem => proItem?.locators?.warehouseId === currentWarehouse?.id && proItem?.locators?.warehouseToId === null)?.map(locator => ({
                              label: `${locator?.locators?.locator} - ${locator?.locators?.locatorName}`,
                              value: locator?.locators?.locator,
                              id: locator?.id,
                              code3A: params?.code3A,
                              totalQuantityCarttonForSale: locator?.totalQuantityCarttonForSale,
                              totalQuantityForSale: locator?.totalQuantityForSale,
                              totalQuantityTinForSale: locator?.totalQuantityTinForSale
                            }))
                           }}
                            placeholder={lang.Locator}
                            fieldProps={{
                              labelInValue: true,
                              onChange: async (value) => {
                                setLocatorSeleted(value?.value)
                                // console.log(value)
                                // const listProductInventory = await productList({ warehouseId: currentWarehouse?.id, isAvailableQauntity: true});
                                // const masterProductId = listProductInventory?.data?.find(item => item?.code3A === value?.code3A)?.id;
                                // const locator = await getAllProudctLocator({ masterProductId: masterProductId, locatorCode: value?.value });
                              
                                const fields = form.getFieldValue();
                                const { internalProduct } = fields;
                                Object.assign(internalProduct[name], { lot: undefined, expireDate: undefined});
                                form.setFieldsValue(internalProduct)
                              },
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              // return true;
                              return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            showSearch
                          />
                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                        <ProFormSelect style={{ fontWeight: 600 }}
                          name={[name, "lot"]}
                          label={lang.lot}
                          rules={[
                            {
                              required: true,
                              message: lang.requiredLot,
                            },
                          ]}
                          params={{ 
                            code3A: form.getFieldValue(['internalProduct', name, 'code3A']), 
                            locatorCode: locatorSelected,
                          }}
                          request={ async (params) => {
                            const {locatorCode} = params;
                            const listProductInventory = await productList({ warehouseId: currentWarehouse?.id, isAvailableQauntity: true});
                            const masterProductId = listProductInventory?.data?.find(item => item?.code3A === params?.code3A)?.id;
                            const locator = await getAllProudctLocator({ masterProductId: masterProductId, locatorCode });
                            return locator?.data.map(locator => ({
                              label: locator?.lot,
                              value: locator?.lot,
                              ...locator
                            }));
                          }}
                          placeholder={lang.lot}
                          fieldProps={{
                            labelInValue: true,
                            onChange: val => {
                              // console.log(val)
                              const fields = form.getFieldValue();
                              const { internalProduct } = fields;
                              Object.assign(internalProduct[name], { expireDate: val?.expireDate});
                              form.setFieldsValue({ internalProduct })
                            },
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            // return true;
                            return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                          showSearch
                        />
                      </Col>
                      <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                        <Form.Item
                          // name="expireDate"
                          name={[name, "expireDate"]}
                          label={lang.expireDate}
                          rules={[
                            {
                              required: true,
                              message: lang.requiredExpirationDate,
                            },
                          ]}
                        >
                          <Input disabled/>
                        </Form.Item>
                      </Col>
                        <Col span={8}>
                          <Form.Item shouldUpdate
                           wrapperCol={{ span: 24 }} 
                           style={{ marginBottom: 0 }}>
                            {(form) => {
                              const locatorSelect = form.getFieldValue(['internalProduct', name, 'locatorId'])?.value;
                              if (locatorSelect) {
                                let dataQuantity = form.getFieldValue(['internalProduct', name, 'locatorId']);
                                // console.log(form.getFieldValue(['internalProduct', name, 'lot']))
                                const cartons = Math.floor((item[name]?.quantityTin - item[name]?.pickingQuantityTin) / parseInt(item[name]?.masterProduct?.tinPerCarton)) + item[name]?.quantityInCarton - item[name]?.pickingQuantityCartton;
                                let totalQuantity = dataQuantity?.totalQuantityCarttonForSale;
                                if (form.getFieldValue(['internalProduct', name, 'lot'])?.lot) {
                                  dataQuantity = form.getFieldValue(['internalProduct', name, 'lot']);
                                  totalQuantity = dataQuantity?.totalQuantityCarttonForSale;
                                }
                                // if (form.getFieldValue(['internalProduct', name, 'expireDate'])) {
                                //   totalQuantity = locatorFind?.find(locator => form.getFieldValue(['internalProduct', name, 'lot'])?.lot === locator.lot && locator.expireDate === form.getFieldValue(['internalProduct', name, 'expireDate']))?.totalQuantityCarttonForSale;
                                // }
                                return <Form.Item
                                  name={[name, 'quantityInCarton']}
                                  label={`${'SL thùng có thể lấy'}(<=${totalQuantity >= 0 ? totalQuantity : 0}) - SL thùng từ điều phối: ${cartons}`}
                                  rules= {[
                                    {
                                      // eslint-disable-next-line prefer-promise-reject-errors
                                      validator: (_, value) => 
                                      // value > cartons ? Promise.reject('SL lấy phải nhỏ hơn SL tối đa') : 
                                      value > totalQuantity ? Promise.reject('SL lấy phải nhỏ hơn SL tồn kho') : 
                                      Promise.resolve(),
                                    },
                                  ]}
                                  initialValue={totalQuantity >= 0 ? totalQuantity : cartons}
                                >
                                  <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
                                </Form.Item>;
                              }
                              const cartons = Math.floor((item[name]?.quantityTin - item[name]?.pickingQuantityTin) / parseInt(item[name]?.masterProduct?.tinPerCarton)) + item[name]?.quantityInCarton - item[name]?.pickingQuantityCartton;
                              const tins = form?.getFieldValue(['internalProduct', name, 'quantityTin']);
                              return <Form.Item
                                name={[name, 'quantityInCarton']}
                                label={`${'SL thùng cần lấy'}(<=${cartons})`}
                                rules= {(cartons > 0 || tins > 0) && [
                                  {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    validator: (_, value) => (tins === 0 && value === 0) || value < 0 || value > cartons ? Promise.reject('SL lấy phải nhỏ hơn SL tồn kho') : Promise.resolve(),
                                  },
                                ]}
                                initialValue={cartons}
                              >
                                <InputNumber style={{ width: '100%' }} placeholder={lang.quantityCartton} min={0} />
                              </Form.Item>;
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                            {(form) => {
                              const locatorSelect = form.getFieldValue(['internalProduct', name, 'locatorId'])?.value;
                              if (locatorSelect) {
                                const cartons = Math.floor((item[name]?.quantityTin - item[name]?.pickingQuantityTin) / parseInt(item[name]?.masterProduct?.tinPerCarton)) + item[name]?.quantityInCarton - item[name]?.pickingQuantityCartton;
                                const tins = item[name]?.quantityTin - item[name]?.pickingQuantityTin + (item[name]?.quantityInCarton - item[name]?.pickingQuantityCartton - cartons) * parseInt(item[name]?.masterProduct?.tinPerCarton);
                                let dataQuantity = form.getFieldValue(['internalProduct', name, 'locatorId']);
                                let totalQuantityTin = dataQuantity?.totalQuantityForSale ;
                                if (form.getFieldValue(['internalProduct', name, 'lot'])?.lot) {
                                  dataQuantity = form.getFieldValue(['internalProduct', name, 'lot']);
                                  totalQuantityTin = dataQuantity?.totalQuantityForSale;
                                }
                                // if (form.getFieldValue(['internalProduct', name, 'expireDate'])) {
                                //   totalQuantityTin = locatorFind?.find(locator => form.getFieldValue(['internalProduct', name, 'lot'])?.lot === locator.lot && locator.expireDate === form.getFieldValue(['internalProduct', name, 'expireDate']))?.totalQuantityForSale ;
                                // }
                                return <Form.Item
                                  name={[name, 'quantityTin']}
                                  label={`${'SL lon có thể lấy'}(<=${totalQuantityTin >= 0 ? totalQuantityTin : 0}) - SL lon từ điều phối: ${tins}`}
                                  rules= {[
                                    {
                                      // eslint-disable-next-line prefer-promise-reject-errors
                                      validator: (_, value) => 
                                      // value > cartons ? Promise.reject('SL lấy phải nhỏ hơn SL tối đa') : 
                                      value > totalQuantityTin ? Promise.reject('SL lấy phải nhỏ hơn SL tồn kho') : 
                                      Promise.resolve(),
                                    },
                                  ]}
                                  initialValue={totalQuantityTin >= 0 ? totalQuantityTin : tins}
                                >
                                  <InputNumber style={{ width: '100%' }} placeholder={lang.quantityCartton} min={0} />
                                </Form.Item>;
                              }
                              const cartons = form?.getFieldValue(['internalProduct', name, 'quantityInCarton']);
                              const tins = item[name]?.quantityTin - item[name]?.pickingQuantityTin + (item[name]?.quantityInCarton - item[name]?.pickingQuantityCartton - cartons) * parseInt(item[name]?.masterProduct?.tinPerCarton);
                              return <Form.Item
                                name={[name, 'quantityTin']}
                                wrapperCol={24}
                                label={`${'SL lon cần lấy'}(<=${tins > 0 ? tins : 0})`}
                                rules={(cartons > 0 || tins > 0) && [
                                  {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    validator: (_, value) =>  value > tins ? Promise.reject('SL lấy phải nhỏ hơn SL tồn kho') : Promise.resolve(),
                                  },
                                ]}
                                initialValue={tins}
                             >
                                <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
                              </Form.Item>;
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                      {name < item?.length - 1 && <Divider />}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

ProducttionInternal.propTypes = {
  item: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default ProducttionInternal;
