export const error = 'ERROR';
export const temporaryIn = 'TEMPORARY';
export const moving = 'MOVING';
export const stored = 'STORED';
export const all = 'ALL';
export const pick = 'PICK';
export const vas = 'VAS';
export const reallocation = 'REALLOCATE';
export const lost = 'LOST';
export const temporaryOut = 'TEMPORARY_OUT';

export const hashStatusPending = {
    [error]: {
      text: 'ERROR',
    },
};
export const hasStatusLost = {
  [lost]: {
    text: 'LOST',
  },
};
export const hashStatusInbound = {
    [all]: {
      text: 'ALL',
    },
    [temporaryIn]: {
      text: 'TEMPORARY',
    },
    [stored]: {
      text: 'STORED',
    },
    [vas]: {
      text: 'VAS',
    },
    [pick]: {
      text: 'PICKING',
    },
    [temporaryOut]: {
      text: 'EXPORTED',
    },
};
