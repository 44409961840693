import ProTable from '@ant-design/pro-table';
import React from 'react';
import PropTypes from 'prop-types';

const ProTableCusTom = ({
    pagination,
    ...props
}) => {
    const { columns } = props;
    const columnsFil = columns?.filter(col => !col?.hideInTable);
    delete props.columns;
    return (
        <ProTable
            // scroll={{ y: 550, x: 'auto' }}
            size="small"
            columns={columnsFil}
            options={{
                search: false,
                reload: false,
                density: false,
            }}
            pagination={{
                ...pagination,
            }}
            {...props}
            search={false}
        />
    );
};
ProTableCusTom.propTypes = {
    columns: PropTypes.array.isRequired,
    pagination: PropTypes.object,
};
export default ProTableCusTom;
