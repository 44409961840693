import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';

import ScanLocationForm from 'components/ScanLocationForm/scan-location.form';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';

import './style.less';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const InboundControlScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { scanInboundControlForProductCodes, scanInboundControlForLocationCode } = useSelector((state) => state.inbound);

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/inbound-control/');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      disabled={scanInboundControlForLocationCode.length === 0 || scanInboundControlForProductCodes.length === 0}
      type="primary"
      onClick={() => {
        navigate('complete');
      }}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/inbound-control',
      breadcrumbName: lang.inboundControl,
    },
    {
      path: '/dashboard/inbound/inbound-control/inbound-control',
      breadcrumbName: lang.scanProduct,
    },
  ];

  return (
    <Layout className="inbound-control-scan layoutContent">
      <PageHeader
        ghost={false}
        title={lang.inboundControlScanProduct}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Row gutter={[16, 16]}>
          <Col sm={24} lg={12} xs={24}>
            <ScanLocationForm type={'INBOUND_CONTROL'} />
          </Col>
          <Col sm={24} lg={12} xs={24}>
            <ScanProductForm type={'INBOUND_CONTROL'} />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default InboundControlScan;
