import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Input, Form, Select, Modal, message } from 'antd';

import { SearchOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons'; //PlusOutlined, 
import { unpackList } from './unpack.service';
import { unpackDelete } from './unpack.service';


import './style.less';
import { defaultPage, formatDate, formatDateFull, STATUS_PRODUCTION_ORDER } from 'utils/constants';
// import { bookingOrderSet } from 'providers/OutboundProvider/actions';
import moment from 'moment-timezone';
import { useSelector} from 'react-redux';//useDispatch
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';
import _get from 'lodash/get';
// import { orderUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'id',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const UnpackList = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);

  //Pagination
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();

  const { currentWarehouse, user } = useSelector((state) => state.auth);
  // const isOperator = _get(user, 'roles', '').includes('operator');
  // const isVasManager = _get(user, 'roles', '').includes('vasManager');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  const [params, setParams] = useState({ ...defaultPage, status: isIdm ? '' : 'NEW'  });
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  useEffect(() => {
    setLoading(true);
    unpackList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
        console.log(result)
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse, isPicker]);

  const columns = [
    {
      title: 'Mã UnPack',
      key: 'documentNo',
      // dataIndex: 'documentNo',
      render: record => (
        <Button type='link' size="small" onClick={() => navigate(`${record?.id}`)}>{record?.documentNo}</Button>
      )
    },
    {
      title: 'Loại lệnh sản xuất',
      key: 'typeProductionOrder',
      dataIndex: 'typeProductionOrder',
      render: val => {
        return val
      }
    },
    {
      title: lang.productName,
      dataIndex: 'nameProduct',
      // with: '5%',
      key: 'nameProduct',
    },
    {
      title: 'Locator',
      dataIndex: 'locatorCode',
      key: 'locatorCode',
    },
    {
      title: lang.lot,
      dataIndex: 'lot',
      key: 'lot',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (val) => moment(val, formatDateFull).format(formatDate),
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      render: (record) => moment(record, formatDateFull).format(formatDate),
    },
    {
      title: lang.creator,
      dataIndex: 'creatorName',
      key: 'creatorName',
    },
    {
      title: lang.description,
      dataIndex: 'tranferReason',
      key: 'tranferReason',
    },
    {
      title: lang.salesChannel,
      key: 'channel',
      dataIndex: 'channel',
      // render: val => val && val?.channelName + ' - ' + val?.channel,
    },
    {
      title: lang.status,
      dataIndex: 'status',
      key: 'status',
      render: (val) => {
        if (val === 'ENABLE') {
          return 'Mới tạo'
        }
      },
    },
  ];

  const extraButton = [
    // isOperator && <Button
    //   key="btn-complete"
    //   type="primary"
    //   onClick={() => {
    //     navigate('create');
    //   }}
    // >
    //   {lang.btnCreate}
    //   <PlusOutlined />
    // </Button>,
    // isPicker && <Button
    // disabled={selectedRowKeys.length === 0 || selectedRowKeys.length > 1 }
    // key="btn-pickingPack"
    // type='primary'
    // onClick={async () => {
    //   try {
    //     if (selectedRowKeys.length > 0 ) {
    //       console.log(selectedRowKeys)
    //       // await orderCheck({ids: selectedRowKeys?.map(orderId => orderId.id), userId: `${user?.userInfo?.sub}`});
    //       dispatch(bookingOrderSet({
    //         pickingOrder: selectedRowKeys,
    //         pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
    //           const vasForBaseProduct = curr?.productOrders?.map(product => {
    //             product.orderCode = curr.code;
    //             product.orderId = curr.id;
    //             product.pickedQuantity = 0;
    //             product.groupOrder = curr.group;
    //             product.saleChannel = curr?.subChannel;
    //             product.orderType = "COMMAND_MANUFACTURING";
    //             product.locator = curr.locatorCode;
    //             product.warehouseId = curr.warehouseId;
    //             product.typeProductionOrder = curr.typeProductionOrder
    //             return product;
    //           });
    //           return [...prev, ...vasForBaseProduct];
    //         }, [])
            
    //       }))
    //       return navigate(`scan`)
    //     }
    //   } catch (error) {
    //     const splitMessage = error?.message?.split(/\s(.*)/s);
    //       message.error(splitMessage[0] + ' ' + lang.messeagePicking);
    //   }
    // }}
    // >
    //   {'Lấy hàng'}
    // </Button>,
  //   isStocker && <Button
  //   key="btn-confirm"
  //   type="primary"
  //   disabled={selectedRowKeys?.length === 0}
  //   onClick={async () => {
  //     Modal.confirm({
  //       title: "Bạn có chắc chắn muốn xác nhận không?",
  //       async onOk() {
  //         try {
  //           if (params?.status === "NEW") {
  //             setLoading(true)
  //             await orderUpdateStatus({ ids: selectedRowKeys.map(itemId => itemId?.id), status: 'LOCK' });
  //             // productionListaa({ ...params, ...sortedInfo, orderType: 'COMMAND_MANUFACTURING', warehouseId: currentWarehouse?.id })
  //             // .then(result => {
  //             //   setItems(result.data);
  //             //   setSelectedRowKeys([])
  //             //   message.success(lang.confirmSuccessfully);
  //             //   setLoading(false);
  //             // })
  //             // .catch((e) => setLoading(false));
  //             return true;
  //           }
  //         } catch (error) {
  //           message.error(error.message);
  //           return false;
  //         }
  //       },
  //       onCancel() {},
  //     });
  //   }}
  // >
  //   {lang.confirm}
  // </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/production-order',
      breadcrumbName: 'unpack',
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={'UnPack'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 6 }} sm={8} xs={12}>
                <Form.Item label={lang.status} name="status" 
                initialValue={isIdm ? '' : 'ENABLE'} 
                // initialValue={isOperator ? '' : isStocker ? 'NEW' : isPicker ? 'LOCK' : isVasManager ? 'PICKING' : 'CONFIRM'} 
                >
                  <Select placeholder={lang.status} allowClear>
                    {!isPicker && <Option key={''} value={''}>{'Tất cả'}</Option>}
                    {!isPicker && <Option key={'ENABLE'} value={'ENABLE'}>{STATUS_PRODUCTION_ORDER.NEW}</Option>}
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{'Danh sách UnPack'}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    // const listDataCheck = [];
                    const check = items?.some(item => selectedRowKeys.indexOf(item?.id) > -1 && item?.status === 'EXPORTED');
                    if (check === true) {
                      Modal.error({
                        title: 'Không thể xóa đơn có trạng thái đã xác nhận!',
                      });
                    } else if (check === false) {
                      Modal.confirm({
                        title: 'Bạn chắc chắn muốn xóa các mục đã chọn?',
                        icon: <ExclamationCircleOutlined />,
                        
                        async onOk() {
                          try {
                            await unpackDelete(selectedRowKeys?.map(unpackId => unpackId?.id));
                            const newArray = [];
                            selectedRowKeys.forEach(item => newArray.push(item?.documentNo))
                            setSelectedRowKeys([]);
                            Modal.success({
                              title: 'Xóa UnPack',
                              content: `${'Đã xóa thành công mã Unpack:'} ${newArray.toString()}`,
                            });
                            setLoading(true);
                            unpackList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
                            .then(result => {
                              setItems(result?.data);
                              setTotalItems(result.totalItem);
                              setLoading(false);
                            })
                            .catch((e) => setLoading(false));
                          } catch (error) {
                            message.error(error.message)
                          }
                        },
                        onCancel() {},
                      });
                    }
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          // Nhớ sữa lại những chỗ đã sd selectedRowkeys
          rowSelection={{
            onChange: (_, selectedRows) => {
              setSelectedRowKeys(selectedRows)
            },
            getCheckboxProps: (record) => ({
              disabled: ((record?.status !== 'ENABLE') || isPicker)
            })
          }}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          // Ông này là để holver vào sẽ click đc trên dòng luôn
          // onRow={(record) => {
          //   return {
          //     onClick: () => navigate(`${record.id}`),
          //     style: { cursor: 'pointer' },
          //   };
          // }}
          pagination={{
            showSizeChanger: true,
            total: totalItems,
            pageSize: params.limit,
            current: params.current,
          }}
          scroll={{x: 2600}}
        />
      </Layout.Content>

    </Layout>
  );
};

export default UnpackList;
