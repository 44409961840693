import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, message as Alert } from 'antd';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { Option } from 'antd/lib/mentions';

import './style.less';
// import { splitReceiptProduct } from './receipt.service';

const VasProductSplitForm = ({ items, onCancel, onCallback, QAcode, isOnlyQuantityAllowForUpdate = false }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const dataProductVas = items.find((productVas) => productVas?.code === QAcode);
  return (
    <Form
      colon={false}
      form={form}
      requiredMark={false}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        try {
          setLoading(true);
          if (values.quantityTin > 0) {
            // await splitReceiptProduct({ id: dataProductVas.id, quantityTin: values.quantityTin, productVasStatus: values.productVasStatus });
          } else {
            throw new Error('Số lượng lon cần phải nhập!');
          }
          onCallback(values);
          setLoading(false);
        } catch (error) {
          Alert.error(error.message);
          setLoading(false);
        }
      }}
      initialValues={{
        quantityCartton: dataProductVas ? 0 : undefined,
        quantityTin: dataProductVas ? 0 : undefined,
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={'Mã sản phẩm'}>
            <Input disabled value={dataProductVas?.masterProduct?.code3A} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.SHORTNAME}>
            <Input disabled value={dataProductVas?.masterProduct?.shortName} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.productName}
          >
            <Input disabled value={dataProductVas?.masterProduct?.nameEN} />
          </Form.Item>
        </Col>
        {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name='quantityCartton' label={`${lang.quantityCartton} hỏng/móp(<=${dataProductVas?.quantityCartton})`}
            rules={[
              {
                required: true,
                message: 'Số lượng thùng cần phải nhập',
                // eslint-disable-next-line prefer-promise-reject-errors
              },
              {
                validator: (_, value) => value ? (value <= (dataProductVas?.quantityCartton) ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng thùng hiện có')) : Promise.resolve(),
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} min={0} defaultValue={0} placeholder={lang.quantityCartton}/>
          </Form.Item>
        </Col> */}
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {(form) => {
              const totalQuantity = (dataProductVas?.quantityCartton - form.getFieldValue('quantityCartton')) * parseInt(dataProductVas?.masterProduct?.tinPerCarton) + dataProductVas?.quantityTin;
              return <Form.Item
                name="quantityTin"
                label={totalQuantity > 0 ? `${lang.quantityTin}(<=${totalQuantity})` : `${lang.quantityTin}(=${totalQuantity})`}
                rules={[
                  {
                    required: true,
                    message: 'Số lượng lon cần phải nhập',
                    // eslint-disable-next-line prefer-promise-reject-errors
                  },
                  {
                    validator: (_, value) => value ? (value <= totalQuantity ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng lon tồn kho')) : Promise.resolve(),
                  }
                ]}
              >
                <InputNumber disabled={totalQuantity === 0} defaultValue={0} style={{ width: '100%' }} min={0} placeholder={lang.quantityTin} value={totalQuantity} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name={'productVasStatus'} label={'Tình trạng sản phẩm'} rules={[{ required: true }]}>
            <Select placeholder={'Chọn tình trạng sản phẩm'}  style={{ width: '100%' }} allowClear>
              <Option value="broken">{'Hỏng'}</Option>
              <Option value="dent">{'Móp'}</Option>
              <Option value="error">{'Lỗi'}</Option>
              <Option value="short">{'Thiếu'}</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

VasProductSplitForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  QACode: PropTypes.string,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
};

export default VasProductSplitForm;
