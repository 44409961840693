import environment from 'environments/environment';
import request from 'utils/request';

export async function salesChannelList(payload) {
  return request(environment.api.salesChannel + '/get-all', payload, 'GET');
}

export async function salesChannelDetail(payload) {
  return request(`${environment.api.salesChannel}/get-one/${payload?.salesChannelId}`, {}, 'GET');
}

export async function salesChannelCreate(payload) {
  return request(environment.api.salesChannel + '/create', payload, 'POST');
}

export async function salesChannelUpdate(payload) {
  return request(environment.api.salesChannel + '/update', payload, 'PUT');
}
export async function salesChannelDelete(id) {
  return request(environment.api.salesChannel + `/delete/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.salesChannel + `/create/excel/${warehouseId}`, formData, 'POST');
}
