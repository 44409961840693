import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, message as Alert, Modal, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import Helper from 'utils/helpers';
import { bomCreate, bomUpdate } from './master-products-bom-gift.service';
import { ProFormSelect } from '@ant-design/pro-form';
import { productList } from '../product/product.service';
import { bomGiftList } from '../bom-gift/bom-gift.service';
import { MODE } from 'utils/constants';
import { useSelector } from 'react-redux';

const MasterProductsBomGiftEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    code3A: item ? item['3AParent'] : undefined,
    product_child: item ? item?.code3A ? item.code3A : item?.code : undefined,
    quantity: item ? item?.quantity : undefined,
  };
  const [masterProduct, setMasterProduct] = useState([]);
  const [bomGifts, setBomGifts] = useState([]);
  const { currentWarehouse } = useSelector((state) => state.auth);
  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  useEffect(() => {
    productList({ warehouseId: currentWarehouse?.id }).then(values => {
      setMasterProduct(values?.data);
    });
    bomGiftList({ warehouseId: currentWarehouse?.id }).then(values => {
      setBomGifts(values?.data);
    });
  }, [currentWarehouse]);

  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);

    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateMasterproductsbomgift : lang.createMasterproductsbomgift}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={1200}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          values.warehouseId = currentWarehouse?.id
          if (!item) { // Create
            bomCreate(values)
              .then(() => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            bomUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24}>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <ProFormSelect
              name='code3A'
              label={lang.productName}
              options={masterProduct?.map(product => ({
                value: product.code3A,
                label: `${product.nameEN} - ${product.code3A}`,
                key: product.id,
              }))}
              placeholder={lang.productName}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <ProFormSelect
              name='product_child'
              label={lang.bomGift}
              options={[...bomGifts?.map(bomgift => ({
                value: bomgift.code,
                label: `${bomgift.name} - ${bomgift.code}`,
                key: bomgift.id,
              })), ...masterProduct?.map(product => ({
                value: product.code3A,
                label: `${product.nameEN} - ${product.code3A}`,
                key: product.id,
              }))]}
              showSearch
              placeholder={lang.bomGift}
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
          </Col>
          <Col sm={{ span: 8 }}>
            <Form.Item
              name="quantity"
              label={lang.quantity}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.quantity} />
            </Form.Item>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

MasterProductsBomGiftEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default MasterProductsBomGiftEditForm;
