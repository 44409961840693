import request from 'utils/request';
import environment from 'environment';

export async function saleorderList(payload) {
  return request(environment.api.Orders + 'get-all', payload, 'GET');
}

export async function saleorderDetail(id) {
  return request(environment.api.Orders + `get-one/${id}`, {}, 'GET');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
    resolve('1');
  });
}
export async function getAllProudctLocator(payload) {
  return request(environment.api.masterProductLocator + '/get-all', payload, 'GET');
}

export async function deleteCommandManuever(id) {
  return request(environment.api.Orders + `delete-order-manuever/${id}`, {}, 'DELETE');
}

// Xong xóa khúc trên
export async function giftCommandManueverList(payload) {
  return request(environment.api.OrdersGift + 'get-all', payload, 'GET');
}

export async function giftCommandManueverCreate(payload) {
  return request(environment.api.OrdersGift + 'create-command-manuever', payload, 'POST');
}

export async function locatorGiftList(payload) {
  return request(environment.api.masterProductsGiftLocators + 'get-all', payload, 'GET');
}

export async function giftManueuverCommandDetail(id) {
  return request(environment.api.OrdersGift + `get-one/${id}`, {}, 'GET');
}

export async function giftOrderCMCreate(payload) {
  return request(environment.api.OrdersGift + 'create-export-branch', payload, 'POST');
}

export async function productGiftOrderUpdate(payload) {
  return request(environment.api.productGiftOrders + 'updateProduct-manuever-command', payload, 'PUT');
}

export async function updateOrderGift(payload) {
  return request(environment.api.OrdersGift + 'update', payload, 'PUT');
}

export async function giftDeleteCommandManuever(id) {
  return request(environment.api.OrdersGift + `delete-command-manuever/${id}`, {}, 'DELETE');
}


