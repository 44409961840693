import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';

export async function productList(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.productTrxList}?${params}`, {}, 'GET');
}

export async function productScan(payload) {
  // const sample =
  // {
  //   packageCodes: ['PKG4'],
  //   productCodes: ['PROD1'],
  //   type: 'TEMPORARY_INBOUND',
  // };
  return request(`${environment.api.productTrxScan}`, payload, 'POST');
}

export async function productUpdates(payload) {
  return request(`${environment.api.productTrxUpdates}`, payload, 'POST');
}

export async function productSplit(payload) {
  return request(`${environment.api.productTrxSplit}`, payload, 'POST');
}

// export async function receiptDetail(payload) {
//     return request(environment.api.receiptDetail, payload, 'GET');
// }

// export async function receiptCreate(payload) {
//     return request(environment.api.receiptCreate, payload, 'POST');
// }

// export async function receiptUpdate(payload) {
//     return request(environment.api.receiptUpdate, payload, 'PUT');
// }

// export async function receiptDelete(payload) {
//   //return request(environment.api.productDelete, payload, 'DELETE');
//   return new Promise((resolve, reject) => {
//       resolve('1');
//   });
// }
