import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Form, Input } from 'antd';
import { TableOutlined, EditOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteReceiptGift,receiptGiftDetail, createProductGiftbyPallet, productGiftUpdateLocation, receiptGiftConfirm, deleteProductsGift, updateReceiptGiftProduct } from './receiptGift.service'; //getOneByStatus
import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';
// import { saleorderDetail } from '../../configuration/sale-order/sale-order.service'
import { ModalForm, ProFormSelect, ProFormTextArea, } from '@ant-design/pro-form';
import './style.less';
import TableCustom from 'components/TableCustom/table';
import ProTableCusTom from 'components/TableCustom/Protable';
import ReceiptGiftProductDetail from './receiptGiftProduct-detail';
// import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';
import Helper from 'utils/helpers';
import _get from 'lodash/get';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import logog3A from 'assets/logoAKAWMS/logo-3A.png';
import moment from 'moment';
import ReceiptGiftProductPrint from './receiptGift-products-print';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [formCancel] = Form.useForm();
  const [items, setItems] = useState([]);
  // const [prosuctAllStatus, setProductAllStatus] = useState([]);
  const [itemsReceiptProduct, setItemsReceiptProduct] = useState([]);
  const [receipt, setReceipt] = useState();
  const [locatorsList, setLocatorList] = useState([]);
  const [isEditModalReceiptProduct, setIsEditModalReceiptProduct] = useState(false);
  const [receiptProduct, setReceiptProduct] = useState();
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [printProducts, setPrintProducts] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingExportKTCLHN, setLoadingExportKTCLHN] = useState(false);
  const [loadingExportPNK, setLoadingExportPNK] = useState(false);
  const [containerNo, setContainerNo] = useState();
  const [description, setDescription] = useState();
  // const [checkLocator, setCheckLocator] = useState(false);
  const [isclose, setIsClose] = useState(false);

  //Roles
  const isStocker = _get(user, 'roles', '').includes('stocker');
  // const isManager = _get(user, 'roles', '').includes('manager');
  const isIDM = _get(user, 'roles', '').includes('idm');
  const isInboundStaff = _get(user, 'roles', '').includes('inboundStaff');

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  // console.log(selectedRowKeys)
  useEffect(() => {
    setLoading(true);
    receiptGiftDetail({ id: parseInt(id) })
      .then(result => {
        setReceipt(result);
        setItems(result?.productGift);
        setItemsReceiptProduct(result?.receiptGiftProduct);
        setContainerNo(result?.containerNo);
        setDescription(result?.description);
        form.setFieldsValue({ locator: result?.locators?.id })
        // form.setFieldsValue({ locator: result?.warehouse });
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [id, form]);
  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id }).then(async result => {const data = [];
      await result?.data?.map(item => {
        if ((item?.locator).includes("Gift") === true) {
          data.push(item);
        }
        return true;
      })
      setLocatorList(data);
    }).catch(e => setLoading(false));
    setLoading(false);
  }, [currentWarehouse]);
//  useEffect(() => {
//   setLoading(true);
//   getOneByStatus({id: parseInt(id)})
//   .then(result => {
//     setProductAllStatus(result);
//     setLoading(false);
//   })
//   .catch((e) => setLoading(false));
//  }, [currentWarehouse, id])
  const columns = [
    {
      title: lang.giftCode,
      key: 'giftCode',
      fixed: 'left',
      width: '5%',
      render: (record) => record?.bomGift?.code,
    },
    {
      title: 'QR code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.giftName,
      key: 'giftName',
      render: (record) => record?.bomGift?.name,
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => `${val?.locator} - ${val?.locatorName}`,
    },
    {
      title: lang.block,
      key: 'block',
      render: record => {
        if (record?.status === 'NEW') {
          if ( record?.rack?.shelf?.block) {
            return   record?.rack?.shelf?.block?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if ( record?.rack?.shelf?.block) {
            return   record?.rack?.shelf?.block?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return record?.rack?.shelf?.block?.name
        }

      }
    },
    {
      title: lang.shelf,
      key: 'shelf',
      render: record => {
        if (record?.status === 'NEW') {
          if (record?.rack) {
            return  record?.rack?.shelf?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if ( record?.rack?.shelf) {
            return   record?.rack?.shelf?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return record?.rack?.shelf?.name
        }
        
      }
    },
    {
      title: lang.rack,
      key: 'rack',
      render: record => {
        if (record?.status === 'NEW') {
          if (record?.rack) {
            return  record?.rack?.code + ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if (record?.rack) {
            return  record?.rack?.code + ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return  record?.rack?.code
        }
        
      }
    },
    {
      title: 'Status QRcode',
      key: 'statusQA',
      dataIndex: 'status',
      render: (value) => {
        if (value === 'NEW') {
          return 'Mới tạo'
        } else if (value === 'TEMPORARY'){
          return 'Tạm nhập'
        } else {
          return 'Trong kho'
        }
      },
    },
    // {
    //   title: lang.action,
    //   align: 'center',
    //   width: '5%',
    //   fixed: 'right',
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<EditOutlined />}
    //       // onClick={() => {
    //       //   setItem(record);
    //       //   setIsEditModal(true);
    //       // }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const columnsReceiptProduct = [
    {
      title: lang.giftCode,
      key: 'giftCode',
      dataIndex: ['bomGift', 'code'],
    },
    {
      title: lang.giftName,
      key: 'giftName',
      render: (record) => record?.bomGift?.name,
    },
    {
      title: 'ContractRefNo',
      key: 'contractRefNo',
      dataIndex: 'contractRefNo',
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Số lượng đã chia',
      key: 'quantityReceived',
      dataIndex: 'quantityReceived',
    },
    {
      title: lang.action,
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            key='btn-edit-receipt-product'
            disabled={!isInboundStaff || record.status ==="DISABLE" || record?.quantity === record?.quantityReceived}
            onClick={() => {
              console.log(record)
              setReceiptProduct(record);
              setIsEditModalReceiptProduct(true);
            }}
          />
        </div>
      ),
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {'Back'}
    </Button>,
     receipt?.status !== 'DELETED' && receipt?.status !== 'RECEIVE' && (isStocker || isInboundStaff || isIDM) && <Button
     key="btn-save"
     type="primary"
     ghost
     onClick={async () => {
       try {
         setLoading(true);
         await form.validateFields().catch(err => {
           throw new Error(err?.errorFields?.[0]?.errors?.[0]);
         });
         const locatorFind = locatorsList.find(locator => locator.id === form.getFieldValue('locator'));
         receipt.locatorCode = locatorFind.locator;

          if (!containerNo) throw new Error(lang.requiredContainerNo);
           
           receipt.containerNo = containerNo;
           receipt.description = description;
           delete receipt.products;
           delete receipt.receiptProduct;
           delete receipt.locator;
         await updateReceiptGiftProduct(receipt);
         receiptGiftDetail({ id: parseInt(id) })
           .then(result => {
             setReceipt(result);
             setItems(result?.productGift);
             setItemsReceiptProduct(result?.receiptGiftProduct);
             setContainerNo(result?.containerNo);
             setDescription(result?.description);
             form.setFieldsValue({ locator: result?.locators?.id });
             setLoading(false);
           })
           .catch((e) => setLoading(false));
         message.success(lang.updateSuccessfully);
         return true;
       } catch (error) {
         message.error(error.message);
         setLoading(false);
         return false;
       }
     }}
   >
     {lang.btnSave}
   </Button>,
 'TEMPORARYCONFIRM' === receipt?.status && (isIDM || isStocker ) && <ModalForm
 title="Hủy phiếu nhập kho"
 trigger={
   <Button
     key="btn-cancel"
     type="primary"
     ghost
   >
     {lang.btnCancel + ' Phiếu'}
   </Button>
 }
 form={formCancel}
 autoFocusFirstInput
 modalProps={{
   destroyOnClose: true,
   // onCancel: () => console.log('run'),
 }}
 onFinish={async (values) => {
   try {
     setLoading(true);
   if (receipt.status === 'TEMPORARYCONFIRM' ) {
       receipt.description = values?.description;
       receipt.status = 'NEW';
       delete receipt.productGift;
       delete receipt.receiptGiftProduct;
       delete receipt.locators;
       await updateReceiptGiftProduct(receipt);
       receiptGiftDetail({ id: parseInt(id) })
      .then(result => {
        setReceipt(result);
        setItems(result?.productGift);
        setItemsReceiptProduct(result?.receiptGiftProduct);
        setContainerNo(result?.containerNo);
        setDescription(result?.description);
        form.setFieldsValue({ locator: result?.locators?.id })
        // form.setFieldsValue({ locator: result?.warehouse });
        setLoading(false);
      })
      .catch((e) => setLoading(false));
         message.success('Hủy phiếu thành công!')
         navigate(-1)
       return true;
     } 
   } catch (error) {
     message.err(error.message);
     setLoading(true);
     return false;
   }
 }}
 key="btn-cancel"
>
 <ProFormTextArea
   name="description"
   label={lang.description}
   placeholder={lang.description}
 />
</ModalForm>,

 'NEW' === receipt?.status &&  isInboundStaff && <ModalForm
title="Hủy phiếu nhập kho"
trigger={
  <Button
    key="btn-cancel"
    type="primary"
    ghost
  >
    {lang.btnCancel + ' Phiếu'}
  </Button>
}
form={formCancel}
autoFocusFirstInput
modalProps={{
  destroyOnClose: true,
  // onCancel: () => console.log('run'),
}}
onFinish={async (values) => {
  console.log(receipt.id)
    setLoading(true);
    try {
      await deleteReceiptGift(receipt.id)
      message.success('Hủy phiếu thành công!')
      navigate(-1)
    } catch (error) {
      setLoading(false);
      navigate(-1)
      message.error(error)

    }
  }
}
key="btn-cancel"
>
<ProFormTextArea
  name="description"
  label={lang.description}
  placeholder={lang.description}
/>
</ModalForm>,
    (isIDM || isStocker) && <Button
    // disabled={receipt?.products?.length === 0 && receipt?.products.filter(item => item?.locator?.area.warehouseName === 'Suspense')?.length === 0}
    disabled={receipt?.products?.length === 0}
    key="btn-export-ktclhn"
    type="primary"
    ghost
    loading={loadingExportKTCLHN}
    onClick={async () => {
      setLoadingExportKTCLHN(true);
      // eslint-disable-next-line new-cap
      // const dataRecipetPro = receipt?.receiptGiftProduct.find(item => item?.locator?.area.warehouseName === 'Suspense');
      let content = `
      <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
  <div style="display:flex">
    <div style=" margin-top: 20px; line-height: 1; width: 20%;">
      <div style="text-align:center; border-image: initial;">
        <img src="${logog3A}" style=" height: 100%; width: 100%" />
      </div>
    </div>
    <div style="width: 80%"></div>
    <div style="width: 20%; margin-top: 20px; ">
      <div style="text-align:left; margin-left:20px; font-weight: bold; border: 2px solid #333;">
        <span>Code: P-85-01-F3</span><br>
        <span>Issue date: </span><br>
        <span>No: </span>
      </div>
    </div>
  </div>
  <div style="margin-top: -40px; margin-bottom: 15px">
    <div style="text-align: center; line-height: 1.2;">
      <div style="font-size: 25px; font-weight:bold; ">GIFT/ POSM CHECKLIST <br> PHIẾU KIỂM TRA QUÀ TẶNG/ VẬT TƯ KHUYẾN MÃI </div>
    </div>
  </div>

  <div>
    <div style="display: flex;">
      <div style="width: 50%;"><p>Tên sản phẩm/ Item: <span style="font-weight: bold">${receipt?.receiptGiftProduct[0]?.bomGift?.name ? receipt?.receiptGiftProduct[0]?.bomGift?.name : ''}</span></p></div>
      <div style="width: 50%;"><p>Đơn vị tính/ Unit:<span style="font-weight: bold"> ${receipt?.receiptGiftProduct[0]?.bomGift?.UOM ? receipt?.receiptGiftProduct[0]?.bomGift?.UOM : ''}</span></p></div>
    </div>
    <p>Số QC trên Gift/ POSM: <span style="font-weight: bold"></span></p>
    <p>Số QC trên file QC đã được RA duyệt/ Hướng dẫn dán nhãn: <span style="font-weight: bold"></span></p>
    <p>(Tham chiếu thông tin trên file QC đã được RA duyệt để kiểm tra hàng thực nhận): <span style="font-weight: bold"></span></p>
    <p>Số lượng nhập/ Received Qty: <span  style="font-weight: bold">${receipt?.receiptGiftProduct[0]?.quantity ? receipt?.receiptGiftProduct[0]?.quantity : ''}</span></p>
    <div style="display: flex;">
      <div style="width: 50%;"><p>Ngày nhận/ Receiving date: <span style="font-weight: bold">${receipt?.movementDate ? moment(receipt?.movementDate, formatDateFull).format(formatDate) : ''}</span></p></div>
      <div style="width: 50%;"><p>Số lượng kiểm mẫu (2 mẫu/ pallet):<span style="font-weight: bold"></span></p></div>
    </div>
  </div>
    
    <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
      <thead>
        <tr>
          <td style="text-align: center;" rowspan="2">No.<br>STT</td>
          <td style="text-align: center;" rowspan="2">Job Description<br>Mô tả công việc</td>
          <td style="text-align: center;" colspan="2">Compliance status<br>Mức độ tuân thủ</td>
          <td style="text-align: center;" rowspan="2">Defected quantity Số lượng lỗi</td>
          <td style="text-align: center;" rowspan="2">Actual quantity Số lượng thực nhận</td>
          <td style="text-align: center;" rowspan="2">Remark<br>Ghi chú</td>
        </tr>
        <tr>
          <td style="text-align: center;">Yes(Có)</td>
          <td style="text-align: center;">No(Không)</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Rách hoặc nhàu nát, bể vỡ, bung keo, rách đường nối (đồ nhồi bông, gối bông, ép hơi,...)/ Damaged or crumpled</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>2</td>
          <td>Bẩn/ Dirty (đính keo, loang mực/ màu in, bụi bẩn,…)</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>3</td>
          <td>Các thiết bị điện tử, cân, đồng hồ...hoạt động tốt</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>4</td>
          <td>Màu sắc (nhạt, lem, đậm) so với QC file</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>5</td>
          <td>Độ dính keo dán (của sticker/ decal- nếu có)</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>6</td>
          <td>Sai số lượng/ Wrong quantity</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>7</td>
          <td>Sai nội dung so với mẫu trong file QC Incorrect in formation compare with QC file</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>8</td>
          <td>Có lẫn các sản phẩm khác bên trong kiện hàng/Wrong product inside the shipper</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>9</td>
          <td>Kích thước khác so với Hướng dẫn dán nhãn (nếu có)</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div style="padding: 10px;">
      <div style="display: flex;">
        <div style="width: 100%;">
          <div style="display: flex">
            <div style="width: 50%">
              <b>* Đồng ý nhận/ Accepted (pass to VAS)</b>
            </div>
            <div style="width: 50%">
              <input type="checkbox" style="width: 50%;"><br>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 50%; padding-top: 20px">
              <b>* Giữ lại để báo cho nhà thầu/ phòng Marketing</b>
            </div>
            <div style="width: 50%; padding-top: 20px">
              <input type="checkbox" style="width: 50%;"><br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p>Quarantine for approval from Supplier/ Marketing,…</p>
      <p>Ngày kiểm/ Checking date:</p>
      <p>Người kiểm/ Quality checker:</p>
    </div>
  </div>
</div>`
        
      const pdf = new jsPdf('p', 'mm', 'a4');
      const template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PKTQT/VTKM${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
      setLoadingExportKTCLHN(false);
    }}
  >
    {'Xuất PKTQT/VTKM'}
  </Button>,
  (isIDM || isStocker) && <Button
  disabled={receipt?.productGift?.length === 0}
  key="btn-export-pdf"
  type="primary"
  ghost
  loading={loadingExportPNK}
  onClick={async () => {
    setLoadingExportPNK(true);
    // eslint-disable-next-line new-cap
    const pdf = new jsPdf('p', 'mm', 'a4');
    const listProduct = receipt?.productGift.reduce((prev, curr) => {
      const finded = receipt?.productGift?.find(pro => pro?.bomGift?.id === curr?.bomGift?.id);
      const find = prev?.find(pre => pre?.some(pr => pr?.locator?.locator === curr?.locator?.locator));
      if (find) {
        find.push({
          ...curr,
          warehouse: finded?.warehouse,
        });
        return prev;
      }
      return [...prev, [{
        ...curr,
        warehouse: finded?.warehouse,
      }]];
    }, []);
    await listProduct.forEach((product, index) => {
      var content = '';
      if (product?.[0]?.locator) {
        content = `
        <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif; borderBottom: 1px solid #333; paddingBottom: 30px">
          <div style="display:flex">
            <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 70%; ">
              <div>
                <p>
                  <h4>CÔNG TY TRÁCH NHIỆM HỮU HẠN DINH DƯỠNG 3A (VIỆT NAM)</h4>
                </p>
              </div>    
              <div>
                <p>
                  <span style="font-weight: bold;">Địa chỉ</span>: Centec Towe, 72-74, Nguyễn Thị Minh Khai, Phường Võ Thị Sáu, Q.3, TP Hồ Chí Minh.
                </p>
              </div>
          </div>
          <div style="margin-top: 20px; text-align:center; margin-left:20px; padding: 10px 0; line-height: 1; width: 30%; font-weight: bold">
            <div>
              <p>Mẫu số 01 - VT</p>
            </div>
            <div>
              <p>(Ban hành theo QĐ số 15/2006/QĐ-BTC <br> Ngày 20/03/2006 của Bộ trưởng BTC)</p>
            </div>
          </div>
          </div>
          <div style="display: flex; min-height: 120px;">
            <div style="text-align:center; width: 20%; border-image: initial; margin-top: -60px">
              <img src="${logog3A}" style=" height: 100%; width: 100%" />
            </div>
            <div style="width: 60%; text-align: center; line-height: 1.2; margin-top: -10px">
              <div style="font-size: 30px; font-weight:bold; ">PHIẾU NHẬP KHO</div>`;

        const dateTime = new Date();
        const date = dateTime.getDate();
        const month = (dateTime.getMonth() + 1);
        const year = (dateTime.getFullYear());

        content += `
                  <div style="text-align: center;">${moment(receipt?.createDate, formatDateFull).format(formatDate )}</div>
                  `;
        content += `
                <div style="text-align: center;">Số: ${receipt?.code}</div>
              </div>
            </div>'
        
          <div style="margin-bottom: 10px; font-weight: bold">
            
            <div style="display: flex; margin-top: 10px">
              <div style = "width: 40%; display: flex; height: 100%;">
                <div style="width: 60%;">Họ và tên người giao: </div>
                <div style="border-bottom: 1px solid; width: 90%;"></div>
              </div>
              
            </div>
            <div style="display: flex; margin-top: 10px">
              <div style = "width: 100%; display: flex; height: 100%;">
                <div style="width: 20%;">Diễn giải: ${receipt?.description ? receipt?.description : ''}</div>
                <div style="width: 80%">${''}</div>
              </div>
            </div>
            <div style="display: flex; margin-top: 10px;">
              <div style = "width: 100%; display: flex; height: 100%;">
                <div style="width: 20%;">Nhập tại kho: </div>
                <div style="width: 80%;">${product?.[0]?.locatorFrom && receipt?.inboundkind === 'IMPORT' ? product?.[0]?.locatorFrom?.locatorName : product?.[0]?.locator?.locatorName} - ${product?.[0]?.locatorFrom && receipt?.inboundkind === 'IMPORT' ? product?.[0]?.locatorFrom?.locator : product?.[0]?.locator?.locator}</div>
              </div>
            </div>
            <div style="display: flex; margin-top: 10px;">
              <div style = "width: 100%; display: flex; height: 100%;">
                <div style="width: 10%">Địa chỉ:</div>
                <div style="width: 90%; margin-left: 20px">${receipt?.receiptGiftProduct[0]?.warehouse?.address }</div>
              </div>
            </div>
          </div>
    
          <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
            <thead>
              <tr style="text-align:center; font-weight: bold;">
                  <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên, nhãn hiệu, qui cách, phẩm chất vật tư, dụng cụ SP, hàng hóa</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px;">Số lượng</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px;">Mô tả</td>
                </tr>
            </thead>
            <tbody>`;
        let totalQuantity = 0
        const listPro = product.reduce((prev, curr) => {
          const fine = prev?.find((item) => item?.bomGift.code === curr?.bomGift.code)
          totalQuantity += curr.quantity;
          if (fine) {
            fine.quantity += curr?.quantity;
            return prev;
          }
          return [...prev, curr];
        }, [])
        listPro.forEach((pro, indexpro) => {
          return content += `
                <tr>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + indexpro}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.bomGift.code} - ${pro.bomGift.name}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantity}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro?.description ? pro?.description : ''}</td>
                </tr>
              `;

        })
        content += `<tr>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">Total</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantity}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                </tr>
        </tbody>
          </table>
          <div style="display: flex; padding-top: 100px">
            <div style="width: 25%"></div>
            <div style="width: 25%"></div>
            <div style="width: 25%"></div>
            <div style="width: 25%">
              <p >Ngày  ${date}  tháng  ${month}  năm  ${year}</p>
            </div>
          </div>
          <div style="display: flex;">
            <div style="width: 25%">
              <div><p></p></div>
              <p style="font-weight: bold;">Người lập phiếu</p>
              <p>(Ký, họ tên)</p>
            </div>
            <div style="width: 25%">
              <div><p></p></div>
              <p style="font-weight: bold;">Người giao hàng</p>
              <p>(Ký, họ tên)</p>
            </div>
            <div style="width: 25%">
              <div><p></p></div> 
              <p style="font-weight: bold;">Thủ kho</p>
              <p>(Ký, họ tên)</p>
            </div>
            <div style="width: 25%; padding-top: 12px;">
              <p style="font-weight: bold; margin-left: 15px">Kế toán trưởng</p>
              <p style="margin-left: 18px">(Ký, họ tên)</p>
            </div>
          </div>
         
        </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          if (index < listProduct?.length - 1) {
            pdf.addPage();
          }
          if (index === listProduct?.length - 1) {
            pdf.save(`PhieuNhapKho${moment().format('DDMMYYYY')}.pdf`);
          }
        });
        document.body.removeChild(template);
      }
    });
    setLoadingExportPNK(false);
  }}
>
  {'Xuất PNK'}
</Button>,
    ((receipt?.status === 'NEW' && isStocker) || (receipt?.status === 'TEMPORARYCONFIRM' && isIDM)) && <Button
      key="btn-complete"
      type="primary"
      disabled={items?.length === 0 }
      // loading={loading}
      onClick={async () => {
        try {
          Modal.confirm({
            title: 'Bạn chắc chắc muốn xác nhận?',
            async onOk() {
              if (isStocker) {
                await updateReceiptGiftProduct({id: parseInt(id), status: 'TEMPORARYCONFIRM', warehouseId: currentWarehouse?.id});
                message.success(lang.confirmSuccessfully);
                navigate('/dashboard/inbound/gift-receipt');
              } else if (isIDM){
                await receiptGiftConfirm(parseInt(id));
                message.success(lang.confirmSuccessfully);
                navigate('/dashboard/inbound/gift-receipt');
              }
            },
            onCancel() {}
          })
          
        } catch (error) {
          if (error?.message) {
            message.error(error.message);
          }
        }
      }}
    >
      {lang.btnConfirm}
      <CheckOutlined />
    </Button>,
  ];
  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/gift-receipt',
      breadcrumbName: lang.receipt,
    },
    {
      path: '',
      breadcrumbName: 'Chi tiết Phiếu nhập kho quà',
    },
  ];
  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={'Chi tiết Phiếu nhập kho quà'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions className="descriptions-class" column={{ xl: 4, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label={lang.receiptCode}>{receipt?.code}</Descriptions.Item>
          <Descriptions.Item label={'Mã PO'}>{receipt?.purchaseOrder?.documentNo}</Descriptions.Item>
          <Descriptions.Item label={lang.inboundKind}>{lang[`RC${receipt?.inboundkind}`]}</Descriptions.Item>
            {receipt?.status === 'RECEIVE' && <Descriptions.Item label={'Đồng bộ ERP'}>{receipt?.statusERP}</Descriptions.Item>}
            
            <Descriptions.Item label={lang.status + ' phiếu'}>{
            receipt?.status === 'NEW' ? 'Mới tạo' : 
            receipt?.status === 'TEMPORARYCONFIRM' ? 'Thủ kho xác nhận' :
            receipt?.status === 'DELETED' ? 'Đã Hủy' : 'IDM xác nhận' 
            
            }</Descriptions.Item>
            <Descriptions.Item label={lang.status + ' nhập hàng'}>{
            receipt?.statusProduct  === 'DELETED' ? 'Đã Hủy' :
            receipt?.statusProduct === 'NEW' ? 'Mới tạo' : 
            receipt?.statusProduct === 'INPROGRESSTEMP' ? 'Đang nhập kho' :
            'Đã nhập kho' 
            }</Descriptions.Item>
            {/* <Descriptions.Item label={'Người tạo'}>{receipt?.creator}</Descriptions.Item> */}
            <Descriptions.Item label={'Ngày nhận'}>{receipt?.createDate ? moment(receipt?.createDate).format(formatDate) : undefined}</Descriptions.Item>
            <Descriptions.Item label={lang.businessPartner}>{receipt?.businessPartner}</Descriptions.Item>
            <Descriptions.Item>
            <Form form={form} style={{ width: '80%' }} >
              <ProFormSelect style={{ fontWeight: 600, width: '100%' }}
                disabled={receipt?.status === 'RECEIVE' || (receipt?.status === 'TEMPORARYCONFIRM' && (isStocker || isIDM))}
                name="locator" 
                label={lang.Locator}
                placeholder={lang.Locator}
                rules={[
                  {
                    required: true,
                    message: lang.requiredLocator,
                  },
                ]}
                // onChange={() => setCheckLocator(true)}
                options={
                  locatorsList?.map(locator => {
                    return {
                      value: locator.id,
                      label: `${locator.locatorName} - ${locator.locator}`,
                    };
                })}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                }
              />
            </Form>
            </Descriptions.Item>
            <Descriptions.Item label={'Mô tả'}>
              <Input style={{ width: '80%' }} disabled={receipt?.status === 'RECEIVE' || (receipt?.status === 'TEMPORARYCONFIRM' && (isStocker || isIDM))}
                name='description' onChange={(val) => setDescription(val.target.value)} value={description}></Input>
            </Descriptions.Item>
            <Descriptions.Item label={'ContainerNo'}
            >
              <Input disabled={receipt?.status === 'RECEIVE' || (receipt?.status === 'TEMPORARYCONFIRM' && (isStocker || isIDM))} name='container' onChange={(val) => setContainerNo(val.target.value)} style={{ width: '80%' }} value={containerNo}></Input>
            </Descriptions.Item>
            {
              receipt?.inboundkind === 'BRANCH' &&  <Descriptions.Item label={'Nhân viên lái xe'}>{receipt?.driverName}</Descriptions.Item>
            }
          </Descriptions>
        </Card>
        <TableCustom
          title={() =>
            <Row>
              <Col span={12}><h3>{lang.productList}</h3></Col>
              <Col span={12} style={{ textAlign: 'right' }}></Col>
            </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsReceiptProduct}
          dataSource={itemsReceiptProduct}
          pagination={false}
          scroll={{ x: 2400 }}
        />
       {receipt?.status !== 'DELETED' ?<ProTableCusTom
          headerTitle={lang.productListDivided}
          toolBarRender={() => [
            <Button
              key="btn-delete"
              type="primary"
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Bạn chắc chắn muốn xóa sản phẩm?',
                  icon: <ExclamationCircleOutlined />,
                  async onOk() {
                    try {
                      setLoading(true)
                      const totalProductSelected = items?.length
                      await deleteProductsGift({ ids: selectedRowKeys, totalProductSelected: totalProductSelected });
                      await receiptGiftDetail({ id: parseInt(id) })
                      .then(result => {
                        setReceipt(result);
                        setItems(result?.productGift);
                        setItemsReceiptProduct(result?.receiptGiftProduct);
                        form.setFieldsValue({ locator: result?.locators?.id })
                        setLoading(false);
                      })
                      .catch((e) => setLoading(false));
                      setSelectedRowKeys([]);
                      message.success(lang.deleteSuccessfully);
                      return true;
                    } catch (error) {
                      message.error(error.message);
                      return false;
                    }
                    
                  },
                  onCancel() {}
                })
              }}
              disabled={selectedRowKeys.length === 0 || !isInboundStaff}
            >
              {lang.btnDelete}
            </Button>,
            <Button
              key="btn-print"
              type="primary"
              ghost
              onClick={() => {
                setPrintProducts(items);
                setIsPrintModal(true);
              }}
              disabled={selectedRowKeys.length === 0 || !isInboundStaff}
            >
              {lang.print}
            </Button>,
            <Button
              key="btn-updatelocation"
              type="primary"
              onClick={() => {
                console.log(items)
                const productIds = items?.filter(item => !item?.rack).map(item => item.id);
                productGiftUpdateLocation({ productIds })
                  .then(result => {
                    Modal.success({
                      title: lang.updateLocationSuccessfully,
                      icon: <CheckOutlined />,
                      onOk() {
                        setLoading(true);
                        receiptGiftDetail({ id: parseInt(id) })
                          .then(result => {
                            setItems(result?.productGift.map(product => {
                              if (items.map(item => item.id).includes(product.id)) {
                                const find = items.find(x => x.id === product.id);
                                return {
                                  ...product,
                                  totalQuantity: find?.totalQuantity,
                                };
                              } else {
                                return product;
                              }
                            }));
                            setLoading(false);
                          })
                          .catch((e) => setLoading(false));
                      },
                    });
                  })
                  .catch((e) => message.error(e.message));
              }}
              disabled={!isInboundStaff || items?.rack != null ||items.status === "DELETED"}
            >
              {lang.updateLocation}
              <TableOutlined />
            </Button>]}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            pageSize: 10,
          }}
          scroll={{ x: 2500 }}
          rowSelection={rowSelection}
        />:""} 
      </Layout.Content>
      <Modal
        visible={isEditModalReceiptProduct}
        title={lang.updateProduct}
        onCancel={() => setIsEditModalReceiptProduct(false)}
        destroyOnClose={true}
        width={768}
        centered
        footer={null}
        wrapClassName="receipt-product-pallet-edit-modal"
      >
        <ReceiptGiftProductDetail
          item={receiptProduct}
          // items={prosuctAllStatus}
          onCallback={async (value) => {
            try {
              setLoading(true);
              await form.validateFields().catch(err => {
                throw new Error(err?.errorFields?.[0]?.errors?.[0]);
              });
              if (value.balletNumber > 0) {
                if (!containerNo) throw new Error(lang.requiredContainerNo);

                if (receipt?.locators === null && items?.length === 0) {
                  throw new Error('Locator và Container chưa lưu lại!');
                 } 
                else  {
                  await createProductGiftbyPallet({
                    warehouseId: currentWarehouse.id,
                    balletNumber: value.balletNumber,
                    codeGift: receiptProduct?.bomGift?.code,
                    shipment: receiptProduct?.shipment,
                    lineNo: receiptProduct?.lineNo,
                    orderReference: receiptProduct?.orderReference,
                    contractRefNo: receiptProduct?.contractRefNo,
                    description: receiptProduct?.description,
                    movementDate: receiptProduct?.movementDate,
                    quantity: value?.quantitied > 0 ? value?.quantitied : value?.quantity,
                    receiptGiftId: parseInt(id),
                    locatorCode: receipt?.locators?.locator,
                    documentNo: receiptProduct?.documentNo,
                    receiptGiftProductId: receiptProduct.id
                  });
                  // setCheckLocator(false)
                  message.success(lang.createSuccessfully);
                  setIsEditModalReceiptProduct(false);
                  await receiptGiftDetail({ id: parseInt(id) })
                  .then(result => {
                    setReceipt(result);
                    setItems(result?.productGift);
                    setItemsReceiptProduct(result?.receiptGiftProduct);
                  })
                  .catch((e) => setLoading(false));
                  setLoading(false);
                }
                setLoading(false);
              } 
              setLoading(false);
            } catch (error) {
              message.error(error.message);
              setLoading(false);
              return false;
            }
          }}
          onCancel={() => setIsEditModalReceiptProduct(false)}
        />
      </Modal>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => setIsPrintModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptGiftProductPrint
          items={printProducts}
          containerNo={receipt?.containerNo}
          warehouseName={receipt?.receiptGiftProduct[0]?.warehouse?.name}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.rack.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/receipt');
              setIsClose(false);
            }
          }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/receipt');
              setIsClose(false);
            }
          }}
          onCancel={() => {
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/receipt');
              setIsClose(false);
            }
          }}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptDetail;

