export const getLanguages = (t) => {
  return {
    login: t('login'),
    password: t('password'),
    slogan: t('slogan'),
    akaName: t('akaName'),
    akaDescription: t('akaDescription'),
    messagePassword: t('messagePassword'),
    placeholderPassword: t('placeholderPassword'),
    placeholderUsername: t('placeholderUsername'),
    messageUsername: t('messageUsername'),
    loading: t('loading'),

    //layout
    inbound: t('inbound'),
    receipt: t('receipt'),
    receiptGift: t('receiptGift'),
    receiptMaterial: t('receiptMaterial'),
    maneuveringCommand: t('maneuveringCommand'),
    maneuveringCommandGift: t('maneuveringCommandGift'),
    inventoryManagementChanel: t('inventoryManagementChanel'),
    temporaryInbound: t('temporaryInbound'),
    storing: t('storing'),
    inboundControl: t('inboundControl'),
    inventory: t('inventory'),
    warehouseState: t('warehouseState'),
    reallocation: t('reallocation'),
    productManagement: t('productManagement'),
    outbound: t('outbound'),
    pickingOrder: t('pickingOrder'),
    packaging: t('packaging'),
    shippingOutboundControl: t('shippingOutboundControl'),
    configuration: t('configuration'),
    supplier: t('supplier'),
    product: t('product'),
    productCategory: t('productCategory'),
    location: t('location'),
    replenishment: t('replenishment'),
    saleOrder: t('saleOrder'),
    exportWarehouse: t('exportWarehouse'),
    createExportWarehouse: t('createExportWarehouse'),
    reporting: t('reporting'),
    auditOrder: t('auditOrder'),
    inventoryWarning: t('inventoryWarning'),
    performance: t('performance'),
    changePassword: t('changePassword'),
    logout: t('logout'),
    confirm: t('confirm'),
    confirmCancelDO: t('confirmCancelDO'),
    cancelDO: t('cancelDO'),
    confirmLogout: t('confirmLogout'),
    warehouse: t('warehouse'),
    user: t('user'),
    admin: t('admin'),

    //inbound/receipt
    receiptId: t('receiptId'),
    receiptCode: t('receiptCode'),
    exportCode: t('exportCode'),
    inboundKind: t('inboundKind'),
    status: t('status'),
    receiptDate: t('receiptDate'),
    receiveDate: t('receiveDate'),
    from: t('from'),
    to: t('to'),
    creator: t('creator'),
    keyword: t('keyword'),
    search: t('search'),
    receiptList: t('receiptList'),
    doYouWantToDeleteTheseItems: t('doYouWantToDeleteTheseItems'),
    deleteReceiptsSuccessfully: t('deleteReceiptsSuccessfully'),
    receiptIdsHasBeenDeleted: t('receiptIdsHasBeenDeleted'),

    //inbound
    productCode: t('productCode'),
    productName: t('productName'),
    expectedQuantity: t('expectedQuantity'),
    cost: t('cost'),
    salePrice: t('salePrice'),
    expireDate: t('expireDate'),
    createReceipt: t('createReceipt'),
    createReceiptSuccessfully: t('createReceiptSuccessfully'),
    pleaseSelectAInboundKind: t('pleaseSelectAInboundKind'),
    messageReceiptDate: t('messageReceiptDate'),
    messageReceiveDate: t('messageReceiveDate'),
    productAdded: t('productAdded'),
    importCSV: t('importCSV'),
    pleaseSelecteAProduct: t('pleaseSelecteAProduct'),
    messageExpectedQuantity: t('messageExpectedQuantity'),
    messageCost: t('messageCost'),
    totalQuantity: t('totalQuantity'),
    totalQuantityTins: t('totalQuantityTins'),
    TotalQuantityTinOdd: t('TotalQuantityTinOdd'),
    zone: t('zone'),
    block: t('block'),
    shelf: t('shelf'),
    rack: t('rack'),
    confirmReceiptSuccessfully: t('confirmReceiptSuccessfully'),
    receiptDetail: t('receiptDetail'),
    productList: t('productList'),
    receiptGifttList: t('receiptGifttList'),
    updateLocationSuccessfully: t('updateLocationSuccessfully'),
    updateLocation: t('updateLocation'),
    print: t('print'),
    messageTotalQuantity: t('messageTotalQuantity'),
    importDate: t('importDate'),
    report: t('report'),
    scanReturnProduct: t('scanReturnProduct'),
    reportErrorProduct: t('reportErrorProduct'),
    reportSuccessfully: t('reportSuccessfully'),
    reportContent: t('reportContent'),
    messageReportContent: t('messageReportContent'),
    btnSubmit: t('btnSubmit'),
    inputCode: t('inputCode'),
    packageCode: t('packageCode'),
    locationCode: t('locationCode'),
    completeScanReturnProductSuccessfully: t('completeScanReturnProductSuccessfully'),
    scanReturnProductComplete: t('scanReturnProductComplete'),
    scanMore: t('scanMore'),
    scan: t('scan'),
    storingDate: t('storingDate'),
    scanProduct: t('scanProduct'),
    storingScanProduct: t('storingScanProduct'),
    completeScanStoringSuccessfully: t('completeScanStoringSuccessfully'),
    storingScanProductComplete: t('storingScanProductComplete'),
    inboundControlDate: t('inboundControlDate'),
    inboundControlScanProduct: t('inboundControlScanProduct'),
    completeInboundControlScanSuccessfully: t('completeInboundControlScanSuccessfully'),
    inboundControlScanProductComplete: t('inboundControlScanProductComplete'),

    //admin
    username: t('username'),
    name: t('name'),
    role: t('role'),
    users: t('users'),
    requiredUsername: t('requiredUsername'),
    requiredPassword: t('requiredPassword'),
    requiredName: t('requiredName'),
    requiredRole: t('requiredRole'),
    requiredWarehouse: t('requiredWarehouse'),
    warehouseCode: t('warehouseCode'),
    warehouseName: t('warehouseName'),
    address: t('address'),
    warehouses: t('warehouses'),
    acreage: t('acreage'),

    btnCancel: t('btnCancel'),
    btnComplete: t('btnComplete'),
    btnAdd: t('btnAdd'),
    btnEdit: t('btnEdit'),
    btnConfirm: t('btnConfirm'),
    btnDelete: t('btnDelete'),
    btnSave: t('btnSave'),
    confirmQuestion: t('confirmQuestion'),
    confirmContent: t('confirmContent'),

    addZone: t('addZone'),
    addShelf: t('addShelf'),
    addBlock: t('addBlock'),
    zoneDetail: t('zoneDetail'),
    zoneCreate: t('zoneCreate'),
    zoneName: t('zoneName'),
    zoneAreDeletedSuccessfully: t('zoneAreDeletedSuccessfully'),
    blockAreDeletedSuccessfully: t('blockAreDeletedSuccessfully'),
    shelfAreDeletedSuccessfully: t('shelfAreDeletedSuccessfully'),
    areYouSureToDeleteThisZone: t('areYouSureToDeleteThisZone'),
    areYouSureToDeleteThisBlock: t('areYouSureToDeleteThisBlock'),
    areYouSureToDeleteThisShelf: t('areYouSureToDeleteThisShelf'),

    //Product category
    placeholderName: t('placeholderName'),
    placeholderSelectMethod: t('placeholderSelectMethod'),
    requiredCategoryName: t('requiredCategoryName'),
    requiredMethod: t('requiredMethod'),

    //Supplier
    placeholderSelectProductCate: t('placeholderSelectProductCate'),
    placeholderAddress: t('placeholderAddress'),
    placeholderPhoneNumber: t('placeholderPhoneNumber'),
    requiredCategory: t('requiredCategory'),
    requiredSupplierName: t('requiredSupplierName'),
    requiredEmail: t('requiredEmail'),
    requiredAddress: t('requiredAddress'),
    requiredPhoneNumber: t('requiredPhoneNumber'),

    //Product
    placeholderSelectSupplier: t('placeholderSelectSupplier'),
    placeholderCapacity: t('placeholderCapacity'),
    requiredImage: t('requiredImage'),
    requiredProductName: t('requiredProductName'),
    requiredSupplier: t('requiredSupplier'),
    requiredCapacity: t('requiredCapacity'),
    requiredStoreTime: t('requiredStoreTime'),

    //Replenishment
    placeholderMin: t('placeholderMin'),
    placeHolderMax: t('placeHolderMax'),
    requiredMin: t('requiredMin'),
    requiredMax: t('requiredMax'),
    placeholderSelectProduct: t('placeholderSelectProduct'),
    requiredProduct: t('requiredProduct'),

    //Location
    requiredZoneName: t('requiredZoneName'),
    requiredBlockName: t('requiredBlockName'),
    placeholderTotalshelf: t('placeholderTotalshelf'),
    requiredTotalshelf: t('requiredTotalshelf'),
    requiredShelfName: t('requiredShelfName'),
    placeholderTotalRack: t('placeholderTotalRack'),
    placeholderLevelPercent: t('placeholderLevelPercent'),
    requiredLevelPercent: t('requiredLevelPercent'),
    requiredTotalRack: t('requiredTotalRack'),

    //Sale order
    placeholderQuantity: t('placeholderQuantity'),
    requiredQuantity: t('requiredQuantity'),
    capacity: t('capacity'),
    success: t('success'),
    blockDetail: t('blockDetail'),
    blockCreate: t('blockCreate'),
    btnUpdate: t('btnUpdate'),
    btnCreate: t('btnCreate'),
    btnSearch: t('btnSearch'),
    blockName: t('blockName'),
    totalShelf: t('totalShelf'),
    updateShelf: t('updateShelf'),
    createShelf: t('createShelf'),
    update: t('update'),
    complete: t('complete'),
    shelfName: t('shelfName'),
    capacityRack: t('capacityRack'),
    levelPercent: t('levelPercent'),
    medium: t('medium'),
    high: t('high'),
    code: t('code'),
    createSuccessfully: t('createSuccessfully'),
    updateSuccessfully: t('updateSuccessfully'),
    confirmSuccessfully: t('confirmSuccessfully'),
    deleteSuccessfully: t('deleteSuccessfully'),
    idsHasBeenDeleted: t('idsHasBeenDeleted'),
    email: t('email'),
    orderCode: t('orderCode'),
    userName: t('userName'),
    orderDate: t('orderDate'),
    orderQuantity: t('orderQuantity'),
    takeQuantity: t('takeQuantity'),
    pickingquantity: t('pickingquantity'),
    placeholderSelectOrderCode: t('placeholderSelectOrderCode'),
    titlePicking: t('titlePicking'),
    suggestLocation: t('suggestLocation'),
    quantity: t('quantity'),
    createPicking: t('createPicking'),
    labelBooking: t('labelBooking'),
    placeholderSelectOrder: t('placeholderSelectOrder'),
    okText: t('okText'),
    cancelText: t('cancelText'),
    titleModalBooking: t('titleModalBooking'),
    scanCode: t('scanCode'),
    confirmPickupList: t('confirmPickupList'),
    titleModalPslitProduct: t('titleModalPslitProduct'),
    placeholderSelectPackageCode: t('placeholderSelectPackageCode'),
    btnDone: t('btnDone'),
    btnClose: t('btnClose'),
    titleModalSuccessSuggestLocation: t('titleModalSuccessSuggestLocation'),
    pickupList: t('pickupList'),
    generatePackageCode: t('generatePackageCode'),
    orderNumber: t('orderNumber'),
    productOrderCode: t('productOrderCode'),
    pickingList: t('pickingList'),
    packagingCode: t('packagingCode'),
    tempOutLot: t('tempOutLot'),
    orderandpackagingInfo: t('orderandpackagingInfo'),
    packagingDetail: t('packagingDetail'),
    orderandshippingInfo: t('orderandshippingInfo'),
    confirmPackageList: t('confirmPackageList'),
    outQuantity: t('outQuantity'),
    startDate: t('startDate'),
    endDate: t('endDate'),
    orderInfo: t('orderInfo'),
    inStock: t('inStock'),
    expirationDate: t('expirationDate'),
    storageExpirationDate: t('storageExpirationDate'),
    exportCSV: t('exportCSV'),
    agingStockAlert: t('agingStockAlert'),
    dotRestock: t('dotRestock'),
    productDetail: t('productDetail'),
    total: t('total'),
    inventorySummary: t('inventorySummary'),
    inboundsummary: t('inboundsummary'),
    productSummary: t('productSummary'),
    action: t('action'),
    warehouseProductDetail: t('warehouseProductDetail'),
    requiredReportContent: t('requiredReportContent'),
    editLocation: t('editLocation'),
    dotEditLocation: t('dotEditLocation'),
    onlyEditLocation: t('onlyEditLocation'),
    pendingState: t('pendingState'),
    inboundState: t('inboundState'),
    inboundStateGift: t('inboundStateGift'),
    lostState: t('lostState'),
    updateProduct: t('updateProduct'),
    requiredZone: t('requiredZone'),
    requiredLocator: t('requiredLocator'),
    requiredBlock: t('requiredBlock'),
    requiredShelf: t('requiredShelf'),
    requiredRack: t('requiredRack'),
    requiredExpirationDate: t('requiredExpirationDate'),
    requiredTotalQauntity: t('requiredTotalQauntity'),
    requiredCost: t('requiredCost'),
    requiredSalePrice: t('requiredSalePrice'),
    requiredStatus: t('requiredStatus'),
    requiredLostDate: t('requiredLostDate'),

    totalPerPallet: t('totalPerPallet'),
    sepratePallet: t('sepratePallet'),
    totalPallet: t('totalPallet'),
    totalRackbetween: t('totalRackbetween'),
    backtoProductList: t('backtoProductList'),
    productInfo: t('productInfo'),
    storageTime: t('storageTime'),
    productImage: t('productImage'),
    imageExtra: t('imageExtra'),
    upload: t('upload'),
    methodExport: t('methodExport'),
    createDate: t('createDate'),
    createProduct: t('createProduct'),
    productCategoryName: t('productCategoryName'),
    productCategoryCode: t('productCategoryCode'),
    productCategoryList: t('productCategoryList'),
    updateProductCategoty: t('updateProductCategoty'),
    createProductCategoty: t('createProductCategoty'),
    minQuantity: t('minQuantity'),
    maxQuantity: t('maxQuantity'),
    notification: t('notification'),
    onHand: t('onHand'),
    toOrder: t('toOrder'),
    replenishmentList: t('replenishmentList'),
    createReplenishment: t('createReplenishment'),
    updateReplenishment: t('updateReplenishment'),
    backtoSupplierList: t('backtoSupplierList'),
    supplierInfo: t('supplierInfo'),
    phone: t('phone'),
    cooperationDate: t('cooperationDate'),
    supplierName: t('supplierName'),
    requiredCooperationDate: t('requiredCooperationDate'),
    supplierCode: t('supplierCode'),
    supplierList: t('supplierList'),
    createSupplier: t('createSupplier'),
    updateSupplier: t('updateSupplier'),
    createSaleOrder: t('createSaleOrder'),
    addProduct: t('addProduct'),
    editProduct: t('editProduct'),
    saleOrderDetail: t('saleOrderDetail'),
    confirmOrderDetail: t('confirmOrderDetail'),
    confirmOrder: t('confirmOrder'),
    orderList: t('orderList'),
    reportDate: t('reportDate'),
    firstName: t('firstName'),
    lastName: t('lastName'),
    lostDate: t('lostDate'),
    phoneLengthMessage: t('phoneLengthMessage'),
    phoneMessage: t('phoneMessage'),
    full: t('full'),
    empty: t('empty'),
    Medium: t('Medium'),
    resetPassword: t('resetPassword'),
    passwordNew: t('passwordNew'),
    currentPassword: t('currentPassword'),
    passwordConfirm: t('passwordConfirm'),
    zoneNameExist: t('zoneNameExist'),
    blockNameExist: t('blockNameExist'),
    shelfNameExist: t('shelfNameExist'),
    subTitleInboundControl: t('subTitleInboundControl'),
    subTitleOutboundCreatePickUpNotify: t('subTitleOutboundCreatePickUpNotify'),
    subTitleStoringNotify: t('subTitleStoringNotify'),
    subTitleInboundTemporaryNotify: t('subTitleInboundTemporaryNotify'),
    yes: t('yes'),
    no: t('no'),
    passwordRegex: t('passwordRegex'),
    updateImageLess: t('updateImageLess'),
    creatorName: t('creatorName'),
    invalidCode: t('invalidCode'),
    tryAgain: t('tryAgain'),
    orderStatus: t('orderStatus'),
    packageStatus: t('packageStatus'),
    noRecordsFound: t('noRecordsFound'),
    emailRequirePlaceholder: t('emailRequirePlaceholder'),
    messeageExpiration: t('messeageExpiration'),
    unlockAccount: t('unlockAccount'),
    January: t('January'),
    February: t('February'),
    March: t('March'),
    April: t('April'),
    May: t('May'),
    June: t('June'),
    July: t('July'),
    August: t('August'),
    September: t('September'),
    October: t('October'),
    November: t('November'),
    December: t('December'),
    splitproduct: t('splitproduct'),
    split: t('split'),
    splitProductList: t('splitProductList'),
    vans: t('vans'),
    addVans: t('addVans'),
    createVans: t('createVans'),
    updateVans: t('updateVans'),
    vansName: t('vansName'),
    vansLicensePlate: t('vansLicensePlate'),
    placeholderLicensePlate: t('placeholderLicensePlate'),
    requiredLicensePlate: t('requiredLicensePlate'),
    requiredVansName: t('requiredVansName'),
    vansState: t('vansState'),
    vansDetail: t('vansDetail'),
    defaultCode: t('defaultCode'),
    requiredDefaultCode: t('requiredDefaultCode'),
    placeHolderDefaultCode: t('placeHolderDefaultCode'),
    placeholderSalePrice: t('placeholderSalePrice'),
    retailPrice: t('retailPrice'),
    placeholderRetailPrice: t('placeholderRetailPrice'),
    lot: t('lot'),
    requiredLot: t('requiredLot'),
    VAT: t('VAT'),
    Divison: t('Divison'),
    Category: t('Category'),
    divisonCode: t('divisonCode'),
    divisonName: t('divisonName'),
    createDivison: t('createDivison'),
    updateDivison: t('updateDivison'),
    placeholderDivisonName: t('placeholderDivisonName'),
    requiredDivisonName: t('requiredDivisonName'),
    categoryCode: t('categoryCode'),
    categoryName: t('categoryName'),
    createCategory: t('createCategory'),
    updateCategory: t('updateCategory'),
    placeholderCategoryName: t('placeholderCategoryName'),
    requiredParentCategoryName: t('requiredCategoryName'),
    requiredDivison: t('requiredDivison'),
    requiredParentCategory: t('requiredCategory'),
    categoryList: t('categoryList'),
    divisonList: t('divisonList'),
    DVT: t('DVT'),
    isActive: t('isActive'),
    contractNumber: t('contractNumber'),
    contractNo: t('contractNo'),
    packingRule: t('packingRule'),
    NEW: t('NEW'),
    CONFIRM1: t('CONFIRM1'),
    CONFIRM2: t('CONFIRM2'),
    TEMPORARYCONFIRM: t('TEMPORARYCONFIRM'),
    INPROGRESS: t('INPROGRESS'),
    ERROR: t('ERROR'),
    TEMPORARY: t('TEMPORARY'),
    STORED: t('STORED'),
    MOVING: t('MOVING'),
    TEMPORARY_OUT: t('TEMPORARY_OUT'),
    LOST: t('LOST'),
    REALLOCATE: t('REALLOCATE'),
    ENABLE: t('ENABLE'),
    DISABLE: t('DISABLE'),
    requestReceipt: t('requestReceipt'),
    note: t('note'),
    itemCode: t('itemCode'),
    length: t('length'),
    width: t('width'),
    height: t('height'),
    RECEIVE: t('RECEIVE'),
    dowloadTemplate: t('dowloadTemplate'),
    importSuccess: t('importSuccess'),
    exportPDF: t('exportPDF'),
    discount: t('discount'),
    isResources: t('isResources'),
    vansCode: t('vansCode'),
    createPO: t('createPO'),
    taxCode: t('taxCode'),
    requiredPackingRule: t('requiredPackingRule'),
    CANCELLED: t('CANCELLED'),
    PICKING: t('PICKING'),
    EXPORTED: t('EXPORTED'),
    PACKAGED: t('PACKAGED'),
    deliveryTime: t('deliveryTime'),
    messeagePicking: t('messeagePicking'),
    bom: t('bom'),
    bomList: t('bomList'),
    caseUOM: t('caseUOM'),
    average: t('average'),
    wastage: t('wastage'),
    createBom: t('createBom'),
    updateBom: t('updateBom'),
    bomCode: t('bomCode'),
    codeNPL: t('codeNPL'),
    producerRule: t('producerRule'),
    cartonRule: t('cartonRule'),
    grossWeightPerCarton: t('grossWeightPerCarton'),
    cartonPerPallet: t('cartonPerPallet'),
    ABBOTT_CODE_16: t('ABBOTT_CODE_16'),
    threeA_CODE: t('threeA_CODE'),
    SHORTNAME: t('SHORTNAME'),
    NAME_EN: t('NAME_EN'),
    UOM_EN: t('UOM_EN'),
    NAME_VN: t('NAME_VN'),
    UOM_VN: t('UOM_VN'),
    FLAVOR: t('FLAVOR'),
    TIN_PER_CARTON: t('TIN_PER_CARTON'),
    BRAND: t('BRAND'),
    SKU_GROUP: t('SKU_GROUP'),
    five_KEYBRAND: t('five_KEYBRAND'),
    COUNTRY_OF_ORIGIN: t('COUNTRY_OF_ORIGIN'),
    SIZE: t('SIZE'),
    SHELF_LIFE_MONTHS: t('SHELF_LIFE_MONTHS'),
    TYPE_OF_PRODUCT: t('TYPE_OF_PRODUCT'),
    CTNS_PER_LAYER_3A: t('CTNS_PER_LAYER_3A'),
    LAYERS_PER_PALLET_3A: t('LAYERS_PER_PALLET_3A'),
    CTMS_PER_PALLET_STORAGE_3A: t('CTMS_PER_PALLET_STORAGE_3A'),
    CTNS_PER_LAYER_3A_FLOOR: t('CTNS_PER_LAYER_3A_FLOOR'),
    LAYERS_PER_PALLET_3A_FLOOR_3: t('LAYERS_PER_PALLET_3A_FLOOR_3'),
    CTNS_PER_PALLET_STORAGE_3A_FLOOR_4: t('CTNS_PER_PALLET_STORAGE_3A_FLOOR_4'),
    CTNS_PER_LAYER2_IMPORT: t('CTNS_PER_LAYER2_IMPORT'),
    LAYERS_PER_PALLET3_IMPORT: t('LAYERS_PER_PALLET3_IMPORT'),
    CTNS_PER_PALLET_IMPORT: t('CTNS_PER_PALLET_IMPORT'),
    IS_PACKAGED_SKU: t('IS_PACKAGED_SKU'),
    MILK_CODE: t('MILK_CODE'),
    SUB_BRAND_FOLLOW_ADS: t('SUB_BRAND_FOLLOW_ADS'),
    TYPEOFPRODUCT2: t('TYPEOFPRODUCT2'),
    GROSS_WEIGHT_KG_PER_CASE: t('GROSS_WEIGHT_KG_PER_CASE'),
    NET_WEIGHT_GRAM_PER_TINS: t('NET_WEIGHT_GRAM_PER_TINS'),
    NET_WEIGHT_KG_PER_CASE: t('NET_WEIGHT_KG_PER_CASE'),
    VOLUME_CM3: t('VOLUME_CM3'),
    GROSS_WEIGHT_KG_PER_PALLET: t('GROSS_WEIGHT_KG_PER_PALLET'),
    NET_WEIGHT_KG_PER_PALLET: t('NET_WEIGHT_KG_PER_PALLET'),
    LENGTH_CARTON_CM: t('LENGTH_CARTON_CM'),
    WIDTH_CARTON_CM: t('WIDTH_CARTON_CM'),
    HEIGHT_CARTON_CM: t('HEIGHT_CARTON_CM'),
    VOLUME_CARTON_CM3: t('VOLUME_CARTON_CM3'),
    BARCODE_TINS: t('BARCODE_TINS'),
    BARCODE_CARTON: t('BARCODE_CARTON'),
    TEN_NHOM_HANG: t('TEN_NHOM_HANG'),
    bomGift: t('bomGift'),
    bomGiftlist: t('bomGiftlist'),
    createBomGift: t('createBomGift'),
    updateBomGift: t('updateBomGift'),
    productBOMCode: t('productBOMCode'),
    umIsFreeItem: t('umIsFreeItem'),
    masterproductPacking: t('masterproductPacking'),
    masterproductPackingList: t('masterproductPackingList'),
    createMasterproductPacking: t('createMasterproductPacking'),
    updateMasterproductPacking: t('updateMasterproductPacking'),
    type: t('type'),
    Double: t('Double'),
    Single: t('Single'),
    handWork: t('handWork'),
    Area: t('Area'),
    areaList: t('areaList'),
    areaName: t('areaName'),
    areaCode: t('areaCode'),
    areaCreate: t('areaCreate'),
    areaUpdate: t('areaUpdate'),
    Locator: t('Locator'),
    locatorList: t('locatorList'),
    locatorName: t('locatorName'),
    locatorCode: t('locatorCode'),
    locatorCreate: t('locatorCreate'),
    locatorUpdate: t('locatorUpdate'),
    apInvoiceList: t('apInvoiceList'),
    updateApInvoice: t('updateApInvoice'),
    apInvoiceDetail: t('apInvoiceDetail'),
    productListDivided: t('productListDivided'),
    giftCode: t('giftCode'),
    giftName: t('giftName'),
    UOM: t('UOM'),
    PCSCase: t('PCSCase'),
    Specification: t('Specification'),
    lengthPCS: t('lengthPCS'),
    widthPCS: t('widthPCS'),
    heightPCS: t('heightPCS'),
    grossWeightPCS: t('grossWeightPCS'),
    volumePCS: t('volumePCS'),
    grossWeight3A: t('grossWeight3A'),
    CTNS_PER_LAYER2_VENDER: t('CTNS_PER_LAYER2_VENDER'),
    LAYERS_PER_PALLET3_VENDER: t('LAYERS_PER_PALLET3_VENDER'),
    CTNS_PER_PALLET_VENDER: t('CTNS_PER_PALLET_VENDER'),
    grossWeightVender: t('grossWeightVender'),
    isRack: t('isRack'),
    salesChannel: t('salesChannel'),
    saleChannelList: t('saleChannelList'),
    salesChannelCode: t('salesChannelCode'),
    salesChannelName: t('salesChannelName'),
    quota: t('quota'),
    shelfLife: t('shelfLife'),
    createSalesChannel: t('createSalesChannel'),
    updateSalesChannel: t('updateSalesChannel'),
    shelftype: t('shelftype'),
    quantityCartton: t('quantityCartton'),
    quantityTin: t('quantityTin'),
    totalQuantityCartton: t('totalQuantityCartton'),
    TotalQuantityTin: t('TotalQuantityTin'),
    quantityCarttonAtLocation: t('quantityCarttonAtLocation'),
    quantityTinAtLocation: t('quantityTinAtLocation'),
    quantityCarttonTaken: t('quantityCarttonTaken'),
    quantityCarttonLeft: t('quantityCarttonLeft'),
    quantityTaken: t('quantityTaken'),
    quantityLeft: t('quantityLeft'),
    quantityCarttonReceived: t('quantityCarttonReceived'),
    quantityTinReceived: t('quantityTinReceived'),
    company: t('company'),
    branch: t('branch'),
    orderReference: t('orderReference'),
    businessLine: t('businessLine'),
    abbottPOReference: t('abbottPOReference'),
    description: t('description'),
    dateOrdered: t('dateOrdered'),
    giftWarehouse: t('giftWarehouse'),
    customerProgram: t('customerProgram'),
    subChannel: t('subChannel'),
    RCRETURN: t('RCRETURN'),
    RCINTERNAL: t('RCINTERNAL'),
    RCBORROW: t('RCBORROW'),
    RCIMPORT: t('RCIMPORT'),
    returnDate: t('returnDate'),
    movementDate: t('movementDate'),
    tranferReason: t('tranferReason'),
    locatorTo: t('locatorTo'),
    movementQuantity: t('movementQuantity'),
    documentNo: t('documentNo'),
    documentType: t('documentType'),
    orderType: t('orderType'),
    consignmentOrder: t('consignmentOrder'),
    borrowed: t('borrowed'),
    enterBorrow: t('enterBorrow'),
    EXPORT_INTERNAL: t('EXPORT_INTERNAL'),
    EXPORT_INTERNAL_GIFT: t('EXPORT_INTERNAL_GIFT'),
    EXPORT_SALE: t('EXPORT_SALE'),
    EXPORT_BORROW: t('EXPORT_BORROW'),
    masterproductsbomgift: t('masterproductsbomgift'),
    createMasterproductsbomgift: t('createMasterproductsbomgift'),
    updateMasterproductsbomgift: t('updateMasterproductsbomgift'),
    masterproductsbomgiftList: t('masterproductsbomgiftList'),
    Vas: t('Vas'),
    completeVasScanSuccessfully: t('completeVasScanSuccessfully'),
    VAS: t('VAS'),
    floor: t('floor'),
    productionOrder: t('productionOrder'),
    productionOrderList: t('productionOrderList'),
    createProductionOrder: t('createProductionOrder'),
    productionOrderDetail: t('productionOrderDetail'),
    confirmProductionOrderProduct: t('confirmProductionOrderProduct'),
    createComboProduct: t('createComboProduct'),
    invoiceNo: t('invoiceNo'),
    orderNo: t('orderNo'),
    shipment: t('shipment'),
    shipmentNo: t('shipmentNo'),
    mfgDate: t('mfgDate'),
    btnlock: t('btnlock'),
    lockproductSuccessfully: t('lockproductSuccessfully'),
    picking: t('picking'),
    expectedQuantityCarton: t('expectedQuantityCarton'),
    expectedQuantityTin: t('expectedQuantityTin'),
    requiredContainerNo: t('requiredContainerNo'),
    requiredlocator: t('requiredlocator'),
    locatorFrom: t('locatorFrom'),
    receivedRemainingQuantityCarton: t('receivedRemainingQuantityCarton'),
    receivedRemainingQuantityTin: t('receivedRemainingQuantityTin'),
    orderConfirm: t('orderConfirm'),
    orderConfirmCreate: t('orderConfirmCreate'),
    inventoryManagement: t('inventoryManagement'),
    lDDNo: t('lDDNo'),
    truckType: t('truckType'),
    noPallet: t('noPallet'),
    quantityCarttonRemainder: t('quantityCarttonRemainder'),
    quantityTinRemainder: t('quantityTinRemainder'),
    warehouseZoneDetail: t('warehouseZoneDetail'),
    customerReturn: t('customerReturn'),
    customerReturnList: t('customerReturnList'),
    targetDocType: t('targetDocType'),
    rmaCategory: t('rmaCategory'),
    origSalesOrder: t('origSalesOrder'),
    origDO: t('origDO'),
    DODate: t('DODate'),
    businessPartner: t('businessPartner'),
    billTo: t('billTo'),
    partnerLocation: t('partnerLocation'),
    repressentative: t('repressentative'),
    campain: t('campain'),
    department: t('department'),
    origDOLine: t('origDOLine'),
    attributeSetInstance: t('attributeSetInstance'),
    unitPrice: t('unitPrice'),
    customerReturnCreate: t('customerReturnCreate'),
    customerReturnDetail: t('customerReturnDetail'),
    billToLocation: t('billToLocation'),
    createReceiptReturn: t('createReceiptReturn'),
    rmaDate: t('rmaDate'),
    availableProduct: t('availableProduct'),
    EXPORT_BRANCH: t('EXPORT_BRANCH'),
    EXPORT_BRANCH_GIFT: t('EXPORT_BRANCH_GIFT'),
    exportMaterial: t('exportMaterial'),
    RCBRANCH: t('RCBRANCH'),
    HOLD: t('HOLD'),
    AV: t('AV'),
    RCNEW: t('RCNEW'),
    EXPORT: t('EXPORT'),
    ENABLEHD: t('ENABLEHD'),
    COMPLETED: t('COMPLETED'),
    NORMAL: t('NORMAL'),
    GIFT: t('GIFT'),
    giftinventory: t('giftinventory'),
    inventorymaterial: t('inventorymaterial'),
    exportSpickingSlip: t('exportSpickingSlip'),
  };
};
