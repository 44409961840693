export default {
  login: 'ĐĂNG NHẬP',
  password: 'Mật khẩu',
  slogan: 'Luôn luôn dõi theo và tư vấn hỗ trợ doanh nghiệp!',
  akaName: 'HỆ THỐNG QUẢN LÝ TỐI ƯU KHO ',
  akaDescription: 'Chuyển Đổi Số (DX)',
  placeholderPassword: 'Mật khẩu',
  messagePassword: 'Vui lòng điền mật khẩu!',
  placeholderUsername: 'Tên đăng nhập',
  messageUsername: 'Vui lòng điền Tên đăng nhập',
  loading: 'Đang tải',
  inbound: 'Nhập hàng',
  receipt: 'Phiếu nhập kho',
  temporaryInbound: 'Tạm Nhập',
  storing: 'Lưu trữ',
  inboundControl: 'Lưu trữ',
  inventory: 'Kiểm kê',
  warehouseState: 'Trạng thái Kho',
  reallocation: 'Chuyển vị trí',
  productManagement: 'Quản lý Sản phẩm',
  receiptGift: 'Phiếu nhập kho quà',
  receiptMaterial: 'Phiếu nhập nguyên phụ liệu',
  maneuveringCommand: 'Lệnh điều động',
  maneuveringCommandGift: 'Lệnh điều động quà',
  inventoryManagementChanel: 'Kiểm tồn theo kênh',
  outbound: 'Xuất hàng',
  pickingOrder: 'Đơn hàng',
  packaging: 'Đóng gói',
  shippingOutboundControl: 'Quản lý xuất kho và đơn hàng',
  configuration: 'Tùy chỉnh',
  supplier: 'Nhà cung cấp',
  product: 'Sản phẩm',
  productCategory: 'Nhóm hàng',
  location: 'Vị trí',
  replenishment: 'Bổ sung Hàng',
  saleOrder: 'Đơn hàng',
  exportWarehouse: 'Đơn hàng',
  createExportWarehouse: 'Tạo phiếu xuất kho',
  reporting: 'Báo cáo',
  auditOrder: 'Kiểm toán Đơn hàng',
  inventoryWarning: 'Cảnh báo Kiểm kê',
  performance: 'Năng suất',
  changePassword: 'Đổi Mật khẩu',
  logout: 'Đăng xuất',
  confirm: 'Xác nhận',
  confirmCancelDO: 'Xác nhận hủy DO',
  cancelDO: 'Hủy DO',
  confirmLogout: 'Bạn muốn đăng xuất?',
  warehouse: 'Nhà kho',
  user: 'Người dùng',
  admin: 'Admin',
  receiptId: 'ID Phiếu nhập kho',
  receiptCode: 'Mã Phiếu nhập kho',
  exportCode: 'Mã phiếu xuất kho',
  inboundKind: 'Loại Nhập Kho',
  status: 'Trạng thái',
  receiptDate: 'Ngày tạo phiếu',
  receiveDate: 'Ngày Nhận',
  from: 'Từ',
  to: 'Tới',
  creator: 'Người tạo',
  keyword: 'Từ khóa',
  search: 'Tìm kiếm',
  receiptList: 'Danh sách Phiếu nhập kho',
  doYouWantToDeleteTheseItems: 'Bạn chắc chắn muốn xóa?',
  deleteReceiptsSuccessfully: 'Đã xóa Phiếu nhập kho thành công',
  receiptIdsHasBeenDeleted: 'Đã xóa thành công các mã phiếu:',
  productCode: 'QR code',
  productName: 'Tên sản phẩm',
  expectedQuantity: 'Số lượng mong muốn',
  cost: 'Giá mua',
  salePrice: 'Giá bán sỉ',
  expireDate: 'Hạn sử dụng',
  createReceipt: 'Tạo Phiếu nhập kho',
  createReceiptSuccessfully: 'Tạo Phiếu nhập kho thành công',
  pleaseSelectAInboundKind: 'Vui lòng chọn loại Nhập hàng',
  messageReceiptDate: 'Vui lòng điền ngày Biên nhận',
  messageReceiveDate: 'Vui lòng điền ngày Nhận',
  productAdded: 'Sản phẩm đã được thêm vào',
  importCSV: 'Thêm Excel',
  pleaseSelecteAProduct: 'Vui lòng chọn sản phẩm',
  messageExpectedQuantity: 'Vui lòng điền Số lượng mong muốn',
  messageCost: 'Vui lòng điền Giá mua',
  totalQuantity: 'Tổng số lượng',
  totalQuantityTins: 'Tổng số lượng(lon)',
  zone: 'Zone',
  block: 'Block',
  shelf: 'Shelf',
  rack: 'Rack',
  confirmReceiptSuccessfully: 'Xác nhận Phiếu nhập kho thành công',
  receiptDetail: 'Chi tiết Phiếu nhập kho',
  productList: 'Danh sách Sản phẩm',
  updateLocationSuccessfully: 'Cập nhật vị trí thành công',
  updateLocation: 'Cập nhật vị trí',
  print: 'In',
  messageTotalQuantity: 'Vui lòng điền Tổng số lượng',
  importDate: 'Ngày nhập nội dung',
  report: 'Báo cáo',
  scanReturnProduct: 'Scan hàng trả về',
  reportErrorProduct: 'Báo cáo sản phẩm lỗi',
  reportSuccessfully: 'Báo cáo thành công',
  reportContent: 'Nội dung báo cáo',
  messageReportContent: 'Vui lòng nhập nội dung báo cáo',
  btnSubmit: 'Gửi đi',
  inputCode: 'Nhập mã',
  packageCode: 'Mã Đóng gói',
  locationCode: 'Mã Vị trí',
  completeScanReturnProductSuccessfully: 'Hoàn thành scan sản phẩm',
  scanReturnProductComplete: 'Hoàn thành scan sản phẩm trả về',
  scanMore: 'Scan thêm',
  scan: 'Scan',
  storingDate: 'Ngày lưu trữ',
  scanProduct: 'Scan sản phẩm',
  storingScanProduct: 'Scan sản phẩm lưu trữ',
  completeScanStoringSuccessfully: 'Scan sản phẩm lưu trữ thành công',
  storingScanProductComplete: 'Danh sách sản phẩm đã scan',
  inboundControlDate: 'Ngày nhập hàng',
  inboundControlScanProduct: 'Scan nhập hàng',
  completeInboundControlScanSuccessfully: 'Scan nhập hàng thành công',
  inboundControlScanProductComplete: 'Danh sách sản phẩm đã scan',
  username: 'Tên đăng nhập',
  name: 'Tên',
  role: 'Vai trò',
  users: 'Người dùng',
  requiredUsername: 'Tên đăng nhập bắt buộc có',
  requiredPassword: 'Mật khẩu bắt buộc có',
  requiredName: 'Tên bắt buộc có',
  requiredRole: 'Vai trò bắt buộc có',
  requiredWarehouse: 'Nhà kho bắt buộc có',
  warehouseCode: 'Mã Nhà kho',
  warehouseName: 'Tên Nhà kho',
  address: 'Địa chỉ',
  warehouses: 'Nhà kho',
  acreage: 'Diện tích(m2)',
  btnCancel: 'Hủy',
  btnComplete: 'Hoàn tất',
  btnUpdate: 'Cập nhật',
  btnCreate: 'Tạo mới',
  btnSearch: 'Tìm kiếm',
  btnAdd: 'Thêm',
  btnEdit: 'Sửa',
  btnConfirm: 'Xác nhận',
  btnDelete: 'Xóa',
  btnSave: 'Lưu',
  confirmQuestion: 'Nội dung đã được sửa đổi, các thay đổi sẽ bị loại bỏ. Bạn có muốn tiếp tục?',
  confirmContent: 'Nếu bạn hủy, thông tin vừa sửa sẽ bị hủy bỏ.',
  yes: 'Có',
  addZone: 'Thêm Zone',
  addShelf: 'Thêm Shelf',
  addBlock: 'Thêm Block',
  zoneDetail: 'Chi tiết Zone',
  zoneCreate: 'Tạo Zone',
  zoneName: 'Tên Zone',
  zoneAreDeletedSuccessfully: 'Zone đã được xóa thành công',
  blockAreDeletedSuccessfully: 'Block đã được xóa thành công',
  shelfAreDeletedSuccessfully: 'Shelf đã được xóa thành công',
  areYouSureToDeleteThisZone: 'Bạn có chắc muốn xóa zone?',
  areYouSureToDeleteThisBlock: 'Bạn có chắc muốn xóa block?',
  areYouSureToDeleteThisShelf: 'Bạn có chắc muốn xóa shelf?',
  placeholderName: 'Vui lòng điền Tên',
  placeholderSelectMethod: 'Vui lòng chọn Phương thức xuất hàng',
  requiredCategoryName: 'Tên nhóm hàng bắt buộc có',
  requiredMethod: 'Phương thức xuất hàng bắt buộc có',
  placeholderSelectProductCate: 'Vui lòng chọn Nhóm hàng',
  placeholderAddress: 'Vui lòng điền Địa chỉ',
  placeholderPhoneNumber: 'Vui lòng điền Số điện thoại',
  requiredCategory: 'Nhóm hàng bắt buộc có',
  requiredSupplierName: 'Tên Nhà cung cấp bắt buộc có',
  requiredEmail: 'Email bắt buộc có',
  requiredAddress: 'Địa chỉ bắt buộc có',
  requiredPhoneNumber: 'Số điện thoại bắt buộc có',
  placeholderSelectSupplier: 'Vui lòng chọn Nhà cung cấp',
  placeholderCapacity: 'Vui lòng điền Sức chứa',
  requiredImage: 'Hình ảnh bắt buộc có',
  requiredProductName: 'Tên sản phẩm bắt buộc có',
  requiredSupplier: 'Nhà cung cấp bắt buộc có',
  requiredCapacity: 'Sức chứa bắt buộc có',
  requiredStoreTime: 'Thời gian lưu kho bắt buộc có',
  placeholderMin: 'Vui lòng điền Giá trị bé nhất',
  placeHolderMax: 'Vui lòng điền Giá trị lớn nhất',
  requiredMin: 'Giá trị bé nhất bắt buộc có',
  requiredMax: 'Giá trị lớn nhất bắt buộc có',
  placeholderSelectProduct: 'Vui lòng chọn Sản phẩm',
  requiredProduct: 'Sản phẩm bắt buộc có',
  requiredZoneName: 'Tên Zone bắt buộc có',
  requiredBlockName: 'Tên Block bắt buộc có',
  placeholderTotalshelf: 'Vui lòng điền Tổng số shelf',
  requiredTotalshelf: 'Tổng số Shelf bắt buộc có',
  requiredShelfName: 'Tên Shelf bắt buộc có',
  placeholderTotalRack: 'Vui lòng điền Tổng số rack',
  requiredTotalRack: 'Tổng số Rack bắt buộc có',
  placeholderLevelPercent: 'Vui lòng điền mức %',
  requiredLevelPercent: 'Mức % bắt buộc có',
  placeholderQuantity: 'Điền số lượng',
  requiredQuantity: 'Số lượng bắt buộc có',
  capacity: 'Sức chứa',
  success: 'Thành công',
  blockDetail: 'Chi tiết Block',
  blockCreate: 'Tạo Block',
  blockName: 'Tên Block',
  totalShelf: 'Tổng số Shelf',
  updateShelf: 'Cập nhật Shelf',
  createShelf: 'Tạo Shelf',
  update: 'Cập nhật',
  complete: 'Hoàn tất',
  shelfName: 'Tên Shelf',
  capacityRack: 'Sức chứa của Rack/Pallet',
  levelPercent: 'Mức %',
  medium: 'trung bình',
  high: 'cao',
  code: 'Mã',
  createSuccessfully: 'Tạo thành công',
  updateSuccessfully: 'Cập nhật thành công',
  confirmSuccessfully: 'Xác nhận thành công',
  deleteSuccessfully: 'Xóa thành công',
  idsHasBeenDeleted: 'ID đã bị xóa:',
  email: 'Email',
  orderCode: 'Mã phiếu xuất',
  userName: 'Tên đăng nhập',
  orderDate: 'Ngày xuất hàng',
  orderQuantity: 'Số lượng Đặt hàng',
  takeQuantity: 'Số lượng Cần lấy',
  pickingquantity: 'Số lượng đã lấy',
  placeholderSelectOrderCode: 'Chọn mã phiếu xuất',
  titlePicking: 'Danh sách phiếu xuất kho',
  suggestLocation: 'Gợi ý vị trí',
  quantity: 'Số lượng',
  createPicking: 'Tạo danh sách lấy hàng',
  labelBooking: 'Đặt hàng',
  placeholderSelectOrder: 'Chọn mã phiếu xuất',
  okText: 'Đồng ý',
  cancelText: 'Hủy',
  titleModalBooking: 'Chọn Đặt hàng',
  scanCode: 'Scan mã',
  confirmPickupList: 'Xác nhận Danh sách lấy hàng',
  titleModalPslitProduct: 'Phân chia Sản phẩm',
  placeholderSelectPackageCode: 'Chọn Mã đóng gói',
  btnDone: 'Xong',
  btnClose: 'Đóng',
  titleModalSuccessSuggestLocation: 'Vị trí gợi ý được in thành công',
  pickupList: 'Danh sách Lấy hàng',
  generatePackageCode: 'Phát hành Mã đóng gói',
  orderNumber: 'Số Đặt hàng',
  productOrderCode: 'Mã sản phẩm đặt hàng',
  pickingList: 'Danh sách lấy hàng',
  packagingCode: 'Mã đóng gói',
  tempOutLot: 'Khu vực tạm xuất',
  orderandpackagingInfo: 'Thông tin Đặt hàng và Đóng gói',
  packagingDetail: 'Chi tiết đóng gói',
  orderandshippingInfo: 'Chi tiết phiếu xuất kho',
  confirmPackageList: 'Xác nhận Danh sách đóng gói',
  outQuantity: 'Số lượng đã hết',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  orderInfo: 'Thông tin Đặt hàng',
  inStock: 'Trong kho',
  expirationDate: 'Ngày hết hạn',
  storageExpirationDate: 'Ngày hết hạn lưu kho',
  exportCSV: 'Xuất Excel',
  agingStockAlert: 'Cảnh báo Quá ngày lưu kho',
  dotRestock: 'Vui lòng bổ sung sản phẩm cho đợt hàng tiếp theo',
  productDetail: 'Chi tiết Sản phẩm',
  total: 'Tổng số',
  inventorySummary: 'Tổng kết Lưu kho',
  inboundsummary: 'Tổng kết Nhập hàng',
  productSummary: 'Tổng kết Xuất hàng',
  action: 'Hành động',
  warehouseProductDetail: 'Chi tiết sản phẩm trong kho',
  requiredReportContent: 'Nội dung báo cáo bắt buộc có',
  editLocation: 'Thay đổi vị trí',
  dotEditLocation: 'Vui lòng chọn sản phẩm bạn muốn thay đổi vị trí',
  onlyEditLocation: 'Chỉ được phép thay đổi 1 dòng mỗi thời điểm',
  pendingState: 'Trạng thái Chờ',
  inboundState: 'Trạng thái Nhập hàng',
  inboundStateGift: 'Trạng thái Nhập hàng Gift',
  lostState: 'Trạng thái Mất',
  updateProduct: 'Cập nhật sản phẩm',
  requiredZone: 'Zone bắt buộc có',
  requiredLocator: 'Locator bắt buộc có',
  requiredBlock: 'Block bắt buộc có',
  requiredShelf: 'Shelf bắt buộc có',
  requiredRack: 'Rack bắt buộc có',
  requiredExpirationDate: 'Ngày hết hạn bắt buộc có',
  requiredTotalQauntity: 'Tổng số lượng bắt buộc có',
  requiredCost: 'Giá mua bắt buộc có',
  requiredSalePrice: 'Giá bán bắt buộc có',
  requiredStatus: 'Trạng thái bắt buộc có',
  requiredLostDate: 'Ngày mất bắt buộc có',

  totalPerPallet: 'Số lượng sản phẩm mỗi pallet',
  sepratePallet: 'Chia pallet',
  totalPallet: 'Số lượng pallet',
  totalRackbetween: 'Tổng số rack',
  backtoProductList: 'Quay về Danh sách Sản phẩm',
  productInfo: 'Thông tin sản phẩm',
  storageTime: 'Thời gian lưu kho còn lại (ngày)',
  productImage: 'Hình ảnh Sản phẩm',
  imageExtra: 'Bạn có thể tải lên hình ảnh dung lượng tối đa 5MB',
  upload: 'Tải lên',
  methodExport: 'Chiến lược xuất hàng',
  createDate: 'Ngày tạo',
  createProduct: 'Tạo sản phẩm',
  productCategoryName: 'Tên Nhóm hàng',
  productCategoryCode: 'Mã Nhóm hàng',
  productCategoryList: 'Danh sách Nhóm hàng',
  updateProductCategoty: 'Cập nhật Nhóm hàng',
  createProductCategoty: 'Tạo Nhóm hàng',
  minQuantity: 'Số lượng tối thiểu',
  maxQuantity: 'Số lượng tối đa',
  notification: 'Thông báo',
  onHand: 'Đang có',
  toOrder: 'Đặt hàng',
  replenishmentList: 'Danh sách Bổ sung hàng',
  createReplenishment: 'Tạo Bổ sung hàng',
  updateReplenishment: 'Cập nhật Bổ sung hàng',
  backtoSupplierList: 'Quay về Danh sách Nhà cung cấp',
  supplierInfo: 'Thông tin Nhà cung cấp',
  phone: 'Số điện thoại',
  cooperationDate: 'Ngày hợp tác',
  supplierName: 'Tên Nhà cung cấp',
  requiredCooperationDate: 'Ngày hợp tác bắt buộc có',
  supplierCode: 'Mã Nhà cung cấp',
  supplierList: 'Danh sách Nhà cung cấp',
  createSupplier: 'Tạo Nhà cung cấp',
  updateSupplier: 'Cập nhật Nhà cung cấp',
  createSaleOrder: 'Tạo Đơn hàng',
  addProduct: 'Thêm Sản phẩm',
  editProduct: 'Sửa Sản phẩm',
  saleOrderDetail: 'Chi tiết Phiếu xuất kho',
  confirmOrderDetail: 'Chi tiết xuất hàng',
  confirmOrder: 'Chi tiết phiếu xác nhận xuất hàng',
  orderList: 'Danh sách Đặt hàng',
  reportDate: 'Ngày báo cáo',
  firstName: 'Họ',
  lastName: 'Tên',
  lostDate: 'Ngày mất',
  phoneLengthMessage: 'Số điện thoại không được dài hơn 11 ký tự',
  phoneMessage: 'Số điện thoại phải là số',
  full: 'Đầy',
  empty: 'Còn trống',
  Medium: 'Trung bình',
  resetPassword: 'Khôi phục mật khẩu',
  passwordNew: 'Mật khẩu mới',
  currentPassword: 'Mật khẩu hiện tại',
  passwordConfirm: 'Xác nhận mật khẩu',
  zoneNameExist: 'Tên zone đã tồn tại',
  blockNameExist: 'Tên block đã tồn tại',
  shelfNameExist: 'Tên shelf đã tồn tại',
  no: 'Không',
  subTitleInboundControl: 'Vui lòng quét vị trí trước khi đặt sản phẩm lên kệ',
  subTitleOutboundCreatePickUpNotify: 'Vui lòng chọn đơn đặt hàng',
  subTitleStoringNotify: 'Vui lòng quét mã QR của sản phẩm trước khi vận chuyển',
  subTitleInboundTemporaryNotify: 'Vui lòng quét mã QR của sản phẩm trước khi vận chuyển',
  passwordRegex: 'Mật khẩu phải có tối thiểu 8 ký tự',
  updateImageLess: 'Vui lòng tải ảnh nhỏ hơn 5MB',
  creatorName: 'Người tạo',
  invalidCode: 'Mã không tồn tại',
  tryAgain: 'Vui lòng thử lại',
  noRecordsFound: 'Không có kết quả tìm kiếm!',
  emailRequirePlaceholder: 'Vui lòng điền email',
  messeageExpiration: 'Ngày hết hạn là bắt buộc',
  unlockAccount: 'Mở khóa tài khoản thành công',
  January: 'Tháng 1',
  February: 'Tháng 2',
  March: 'Tháng 3',
  April: 'Tháng 4',
  May: 'Tháng 5',
  June: 'Tháng 6',
  July: 'Tháng 7',
  August: 'Tháng 8',
  September: 'Tháng 9',
  October: 'Tháng 10',
  November: 'Tháng 11',
  December: 'Tháng 12',
  orderStatus: 'Trạng thái đặt hàng',
  packageStatus: 'Trạng thái đóng gói',
  splitproduct: 'Tách sản phẩm',
  split: 'Tách',
  splitProductList: 'Danh sách tách sản phẩm',
  defaultCode: 'Mã sản phẩm',
  requiredDefaultCode: 'Mã mặc định là bắt buộc có',
  placeHolderDefaultCode: 'Vui lòng nhập mã mặc định',
  placeholderSalePrice: 'Vui lòng nhập giá bán',
  retailPrice: 'Giá bán lẻ',
  placeholderRetailPrice: 'Vui lòng nhập giá bán lẻ',
  lot: 'Lot',
  requiredLot: 'Số lượng lô là bắt buộc có',
  VAT: 'Thuế (%)',
  Divison: 'Khối hàng',
  Category: 'Ngành hàng',
  divisonCode: 'Mã khối hàng',
  divisonName: 'Tên khối hàng',
  createDivison: 'Tạo khối hàng',
  updateDivison: 'Cập nhật khối hàng',
  placeholderDivisonName: 'Vui lòng nhập tên khối hàng',
  requiredDivisonName: 'Tên khối hàng là bắt buộc',
  categoryCode: 'Mã ngành hàng',
  categoryName: 'Tên ngành hàng',
  createCategory: 'Tạo ngành hàng',
  updateCategory: 'Cập nhật ngành hàng',
  placeholderCategoryName: 'Vui lòng nhập tên ngành hàng',
  requiredParentCategoryName: 'Tên ngành hàng là bắt buộc',
  requiredDivison: 'Khối hàng là bắt buộc',
  requiredParentCategory: 'Ngành hàng là bắt buộc',
  categoryList: 'Danh sách ngành hành',
  divisonList: 'Danh sách khối hàng',
  DVT: 'Đơn vị tính',
  isActive: 'Kích hoạt',
  contractNumber: 'Số hợp đồng',
  packingRule: 'Quy cách đóng gói',
  NEW: 'Draft',
  INPROGRESS: 'Đang nhập kho',
  TEMPORARYCONFIRM: 'Chờ xác nhận',
  CONFIRM1: 'Thủ kho xác nhận',
  CONFIRM2: 'IDM xác nhận',
  ERROR: 'LỖI',
  TEMPORARY: 'TẠM NHẬP',
  STORED: 'TRONG KHO',
  MOVING: 'DI CHUYỂN',
  TEMPORARY_OUT: 'XUẤT',
  LOST: 'MẤT',
  REALLOCATE: 'ĐỔI LẠI',
  ENABLE: 'CHO PHÉP',
  DISABLE: 'VÔ HIỆU HÓA',
  requestReceipt: 'Ap Invoice',
  note: 'Ghi chú',
  itemCode: 'Mã item',
  length: 'Dài',
  width: 'Rộng',
  height: 'Cao',
  RECEIVE: 'Completed',
  dowloadTemplate: 'Tải mẫu',
  importSuccess: 'Tải lên thành công',
  exportPDF: 'Xuất PDF',
  discount: 'Chiết khấu',
  isResources: 'Nguồn hàng',
  vansCode: 'Container No',
  createPO: 'Phiếu nhập kho',
  taxCode: 'Mã số thuế',
  requiredPackingRule: 'Quy cách đóng gói là bắt buộc',
  CANCELLED: 'HỦY',
  PICKING: 'ĐÃ LẤY',
  EXPORTED: 'ĐÃ XUẤT',
  PACKAGED: 'ĐÓNG GÓI',
  deliveryTime: 'Thời gian giao hàng',
  messeagePicking: 'Đơn hàng đang được chọn bởi 1 người dùng khác',
  bom: 'Nguyên liệu sản phẩm',
  bomList: 'Danh sách nguyên liệu sản phẩm',
  caseUOM: 'Tên nguyên liệu',
  average: 'Số lượng sử dụng/ 1 thùng thành phẩm',
  wastage: 'Hao hụt sản xuất',
  createBom: 'Tạo mới nguyên liệu sản phẩm',
  updateBom: 'Cập nhật nguyên liệu sản phẩm',
  bomCode: 'Mã nguyên liệu',
  codeNPL: 'Mã NPL',
  producerRule: 'Quy cách nhà sản xuất',
  cartonRule: 'Quy cách thùng chứa',
  grossWeightPerCarton: 'Gross weight/ thùng',
  cartonPerPallet: 'Số lượng thùng/ pallet',
  ABBOTT_CODE_16: 'Mã sản phẩm -nhà máy',
  threeA_CODE: 'Mã sản phẩm - ERP',
  SHORTNAME: 'Tên sản phẩm - Viết tắt',
  NAME_EN: 'Tên sản phẩm - Tiếng Anh',
  UOM_EN: 'Đơn vị - Tiếng Anh',
  NAME_VN: 'Tên sản phẩm - Tiếng Việt',
  UOM_VN: 'Đơn vị - Tiếng Việt',
  FLAVOR: 'Hương sản phẩm',
  TIN_PER_CARTON: 'Số lượng lon/ thùng',
  BRAND: 'Nhãn hàng',
  SKU_GROUP: 'Nhóm sản phẩm',
  five_KEYBRAND: 'Nhóm nhãn hàng',
  COUNTRY_OF_ORIGIN: 'Nơi sản xuất',
  SIZE: 'Trọng lượng (Gram/lon)',
  SHELF_LIFE_MONTHS: 'Hạn sử dụng',
  TYPE_OF_PRODUCT: 'Loại sản phẩm',
  CTNS_PER_LAYER_3A: 'Tiêu chuẩn 3A - Số thùng/lớp - Trên kệ',
  LAYERS_PER_PALLET_3A: 'Tiêu chuẩn 3A - Số lớp/ pallet',
  CTMS_PER_PALLET_STORAGE_3A: 'Tiêu chuẩn 3A - Số thùng/pallet - Trên kệ',
  CTNS_PER_LAYER_3A_FLOOR: 'Tiêu chuẩn 3A - Số thùng/ lớp - Dưới sàn',
  LAYERS_PER_PALLET_3A_FLOOR_3: 'Tiêu chuẩn 3A - Số lớp/ pallet - Dưới sàn',
  CTNS_PER_PALLET_STORAGE_3A_FLOOR_4: 'Tiêu chuẩn 3A - Số thùng/ pallet - Dưới sàn',
  CTNS_PER_LAYER2_IMPORT: 'Tiêu chuẩn nhập khẩu - Số thùng/ lớp - Dưới sàn',
  LAYERS_PER_PALLET3_IMPORT: 'Tiêu chuẩn nhập khẩu - Số lớp/ pallet - Dưới sàn',
  CTNS_PER_PALLET_IMPORT: 'Tiêu chuẩn nhập khẩu - Số thùng/ pallet - Dưới sàn',
  IS_PACKAGED_SKU: 'Sản phẩm đóng gói?',
  MILK_CODE: 'Sản phẩm sữa cho trẻ dưới 2 tuổi?',
  SUB_BRAND_FOLLOW_ADS: 'Nhãn phụ - ADS',
  TYPEOFPRODUCT2: 'Ngành sản phẩm con',
  GROSS_WEIGHT_KG_PER_CASE: 'Tổng trọng lượng/ thùng sản phẩm',
  NET_WEIGHT_GRAM_PER_TINS: 'Tổng trọng lượng sữa (NET)/ 1 đơn vị sản phẩm',
  NET_WEIGHT_KG_PER_CASE: 'Tổng trọng lượng sữa (NET)/ 1 thùng sản phẩm',
  VOLUME_CM3: 'Thể tích/ đơn vị sản phẩm',
  GROSS_WEIGHT_KG_PER_PALLET: 'Tổng trọng lượng/ 1 pallet',
  NET_WEIGHT_KG_PER_PALLET: 'Tổng trọng lượng NET/ 1 pallet',
  LENGTH_CARTON_CM: 'Chiều dài thùng sản phẩm',
  WIDTH_CARTON_CM: 'Chiều rộng thùng sản phẩm',
  HEIGHT_CARTON_CM: 'Chiều cao thùng sản phẩm',
  VOLUME_CARTON_CM3: 'Thể tích thùng sản phẩm',
  BARCODE_TINS: 'Mã vạch theo sản phẩm(lon)',
  BARCODE_CARTON: 'Mã vạch theo sản phẩm(thùng)',
  TEN_NHOM_HANG: 'Nhóm hàng',
  bomGift: 'Sản phẩm tặng kèm',
  bomGiftlist: 'Product Gift',
  createBomGift: 'Tạo sản phẩm tặng kèm',
  updateBomGift: 'Cập nhật sản phẩm tặng kèm',
  productBOMCode: 'Mã BOM sản phẩm',
  umIsFreeItem: 'Hàng miễn phí',
  masterproductPacking: 'Quy cách đóng gói NPL',
  masterproductPackingList: 'Danh sách quy cách đóng gói nguyên phụ liệu',
  createMasterproductPacking: 'Tạo mới quy cách đóng gói nguyên phụ liệu',
  updateMasterproductPacking: 'Cập nhật quy cách đóng gói nguyên phụ liệu',
  type: 'Loại',
  Double: 'Máy co đôi',
  Single: 'Máy đơn',
  handWork: 'Thủ công',
  Area: 'Khu vực',
  areaList: 'Danh sách khu vực',
  areaName: 'Tên khu vực',
  areaCode: 'Mã khu vực',
  areaCreate: 'Tạo mới khu vực',
  areaUpdate: 'Cập nhật khu vực',
  Locator: 'Locator',
  locatorList: 'Danh sách locator',
  locatorName: 'Tên locator',
  locatorCode: 'Mã locator',
  locatorCreate: 'Tạo mới locator',
  locatorUpdate: 'Cập nhật locator',
  apInvoiceList: 'Danh sách Ap Invoice',
  updateApInvoice: 'Cập nhật Ap Invoice',
  apInvoiceDetail: 'Chi tiết APInvoice',
  productListDivided: 'Danh sách sản phẩm đã chia',
  giftCode: 'Mã quà tặng',
  giftName: 'Tên quà tặng',
  UOM: 'Đơn vị',
  PCSCase: 'PCS/CASE',
  Specification: 'Specification',
  lengthPCS: 'Độ dài PCS',
  widthPCS: 'Chiều rộng PCS',
  heightPCS: 'Chiều cao PCS',
  grossWeightPCS: 'Tổng trọng lượng (PCS)',
  volumePCS: 'Thể tích PCS',
  grossWeight3A: 'Tổng trọng lượng (3A)',
  CTNS_PER_LAYER2_VENDER: 'Ctns/Layer2(Vender)',
  LAYERS_PER_PALLET3_VENDER: 'Layers /Pallet3(Vender)',
  CTNS_PER_PALLET_VENDER: 'Ctns/pallet(Vender)',
  grossWeightVender: 'Tổng trọng lượng (Vender)',
  isRack: 'Is rack',
  salesChannel: 'Kênh bán hàng',
  saleChannelList: 'Danh sách kênh bán hàng',
  salesChannelCode: 'Mã kênh bán hàng',
  salesChannelName: 'Tên kênh bán hàng',
  quota: 'Số lượng giới hạn',
  shelfLife: 'Remaining ShelfLife (%)',
  createSalesChannel: 'Tạo kênh bán hàng',
  updateSalesChannel: 'Cập nhật kênh bán hàng',
  shelftype: 'Loại kệ',
  quantityCartton: 'Số lượng thùng',
  quantityTin: 'Số lượng lon',
  totalQuantityCartton: 'Tổng số lượng thùng',
  TotalQuantityTin: 'Tổng số lượng lon',
  TotalQuantityTinOdd: 'Tổng số lượng lon lẻ',
  quantityCarttonAtLocation: 'Số lượng thùng tại vị trí',
  quantityTinAtLocation: 'Số lượng lon tại vị trí',
  quantityCarttonTaken: 'Số lượng thùng đã lấy',
  quantityCarttonLeft: 'Số lượng thùng còn lại',
  quantityTaken: 'Số lượng lon đã lấy',
  quantityLeft: 'Số lượng lon còn lại',
  quantityCarttonReceived: 'Số lượng thùng thực nhận',
  quantityTinReceived: 'Số lượng lon thực nhận',
  company: 'Công ty',
  branch: 'Chi nhánh',
  orderReference: 'Order tham chiếu',
  businessLine: 'Kênh khách hàng',
  abbottPOReference: 'PO tham chiếu',
  description: 'Diễn giải',
  dateOrdered: 'Ngày xuất hàng',
  giftWarehouse: 'Kho hàng tặng',
  customerProgram: 'Customer program',
  subChannel: 'Sub channel',
  RCRETURN: 'TRẢ VỀ',
  RCINTERNAL: 'NỘI BỘ',
  RCBORROW: 'NHẬP MƯỢN',
  RCIMPORT: 'NHẬP HÀNG GIFT',
  returnDate: 'Ngày trả về',
  movementDate: 'Ngày chuyển',
  tranferReason: 'Lý do chuyển',
  locatorTo: 'Locator nhận',
  movementQuantity: 'Số lượng chuyển',
  documentNo: 'Document No',
  documentType: 'Document Type',
  orderType: 'Loại xuất kho',
  consignmentOrder: 'Loại nhập',
  borrowed: 'Xuất mượn',
  enterBorrow: 'Nhập mượn',
  EXPORT_INTERNAL: ' Đơn xuất nội bộ',
  EXPORT_INTERNAL_GIFT: ' Đơn xuất nội bộ quà tặng',
  EXPORT_SALE: 'Đơn xuất bán',
  EXPORT_BORROW: 'Đơn xuất mượn',
  masterproductsbomgift: 'Quy cách đóng gói SPTK',
  createMasterproductsbomgift: 'Tạo mới quy cách đóng gói sản phẩm tặng kèm',
  updateMasterproductsbomgift: 'Cập nhật quy cách đóng gói sản phẩm tặng kèm',
  masterproductsbomgiftList: 'Danh sách quy cách đóng gói sản phẩm tặng kèm',
  Vas: 'Vas',
  completeVasScanSuccessfully: 'Scan Vas thành công',
  VAS: 'VAS',
  floor: 'Số tầng',
  productionOrder: 'Lệnh sản xuất',
  productionOrderList: 'Danh sách lệnh sản xuất',
  receiptGifttList: 'Danh sách phiếu nhập kho quà',
  exportMaterial: 'Xuất nguyên phụ liệu',
  createProductionOrder: 'Tạo lệnh sản xuất',
  productionOrderDetail: 'Chi tiết lệnh sản xuất',
  confirmProductionOrderProduct: 'Xác nhận danh sách sản phẩm lệnh sản xuất',
  createComboProduct: 'Tạo combo sản phẩm',
  invoiceNo: 'Invoice No',
  orderNo: 'Order No',
  contractNo: 'Contract No',
  shipment: 'Shipment No',
  shipmentNo: 'Shipment No',
  mfgDate: 'Ngày sản xuất',
  btnlock: 'Khóa',
  lockproductSuccessfully: 'Khóa sản phẩm thành công',
  picking: 'Lấy hàng',
  expectedQuantityCarton: 'Số lượng thùng mong muốn',
  expectedQuantityTin: 'Số lượng lon mong muốn',
  requiredContainerNo: 'Container no là bắt buộc',
  locatorFrom: 'Từ Locator',
  receivedRemainingQuantityCarton: 'Số lượng thùng còn lại phải nhận',
  receivedRemainingQuantityTin: 'Số lượng lon còn lại phải nhận',
  orderConfirm: 'Xác nhận xuất hàng',
  orderConfirmCreate: 'Tạo mới xác nhận đơn hàng',
  inventoryManagement: 'Kiểm tồn',
  lDDNo: 'Số lệnh điều động',
  truckType: 'Loại xe',
  noPallet: 'Không có pallet',
  quantityCarttonRemainder: 'Số lượng thùng lẻ/Pallet',
  quantityTinRemainder: 'Số lượng lon lẻ',
  warehouseZoneDetail: 'Chi tiết khu vực',
  customerReturn: 'Customer RMA',
  customerReturnList: 'Customer RMA list',
  customerReturnCreate: 'Customer RMA create',
  customerReturnDetail: 'Customer RMA detail',
  targetDocType: 'Target doc type',
  rmaCategory: 'RMA category',
  origSalesOrder: 'Orig sales order',
  origDO: 'Orig DO',
  DODate: 'DO date',
  businessPartner: 'Business partner',
  billTo: 'Bill to',
  partnerLocation: 'Partner location',
  billToLocation: 'Bill to location',
  repressentative: 'Repressentative',
  campain: 'Campain',
  department: 'Department',
  origDOLine: 'Orig DO line',
  attributeSetInstance: 'Attribute set instance',
  unitPrice: 'Unit price',
  createReceiptReturn: 'Tạo phiếu trả về',
  rmaDate: 'RMA date',
  availableProduct: 'Có hàng',
  EXPORT_BRANCH: 'Xuất chi nhánh',
  EXPORT_BRANCH_GIFT: 'Xuất chi nhánh Gift',
  RCBRANCH: 'CHI NHÁNH',
  HOLD: 'HOLD',
  AV: 'AV',
  RCNEW: 'NHẬP KHẨU',
  EXPORT: 'XUẤT HÀNG',
  ENABLEHD: 'Hoạt động',
  COMPLETED: 'COMPLETED',
  NORMAL: 'NORMAL',
  GIFT: 'GIFT',
  giftinventory: 'Kiểm tồn quà tặng',
  inventorymaterial: 'Kiểm tồn NLSP',
  exportSpickingSlip: 'In Picking Slip',
};
