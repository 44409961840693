import React, { useEffect, useRef } from 'react';
import { Button, Col, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { ModalForm, ProFormSelect, ProFormText, ProFormDigit } from '@ant-design/pro-form';
import './location-style.less';
import PropTypes from 'prop-types';
import { updateRack } from './location.service';

const RackModalForm = ({ item, setVisible, visible, fetchBlock, setVisiblePrintRack }) => {
    // const [form] = Form.useForm();
    const formRef = useRef();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    useEffect(() => {
        if (formRef?.current) {
            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                formRef.current.resetFields();
            };
        }
    }, [visible]);
    // useEffect(() => {
    //     return () => {
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //         formRef.current.setFieldsValue(item);
    //     };
    // }, [visible]);
    // const initValues = {
    //     code: item ? item?.code : undefined,
    //     status: item ? item?.status : undefined,
    // };
    return (
        <ModalForm
            title='Cập nhật rack'
            visible={visible}
            width="768px"
            formRef={formRef}
            onVisibleChange={setVisible}
            modalProps={{
                closable: false,
                centered: true,
            }}
            onFinish={async (values) => {
                try {
                    // delete va
                    // const resVal = { ...values, ...item };
                    const resVal = Object.assign({}, item, values);
                    delete resVal.createDate;
                    delete resVal.index;
                    await updateRack(resVal);
                    message.success(lang.updateSuccessfully);
                    fetchBlock();
                    return true;
                } catch (error) {
                    message.error(error.message);
                    return false;
                }
            }}
            submitter={{
                render: (_, dom) => [
                    dom[0],
                    <Button key='print' onClick={() => setVisiblePrintRack(true)}>
                        {lang.print}
                    </Button>,
                    dom[1],
                ],
            }}
        initialValues={{
            code: item?.code,
            status: item?.status,
            capacity: item?.capacity,
        }}
        >
            <Row gutter={16}>
                <Col span={8}>
                    <ProFormText name='code' label={lang.code} placeholder={lang.code} />
                </Col>
                <Col span={8}>
                    <ProFormSelect name='status' label={lang.status} placeholder={lang.status} options={[
                        {
                            value: 'ENABLE',
                            label: lang.ENABLE,
                        },
                        {
                            value: 'DISABLE',
                            label: lang.DISABLE,
                        },
                    ]} />
                </Col>
                <Col span={8}>
                    <ProFormDigit name='capacity' label={lang.capacity} placeholder={lang.capacity} />
                </Col>
            </Row>
        </ModalForm>
    );
};
RackModalForm.propTypes = {
    item: PropTypes.object,
    setVisible: PropTypes.func,
    visible: PropTypes.bool,
    fetchBlock: PropTypes.func,
    setVisiblePrintRack: PropTypes.func,

};
export default RackModalForm;
