import React from 'react';
import { Layout, Row, Col } from 'antd';
// import logo from 'assets/logoAKAWMS/abbott-slogan.png';
import './styles.less';

import bgImg from 'assets/background.png';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

// const breadcrumbItemRender = (route, params, routes, paths) => {
//   const last = routes.indexOf(route) === routes.length - 1;
//   return last ? (
//     <span>{route.breadcrumbName}</span>
//   ) : (
//     <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
//   );
// };

const Welcome = () => {
  // const routes = [
  //   {
  //     path: '/mysalon',
  //     breadcrumbName: 'Dashboard',
  //   },
  //   {
  //     path: '/welcome',
  //     breadcrumbName: 'Welcome',
  //   },
  // ];
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Layout className="welcome-page">
      <Row justify="space-between" style={{ height: window.innerHeight - 64 }}>
        <Col
          xs={{ span: 24 }}
          style={{
            backgroundImage: `url(${bgImg})`,
            width: '100%',
            backgroundSize: 'cover',
            overflow: 'hidden',
          }}
        >
          <div className="wrapDes" style={{ marginTop: window.innerHeight / 2 - 150 }}>
            {/* <div className="wrapLogo">
              <img src={logo} className="logo" alt="akaWMS" style={{ height: 115 }} />
            </div>
            <div style={{ borderLeft: '1px solid white' }} /> */}
            <div className="akaName">
              <div>{lang.akaName}</div>
              <div>{lang.akaDescription}</div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default Welcome;
