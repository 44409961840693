import request from 'utils/request';
import environment from 'environment';
export async function saleorderList(payload) {
  return request(environment.api.Orders + 'get-all', payload, 'GET');
}

export async function saleorderDetail(id) {
  return request(environment.api.Orders + `get-one/${id}`, {}, 'GET');
}

export async function saleorderCreate(payload) {
  return request(environment.api.Orders + 'create', payload, 'POST');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
    resolve('1');
  });
}
export async function productOrderUpdate(payload) {
  return request(environment.api.productOrder + '/update', payload, 'PUT');
}

export async function productOrderUpdateVasBaseProduct(payload) {
  return request(environment.api.productOrder + '/updateProduct-vas-base-product', payload, 'PUT');
}

export async function getAllProudctLocator(payload) {
  return request(environment.api.masterProductLocator + '/get-all', payload, 'GET');
}

// Hàm tạo mới một QA code trong LSX
export async function createPalletVasProduct(payload) {
  return request(environment.api.products + 'create-production-pallet', payload, 'POST');
}

export async function deleteProductsPallet(payload) {
  return request(environment.api.products + 'deletes-production-order', payload, 'POST');
}



// API Production productionOrderCreate
export async function productionOrderCreate(payload) {
  return request(environment.api.productionOrder + 'create', payload, 'POST');
}

export async function productionOrderUnpack(payload) {
  return request(environment.api.masterProductLocator + '/unpacking ', payload, 'POST');
}