import request from 'utils/request';
import environment from 'environments/environment';
import qs from 'querystring';

export async function warehouseListAll() {
    return request(`${environment.api.warehouseList}?sortBy=id&sortDirection=desc`, {}, 'GET');
}

export async function warehouseList(payload) {
    const params = qs.stringify(payload);
    return request(`${environment.api.warehouseList}?${params}`, {}, 'GET');
}

export async function warehouseDetail(payload) {
    return request(environment.api.warehouseDetail + `/${payload}`, {}, 'GET');
}
export async function warehouseCreate(payload) {
    return request(environment.api.warehouseCreate, payload, 'POST');
}

export async function warehouseUpdate(payload) {
    return request(environment.api.warehouseUpdate, payload, 'PUT');
}

export async function warehouseDelete(payload) {
    return request(environment.api.warehouseDelete + `/${payload?.id}`, {}, 'DELETE');
}
