import {
  ZONE_CREATE,
  ZONE_CREATE_SUCCESS,
  ZONE_CREATE_FAIL,
  BLOCK_CREATE,
  BLOCK_CREATE_FAIL,
  BLOCK_CREATE_SUCCESS,
  ZONE_LIST,
  ZONE_LIST_SUCCESS,
  ZONE_LIST_FAIL,
  BLOCK_LIST,
  BLOCK_LIST_FAIL,
  BLOCK_LIST_SUCCESS,
  ZONE_DELETE,
  ZONE_DELETE_FAIL,
  ZONE_DELETE_SUCCESS,
  BLOCK_DELETE,
  BLOCK_DELETE_SUCCESS,
  BLOCK_DELETE_FAIL,
  SHELF_CREATE,
  SHELF_CREATE_FAIL,
  SHELF_CREATE_SUCCESS,
  ZONE_UPDATE,
  ZONE_UPDATE_SUCCESS,
  ZONE_UPDATE_FAIL,
  BLOCK_UPDATE,
  BLOCK_UPDATE_SUCCESS,
  BLOCK_UPDATE_FAIL,
  SHELF_UPDATE,
  SHELF_UPDATE_SUCCESS,
  SHELF_UPDATE_FAIL,
} from './types';

export function zoneList(payload = {}) {
  return { type: ZONE_LIST, payload, meta: { thunk: true } };
}
export function zoneListSuccess(payload = {}, meta) {
  return { type: ZONE_LIST_SUCCESS, payload, meta };
}
export function zoneListFail(payload = {}, meta) {
  return { type: ZONE_LIST_FAIL, payload, meta };
}
export function zoneCreate(payload = {}) {
  return { type: ZONE_CREATE, payload, meta: { thunk: true } };
}
export function zoneCreateSuccess(payload = {}, meta) {
  return { type: ZONE_CREATE_SUCCESS, payload, meta };
}
export function zoneCreateFail(payload = {}, meta) {
  return { type: ZONE_CREATE_FAIL, payload, meta };
}
export function zoneUpdate(payload = {}) {
  return { type: ZONE_UPDATE, payload, meta: { thunk: true } };
}
export function zoneUpdateSuccess(payload = {}, meta) {
  return { type: ZONE_UPDATE_SUCCESS, payload, meta };
}
export function zoneUpdateFail(payload = {}, meta) {
  return { type: ZONE_UPDATE_FAIL, payload, meta };
}
export function zoneDelete(payload = {}) {
  return { type: ZONE_DELETE, payload, meta: { thunk: true } };
}
export function zoneDeleteSuccess(payload = {}, meta) {
  return { type: ZONE_DELETE_SUCCESS, payload, meta };
}
export function zoneDeleteFail(payload = {}, meta) {
  return { type: ZONE_DELETE_FAIL, payload, meta };
}

export function blockList(payload = {}) {
  return { type: BLOCK_LIST, payload, meta: { thunk: true } };
}
export function blockListSuccess(payload = {}, meta) {
  return { type: BLOCK_LIST_SUCCESS, payload, meta };
}
export function blockListFail(payload = {}, meta) {
  return { type: BLOCK_LIST_FAIL, payload, meta };
}

export function blockCreate(payload = {}) {
  return { type: BLOCK_CREATE, payload, meta: { thunk: true } };
}
export function blockCreateSuccess(payload = {}, meta) {
  return { type: BLOCK_CREATE_SUCCESS, payload, meta };
}
export function blockCreateFail(payload = {}, meta) {
  return { type: BLOCK_CREATE_FAIL, payload, meta };
}

export function blockUpdate(payload = {}) {
  return { type: BLOCK_UPDATE, payload, meta: { thunk: true } };
}
export function blockUpdateSuccess(payload = {}, meta) {
  return { type: BLOCK_UPDATE_SUCCESS, payload, meta };
}
export function blockUpdateFail(payload = {}, meta) {
  return { type: BLOCK_UPDATE_FAIL, payload, meta };
}

export function blockDelete(payload = {}) {
  return { type: BLOCK_DELETE, payload, meta: { thunk: true } };
}
export function blockDeleteSuccess(payload = {}, meta) {
  return { type: BLOCK_DELETE_SUCCESS, payload, meta };
}
export function blockDeleteFail(payload = {}, meta) {
  return { type: BLOCK_DELETE_FAIL, payload, meta };
}

export function shelfCreate(payload = {}) {
  return { type: SHELF_CREATE, payload, meta: { thunk: true } };
}
export function shelfCreateSuccess(payload = {}, meta) {
  return { type: SHELF_CREATE_SUCCESS, payload, meta };
}
export function shelfCreateFail(payload = {}, meta) {
  return { type: SHELF_CREATE_FAIL, payload, meta };
}

export function shelfUpdate(payload = {}) {
  return { type: SHELF_UPDATE, payload, meta: { thunk: true } };
}
export function shelfUpdateSuccess(payload = {}, meta) {
  return { type: SHELF_UPDATE_SUCCESS, payload, meta };
}
export function shelfUpdateFail(payload = {}, meta) {
  return { type: SHELF_UPDATE_FAIL, payload, meta };
}

// export function supplierDetail(payload = {}) {
//   return { type: SUPPLIER_DETAIL, payload, meta: { thunk: true } };
// }
// export function supplierDetailSuccess(payload = {}, meta) {
//   return { type: SUPPLIER_DETAIL_SUCCESS, payload, meta };
// }
// export function supplierDetailFail(payload = {}, meta) {
//   return { type: SUPPLIER_DETAIL_FAIL, payload, meta };
// }

// export function supplierCreate(payload = {}) {
//   return { type: SUPPLIER_CREATE, payload, meta: { thunk: true } };
// }
// export function supplierCreateSuccess(payload = {}, meta) {
//   return { type: SUPPLIER_CREATE_SUCCESS, payload, meta };
// }
// export function supplierCreateFail(payload = {}, meta) {
//   return { type: SUPPLIER_CREATE_FAIL, payload, meta, error: true };
// }

// export function supplierUpdate(payload = {}) {
//   return { type: SUPPLIER_UPDATE, payload, meta: { thunk: true } };
// }
// export function supplierUpdateSuccess(payload = {}, meta) {
//   return { type: SUPPLIER_UPDATE_SUCCESS, payload, meta };
// }
// export function supplierUpdateFail(payload = {}, meta) {
//   return { type: SUPPLIER_UPDATE_FAIL, payload, meta, error: true };
// }
