import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag, message, Select } from 'antd';
import { PlusOutlined, DeleteOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import { receiptDelete, receiptList } from './receipt.service';
import _get from 'lodash/get';
import logog3A from 'assets/logoAKAWMS/logo-3A.png';

import './style.less';
import moment from 'moment';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff') || _get(user, 'roles', '').includes('inboundStaff');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isOperator = _get(user, 'roles', '').includes('operator');
  // const isManager = _get(user, 'roles', '').includes('manager');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');
  const [kind, setKind] = useState('NEW');
  const [status, setStatus] = useState(isStocker || isStaff ? 'NEW' : isIdm ? 'TEMPORARYCONFIRM' : isOperator ? '' : 'RECEIVE');
  const [statusInbound, setStatusInbound] = useState('NEW');
  const [params, setParams] = useState({ 
    ...defaultPage, status: isStocker || isStaff ? 'NEW' : 
    isIdm ? 'TEMPORARYCONFIRM' : 
    isOperator ? '' :
    'RECEIVE', 
    inboundkind: 'NEW' });
  const [loadingExport, setLoadingExport] = useState(false);
  //
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getReceipts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    receiptList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse?.id })
      .then(result => {
        result.data.map((value) => {
          value.shipmentNo = value.apInvoice?.shipmentNo;
          return value;
        });
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    getReceipts(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);

  const columns = [
    {
      title: lang.receiptCode,
      // dataIndex: 'code',
      key: 'code',
      render: (record) => {
        // if (isStaff) {
        //   return record?.inboundkind === 'RETURN' ? record?.documentNo : record?.code;
        // } else {
          // const dataTime = moment(record?.receiptDate, formatDateFull).format(formatNo);
          return (
            <Button type="link" size="small" onClick={() => navigate(`${record?.id}`)}>{record?.inboundkind === 'RETURN' ? record?.documentNo : record?.code}</Button>
          );
        // }
      },
    },
    {
      title: lang.inboundKind,
      dataIndex: 'inboundkind',
      key: 'inboundkind',
      render: val => lang[`RC${val}`],
    },
    {
      title: lang.requestReceipt,
      dataIndex: ['apInvoice', 'vendorInvoiceNo'],
      key: 'vendorInvoiceNo',
      hideInTable: params?.inboundkind === 'BRANCH',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipmentNo',
      key: 'shipmentNo',
      hideInTable: params?.inboundkind === 'BRANCH',
    },
    {
      title: 'Container',
      dataIndex: ['boothCode'],
      key: 'boothCode',
      hideInTable: params?.inboundkind === 'NEW',
    },
    {
      title: 'Biển số xe',
      dataIndex: ['driverName'],
      key: 'driverName',
      hideInTable: params?.inboundkind === 'NEW',
    },
    {
      title: lang.orderCode,
      dataIndex: ['orderNumber'],
      key: 'orderNumber',
      hideInTable: params?.inboundkind === 'NEW',
    },
    {
      title: lang.status + ' phiếu',
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        if (record === 'NEW') {
          return (
            <Tag>{'Mới tạo'}</Tag>
          );
        } else if (record === 'TEMPORARYCONFIRM') {
          return (
            <Tag>{'Thủ kho xác nhận'}</Tag>
          );
        } else {
          return (
            <Tag>{'IDM xác nhận'}</Tag>
          );
        }
      },
    },
    {
      title: lang.status + ' nhập hàng',
      key: 'statusProduct',
      dataIndex: 'statusProduct',
      render: (record) => {
        if (record === 'NEW' || record === null) {
          return (
            <Tag>{'Mới tạo'}</Tag>
          );
        } else if (record === 'INPROGRESS') {
          return (
            <Tag>{'Đang nhập kho'}</Tag>
          );
        } else {
          return (
            <Tag>{'Đã nhập kho'}</Tag>
          );
        }
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: lang.receiveDate,
      dataIndex: 'receiptDate',
      //sortOrder: sortedInfo.columnKey === 'createDate' && sortedInfo.order,
      key: 'receiptDate',
      render: value => moment(value, formatDateFull).format(formatDate),
      // sorter: true,
      // sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: lang.creator,
      dataIndex: 'creatorName',
      key: 'creatorName',
    },
  ];

  const extraButton = [
    <Button
      disabled={!(selectedRowKeys.length === 1)}
      key="btn-export"
      type="primary"
      loading={loadingExport}
      onClick={async () => {
        const receipt = await items;
        const masterProductFind = receipt?.find(item => item?.id === selectedRowKeys[0]);
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('p', 'mm', 'a4');
        const listProduct = receipt.find(e => e.id === selectedRowKeys[0]).products.reduce((prev, curr) => {
          const finded = receipt.find(e => e.id === selectedRowKeys[0]).receiptProduct.find(pro => pro?.masterProduct?.id === masterProductFind?.receiptProduct[0]?.masterProduct?.id);
          const warehouseFind = receipt.find(e => e.id === selectedRowKeys[0]).products.find(wh => wh?.warehouseId === curr?.warehouseId);
          // console.log(warehouseFind)
          const find = prev?.find(pre => pre?.some(pr => pr?.locator?.locator === curr?.locator?.locator));
          // console.log(find)
          if (find) {
            find.push({
              ...curr,
              warehouse: warehouseFind?.warehouse,
              masterProduct: finded?.masterProduct
            });
            return prev;
          }

          return [...prev, [{
            ...curr,
            warehouse: warehouseFind?.warehouse,
            masterProduct: finded?.masterProduct
          }]];
        }, []);

        listProduct.map((product, index) => {
          var content = '';
          setLoadingExport(true);
          if (product?.[0]?.locator) {
            content = `
            <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;">
              <div style="display:flex">
                <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 70%; ">
                  <div>
                    <p>
                      <h4>CÔNG TY TRÁCH NHIỆM HỮU HẠN DINH DƯỠNG 3A (VIỆT NAM)</h4>
                    </p>
                  </div>    
                  <div>
                    <p>
                      <span style="font-weight: bold;">Địa chỉ</span>: Centec Towe, 72-74, Nguyễn Thị Minh Khai, Phường Võ Thị Sáu, Q.3, TP Hồ Chí Minh.
                    </p>
                  </div>
              </div>
              <div style="margin-top: 20px; text-align:center; margin-left:20px; padding: 10px 0; line-height: 1; width: 30%; font-weight: bold">
                <div>
                  <p>Mẫu số 01 - VT</p>
                </div>
                <div>
                  <p>(Ban hành theo QĐ số 15/2006/QĐ-BTC <br> Ngày 20/03/2006 của Bộ trưởng BTC)</p>
                </div>
              </div>
              </div>
              <div style="display: flex; min-height: 120px;">
                <div style="text-align:center; width: 20%; border-image: initial; margin-top: -60px">
                  <img src="${logog3A}" style=" height: 100%; width: 100%" />
                </div>
                <div style="width: 60%; text-align: center; line-height: 1.2; margin-top: -10px">
                  <div style="font-size: 30px; font-weight:bold; ">PHIẾU NHẬP KHO</div>`;
          }

          const dateTime = new Date();
          const date = dateTime.getDate();
          const month = (dateTime.getMonth() + 1);
          const year = (dateTime.getFullYear());

          content += `
                    <div style="text-align: center;">Ngày ${date} tháng ${month} năm ${year}</div>
                    `;
          content += `
                  <div style="text-align: center;">Số: ${receipt?.find(e => e.id === selectedRowKeys[0]).code}</div>
                </div>
              </div>'
            
              <div style="margin-bottom: 10px; font-weight: bold">
                
                
                <div style="display: flex; margin-top: 10px">
                  <div style = "width: 40%; display: flex; height: 100%;">
                    <div style="width: 60%;">Họ và tên người giao: </div>
                    <div style="border-bottom: 1px solid; width: 90%;"></div>
                  </div>
                  
                </div>
                <div style="display: flex; margin-top: 10px">
                  <div style = "width: 80%; display: flex; height: 100%;">
                    <div style="width: 20%;">Diễn giải: ${product?.description ? product?.description : ''}</div>
                    <div style="width: 80%">${''}</div>
                  </div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style = "width: 60%; display: flex; height: 100%;">
                    <div style="width: 20%;">Nhập tại kho: </div>
                    <div style="width: 80%;">${product[0]?.locator?.locatorName} - ${product[0]?.locator?.locator}</div>
                  </div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style = "width: 100%; display: flex; height: 100%;">
                    <div style="width: 10%">Địa chỉ: </div>
                    <div style="width: 90%; margin-left: 20px">${product?.[0].warehouse.address}</div>
                  </div>
                </div>
              </div>
              
        
              <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
                <thead>
                  <tr style="text-align:center; font-weight: bold;">
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên, nhãn hiệu, qui cách, phẩm chất vật tư, dụng cụ SP, hàng hóa</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên SP viết tắt</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">ShipmentNo</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Số HĐ</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Thùng</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Lon</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Số lô</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Hạn dùng</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Mô tả</td>
                    </tr>
                </thead>
                <tbody>`;
                let totalQuantityCarton = 0
                let totalQuantitins = 0
                const listPro = product.reduce((prev, curr) => {
                  const fine = prev?.find((item) => item?.lot === curr?.lot && item?.expireDate === curr?.expireDate && item?.masterProduct.code3A === curr?.masterProduct.code3A)
                  totalQuantityCarton += curr.quantityCartton;
                  totalQuantitins += curr.quantityTin;
                  if (fine) {
                    fine.quantityCartton += curr?.quantityCartton;
                    fine.quantityTin += curr?.quantityTin;
                    return prev;
                  }
                  return [...prev, curr];
                }, [])
                // console.log(listPro)
                listPro.forEach((pro, indexpro) => {
            return content += `
                  <tr>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + indexpro}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.masterProduct.code3A} - ${pro.masterProduct.nameEN}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.masterProduct.shortName}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.shipment}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.contract}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantityCartton}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantityTin}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.lot}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${moment(pro.expireDate, formatDateFull).format(formatDate)}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro?.description ? pro?.description : ''}</td>
                  </tr>
                    `;
            
          });
          content += `
                    <tr>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">Total</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantityCarton}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantitins}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    </tr>
                </tbody>  
              </table>
            </div>`;
          var template = document.createElement('div');
          template.innerHTML = content;
          document.body.appendChild(template);
          html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
            const imgWidth = 208;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            var imgData = canvas.toDataURL('image/png');
            // eslint-disable-next-line new-cap
            pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
            if (index < listProduct.length - 1) {
              pdf.addPage();
            }
            if (index === listProduct.length - 1) {
              pdf.save(`PhieuNhapKho${moment().format('DDMMYYYY')}.pdf`);
            }
          });
          document.body.removeChild(template);
          setLoadingExport(false);
          return '';
        });
      }}
    >
      {'Xuất PNK'}
    </Button>,
    <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('create');
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/receipt',
      breadcrumbName: lang.receipt,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.receipt}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.receiveDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>
            <Col style={{ marginTop: 4 }}>
              <label>{'Loại nhập kho'}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Select defaultValue='NEW' onChange={(value) => { setKind(value); }} style={{ width: '100%' }} allowClear>
                <Option value="">{'Tất cả'}</Option>
                <Option value="NEW">{'NHẬP KHẨU'}</Option>
                <Option value="BRANCH">{'NHẬP CHI NHÁNH'}</Option>
                <Option value="INTERNAL">{'NHẬP NỘI BỘ'}</Option>
                <Option value="RETURN">{'TRẢ VỀ'}</Option>
              </Select>
            </Col>
            <Col style={{ marginTop: 4 }}>
              <label>{'Trạng thái phiếu'}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Select defaultValue={status} onChange={(value) => setStatus(value)} style={{ width: '100%' }} allowClear>
                <Option value="">{'Tất cả'}</Option>
                <Option value="NEW">{'Mới tạo'}</Option>
                <Option value="TEMPORARYCONFIRM">{'Thủ kho xác nhận'}</Option>
                <Option value="RECEIVE">{'IDM xác nhận'}</Option>
              </Select>
            </Col>
            <Col style={{ marginTop: 4 }}>
              <label>{'Trạng thái nhập hàng'}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Select defaultValue={statusInbound} onChange={(value) => setStatusInbound(value)} style={{ width: '100%' }} allowClear>
                <Option value="">{'Tất cả'}</Option>
                <Option value="NEW">{'Mới tạo'}</Option>
                <Option value="INPROGRESS">{'Đang nhập kho'}</Option>
                <Option value="SCANCOMPLETE">{'Đã nhập kho'}</Option>
              </Select>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} allowClear />
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (status) {
                    Object.assign(params, { status: status });
                  } else {
                    Object.assign(params, { status: undefined });
                  }

                  if (statusInbound) {
                    Object.assign(params, { productStatus: statusInbound });
                  } else {
                    Object.assign(params, { productStatus: undefined });
                  }

                  if (kind) {
                    params.inboundkind = kind;
                  } else params.inboundkind = undefined;

                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }

                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.receiptList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && params?.inboundkind !== 'BRANCH' && (
                <Button
                  type="primary"
                  onClick={() => {
                    // const listDataCheck = [];
                    const check = items?.some(item => selectedRowKeys.indexOf(item?.id) > -1 && item?.status === 'RECEIVE');
                    if (check === true) {
                      Modal.error({
                        title: 'Không thể xóa phiếu có trạng thái xác nhận nhập kho!',
                      });
                    } else if (check === false) {
                      Modal.confirm({
                        title: 'Bạn chắc chắn muốn xóa các mục đã chọn?',
                        icon: <ExclamationCircleOutlined />,
                        onOk() {
                          receiptDelete({
                            ids: selectedRowKeys,
                          })
                            .then(result => {
                              params.page = 1;
                              setParams({ ...defaultPage, status });
                              setSelectedRowKeys([]);
                              Modal.success({
                                title: lang.deleteReceiptsSuccessfully,
                                content: `${lang.receiptIdsHasBeenDeleted} ${JSON.stringify(items.filter(item => selectedRowKeys.indexOf(item.id) > -1 && item?.status !== 'RECEIVE')?.map(item => item.code))}`,
                              });
                            })
                            .catch((e) => message.error(e.message));
                        },
                        onCancel() {},
                      });
                    }
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={params?.status !== 'RECEIVE' && rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // if (sorter) {
            //   setSortedInfo({
            //     columnKey: sorter.columnKey,
            //     order: sorter.order,
            //   });
            // }
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 526 }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default ReceiptList;
