import { all } from 'redux-saga/effects';
import watchAuth from 'providers/AuthProvider/saga';
import watchLocation from 'providers/LocationProvider/saga';
import watchOutbound from 'providers/OutboundProvider/saga';

export default function * rootSaga() {
  yield all([
    watchAuth(),
    watchOutbound(),
    watchLocation(),
  ]);
}
