import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';

export async function receiptGifttList(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.receiptGift}/get-all/?${params}`, {}, 'GET');
}

export async function receiptGiftDetail(payload) {
  return request(`${environment.api.receiptGift}/get-one/${payload?.id}`, {}, 'GET');
}

export async function receiptGiftCreate(payload) {
  return request(environment.api.receiptGift + '/create', payload, 'POST');
}

export async function receiptGiftConfirm(id) {
  return request(environment.api.receiptGift + `/confirm/${id}`, {}, 'GET');
}

export async function createProductGiftbyPallet(payload) {
  return request(environment.api.productGifts + "/create/pallet", payload, 'POST');
}

export async function productGiftUpdateLocation(payload) {
  return request(environment.api.productGifts + "/update/location", payload, 'POST');
}

export async function deleteProductsGift(payload) {
  return request(environment.api.productGifts + '/deletes', payload, 'POST');
}

export async function updateReceiptGiftProduct(payload) {
  return request(`${environment.api.receiptGift}/update`, payload, 'PUT');
}

export async function getOneByStatus(payload) {
  return request(`${environment.api.receiptGift}/getOneByStatus/${payload?.id}`, {}, 'GET');
}

export async function deleteReceiptGift(payload) {
  return request(`${environment.api.receiptGift}/delete/${payload}`, {}, 'DELETE');
}





export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}


export async function packageList(payload) {
  return request(`${environment.api.packageList}`, {}, 'GET');
}

export async function deleteProducts(payload) {
  return request(environment.api.products + 'deletes', payload, 'POST');
}
export async function splitReceiptProduct(payload) {
  return request(environment.api.receiptProduct + '/split', payload, 'POST');
}
export async function deleteReceiptProduct(id) {
  return request(environment.api.receiptProduct + `/delete/${id}`, {}, 'DELETE');
}

