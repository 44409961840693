import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Card, Row, Col } from 'antd';
import './warehouse-style.less';
import ProCard from '@ant-design/pro-card';
import { blockList, zoneList } from 'pages/dashboard/configuration/location/location.service';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';

const scrollStyle = {
    overflowX: 'scroll',
    height: '85vh',
    margin: 'auto',
    width: '100%',
    marginBottom: 19,
};
const headeStyleZone = {
    margin: 0,
    border: 0,
    height: 0,
    minHeight: 0,
};
const headStyleBlock = {
    borderBottom: 0,
};

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const WarehouseState = () => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [listzone, setListzone] = useState([]);
    const [listblock, setListblock] = useState([]);
    const { currentWarehouse } = useSelector((state) => state.auth);

    //Pagination

    const fetchZones = async (currentWarehouse) => {
        const response = await zoneList({ warehouseId: currentWarehouse.id });
        setListzone(response?.data);
    };
    const fetchBlocks = async (currentWarehouse) => {
        const response = await blockList({ warehouseId: currentWarehouse.id });
        const dataremp = response.data.reduce((prev, current) => {
            const positionFind = prev?.find(pre => pre.position === current.position);
            if (positionFind) {
                positionFind.blocks.push(current);
                return prev;
            }
            return [...prev, {
                position: current.position,
                blocks: [current],
            }];
        }, []);
        setListblock([...dataremp]);
    };
    const recentShelf = (racks) => {
        // let sumUsed = 0;
        // let sumCap = 0;
        const ctesa = racks?.reduce((prev, current) => {
            if (current.floor !== 1) {
                prev.sumUsed = prev.sumUsed + current.usedCapacity;
                prev.sumCap = prev.sumCap + current.capacity;
            }
            return prev;
        }, { sumUsed: 0, sumCap: 0 });
        return Math.abs(ctesa.sumUsed / ctesa.sumCap) * 100 || 0;
    };

    useEffect(() => {
        fetchZones(currentWarehouse);
        const socket = io(process.env.SOCKET_URL);
        socket.on('msgToServerRack', (message) => {
            fetchBlocks(currentWarehouse);
        });
        fetchBlocks(currentWarehouse);
        return () => {
            socket.disconnect();
        };
    }, [currentWarehouse]);
    const routes = [
        {
            path: '/dashboard',
            breadcrumbName: 'Home',
        },
        {
            path: '/dashboard',
            breadcrumbName: lang.inventory,
        },
        {
            path: '/warehousestate',
            breadcrumbName: lang.warehouseState,
        },
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.warehouseState}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            //onBack={onBack}
            />
            <Layout.Content>
                <ProCard bodyStyle={scrollStyle} >
                    <Row gutter={16} className="RowMargin">
                        <Col flex="102px" className="ColLeft">
                            <Row gutter={[16, 16]}>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <span style={{ fontWeight: 'bold' }}>Zone</span>
                                </Col>
                                {listzone?.map((zone) =>
                                    <Col key={zone.id} span={24}>
                                        <Card className="CardStyle"
                                            style={{ background: '#dee0e0' }}
                                            headStyle={headeStyleZone}
                                            bodyStyle={{ padding: '24px 0px' }}
                                            onClick={() => navigate('/dashboard/inventory/warehousestate/warehousezone/' + zone.id)}
                                        >
                                            <p style={{ wordWrap: 'break-word' }}>{zone.name}</p>
                                        </Card>
                                    </Col>,

                                )}
                            </Row>
                        </Col>
                        <Col flex="auto">
                            <Row gutter={[16, 16]} style={{ minWidth: '1600px' }}>
                                <Col span={24}>
                                    <Row>
                                        <Col span={3}>
                                            <div className="statusSummary">
                                                <div style={{ background: '#E4595C' }}></div>
                                                <span>{lang.full}</span>
                                            </div>
                                        </Col>
                                        <Col span={3}>
                                            <div className="statusSummary">
                                                <div style={{ background: '#F2C94C' }}></div>
                                                <span>{lang.Medium}</span>
                                            </div>
                                        </Col>
                                        <Col span={3}>
                                            <div className="statusSummary">
                                                <div style={{ background: '#00FF00' }}></div>
                                                <span>{lang.availableProduct}</span>
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            <div className="statusSummary">
                                                <div style={{ background: '#EFF1F1' }}></div>
                                                <span>{lang.empty}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <div>
                                                <div style={{fontSize: '18px', fontWeight: 'bold', marginTop: '15px'}}>Tổng vị trí có hàng/Tổng vị trí trong kho: <span>10/100</span></div>
                                            </div>
                                        </Col>
                                    </Row> */}
                                </Col>
                                <Col>
                                    {listblock.map((columnPos) => (
                                        <Row gutter={[16, 32]} key={columnPos.position} wrap={false} style={{ marginBottom: 32, width: 'max-content' }}>
                                            {columnPos?.blocks.map((block) =>
                                                <Col key={block.id}>
                                                    <Card
                                                        title={<span style={{textAlign: 'center', display: 'block'}}>{block?.name} - {block?.shelfs?.reduce((prev, curr) => {
                                                            return [ prev?.[0] + curr?.rackUsed, prev?.[1] + curr?.totalRack ]
                                                        }, [0, 0]).join('/')}</span>}
                                                        style={{ width: 'max-content' }}
                                                        headStyle={headStyleBlock}
                                                        bodyStyle={{ padding: '0 12px', overflowY: 'scroll' }}
                                                        className="warehouseCardBlock" size="small"
                                                    // onClick={() => navigate('/dashboard/inventory/warehousestate/warehouseblock/' + block.id, { state: block })}
                                                    >
                                                        {
                                                            block?.shelfs?.reduce((prev, curr) => {
                                                                if (prev?.[curr.position - 1]) {
                                                                    prev[curr.position - 1] = [...prev[curr.position - 1], curr];
                                                                } else {
                                                                    prev = [...prev, [curr]];
                                                                }
                                                                return prev;
                                                            }, [])?.map((shelfs, index) => <Row key={index} gutter={[16, 16]} style={{ marginBottom: 8 }}>
                                                                {shelfs?.map(self => <><Col>
                                                                    <Card
                                                                        headStyle={headeStyleZone}
                                                                        style={{ background: '#EFF1F1', borderRadius: 4, height: '100%' }}
                                                                        className={recentShelf(self.racks) > self.high ? 'warehouseCardShelf fullClass' : recentShelf(self.racks) < self.medium ? 'warehouseCardShelf' : 'warehouseCardShelf mediumClass'}
                                                                        bodyStyle={{ padding: 0, height: '100%', display: 'flex', alignItems: 'flex-start' }}
                                                                    >
                                                                        <span style={{ wordWrap: 'break-word', width: '100%', alignSelf: 'center' }}>{self?.isDivedShelf ? `${self.name} (Xuyên sâu)` : self.name}</span>
                                                                        <span style={{ wordWrap: 'break-word', width: '100%', alignSelf: 'center', display: 'block', fontWeight: 'bold' }}>({`${self?.rackUsed}/${self?.totalRack}`})</span>
                                                                    </Card>
                                                                </Col>
                                                                    <Col>
                                                                        <Card headStyle={headeStyleZone} style={{ borderRadius: 4, marginTop: 5 }} bodyStyle={{ padding: '0 8px', display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
                                                                            {!self?.isDivedShelf ? self?.racks?.reduce((prev, curr, index) => {
                                                                                const finded = prev?.find((_, index) => index === curr.floor - 1);
                                                                                if (finded) {
                                                                                    finded.push({ ...curr, index });
                                                                                }
                                                                                return prev;
                                                                            }, [...Array(self?.floor)]?.map(i => []))?.reverse()?.map((val, idx) => <Row gutter={[16, 16]} key={idx}>
                                                                                {val?.map((rack, stt) => 
                                                                                <Col key={rack.index}>
                                                                                    <div 
                                                                                        onClick={() => {
                                                                                            rack.selfType = self?.type;
                                                                                            return navigate('/dashboard/inventory/warehousestate/warehouseblock/' + rack.id, { state: rack,});
                                                                                        }} 
                                                                                        style={{
                                                                                            width: 45, 
                                                                                            backgroundColor: 
                                                                                            rack.status !== 'ENABLE' ? 'grey' 
                                                                                            : rack.floor === 1 && rack.usedCapacity > 0 ? '#00FF00'  //Xanh, chỉ ở tầng 1
                                                                                            : (rack?.usedCapacity / rack?.capacity) * 100 > self.high ? '#E4595C'  //đỏ
                                                                                            : (rack?.usedCapacity / rack?.capacity) * 100 < self.medium ? rack?.usedCapacity === 0 ? '#EFF1F1' // xám
                                                                                            : '#00FF00' 
                                                                                            : '#F2C94C', 
                                                                                            textAlign: 'center' 
                                                                                        }}>
                                                                                        {`T${rack?.floor}_${stt + 1 >= 10 ? stt + 1 : '0' + (stt + 1)}`}
                                                                                    </div>
                                                                                </Col>).sort(() => -1)}
                                                                            </Row>) : self?.racks?.reduce((prev, curr, index) => {
                                                                                const finded = prev?.find((_, index) => index === curr.floor - 1);
                                                                                if (finded) {
                                                                                    const bin = finded?.find((val) => val?.[0]?.bin === curr.bin);
                                                                                    if (bin) {
                                                                                        bin.push({ ...curr });
                                                                                    } else finded.push([{ ...curr }]);
                                                                                }
                                                                                return prev;
                                                                            }, [...Array(self?.floor)]?.map(i => []))?.sort(() => -1)?.map((racks, idx) =>
                                                                                <Row gutter={[16, 16]} key={idx} style={{ paddingLeft: 8 }}>
                                                                                    {
                                                                                        racks?.sort(() => -1).map((rack, ind) => <Row gutter={[16, 16]} key={ind} style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
                                                                                            {rack?.sort((a, b) => b.id - a.id)?.map((r) => {
                                                                                                const arr = r?.code?.split('_');
                                                                                                return <Col key={r.id}>
                                                                                                    <div onClick={() => {
                                                                                                        r.selfType = self?.type;
                                                                                                        return navigate('/dashboard/inventory/warehousestate/warehouseblock/' + r.id, { state: r })
                                                                                                    }} 
                                                                                                        style={{ width: 45, backgroundColor: r.status !== 'ENABLE' ? 'grey' : r.floor === 1 && r.usedCapacity > 0 ? '#00FF00' : (r?.usedCapacity / r?.capacity) * 100 > self.high ? '#E4595C' : (r?.usedCapacity / r?.capacity) * 100 < self.medium ? r?.usedCapacity === 0 ? '#EFF1F1' : '#00FF00' : '#F2C94C', textAlign: 'center' }}>
                                                                                                        {[arr[1], arr[2]].join('_')}
                                                                                                    </div>
                                                                                                </Col>;
                                                                                            })}
                                                                                        </Row>)}
                                                                                </Row>,
                                                                            )}
                                                                        </Card>
                                                                    </Col>
                                                                </>)}
                                                            </Row>)
                                                        }
                                                    </Card>
                                                </Col>,
                                            )}
                                        </Row>))}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </ProCard>
            </Layout.Content>
        </Layout>
    );
};

export default WarehouseState;
