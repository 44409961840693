import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, Input, Tag, Form, DatePicker, Select } from 'antd'; //message, Modal
import { SearchOutlined } from '@ant-design/icons'; //InboxOutlined
import { bookingList} from './picking.service'; // orderCheck, orderUpdateStatus, suggestLock, importExe
import { useSelector } from 'react-redux'; //useDispatch
// import { bookingOrderSet } from 'providers/OutboundProvider/actions';
import './style.less';
import moment from 'moment-timezone';
import { defaultPage, formatDate, formatDateFull, STATUS_INTERNAL_TRANFER } from 'utils/constants';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
// import Helper from 'utils/helpers';
// import Dragger from 'antd/lib/upload/Dragger';
// import jsPdf from 'jspdf';
// import html2canvas from 'html2canvas';
// import SuggestLocationForm from './suggest-location.form';
import { Option } from 'antd/lib/mentions';
const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

// const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const InternalTranferList = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  //Roles
  const { currentWarehouse } = useSelector((state) => state.auth); //user

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  // const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const [items, setItems] = useState([]);
  //Pagination
  const [params, setParams] = useState({ status: 'EXPORTED', ...defaultPage });
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  // const [pageSize, SetPageSize] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [searchCode, setSearchCode] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [form] = Form.useForm();
  // const [uploadModal, setUploadModal] = useState(false);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [file, setFile] = useState();
  // const [initDataOrders, setInitDataOrders] = useState([]);
  // const [item, setItem] = useState();

  useEffect(() => {
    setLoading(true);
    bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
        // if (params?.status === 'NEW') {
        //   const rempData = result.data?.reduce((prev, curr) => {
        //     const finded = prev?.find(order => order?.group && order.group === curr.group);
        //     if (finded) {
        //       finded.code = `${finded.code}, ${curr.code}`;
        //       return prev;
        //     }
        //     return [...prev, { ...curr }];
        //   }, []);
        //   setItems(rempData);
        //   setInitDataOrders(result.data);
        //   setTotalItems(result.totalItem);
        // } else {
          setItems(result.data);
          setTotalItems(result.totalItem);
        // }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);
  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      // width: '20%',
      // dataIndex: 'code',
      render: rec => <Button
        key="btn-detail"
        type="link"
        onClick={() => navigate(`${rec.id}`)}
        size='small'
      >
        {rec.code}
      </Button>,
    },
    {
      title: lang.documentNo,
      key: 'documentNo',
      dataIndex: 'documentNo',
    },
    {
      title: lang.locatorTo,
      dataIndex: 'locatorCode',
      key: 'locatorCode',
      // width: '20%',
    },
    {
      title: lang.salesChannel,
      key: 'channel',
      dataIndex: ['channel'],
      render: val => val && val?.channelName + ' - ' + val?.channel,
    },
    {
      title: lang.tranferReason,
      dataIndex: 'tranferReason',
      key: 'tranferReason',
      // width: '20%',
    },
    {
      title: 'Loại phiếu xuất',
      dataIndex: 'orderType',
      key: 'orderType',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
      // width: '20%',
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      // width: '20%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.userName,
      dataIndex: 'userId',
      key: 'userId',
      // width: '20%',
      render: value => value ? value.split(' - ')?.[1] : undefined,
      hideInTable: params?.status !== 'LOCK',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '15%',
      render: (record) => {
        if (record === 'VAS') {
          return <Tag>{'Đóng VAS thành công'}</Tag>
        } else {
          return (
            <Tag>{STATUS_INTERNAL_TRANFER[record]}</Tag>
          );
        }
      },
    },
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/dashboard/inbound/completed-vas',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/internal-tranfer',
      breadcrumbName: 'Đóng Vas thành công'
    },
  ];

  return (
    <Layout className="picking-list">
      <PageHeader
        ghost={false}
        title={'Vas for base product'}
        // extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={[16, 16]}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6}>
              <Form.Item name={'status'} label={'Trạng thái'}>
              <Select placeholder={'Tìm kiếm theo trạng thái'} defaultValue={'EXPORTED'}  style={{ width: '100%' }} allowClear>
                <Option value="EXPORTED">{'Soạn hàng thành công'}</Option>
                <Option value="VAS">{'Đóng VAS thành công'}</Option>
              </Select>
              </Form.Item>
            </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.search}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card size="small" className="cardSearch">
          <Row style={{ marginBottom: 0 }}>
            <Col span={12} style={{ display: 'flex' }}>
              <h3>{`Danh sách ${lang.EXPORT_INTERNAL}`}</h3>
              <div style={{ lineHeight: '27px', marginLeft: '100px' }}>{`Tổng số phiếu xuất: ${totalItems}`}</div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                // rowSelection={!isStaff && rowSelection}
                // rowSelection={params?.status !== 'PICKING' && {
                //   onChange: (_, selectedRows) => {
                //     setSelectedRowKeys(selectedRows);
                //   },
                // }}
                className="tableCustom"
                loading={loading}
                bordered
                columns={columns}
                dataSource={items}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  if (pagination.pageSize !== params.limit) {
                    params.page = 1;
                  } else {
                    params.page = pagination.current;
                  }
                  if (sorter) {
                    setSortedInfo({ sortDirection: sorter.order, sortBy: sorter.field });
                  }
                  params.limit = pagination.pageSize;
                  setParams({ ...params });
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalItems,
                  pageSize: params.limit,
                  current: params.page,
                }}
                scroll={{ x: 600 }}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
      {/* <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(formData, currentWarehouse.id);
              setFile();
            }
            setParams({ ...params });
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ 'Order Code': '', Group: '' }], 'TemplateOrders')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal> */}
      {/* <Modal
        visible={isSuggestLocationModal}
        title={lang.suggestLocation}
        onCancel={async () => {
          await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
          setIsSuggestLocationModal(false);
        }}
        destroyOnClose={true}
        width={1220}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <SuggestLocationForm
          item={selectedRowKeys}
          totalItems={totalItems}
          productOrders={selectedRowKeys?.reduce((prev, curr) => {
            const productOrders = curr?.productOrders?.filter(product => product.status === 'ENABLE' || product.status === 'PENDING')?.map(product => {
              product.orderCode = curr.code;
              product.orderId = curr.id;
              product.pickedQuantityCartton = 0;
              product.pickedQuantityTin = 0;
              product.groupOrder = curr.group;
              return product;
            });
            return [...prev, ...productOrders];
          }, [])}
          onCallback={async (values) => {
            const content = `
        <div style="display: grid ;line-height: 0.5; width: 900px; font-family: "Times New Roman", Times, serif;">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <h3>Công ty TNHH PinNow Việt Nam</h3>
            <p>134 Bạch Đằng, Phường 2, Q.Tân Bình,</p>
            <p>0316954732</p>
          </div>
          <div style="text-align: center;">
            <h3>Mẫu số: 01-VT*</h3>
            <p>Ban hành theo thông tư</p>
            <p>số 200/2014/TT-BTC</p>
            <p>ngày 22/12/2014 của Bộ</p>
          </div>
        </div>
        <div style="text-align: center;">
          <h1>DANH SÁCH GỢI Ý SẢN PHẨM</h1>
          <p>Ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')}</p>
        </div>
        <div>
          <table border="1" style="width: 100%; border-collapse: collapse;margin-top: 10px; line-height: 1;">
            <thead>
              <tr style="height: 40px;">
                <th>STT</th>
                <th>Mã sản phẩm</th>
                <th>Mã lô sản phẩm</th>
                <th>Nhóm hàng</th>
                <th>Tên sản phẩm</th>
                <th>Block</th>
                <th>Shelf</th>
                <th>Rack</th>
                <th>Số lượng</th>
              </tr>
            </thead>
            <tbody>
              ${values?.map((product, index) => {
              return `<tr style="text-align: center; height: 40px;">
                    <td>${index + 1}</td>
                    <td>${product.masterProduct.barCode}</td>
                    <td>${product.code}</td>
                    <td>${product.masterProduct.productCategory?.name}</td>
                    <td>${product.masterProduct.name}</td>
                    <td>${product?.block?.name}</td>
                    <td>${product?.rack?.shelf?.name}</td>
                    <td>${product?.rack?.code}</td>
                    <td>${product?.totalQuantity}</td>
                </tr>`;
            }).join(' \n')}
            </tbody>
          </table>
        </div>
      </div>`;
            var template = document.createElement('div');
            template.innerHTML = content;
            document.body.appendChild(template);
            await html2canvas(template, { width: 1000, scale: 3 }).then((canvas) => {
              const imgWidth = 208;
              const imgHeight = canvas.height * imgWidth / canvas.width;
              var imgData = canvas.toDataURL('image/png');
              // eslint-disable-next-line new-cap
              const pdf = new jsPdf('p', 'mm', 'a4');
              pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
              pdf.save(`SuggestProducts${moment().format('DDMMYYYY')}.pdf`);
            });
            document.body.removeChild(template);
            setIsSuggestLocationModal(false);
          }}
          onCancel={async () => {
            await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
            setIsSuggestLocationModal(false);
          }}
          onLock={async (values) => {
            try {
              await Promise.all(values?.map(value => suggestLock(value)), orderUpdateStatus({ ids: selectedRowKeys?.map(order => order?.id), status: 'LOCK' }));
              message.success(lang.lockproductSuccessfully);
              await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
              setParams({ ...params });
              setSelectedRowKeys([]);
              setIsSuggestLocationModal(false);
              return true;
            } catch (error) {
              message.error(error.message);
              await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
              return false;
            }
          }}
          productPicked={[]}
        />
      </Modal> */}
    </Layout>
  );
};

export default InternalTranferList;
