/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
// import { ProFormSelect } from '@ant-design/pro-form';
import './style.less';
import Helper from 'utils/helpers';
import { blockList } from 'pages/dashboard/configuration/location/location.service';

const { Option } = Select;

const ReceiptMaterialProductEditForm = ({ item, products, productsSelected, warehouseId, setIsEditModal, onCallback, isOnlyQuantityAllowForUpdate = false, isEditModal, kind }) => {
  const [form] = Form.useForm();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [loading, setLoading] = useState(false);
  const [selectRackId, setSelectRackId] = useState();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  useEffect(() => {
    blockList({warehouseId: currentWarehouse?.id})
      .then(result => {
        const dataRack = result?.data[0].shelfs.find(item => item?.type === 'MATERIAL');
        setSelectRackId(dataRack);
      })
  }, [currentWarehouse])

  const initValues = {
    masterProductId: item ? item?.masterProduct.id : undefined,
    DO: item ? item?.DO : undefined,
    lineNo: item ? item?.lineNo : undefined,
    quantityTin: item ? item?.quantityTin : undefined,
    quantityCarttonReceived: item ? item?.quantityCarttonReceived : undefined,
    quantityTinReceived: item ? item?.quantityTinReceived : undefined,
    actualQuantityErrorTin: item ? item?.actualQuantityErrorTin : undefined,
    actualQuantityReceivedCartonPallet: item ? item?.actualQuantityReceivedCartonPallet : undefined,
    DVT: item ? item?.DVT : undefined,
    expireDate: item ? item?.expireDate : null,
    lot: item ? item?.lot : undefined,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
      onCancel={onCancel}
      width={980}
      centered
      footer={null}
      wrapClassName="receipt-product-edit-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="receiptProductEditForm"
        onFinish={(values) => {
          setLoading(true);
          const obj = products.find(x => x.code === values.codeMaterial);
          values.rack = selectRackId?.racks.find(itemRackById => itemRackById?.id === values?.rackId)
          onCallback({ bomProduction: obj, ...values, key: item?.id });
          setLoading(false);
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item name="codeMaterial" label={lang.productName}>
              <Select
                placeholder={lang.productName}
                onChange={(value) => {
                  const obj = products.find(x => x.id === value);
                  form.setFieldsValue({ DVT: obj?.UOM_VN });
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                }
              >
                {products?.map(product => {
                  return <Option key={product.id} value={product.code}>{product.name} - {product.code}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="shipment"
              label={lang.shipment}
            >
              <Input
                style={{ width: '100%' }} placeholder={lang.shipment}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="lineNo"
              label={'Line NO'}
            >
              <Input
                style={{ width: '100%' }} placeholder={'Line NO'}
              />
            </Form.Item>
          </Col> */}
          {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="orderReference"
              label={lang.orderReference}
            >
              <Input
                style={{ width: '100%' }} placeholder={lang.orderReference}
              />
            </Form.Item>
          </Col> */}
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="contractRefNo"
              label={'ContractRefNo '}
            >
              <Input
                style={{ width: '100%' }} placeholder={'ContractRefNo'}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="description"
              label={lang.description}
            >
              <Input
                style={{ width: '100%' }} placeholder={lang.description}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="quantity"
              label={lang.quantity}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item name="rackId" 
            label={'Chọn Rack'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn rack Rack!',
              },
            ]}
            >
              <Select
                placeholder={lang.rack}
                // onChange={(value) => {
                //   const obj = products.find(x => x.id === value);
                //   form.setFieldsValue({ DVT: obj?.UOM_VN });
                // }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                }
              >
                {selectRackId?.racks?.map(rackId => {
                  return <Option key={rackId.id} value={rackId.id}>{rackId.code}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

ReceiptMaterialProductEditForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsSelected: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
  warehouseId: PropTypes.number,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  isEditModal: PropTypes.bool,
  kind: PropTypes.string,
};

export default ReceiptMaterialProductEditForm;
