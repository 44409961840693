import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Tag, Select, Modal, message } from 'antd';
import { PlusOutlined, SearchOutlined, ExclamationCircleOutlined,DeleteOutlined } from '@ant-design/icons';
// import html2canvas from 'html2canvas';
// import jsPdf from 'jspdf';
import { receiptMaterial, deleteReceiptBomProduction } from './material.service';
import _get from 'lodash/get';
// import logog3A from 'assets/logoAKAWMS/logo-3A.png';

import './style.less';
import moment from 'moment';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const MaterialList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');
  // const isOperator = _get(user, 'roles', '').includes('operator');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isInboundStaff = _get(user, 'roles', '').includes('inboundStaff');

  const [items, setItems] = useState([]);
  const [inboundkind, setInboundKind] = useState('');
  const [totalItem, setTotalItem] = useState(0);

  const [params, setParams] = useState({ 
    ...defaultPage, status: isStocker ? 'NEW' : 
    isIdm ? 'TEMPORARYCONFIRM' : 
    isInboundStaff ? '' :
    'RECEIVE', 
    // inboundkind: 'NEW' 
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  // const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState(isStocker ? 'NEW' : isIdm ? 'TEMPORARYCONFIRM' : isInboundStaff ? '' : 'RECEIVE');
  // const [loadingExport, setLoadingExport] = useState(false);
  //
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getReceiptMaterial = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    receiptMaterial({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    getReceiptMaterial(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);

  const columns = [
    {
      title: lang.receiptCode,
      // dataIndex: 'code',
      key: 'code',
      render: (record) => {
          return (
            <Button type="link" size="small" onClick={() => navigate(`${record?.id}`)}>{record?.documentNo}</Button>
          );
      },
    },
    {
      title: lang.inboundKind,
      dataIndex: 'inboundkind',
      key: 'businessPartner',
      render: val => val === 'IMPORT' ? 'Nhập thường' : 'Nhập chi nhánh'
    },
    {
      title: 'Ngày nhập',
      dataIndex: 'movementDate',
      //sortOrder: sortedInfo.columnKey === 'createDate' && sortedInfo.order,
      key: 'movementDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
      // sorter: true,
      // sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        return (
          record === 'NEW' ? <Tag>{'Mới tạo'}</Tag> : 
          record === 'TEMPORARYCONFIRM' ? <Tag>{'Thủ kho xác nhận'}</Tag> :
          record === 'CANCELED' ? <Tag>{'Đã hủy'}</Tag> :
          <Tag>{'IDM xác nhận'}</Tag> 
        );
      },
    },
    {
      title: lang.description,
      dataIndex: 'description',
      key: 'description',
    },
    {
    title: 'Từ kho',
    dataIndex: 'warehouseFrom',
    key: 'warehouseFrom',
    },
  ];

  const extraButton = [
    // <Button
    //   disabled={ !(selectedRowKeys.length === 1) }
    //   key="btn-export"
    //   type="primary"
    //   ghost
    //   loading={loadingExport}
    //   onClick={async () => {
    //     const receipt = await items;
    //     // eslint-disable-next-line new-cap
    //     const pdf = new jsPdf('p', 'mm', 'a4');
    //     const listProduct = receipt.find(e => e.id === selectedRowKeys[0]).products.reduce((prev, curr) => {
    //       const prevItem = prev?.find(item => (item?.locator?.locatorName === curr?.locator?.locatorName));
    //       if (prevItem) {
    //         prevItem.quantityCartton += curr.quantityCartton;
    //         prevItem.quantityTin += curr.quantityTin;
    //         return prev;
    //       }

    //       if (curr.locator) {
    //         return [...prev, { ...curr }];
    //       } else {
    //         return [...prev];
    //       }
    //     }, []);

    //     listProduct.map((product, index) => {
    //       var content = '';
    //         setLoadingExport(true);
    //         content = `
    //         <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;">
    //           <div style="display:flex">
    //             <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 70%; ">
    //               <div>
    //                 <p>
    //                   <h4>CÔNG TY TRÁCH NHIỆM HỮU HẠN DINH DƯỠNG 3A (VIỆT NAM)</h4>
    //                 </p>
    //               </div>
    //               <div>
    //                 <p>
    //                   <span style="font-weight: bold;">Địa chỉ</span>: Centec Towe, 72-74, Nguyễn Thị Minh Khai, P.6, Q.3, TP Hồ Chí Minh.
    //                 </p>
    //               </div>
    //           </div>
    //           <div style="margin-top: 20px; text-align:center; margin-left:20px; padding: 10px 0; line-height: 1; width: 30%; font-weight: bold">
    //             <div>
    //               <p>Mẫu số 01 - VT</p>
    //             </div>
    //             <div>
    //               <p>(Ban hành theo QĐ số 15/2006/QĐ-BTC <br> Ngày 20/03/2006 của Bộ trưởng BTC)</p>
    //             </div>
    //           </div>
    //           </div>
    //           <div style="display: flex; min-height: 120px;">
    //             <div style="text-align:center; width: 20%; border-image: initial; margin-top: -60px">
    //               <img src="${logog3A}" style=" height: 100%; width: 100%" />
    //             </div>
    //             <div style="width: 60%; text-align: center; line-height: 1.2; margin-top: -10px">
    //               <div style="font-size: 30px; font-weight:bold; ">PHIẾU NHẬP KHO</div>`;

    //           const dateTime = new Date();
    //           const date = dateTime.getDate();
    //           const month = (dateTime.getMonth() + 1);
    //           const year = (dateTime.getFullYear());

    //           content += `
    //                 <div style="text-align: center;">Ngày ${date} tháng ${month} năm ${year}</div>
    //                 `;
    //           content += `
    //               <div style="text-align: center;">Số: ${receipt?.find(e => e.id === selectedRowKeys[0]).code}</div>
    //             </div>
    //           </div>'

    //           <div style="margin-bottom: 10px; font-weight: bold">

    //             <div style="display: flex; margin-top: 10px">
    //               <div style = "width: 40%; display: flex; height: 100%;">
    //                 <div style="width: 60%;">Họ và tên người giao: </div>
    //                 <div style="border-bottom: 1px solid; width: 90%;"></div>
    //               </div>

    //             </div>
    //             <div style="display: flex; margin-top: 10px">
    //               <div style = "width: 80%; display: flex; height: 100%;">
    //                 <div style="width: 20%;">Diễn giải: ${product?.description ? product?.description : ''}</div>
    //                 <div style="width: 80%">${''}</div>
    //               </div>
    //             </div>
    //             <div style="display: flex; margin-top: 10px;">
    //               <div style = "width: 60%; display: flex; height: 100%;">
    //                 <div style="width: 20%;">Nhập tại kho: </div>
    //                 <div style="width: 80%;">${product?.locator?.locatorName}</div>
    //               </div>
    //             </div>
    //             <div style="display: flex; margin-top: 10px;">
    //               <div style = "width: 100%; display: flex; height: 100%;">
    //                 <div style="width: 10%">Địa chỉ: </div>
    //                 <div style="width: 90%; margin-left: 20px">${product.warehouse.address}</div>
    //               </div>
    //             </div>
    //           </div>

    //           <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
    //             <thead>
    //               <tr style="text-align:center; font-weight: bold;">
    //                   <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên, nhãn hiệu, qui cách, phẩm chất vật tư, dụng cụ SP, hàng hóa</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px;">Mã SP</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px;">Số HĐ</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px;">Thùng</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px;">Lon</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px;">Số lô</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px;">Hạn dùng</td>
    //                 </tr>
    //             </thead>
    //             <tbody>`;

    //             receipt[0].receiptProduct.map((data) => {
    //             content += `
    //               <tr>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + index}</td>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${data.masterProduct.code3A} - ${data.masterProduct.nameEN}</td>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${data.masterProduct.shortName}</td>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.contract} - ${moment(product.mfgDate, formatDateFull).format(formatDateSlash)}</td>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityCartton}</td>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityTin}</td>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.lot}</td>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${moment(product.expireDate, formatDateFull).format(formatDate)}</td>
    //               </tr>
    //                 `;
    //                 return '';
    //               });
    //             content += `
    //                 <tr>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">Total</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityCartton}</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityTin}</td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                     <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 </tr>
    //             </tbody>
    //           </table>
    //         </div>`;
    //         var template = document.createElement('div');
    //         template.innerHTML = content;
    //         document.body.appendChild(template);
    //           html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
    //               const imgWidth = 208;
    //               const imgHeight = canvas.height * imgWidth / canvas.width;
    //               var imgData = canvas.toDataURL('image/png');
    //               // eslint-disable-next-line new-cap
    //               pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
    //               if (index < listProduct.length - 1) {
    //                 pdf.addPage();
    //               }
    //               if (index === listProduct.length - 1) {
    //                pdf.save(`PhieuNhapKho${moment().format('DDMMYYYY')}.pdf`);
    //               }
    //           });
    //         document.body.removeChild(template);
    //         setLoadingExport(false);
    //         return '';
    //     });
    //   }}
    // >
    //   {lang.exportPDF}
    // </Button>,
    isInboundStaff && <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('create');
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/receipt-gift',
      breadcrumbName: 'Phiếu nhập material',
    },
  ];
  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={'Phiếu nhập material'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.receiveDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>

            {/* <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
            </Col> */}
            <Col style={{ marginTop: 4 }}>
              <label>{lang.status}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Select placeholder={lang.status} defaultValue={status} onChange={(value) => setStatus(value)} style={{ width: '100%' }} allowClear>
              <Option value="">{'Tất cả'}</Option>
                <Option value="NEW">{'Mới tạo'}</Option>
                <Option value="CANCELED">{'Đã hủy'}</Option>
                <Option value="TEMPORARYCONFIRM">{'Thủ kho xác nhận'}</Option>
                <Option value="RECEIVE">{'IDM xác nhận'}</Option>
              </Select>
            </Col>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.inboundKind}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              {/* <Input placeholder={lang.keyword} onChange={(val) => setInboundKind(val.target.value)} allowClear /> */}
              <Select defaultValue={inboundkind} placeholder={lang.keyword} onChange={(val) => setInboundKind(val)} style={{ width: '100%' }} allowClear>
              <Option value="">{'Tất cả'}</Option>
                <Option value="IMPORT">{'Nhập thường'}</Option>
                <Option value="BRANCH">{'Nhập chi nhánh'}</Option>
              </Select>
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (status) {
                    Object.assign(params, { status: status });
                  } else {
                    Object.assign(params, { status: '' });
                  }
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }

                  params.page = 1;
                  setParams({ ...params, inboundkind });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{'Danh sách phiếu nhập Material'}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      async onOk() {
                        try {
                        setLoading(true)
                        await selectedRowKeys?.map(async bomPro => {
                        await deleteReceiptBomProduction(bomPro?.id);
                        receiptMaterial({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
                        .then(result => {
                            setLoading(true)
                            setItems(result.data);
                            setTotalItem(result.totalItem);
                            setLoading(false);
                        })
                        .catch((e) => setLoading(false));
                        })
                        setSelectedRowKeys([]);
                        setLoading(false)
                        Modal.success({
                            title: lang.deleteReceiptsSuccessfully,
                            content: `${'Đã xóa thành công phiếu có mã:'} ${selectedRowKeys.map(item => item?.id)}`,
                        });
                        } catch (error) {
                           message.error(error) 
                           setLoading(false)
                        }
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={{
            onChange: (_, selectedRows) => {
                setSelectedRowKeys(selectedRows);
              },
              getCheckboxProps: (record) => ({
                disabled: record.status === 'RECEIVE', // Column configuration not to be checked
                name: record.status,
              }),
          }}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });

            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 526 }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default MaterialList;
