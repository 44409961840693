import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, Select, Input, Tag, Form, message, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { bookingList } from './export-branch-gift.service'; //orderCheck
import { useDispatch, useSelector } from 'react-redux';
import { bookingOrderSet } from 'providers/OutboundProvider/actions';
import './style.less';
import moment from 'moment-timezone';
import { defaultPage, formatDate, formatDateFull, STATUS_OUTBOUND } from 'utils/constants';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import _get from 'lodash/get';
// import { userList } from 'pages/admin/user/user.service';
// import { warehouseListAll } from 'pages/admin/warehouse/warehouse.service';
// import { orderUpdateStatus } from '../picking/picking.service';
const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ExportBranchList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);
  // const isManager = _get(user, 'roles', '').includes('manager');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  // const isIdm = _get(user, 'roles', '').includes('idm');
  // const isOutboundStaff = _get(user, 'roles', '').includes('outboundStaff');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  
  //Pagination
  // const [pickers, setPickers] = useState([]);
  const [params, setParams] = useState({ status: isPicker ? 'LOCK' || 'COMPLETE' || 'PICKING' : 'NEW', ...defaultPage });
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  const [form] = Form.useForm();
  // const [userId, setUserId] = useState([]);
  // const [warehouses, setWarehouses] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState(isPicker ? 'LOCK' : 'NEW');

  useEffect(() => {
    setLoading(true);
    bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
      if (isPicker) {
        const findByUserId = result.data.filter(item => item?.userId === null && item?.assignAll === true)
        const findDo = result.data.filter(item => {
          const idString = JSON.stringify(item?.userId);
          if (idString.indexOf(user?.userInfo?.sub) !== -1) {
            return item;
          }
          return '';
        });

        //TH vừa có đơn k asign và vừa có đơn asign
        if (findByUserId?.length > 0) {
          const result = findByUserId.concat(findDo)
          setItems(result)
        } 
        // TH lọc ra những đơn đã asign
        else if (findDo.length > 0) {
          setItems(findDo)
        } else {
          setItems([])
        }
      } else {
        setItems(result.data);
      }
      setTotalItems(result.totalItem);
      setLoading(false);
    })
    .catch((e) => setLoading(false));
  
    }, [params, sortedInfo, currentWarehouse, isPicker, user.userInfo.sub]);
  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      // width: '20%',
      // dataIndex: 'code',
      render: rec => <Button
        key="btn-detail"
        type="link"
        onClick={() => navigate(`/dashboard/outbound/gift-export-branch/${rec.id}`)}
        size='small'
      >
        {rec.documentNo}
      </Button>,
    },
    {
      title: 'Mã lệnh điều động',
      key: 'IDDNo',
      dataIndex: 'IDDNo',
    },
    {
      title: lang.truckType,
      key: 'truckType',
      dataIndex: 'truckType',
    },
    {
      title: 'Tải trọng xe (tấn)',
      key: 'truckWeight',
      dataIndex: 'truckWeight',
    },
    {
      title: `${lang.Locator} (Transit)`,
      key: 'locatorTransitCode',
      dataIndex: 'locatorTransitCode',
    },
    {
      title: lang.tranferReason,
      dataIndex: 'tranferReason',
      key: 'tranferReason',
    },
    {
      title: lang.movementDate,
      dataIndex: 'movementDate',
      key: 'movementDate',
      render: (record) => moment(record, formatDateFull).format(formatDate),
    },
    {
      title: 'Loại phiếu xuất',
      dataIndex: 'orderGiftType',
      key: 'orderGiftType',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
      // width: '20%',
    },
    {
      title: lang.status,
      dataIndex: 'status',
      key: 'status',
      render: val => {
        if (val === 'NEW') {
          return <Tag>{'Mới tạo'}</Tag>
        } else if (val === 'LOCK') {
          return <Tag>{'Chờ Soạn'}</Tag>
        } else if (val === 'PICKING') { 
          return <Tag>{'Đang Soạn'}</Tag>
        } else if (val === 'COMPLETE') {
          return <Tag>{'Đã Lấy Hàng'}</Tag>
        } else return <Tag>{'Hoàn Tất Xuất Hàng'}</Tag>
      },
    },
    // {
    //   title: 'User asignned',
    //   dataIndex: 'userId',
    //   key: 'userId',
    //   hideInTable: true, //
    //   render: (value, record) => {
    //     const idString = JSON.stringify(value)
    //     if (!(record?.userIdPicked)) {
    //       const data = pickers.map((item) => item);
    //       const findUser = data.filter(user => user?.roles.length > 0)
    //       const checkUser = findUser.map(itemUser => {
    //         if ( idString.indexOf(itemUser.id) !== -1 ) {
    //           return itemUser
    //         }
    //         return '';
    //       })
    //       const listId = checkUser.filter(data => data !== '');
    //       return listId.map((i, index) =>index === listId.length-1 ? i.username  : i.username + ' , ' );
    //     }  
    //   },
    // },
    // {
    //   title: 'User Picked',
    //   dataIndex: 'userIdPicked',
    //   key: 'userIdPicked',
    //   hideInTable: true, //
    //   render: (value) => {
    //     const data = pickers.map((item) => item);
    //     const findUser = data.filter(user => user?.roles.length > 0)
    //     const checkUser = findUser.map(itemUser => {
    //       if (itemUser.id === value  ) {
    //         return itemUser
    //       }
    //       return '';
    //     })
    //    for(let name of checkUser){
    //     if (name){
    //       return <Tag>{name.username}</Tag>
    //     }
    //    }
         
    //   },
    // },
  ];

  const extraButton = [
    // params?.status === 'LOCK' && !isPicker && !isManager && <Button
    // disabled={selectedRowKeys.length === 0 }
    // key="btn-save"
    // type='primary'
    // onClick={async () => {
    //   Modal.confirm({
    //     title: "Bạn có chắc chắn muốn Assign?",
    //     async onOk() {
    //       try {     
    //         setLoading(true)
    //         await orderUpdateStatus({ids: selectedRowKeys?.map(order => order?.id), assignAll: true}) //, userId: userId
    //         bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
    //           .then(result => {
    //             setItems(result.data);
    //             setSelectedRowKeys([])
    //           })
    //           .catch((e) => setLoading(false));
    //             message.success('Assign thành công!')
    //             setLoading(false)
    //             return true
    //           } catch (error) {
    //             message.error(error.message);
    //             setLoading(false)
    //             return false;
    //           }
    //         },
    //       onCancel() {},
    //   });
    // }}
    // >
    //   {'Assign Staff'}
    // </Button>,
    (isPicker) && <Button
      key="btn-complete"
      disabled={selectedRowKeys.length === 0}
      type="primary"
      onClick={async () => {
        try {
          if (selectedRowKeys.length > 0) {
            // console.log(user?.userInfo?.sub)
            // await orderCheck({ ids: selectedRowKeys?.map(order => order.id), userId: `${user?.userInfo?.sub}` });
            dispatch(bookingOrderSet({
              pickingOrder: selectedRowKeys,
              pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
                const productGiftOrders = curr?.productGiftOrders?.map(product => {
                  product.orderGiftCode = curr.documentNo;
                  product.orderGiftId = curr.id;
                  product.pickedQuantity = 0;
                  product.groupOrder = curr.group;
                  // product.saleChannel = curr?.channel?.channel;
                  return product;
                });
                return [...prev, ...productGiftOrders];
              }, []),
            }));
            navigate('picking/scan');
            return true;

          }
          // dispatch(pickingOrderCreate());
        } catch (error) {
          const splitMessage = error?.message?.split(/\s(.*)/s);
          message.error(splitMessage[0] + ' ' + lang.messeagePicking);
        }
      }}
    >
      {lang.picking}
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/outbound',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/export-branch',
      breadcrumbName: lang.EXPORT_BRANCH,
    },
  ];

  return (
    <Layout className="picking-list">
      <PageHeader
        ghost={false}
        title={lang.EXPORT_BRANCH}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            if (!cleanValue?.orderCode && !cleanValue?.status) {
              cleanValue.status = 'NEW';
            }
            if (cleanValue?.orderCode) {
              cleanValue.status = undefined;
            }
            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={[16, 16]}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item label={lang.status} name="status" initialValue={isPicker ? 'LOCK' : 'NEW'}>
                  <Select placeholder={lang.status} defaultValue={status} onChange={(value) => setStatus(value)} showSearch allowClear>
                  {!isPicker && <Option key={'NEW'} value={'NEW'}>{STATUS_OUTBOUND.NEW}</Option>}
                    <Option key={'LOCK'} value={'LOCK'}>{STATUS_OUTBOUND.LOCK}</Option>
                    <Option key={'PICKING'} value={'PICKING'}>{STATUS_OUTBOUND.WAITING}</Option>
                    <Option key={'COMPLETE'} value={'COMPLETE'}>{STATUS_OUTBOUND.PICKING}</Option>
                    <Option key={'EXPORTED'} value={'EXPORTED'}>{STATUS_OUTBOUND.CONFIRM}</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* {
                params?.status === 'LOCK' && !isPicker && !isManager && !isIdm &&
                <Col xxl={4} md={6} sm={12}>
                  <Form.Item label='Chọn nhân viên' name="asignStaff">
                    <Select placeholder='Chọn nhân viên' 
                    mode="multiple"
                    showSearch 
                    allowClear
                    onChange={(value) => {
                      return (setUserId(value))
                    }}
                    >
                      { 
                        pickers?.map((pickerAsignned, index) => {
                          if (pickerAsignned.roles.length > 0) {
                            const findWarehouse = warehouses?.find(warehouse => warehouse?.id === currentWarehouse?.id)
                            if (findWarehouse) {
                              const listUserId = findWarehouse?.userIds
                              const checkUser = listUserId.includes(pickerAsignned?.id)
                              if (checkUser) {
                                return <Option key={index} value={pickerAsignned?.id}>{pickerAsignned.username}</Option>
                              }
                            }
                          }
                          return '';
                        })
                      }
                    </Select>
                  </Form.Item>
              </Col>
              } */}
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.search}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card size="small" className="cardSearch">
          <Row style={{ marginBottom: 0 }}>
            <Col span={12} style={{ display: 'flex' }}>
              <h3>{`Danh sách đơn ${lang.EXPORT_BRANCH}`}</h3>
              <div style={{ lineHeight: '27px', marginLeft: '100px' }}>{`Tổng số phiếu xuất: ${totalItems}`}</div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                // rowSelection={!isStaff && rowSelection}
                rowSelection={((params?.status === 'LOCK' && !isStocker) || (params?.status === 'PICKING' && !isStocker)) && {
                  onChange: (_, selectedRows) => {
                    setSelectedRowKeys(selectedRows);
                  },
                }}
                className="tableCustom"
                loading={loading}
                bordered
                columns={columns}
                dataSource={items}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  if (pagination.pageSize !== params.limit) {
                    params.page = 1;
                  } else {
                    params.page = pagination.current;
                  }
                  if (sorter) {
                    setSortedInfo({ sortDirection: sorter.order, sortBy: sorter.field });
                  }
                  params.limit = pagination.pageSize;
                  setParams({ ...params });
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalItems,
                  pageSize: params.limit,
                  current: params.page,
                }}
                scroll={{ x: 600 }}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
    </Layout>
  );
};

export default ExportBranchList;
