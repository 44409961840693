import React, { useEffect, useState } from 'react';
import {  Form, Col, Row, Button, InputNumber, Input, message } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import { masterproductPackingFindOne } from 'pages/dashboard/configuration/masterproduct-packing/masterproduct-packing.service';

const ProducttionUnpack = ({ item, onCallBack, onCancel, isEditModal }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const lang = getLanguages(t);
  const [changeQuantity, setChangeQuantity] = useState(0);
  const [dataVas, setDataVas] = useState([]);


  const initValues = {
    productUnPack: item?.productUnPack,
    quantity: item?.totalUnPack,
    internalProductGiftUnPack: item?.listProductionGiftUnPack?.map(productLine => ({
      code: `${productLine?.code}`,
      name: `${productLine?.name}`,
      locator: `${productLine?.locator?.locator} -  ${productLine?.locator?.locatorName}`,
      quantity: productLine?.movementQuantity,
    })),
    internalProductMasterUnPack: item?.listProductionMasterUnPack?.map(productLine => ({
      code: `${productLine?.code}`,
      name: `${productLine?.name}`,
      lot: `${productLine?.lot}`,
      expireDate: `${productLine?.expireDate}`,
      locator: `${productLine?.locator?.locator} -  ${productLine?.locator?.locatorName}`,
      quantity: productLine?.movementQuantity,
    })),
   
  };
  useEffect(() => {
    masterproductPackingFindOne({ masterProductId: item?.masterProduct?.id, warehouseId: currentWarehouse?.id })
        .then(dataPack => {
          if (dataPack) {
            setDataVas(dataPack);
          }
        })
        .catch((e) => {
          message.error(e);
        });
  }, [currentWarehouse, item]);
  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);
  return (
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        onFinish={(values) => {
          delete item?.listProductionGiftUnPack
          delete item?.listProductionMasterUnPack
          item.bundledGift.forEach(gift => gift.quantity = changeQuantity)
          item.bundledProduct.forEach(product => product.quantity = changeQuantity)
          item.productionMaterial.forEach(material => material.quantity = changeQuantity)
          item.total = changeQuantity;
          onCallBack({...item});
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col span={24}>
            <Row gutter={[24, 24]} align="middle"  style={{ marginBottom: '5px solid #333' }}>
              <Col span={16}>
                <Form.Item
                  name={'productUnPack'}
                  wrapperCol={24}
                  label={'Sản phẩm unpack'}
                >
                  <Input style={{ width: '100%' }} disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'quantity'}
                  wrapperCol={24}
                  label={'Tổng số lượng'}
                >
                  <InputNumber style={{ width: '100%' }}
                    onChange={(value) => {
                      setChangeQuantity(value)
                      const dataProGift = form.getFieldValue(['internalProductGiftUnPack']);
                      const dataProMaster = form.getFieldValue(['internalProductMasterUnPack']);
                       dataProGift.forEach((item, index) => {
                        dataProGift[index].quantity = value;
                        form.setFieldsValue({internalProductGiftUnPack: dataProGift});
                       })

                       dataProMaster.forEach((item, index) => {
                        dataProMaster[index].quantity = value;
                        form.setFieldsValue({internalProductMasterUnPack: dataProMaster});
                       })

                       form.setFieldsValue({quantity: value});
                    }} 
                    min={0} 
                    max={item?.totalUnPack} />
                </Form.Item>
              </Col>
              <Col lg={{span: 24}}  xs={{ span: 24 }}>
                <h3>Quà Tặng UnPack</h3>
              </Col> 
            </Row>

            <Form.List name="internalProductGiftUnPack">
              {(fields) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                      <Row gutter={[24, 24]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={4}>
                          <Form.Item
                            name={[name, 'code']}
                            label={'Mã quà tặng'}
                            // initialValue={cartons}
                          >
                            <InputNumber style={{ width: '100%' }} placeholder={lang.code} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'name']}
                            label={'Tên quà tặng'}
                            // initialValue={cartons}
                          >
                            <InputNumber style={{ width: '100%' }} placeholder={lang.code} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'locator']}
                            wrapperCol={24}
                            label={'Locator'}
                            // initialValue={tins}
                          >
                            <InputNumber style={{ width: '100%' }} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            name={[name, 'quantity']}
                            wrapperCol={24}
                            label={'Số lượng'}
                          >
                            <Input style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* {name < item?.length - 1 && <Divider />} */}
                    </>
                  ))}
                </>
              )}
            </Form.List>

            <Form.List name="internalProductMasterUnPack" >
              {(fields) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                      <Row gutter={[24, 24]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'code']}
                            label={'Mã quà tặng'}
                            // initialValue={cartons}
                          >
                            <InputNumber style={{ width: '100%' }} placeholder={lang.code} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'name']}
                            label={'Tên quà tặng'}
                            // initialValue={cartons}
                          >
                            <InputNumber style={{ width: '100%' }} placeholder={lang.code} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'locator']}
                            wrapperCol={24}
                            label={'Locator'}
                            // initialValue={tins}
                          >
                            <InputNumber style={{ width: '100%' }} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'lot']}
                            wrapperCol={24}
                            label={'Lot'}
                            // initialValue={tins}
                          >
                            <InputNumber style={{ width: '100%' }} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'expireDate']}
                            wrapperCol={24}
                            label={lang.expireDate}
                            // initialValue={tins}
                          >
                            <InputNumber style={{ width: '100%' }} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'quantity']}
                            wrapperCol={24}
                            label={'Số lượng'}
                          >
                            <Input style={{ width: '100%' }} placeholder={'Tổng SL lon'} disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* {name < item?.listProductionGiftUnPack?.length - 1 && <Divider />} */}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Col>

          <Col lg={{ span: 24 }} xs={{ span: 8 }}>
            <Row>
              <h3>{'Thông tin nguyên phụ liệu:'}</h3>
            </Row>
            <Col lg={{ span: 24 }} xs={{ span: 24 }}>
              <Form.Item shouldUpdate>
                {(form) => {
                  return dataVas?.length > 0 ? dataVas.map((item) => {
                    // let quantity = quantityPack + quantityMaster;
                    let totalQuantity = form.getFieldValue('quantity');
                    let totalStockAvailableForSale = item?.bomProduction?.stockAvailableForSale;
                    let totalQuantityTinTake = ( totalQuantity * item?.using > 0 ?  totalQuantity * item?.using : 0);
                    // const StockAvailableForSale = totalStockAvailableForSale - totalQuantityTinTake;
                    //Tính toán để sd lại SL khả dụng của BomProduction khi có SP thứ 2 trở đi
                    // if (StockAvailableForSale > 0) {
                    //   if (sessionStorage) {
                    //     sessionStorage.setItem('StockAvailableForSale', StockAvailableForSale)
                    //     sessionStorage.setItem('idBomProduction', item?.bomProduction?.id)
                    //     sessionStorage.setItem('nameBomProduction', item?.bomProduction?.name)
                    //   }
                    //   // TODO
                    // }
                    return (
                      <div>{item?.bomProduction?.name} - <span>Using: {item?.using}</span> - <span>Khả dụng: {totalStockAvailableForSale - (changeQuantity * item?.using)}</span> - <span>Số lượng cần trả: {totalQuantityTinTake}</span></div>
                    )
                  }) : <div style={{color: 'red'}}>Sản phẩm chưa có cấu hình</div>
                }}
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

ProducttionUnpack.propTypes = {
  item: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
  typeProductionOrder: PropTypes.string,
};

export default ProducttionUnpack;
