import request from 'utils/request';
import environment from 'environment';
import { formatDateFull } from 'utils/constants';

export async function apInvoiceList(payload) {
  return request(`${environment.api.apInvoice}/get-all?`, payload, 'GET');
}

export async function apInvoiceDetail(id) {
  return request(`${environment.api.apInvoice}/get-one/${id}`, {}, 'GET');
}

export async function receiptCreate(payload) {
  const { creatorId, creatorName, inboundkind, receiptDate, warehouseId, products } = payload;
  const receipt = {
    creatorId,
    creatorName,
    inboundkind,
    receiptDate,
    warehouseId,
    supplierId: 0,
    products: products.map(item => {
      if (item?.expireDate) {
        return {
          ...item,
          inboundKind: inboundkind,
          expireDate: item.expireDate.format(formatDateFull),
        };
      }
      return {
        ...item,
        inboundKind: inboundkind,
      };
    }),
  };
  return request(environment.api.receiptCreate, receipt, 'POST');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptConfirm(payload) {
  return request(environment.api.receiptConfirm, payload, 'PUT');
}

export async function receiptDelete(id) {
  return request(`${environment.api.apInvoice}/delete/${id}`, {}, 'DELETE');
}

export async function packageList(payload) {
  return request(`${environment.api.packageList}`, {}, 'GET');
}
export async function receiptCreateAPInvoice(payload) {
  return request(environment.api.receiptCreate, payload, 'POST');
}
export async function createAPInvoice(payload) {
  return request(`${environment.api.apInvoice}/create`, payload, 'POST');
}
export async function updateAPInvoice(payload) {
  return request(`${environment.api.apInvoice}/update`, payload, 'PUT');
}
