
import environment from 'environments/environment';
import request from 'utils/request';

export async function productcategoryList(payload) {
  return request(environment.api.productCategoryList, payload, 'GET');
}

export async function productsList(payload) {
  return request(environment.api.productTrxList, payload, 'GET');
}
export async function productsGiftList(payload) {
  return request(environment.api.productGifts + '/get-all', payload, 'GET');
}
export async function zoneList(payload) {
  return request(environment.api.zones + 'get-all', payload, 'GET');
}
export async function blockList(payload) {
  return request(environment.api.blocks + 'get-all', payload, 'GET');
}
export async function blockDetail(id) {
  return request(environment.api.blocks + `get-one/${id}`, {}, 'GET');
}

export async function shelfDetail(id) {
  return request(environment.api.shelves + `get-one/${id}`, {}, 'GET');
}
export async function productUpdate(payload) {
  return request(environment.api.products + 'update', payload, 'PUT');
}
export async function productUpdateLocator(payload) {
  return request(environment.api.products + 'update/locator', payload, 'PUT');
}
export async function productUpdateStatus(payload) {
  return request(environment.api.products + 'update-status', payload, 'PUT');
}
export async function receiptList(payload) {
  return request(environment.api.receiptList, payload, 'GET');
}
export async function locatorList(payload) {
  return request(environment.api.locator + '/get-all', payload, 'GET');
}
export async function createPallet(payload) {
  return request(environment.api.products + 'create/pallet', payload, 'POST');
}
