import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Row, Button, Select, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { bookingOrderSet } from 'providers/OutboundProvider/actions';

import { getLanguages } from 'utils/lang';

import './style.less';
import TableCustom from 'components/TableCustom/table';
import { orderCheck } from './picking.service';

const { Option, OptGroup } = Select;
const BookingOrderForm = ({ onCancel, onCallback, listBooking, listPicking }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  // const [listBooking, setListBooking] = useState([]);
  // const [listPicking, setListPicking] = useState({});
  const { user } = useSelector((state) => state.auth);
  const { pickingOrder } = useSelector((state) => state.outbound);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState([]);
  // useEffect(() => {
  //   setLoading(true);
  //   Promise.all([bookingList({ warehouseId: currentWarehouse.id, status: 'NEW', sortDirection: 'desc', sortBy: 'createDate' }),
  //   bookingList({ warehouseId: currentWarehouse.id, status: 'PICKING', sortDirection: 'desc', sortBy: 'createDate' })]).then((values) => {
  //     setListBooking(values[0]?.data);
  //     setListPicking(values[1]?.data?.reduce((prev, curr) => {
  //       if (prev.hasOwnProperty(curr?.group)) {
  //         prev[curr?.group].push(curr);
  //         return prev
  //       }
  //       prev[curr?.group] = [curr];
  //       return prev
  //     }, {}));
  //     setLoading(false);
  //   }).catch(() => setLoading(false));

  //   // bookingList({ warehouseId: currentWarehouse.id, status: 'NEW', sortDirection: 'desc', sortBy: 'createDate' })
  //   //   .then(result => {
  //   //     setListBooking(result.data);
  //   //     setLoading(false);
  //   //   })
  //   //   .catch((e) => setLoading(false));
  //   //   bookingList({ warehouseId: currentWarehouse.id, status: 'PICKING', sortDirection: 'desc', sortBy: 'createDate' })
  //   //   .then(result => {
  //   //     setListPicking(result.data);
  //   //     setLoading(false);
  //   //   })
  //   //   .catch((e) => setLoading(false));
  // }, [item, currentWarehouse]);
  useEffect(() => {
    if (item) {
      setLoading(true);
      const data = item.reduce((prev, curr) => {
        if (typeof (curr?.value) === 'string') {
          const products = listPicking[curr?.value]?.reduce((prev, curr) => {
            const productOrders = curr?.productOrders?.map(product => {
              product.orderCode = curr.code;
              product.orderId = curr.id;
              return product;
            });
            return [...prev, ...productOrders];
          }, []);
          return [...prev, ...products];
        }
        const finded = listBooking?.find(order => order.id === curr?.value);
        return [...prev, ...finded?.productOrders?.map(product => {
          product.orderCode = finded.code;
          product.orderId = curr.value;
          return product;
        })];
      }, []);
      setItems(data);
      setSelectedRowKeys(data?.map(d => d.id));
      setLoading(false);
    }
  }, [item, listBooking, listPicking]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      width: '15%',
      render: (record) => {
        return <div
          style={{ cursor: 'pointer' }}
        // onClick={() => console.log('Click: ', record)}
        >
          {record?.orderCode}
        </div>;
      },
    },
    {
      title: lang.productCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
      width: '15%',
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      width: '20%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
    // {
    //   title: lang.pickingquantity,
    //   dataIndex: 'pickingQuantity',
    //   key: 'pickingQuantity',
    //   width: '15%',
    //   render: (value) => value ? value : 0,
    // },
  ];

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async () => {
        try {
          await orderCheck({ ids: pickingOrder?.map(order => order.id), userId: null });
          const listBookingorder = item?.reduce((prev, curr) => {
            if (typeof (curr?.value) === 'string') {
              return [...prev, ...listPicking[curr?.value]];
            }
            const rs = listBooking?.find(booking => booking.id === curr.value);
            return [...prev, rs];
          }, []);
          await orderCheck({ ids: listBookingorder?.map(order => order.id), userId: user?.userInfo?.sub });
          dispatch(bookingOrderSet({
            pickingOrder: listBookingorder,
            pickingProducts: items?.filter(item => selectedRowKeys.indexOf(item.id) > -1),
          }));
          onCallback(items);
        } catch (error) {
          const splitMessage = error?.message?.split(/\s(.*)/s);
          message.error(splitMessage[0] + ' ' + lang.messeagePicking);
        }
      }}
      initialValues={{}}
    >
      <Row gutter={24} style={{ marginBottom: 24 }}>
        <Col>
          <h3 style={{ lineHeight: '32px' }}>{`${lang.labelBooking}`}</h3>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 12 }}>
          <Select
            placeholder={lang.placeholderSelectOrder}
            value={item}
            onChange={(value) => {
              setItem(value);
            }}
            mode='multiple'
            labelInValue
            style={{ width: '100%' }}
          >
            <OptGroup label={lang.NEW}>
              {listBooking?.map(booking => <Option key={booking.id} value={booking.id}>{booking.code}</Option>)}
            </OptGroup>
            <OptGroup label={`${lang.PICKING} (*Chỉ được chọn 1)`}>
              {Object.keys(listPicking)?.map(key => {
                return <Option key={key} value={key}>{listPicking[key]?.map(order => order?.code).join()}</Option>;
              })}
            </OptGroup>
          </Select>
        </Col>
      </Row>
      <TableCustom
        rowKey="id"
        rowSelection={rowSelection}
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 600 }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnClose}
            </Button>
            {loading === false ? (
              <Button type="primary" disabled={items.length === 0} htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnDone}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

BookingOrderForm.propTypes = {
  listPicking: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  listBooking: PropTypes.array,
};

export default BookingOrderForm;
