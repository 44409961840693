import request from 'utils/request';
import environment from 'environment';
export async function saleorderList(payload) {
  return request(environment.api.Orders + 'get-all', payload, 'GET');
}

export async function saleorderDetail(id) {
  return request(environment.api.Orders + `get-one/${id}`, {}, 'GET');
}

export async function updateOrder(payload) {
  return request(environment.api.Orders + `update-manuever`, payload, 'PUT');
}

export async function saleorderCreate(payload) {
  return request(environment.api.Orders + 'create', payload, 'POST');
}

export async function saleorderCreateManuever(payload) {
  return request(environment.api.Orders + 'create-manuever-command', payload, 'POST');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
    resolve('1');
  });
}
export async function productOrderUpdate(payload) {
  return request(environment.api.productOrder + '/update', payload, 'PUT');
}
export async function getAllProudctLocator(payload) {
  return request(environment.api.masterProductLocator + '/get-all', payload, 'GET');
}

export async function deleteCommandManuever(id) {
  return request(environment.api.Orders + `delete-order-manuever/${id}`, {}, 'DELETE');
}