import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Form, Card, Row, Col, DatePicker, message as Alert, Input, message } from 'antd';
import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { receiptMaterialCreate } from './material.service';
import ReceiptCreateProductEditForm from './receiptMaterial-create-product-edit.form';
import { formatDate, formatDateFull } from 'utils/constants';
import './style.less';
import ProTableCusTom from 'components/TableCustom/Protable';
import Helper from 'utils/helpers';
import Dragger from 'antd/lib/upload/Dragger';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
// import { ProFormSelect } from '@ant-design/pro-form';
// import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';
import { bomList } from 'pages/dashboard/configuration/bom/bom.service'

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptMaterialCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [isEditModal, setIsEditModal] = useState(false);

  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [productMasters, setProductMasters] = useState([]);
//   const [locators, setLocators] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: 'Mã material',
      dataIndex: ['bomProduction', 'code'],
      key: 'code',
    },
    {
      title: 'Tên material',
      dataIndex: ['bomProduction', 'name'],
      key: 'name',
    },
    {
      title: lang.quantity,
      dataIndex: 'quantity',
      key: 'quantity',
    },
    // {
    //   title: lang.shipment,
    //   dataIndex: 'shipment',
    //   key: 'shipment',
    // },
    // {
    //   title: 'Line NO',
    //   dataIndex: 'lineNo',
    //   key: 'lineNo',
    // },
    {
      title: 'ContractRefNo',
      key: 'contractRefNo',
      dataIndex: 'contractRefNo',
    },
    {
      title: lang.rack,
      key: 'rackId',
      dataIndex: ['rack','code'],
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    // {
    //   title: lang.action,
    //   align: 'center',
    //   width: '8%',
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <Button
    //         type="link"
    //         icon={<EditOutlined />}
    //         size="small"
    //         onClick={() => {
    //           // const detail = products.find((item) => item.masterProductId === record.masterProductId);
    //           setProduct(record);
    //           setIsEditModal(true);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/material');
      }}
    >
      {'Back'}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={products.length === 0}
      onClick={() => {
        form.submit();
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/receipt-gift',
      breadcrumbName: 'Phiếu nhập material',
    },
    {
      path: '',
      breadcrumbName: 'Tạo Phiếu nhập material',
    },
  ];

  useEffect(() => {
    setLoading(true);
    bomList({ warehouseId: currentWarehouse.id })
      .then(result => {
        setProductMasters(result.data);
      })
      .catch((e) => setLoading(false));
    // // locatorList({ warehouseId: currentWarehouse.id }).then(async result => {
    // //   const data = [];
    // //   await result?.data?.map(item => {
    // //     if ((item?.locator).includes("Gift") === true) {
    // //       data.push(item);
    // //     }
    // //     return true;
    // //   })
    // //   setLocators(data);
    // }).catch(e => setLoading(false));
    setLoading(false);
  }, [currentWarehouse]);
  
  return (
    <Layout className="receipt-list layoutContent">
      <PageHeader
        ghost={false}
        title={'Tạo Phiếu nhập material'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form className="customFormSearch"
            colon={false}
            form={form}
            layout="vertical"
            name="receiptCreateForm"
            onFinish={async (values) => {
              try {
                // values.creatorId = user?.userInfo?.sub;
                values.createrName = user?.userInfo?.name;
                values.warehouseId = currentWarehouse?.id;
                if (values?.ataDate) {
                  values.ataDate = values.ataDate.format(formatDateFull);
                }
                if(values?.movementDate){
                  values.movementDate = values.movementDate.format(formatDateFull);
                }
                values.receiptMaterialProducts = products?.map(product => {
                  // console.log(product)
                  const {codeMaterial, shipment,lineNo,orderReference,contractRefNo,description, quantity} = product;
                  return {
                    codeMaterial,
                    shipment,
                    lineNo,
                    orderReference,
                    contractRefNo,
                    warehouseId: currentWarehouse.id,
                    description,
                    movementDate: moment().format(formatDateFull),
                    movementQuantity: quantity,
                    quantity,
                    rackId: product?.rackId,
                    updateCapacityRack: parseInt(1)
                  };
                });
                setLoading(true);
                // console.log(values)
                receiptMaterialCreate(values)
                  .then(result => {
                    setLoading(false);
                    Modal.success({
                      title: lang.createReceiptSuccessfully,
                      content: `${lang.receiptCode}: ${result?.id}`,
                      icon: <CheckOutlined />,
                      onOk() {
                        navigate(-1);
                      },
                    });
                  })
                  .catch((error) => {
                    Alert.error(error?.message);
                    setLoading(false);
                  });
                return true;
              } catch (error) {
                Alert.error(error?.message);
                setLoading(false);
              }
            }}
            initialValues={{}}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name='company'
                  label={lang.company}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.company} />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="branch"
                  label={lang.branch}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.branch} />
                </Form.Item>
              </Col> */}
              {/* <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="documentNo"
                  label={lang.documentNo}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.documentNo} />
                </Form.Item>
              </Col> */}
              {/* <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="businessLine"
                  label={lang.businessLine}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.businessLine} />
                </Form.Item>
              </Col> */}
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="description"
                  label={lang.description}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.description} />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="documentType"
                  label={lang.documentType}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.documentType} />
                </Form.Item>
              </Col> */}
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="movementDate"
                  label={lang.movementDate}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="ataDate"
                  label={'atadate'}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col> */}
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="businessPartner"
                  label={lang.businessPartner}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.businessPartner} />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="partnerLocation"
                  label={lang.partnerLocation}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.partnerLocation} />
                </Form.Item>
              </Col> */}
              {/* <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="representative"
                  label={lang.repressentative}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.repressentative} />
                </Form.Item>
              </Col> */}
              {/* <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <ProFormSelect name="warehouse" label={lang.Locator} placeholder={lang.Locator}
                  options={locators?.map(locator => ({
                    value: locator.locator,
                    label: `${locator.locatorName} - ${locator.locator}`,
                  }))}
                  showSearch
                />
              </Col> */}
              {/* <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="documentStatus"
                  label={'Document Status'}
                >
                  <Input style={{ width: '100%' }} placeholder={'Document Status'} />
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </Card>
        <ProTableCusTom
          title={() => <Row>
            <Col sm={12} xs={10}><h3>{lang.productAdded}</h3></Col>
            <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setProduct();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        setProducts(products.filter(item => !selectedRowKeys.includes(item.key)));
                        setSelectedRowKeys([]);
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="key"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={products}
          scroll={{ x: 1800 }}
          search={false}
          tableAlertRender={false}
          options={{
            search: false,
            reload: false,
            density: false,
          }}
        // pagination={false}
        />
      </Layout.Content>
      <ReceiptCreateProductEditForm
        item={product}
        products={productMasters}
        productsSelected={products}
        warehouseId={currentWarehouse?.id}
        onCallback={(values) => {
          const productsfind = products.find(item => item?.key === values?.key);
          if (productsfind) {
            Object.assign(productsfind, values);
            setProducts([...products]);
          } else {
            setProducts([...products, { ...values, key: products.length }]);
          }
          // const productUpdate = products?.find(product => product.key === values[0].key);
          // if (productUpdate) {
          //   productUpdate.expectedQuantity = values[0].expectedQuantity;
          //   productUpdate.supplier = values[0].supplier;
          //   productUpdate.supplierId = values[0].supplierId;
          //   setProducts([...products]);
          //   setIsEditModal(false);
          //   return true;
          // }
          setIsEditModal(false);
        }}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
      />
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file?.originFileObj);
              fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'binary' });
                const sheetName = wb.SheetNames[0];
                const sheet = wb.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                if (data) {
                  const result = data?.reduce((prev, curr, index) => {
                    if (index > 0 && !!curr) {
                      const finded = productMasters?.find(master => master.barCode === curr[0]?.toString());
                      if (finded) {
                        return [...prev, {
                          key: index - 1,
                          barCode: finded.barCode,
                          productName: finded?.name,
                          productCategory: finded?.productCategory?.name,
                          expectedQuantity: curr[1],
                          supplier: finded?.suppliers?.find(supp => supp?.id === curr[2])?.name,
                          purchasePrice: finded?.purchasePrice,
                          salePrice: finded?.salePrice,
                          retailPrice: finded?.retailPrice,
                          expireDate: curr[3] && moment(curr[3], formatDateFull),
                          masterProductId: finded?.id,
                          productCategoryId: finded?.productCategory?.id,
                          productCode: finded?.code,
                          warehouseId: finded?.warehouseId,
                          DVT: finded?.DVT,
                        }];
                      }
                      return prev;
                    }
                    return prev;
                  }, []);
                  setProducts([...products, ...result]);
                }
              };
              setFile();
            }
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ barCode: '', expectedQuantity: 0, supplierId: 0, expireDate: moment().format(formatDate) }], 'TemplateDataPO')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default ReceiptMaterialCreate;
