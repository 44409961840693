import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col, message } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import Helper from 'utils/helpers';

import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import ProTableCusTom from 'components/TableCustom/Protable';
// import { orderUpdateStatus, productUpdateStatus } from '../picking/picking.service';
import { scanPackagingProductCodeAdd } from 'providers/OutboundProvider/actions';
import logog3A from 'assets/logoAKAWMS/logo-3A.png';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ShippingScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { packagingOrder, pickingProducts } = useSelector((state) => state.outbound);
  const [selectedOrder, setSelectedOrder] = useState(packagingOrder?.orders?.[0]);
  const [loadingExport, setLoadingExport] = useState(false);
  // const { currentWarehouse } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const columnsPickingList = [
    // {
    //   title: 'QRcode',
    //   key: 'QRcode',
    //   dataIndex: 'code',
    //   render: value => Helper.genarateQRcode([value]),
    //   // hideInTable: true,
    // },
    {
      title: lang.defaultCode,
      key: 'code',
      dataIndex: 'masterProduct',
      render: value => value?.code3A,
    },
    {
      title: lang.productCode,
      key: 'code',
      dataIndex: 'code',
      // render: val => <span>{val}<div id={val} style={{ display: 'none' }}>{Helper.genarateOneQRcode(val)}</div></span>,
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: ['zone','name'],
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      render: value => value?.code,
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancle"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/shipping');
      }}
    >
      {lang.btnCancel}
    </Button>,
    pickingProducts.length > 0 && <Button
      key="btn-export"
      type="primary"
      ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        var totalQuantityCartton = 0;
        var totalQuantityTin = 0;
        const dateTime = new Date();
        const date = dateTime.getDate();
        const month = (dateTime.getMonth() + 1);
        const year = (dateTime.getFullYear());
        const content = `
              <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;">
              <div style="display:flex">
                <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 70%; ">
                  <div>
                    <p>
                      <h4>CÔNG TY TRÁCH NHIỆM HỮU HẠN DINH DƯỠNG 3A (VIỆT NAM)</h4>
                    </p>
                  </div>    
                  <div>
                    <p>
                      <span style="font-weight: bold;">Địa chỉ</span>: ${pickingProducts[0]?.warehouse?.address}.
                    </p>
                  </div>
                </div>
                <div style="margin-top: 30px; text-align:center; margin-left:20px; padding: 10px 0; line-height: 1; width: 30%;">
                  <div style="text-align: center;"><span style="font-weight: bold">Ngày in: </span>Ngày ${date} tháng ${month} năm ${year}</div>
                </div>
              </div>
              <div style="display: flex; min-height: 120px;">
                <div style="text-align:center; width: 20%; border-image: initial; margin-top: -60px">
                  <img src="${logog3A}" style=" height: 100%; width: 100%" />
                </div>
                <div style="width: 60%; text-align: center; line-height: 1.2; margin-top: -10px">
                  <div style="font-size: 30px; font-weight:bold; ">Danh Sách Sản Phẩm Đã Lấy</div>
                </div>
              </div>
      
              <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
                <thead>
                  <tr style="text-align:center; font-weight: bold;">
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">QR code</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Mã SP</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Mã lô SP</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Nhóm hàng</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên SP</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">SL thùng</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">SL lon</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Block</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Shelf</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Rack</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">Note</td>
                  </tr>
                </thead>
                <tbody>

                ${pickingProducts.map((product, index) => {
          const QRcode = document.getElementById(product.code);
          totalQuantityCartton += product.quantityCartton;
          totalQuantityTin += product.quantityTin;
          return `<tr>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + index}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${QRcode?.innerHTML}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.masterProduct.code3A}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.code}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.masterProduct.productGroupName}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.masterProduct.nameEN}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityCartton}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityTin}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.block?.name}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.rack?.shelf?.name}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.rack?.code}</td>
                      <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    </tr>`;
        }).join(' \n')}             
                    <tr>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">Total</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantityCartton}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantityTin}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    </tr>
                </tbody>
              </table>
            </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PhieuLayHang${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    >
      {lang.exportPDF}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        try {
          // const locatorCodeTo = selectedOrder?.productOrders?.[0]?.locatorCodeTo;
          // const locatorTransitCode = selectedOrder?.locatorTransitCode;
          // await productUpdateStatus({ codes: scanPackagingForProductCodes?.map(product => product.code), status: 'TEMPORARY_OUT', warehouseId: currentWarehouse.id, orderCode: selectedOrder?.code, locatorCodeTo, locatorTransitCode });
          // if (scanPackagingForProductCodes.length === pickingProducts?.filter(product => product.orderId === selectedOrder.id).length) {
          //   const orderUpdatesReady = { ids: [selectedOrder.id], status: 'READY' };
          //   await orderUpdateStatus(orderUpdatesReady);
          // }
          dispatch(scanPackagingProductCodeAdd([]));
          navigate('/dashboard/outbound/shipping');
          // message.success(lang.success);
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      disabled={pickingProducts.length > 0}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];
  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/shipping',
      breadcrumbName: lang.shippingOutboundControl,
    },
    {
      path: '/',
      breadcrumbName: lang.scanCode,
    },
  ];
  return (
    <Layout className="storing-scan">
      <PageHeader
        ghost={false}
        title={lang.scanCode}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Row gutter={20}>
          <Col span={24}>
            <ScanProductForm type={'PACKAGING'} pickingOrder={{ orders: packagingOrder?.orders, pickingProducts: pickingProducts }} setSelectedOrder={setSelectedOrder} selectedOrder={selectedOrder} />
          </Col>
          <Col span={24}>
            <ProTableCusTom
              headerTitle="Sản phẩm đã lấy"
              rowKey="id"
              size="small"
              className="tableCustom"
              bordered
              columns={columnsPickingList}
              // loading={loading}
              params={{ orderId: selectedOrder?.id, pickingProducts }}
              request={async (params) => {
                const { orderId, pickingProducts } = params;
                if (orderId) {
                  return Promise.resolve({
                    data: pickingProducts?.filter(product => product.orderId === orderId),
                    total: pickingProducts.length,
                    success: true,
                  });
                }
                return Promise.resolve({
                  data: pickingProducts,
                  total: pickingProducts.length,
                  success: true,
                });
              }}
              search={false}
              options={{
                search: false,
                reload: false,
                density: false,
              }}
              pagination={{
                showTitle: false,
                showTotal: false,
                pageSize: 10,
              }}
              scroll={{ x: 800 }}
            />
          </Col>
          <Col style={{display: 'none'}}>
              {pickingProducts?.map(pro=><div id={pro?.code} style={{ display: 'none' }}>{Helper.genarateOneQRcode(pro?.code)}</div>)}
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default ShippingScan;
