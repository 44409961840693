import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Modal, InputNumber, message, Input, Divider } from 'antd';
import { formatDate, formatDateAPi, formatDateFull } from 'utils/constants';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
// import Helper from 'utils/helpers';
// import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
import { ProFormSelect, ProFormText, ProFormDatePicker } from '@ant-design/pro-form';
import { warehouseList } from 'pages/admin/warehouse/warehouse.service';
import { receiptCreateAPInvoice } from './apinvoice.service';
import moment from 'moment-timezone';

const ApInvoiceForm = ({ item, onCallBack, setIsEditModal, isEditModal, apInvoice }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [formParent] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [listwarehouse, setListwarehouse] = useState([]);
  const initValues = {
    warehouseId: currentWarehouse?.id,
    receiptProducts: item?.map(apInvoice => ({
      id: apInvoice?.id,
      masterId: apInvoice?.masterProduct?.id,
      name: `${apInvoice?.masterProduct?.nameEN}(${apInvoice?.masterProduct?.shortName}) - ${apInvoice?.masterProduct?.code3A}`,
      mfgDate: apInvoice?.mfgDate ? moment(apInvoice?.mfgDate, formatDateFull) : undefined,
      lot: apInvoice?.lot ? apInvoice?.lot : undefined,
      warehouseId: apInvoice.warehouseId,
      expireDate: apInvoice?.expiryDate ? moment(apInvoice.expiryDate, formatDateFull) : undefined,
      shipmentNo: apInvoice.shipmentNo,
      contractNo: apInvoice?.contractNo,
      description: apInvoice?.description,
      poReference: apInvoice?.poReference,
      totalQuantityTin: apInvoice?.quantityTin,
      shortName: apInvoice?.masterProduct?.shortName,
      code3A: apInvoice?.masterProduct?.code3A,
    })),
  };
  useEffect(() => {
    return () => {
      formParent.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  useEffect(() => {
    warehouseList().then(value => {
      if (value) {
        setListwarehouse(value.data);
      }
    });
  }, [currentWarehouse]);
  const onCancel = () => {
    // const valueForm = formParent.getFieldsValue();
    // const isDefined = Object.values(valueForm).some(value => value !== undefined);
    // const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    // // console.log(JSON.stringify(valueForm))
    // // console.log(JSON.stringify(initValues))

    // if (!isDefined || isUnChange) {
    //   setIsEditModal(false);
    //   return true;
    // }
    // Helper.conFirm(setIsEditModal);
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title='Tạo phiếu nhập kho'
      onCancel={onCancel}
      afterClose={() => {
        formParent.resetFields();
        formParent.setFieldsValue({ receiptProducts: undefined });
      }}
      width={768}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={formParent}
        layout="vertical"
        name="productCategoryEditForm"
        onFinish={(values) => {
          setLoading(true);
          const { receiptProducts } = values;
          values.receiptProducts = receiptProducts?.map(product => ({
            apInvoiceProductId: product.id,
            masterProductId: product.masterId,
            warehouseId: product.warehouseId,
            quantityCartton: product.quantityCartton,
            quantityTin: product.quantityTin,
            lot: product.lot,
            mfgDate: product.mfgDate ? product.mfgDate.format(formatDateAPi) : undefined,
            contract: product.contractNo,
            expireDate: product.expireDate ? product.expireDate.format(formatDateAPi) : undefined,
            shipment: product.shipmentNo,
            poReference: product?.poReference,
            description: product?.description,
          }));
          values.inboundkind = 'NEW';
          values.receiptDate = moment().format(formatDateFull);
          values.apInvoiceId = apInvoice.id;
          values.businessPartner = apInvoice.businessPartner;
          receiptCreateAPInvoice(values).then((item) => {
            setLoading(false);
            onCallBack();
            message.success(lang.createSuccessfully);
            setIsEditModal(false);
          })
            .catch((err) => message.error(err.message), setLoading(false));
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <ProFormSelect
              name='warehouseId'
              label={lang.warehouse}
              options={listwarehouse?.map(warehouse => ({
                value: warehouse.id,
                label: warehouse.name,
                key: warehouse.id,
              }))}
              placeholder={lang.warehouse}
              rules={[
                {
                  required: true,
                  message: lang.requiredWarehouse,
                },
              ]}
              disabled
            />
          </Col>
          <Col span={24}>
            <Form.List name="receiptProducts" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                    <Row gutter={[16, 16]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                      <Col span={24}>
                        <Form.Item
                          name={[name, 'code3A']}
                          wrapperCol={24}
                          label={lang.defaultCode}
                        >
                          <Input style={{ width: '100%' }} placeholder={lang.defaultCode} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name={[name, 'shortName']}
                          wrapperCol={24}
                          label={lang.SHORTNAME}
                        >
                          <Input style={{ width: '100%' }} placeholder={lang.SHORTNAME} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <ProFormText
                          name={[name, 'name']}
                          label={lang.productName}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: intl.formatMessage({
                          //       id: 'pages.goodIO.message.required-description',
                          //       defaultMessage: 'Please enter goods description!',
                          //     }),
                          //   },
                          // ]}
                          // fieldProps={{
                          //   labelInValue: true
                          // }}
                          placeholder={lang.productName}
                          disabled
                        />
                      </Col>
                      <Col span={8}>
                        <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                          {(form) => {
                            const cartons = Math.floor((item[name]?.quantityTin - item[name]?.quantityTaken) / parseInt(item[name]?.masterProduct?.tinPerCarton)) - item[name]?.quantityCarttonTaken; //+ item[name]?.quantityCartton
                            const tins = form?.getFieldValue(['receiptProducts', name, 'quantityTin']);
                            return <Form.Item
                              name={[name, 'quantityCartton']}
                              label={`${'SL thùng có thể lấy'}(<=${cartons})`}
                              rules={[
                                {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  validator: (_, value) => (tins === 0 && value === 0) || value < 0 || value > cartons ? Promise.reject('Số lượng không tương thích, vui lòng kiểm tra lại!') : Promise.resolve(),
                                },
                              ]}
                              initialValue={cartons}
                            >
                              <InputNumber style={{ width: '100%' }} placeholder={lang.quantityCartton} min={0} />
                            </Form.Item>;
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                          {(form) => {
                            const cartons = form?.getFieldValue(['receiptProducts', name, 'quantityCartton']);
                            const tins = item[name]?.quantityTin - item[name]?.quantityTaken + (0 - item[name]?.quantityCarttonTaken - cartons) * parseInt(item[name]?.masterProduct?.tinPerCarton);
                            return <Form.Item
                              name={[name, 'quantityTin']}
                              wrapperCol={24}
                              label={`${'SL lon có thể lấy'}(<=${tins})`}
                              rules={[
                                {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  validator: (_, value) => (cartons === 0 && value === 0) || value < 0 || value > tins ? Promise.reject('Số lượng không tương thích, vui lòng kiểm tra lại!') : Promise.resolve(),
                                },
                              ]}
                              initialValue={tins}
                            >
                              <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
                            </Form.Item>;
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item
                          name={[name, 'totalQuantityTin']}
                          wrapperCol={24}
                          label={'Tổng SL lon (Từ ERP)'}
                        >
                          <Input style={{ width: '100%' }} defaultValue={0} placeholder={'Tổng SL lon (Từ ERP)'} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                          {(form) => {
                            // const cartons = Math.floor((item[name]?.quantityTin - item[name]?.quantityTaken) / parseInt(item[name]?.masterProduct?.tinPerCarton)) - item[name]?.quantityCarttonTaken;
                            // const numberPallet = cartons / parseInt(item[name]?.masterProduct?.CTNSPerPalletImport);
                            const quantityCartonTake = form?.getFieldValue(['receiptProducts', name, 'quantityCartton']);
                            const totalQuantityTinTake = quantityCartonTake * parseInt(item[name]?.masterProduct?.tinPerCarton) + form?.getFieldValue(['receiptProducts', name, 'quantityTin']);
                            const divPallet = totalQuantityTinTake / parseInt((item[name]?.masterProduct?.CTNSPerPalletImport) * (item[name]?.masterProduct?.tinPerCarton));
                              return <Form.Item
                              label={'Số lượng Pallet'}
                              // initialValue={cartons}
                            >
                              <InputNumber style={{ width: '100%' }} placeholder={'Số lượng Pallet'} value={divPallet % 1 === 0 ? divPallet : Math.floor(divPallet) + 1} disabled/>
                            </Form.Item>;
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={[name, 'lot']}
                          wrapperCol={24}
                          label={'Lot'}
                          rules={[
                            {
                              required: true,
                              message: 'Lot cần phải nhập!',
                            },
                          ]}
                        >
                          <Input style={{ width: '100%' }} placeholder={'Nhập Lot'} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <ProFormDatePicker name={[name, 'mfgDate']} label={lang.mfgDate} placeholder={lang.mfgDate}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: 'NSX cần phải nhập!',
                        //   },
                        // ]}
                        fieldProps={{
                          style: { width: '100%' },
                          format: formatDate,
                          disabledDate: (date) => date > moment(),
                        }} />
                      </Col>
                      <Col span={8}>
                        <ProFormDatePicker name={[name, 'expireDate']} label={lang.expireDate} placeholder={lang.expireDate}
                          rules={[
                            {
                              required: true,
                              message: 'HSD cần phải nhập!',
                            },
                          ]}
                          fieldProps={{
                            style: { width: '100%' },
                            format: formatDate,
                            disabledDate: (date) => date < moment(),
                          }} />
                      </Col>
                    </Row>
                    {name < item?.length - 1 && <Divider/>}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => formParent.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

ApInvoiceForm.propTypes = {
  item: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
  apInvoice: PropTypes.object,
};

export default ApInvoiceForm;
