import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { ProFormSelect } from '@ant-design/pro-form';
import './style.less';
import { locatorList } from 'pages/dashboard/configuration/locator/locator.service';

const ProductComboForm = ({ item, onCancel, onCallback, warehouseId }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [locatorsList, setLocatorList] = useState([]);
  useEffect(() => {
    locatorList({ warehouseId }).then(value => setLocatorList(value?.data));
  }, [warehouseId]);
  return (
    <Form
      colon={false}
      form={form}
      requiredMark={false}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        onCallback(values);
      }}
      initialValues={null}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name="locatorId" label={lang.Locator}
            placeholder={lang.Locator}
            options={locatorsList?.map(locator => {
              return {
                value: locator.id,
                label: `${locator.locatorName} - ${locator.locator}`,
              };
            })}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            }
          />
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
              {lang.btnSave}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ProductComboForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  warehouseId: PropTypes.string,
};

export default ProductComboForm;
