import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';

import './style.less';
import { useSelector } from 'react-redux';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const InboundControlScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanVasForProductCodes } = useSelector((state) => state.inbound);

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/vas/');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        navigate('complete');
      }}
      disabled={scanVasForProductCodes.length === 0}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/vas',
      breadcrumbName: lang.Vas,
    },
    {
      path: '/dashboard/inbound/vas/vas-scan',
      breadcrumbName: lang.scanProduct,
    },
  ];

  return (
    <Layout className="inbound-control-scan layoutContent">
      <PageHeader
        ghost={false}
        title={lang.inboundControlScanProduct}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <ScanProductForm type={'VAS'} />
      </Layout.Content>
    </Layout>
  );
};

export default InboundControlScan;
