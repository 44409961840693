import environment from 'environments/environment';
import request from 'utils/request';

export async function bomList(payload) {
  return request(environment.api.bomProduction + '/get-all', payload, 'GET');
}

export async function bomDetail(payload) {
  return request(`${environment.api.bomProduction}/get-one/${payload?.bomId}`, {}, 'GET');
}

export async function bomCreate(payload) {
  return request(environment.api.bomProduction + '/create', payload, 'POST');
}

export async function bomUpdate(payload) {
  return request(environment.api.bomProduction + '/update', payload, 'PUT');
}
export async function bomDelete(id) {
  return request(environment.api.bomProduction + `/delete/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.bomProduction + `/create/excel/${warehouseId}`, formData , 'POST');
}
