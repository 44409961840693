import environment from 'environments/environment';
import request from 'utils/request';

export async function replenishmentList(payload) {
  return request(environment.api.replenishments + 'get-all', payload, 'GET');
}
export async function productsList(payload) {
  return request(environment.api.productTrxList, payload, 'GET');
}
export async function productCategoryList(payload) {
  return request(environment.api.productCategoryList, payload, 'GET');
}
