import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Form, Card, Row, Col, DatePicker, message as Alert, Input, message, Select } from 'antd';
import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { physicalInventoryConfirm, physicalInventoryCreate } from './physical-inventory.service';
import PhysicalInventoryCreateProductEditForm from './physical-inventory-create-product-edit.form';
import { formatDate, formatDateAPi, formatDateFull } from 'utils/constants';
import './style.less';
import { productList } from '../../configuration/product/product.service';
import ProTableCusTom from 'components/TableCustom/Protable';
import Helper from 'utils/helpers';
import Dragger from 'antd/lib/upload/Dragger';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { Option } from 'antd/lib/mentions';
import _get from 'lodash/get';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};


const PhysicalInventoryCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [isEditModal, setIsEditModal] = useState(false);

  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [productMasters, setProductMasters] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState();
  const [documentType, setDocumentType] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const isIdm = _get(user, 'roles', '').includes('idm');

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'code3A',
      key: 'code3A',
    },
    {
      title: lang.productName,
      dataIndex: 'nameEN',
      key: 'nameEN',
    },
    // {
    //   title: 'Campaign',
    //   dataIndex: 'campaign',
    //   key: 'Campaign',
    // },
    {
      title: lang.Locator,
      dataIndex: ['locatorId', 'label'],
      key: 'locatorId',
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: 'expireDate',
    },
    {
      title: lang.quantity,
      key: 'qtyCount',
      // dataIndex: 'qtyCount',
      render: rec => rec?.qtyCount ? rec?.qtyCount : ''
    },
    {
      title: 'Qty Scrapped',
      dataIndex: 'qtyScrapped',
      key: 'carton',
    },
    {
      title: 'Quantity book',
      // dataIndex: 'quantityBook',
      key: 'quantityTin',
      render: rec => rec?.quantityBook ? rec?.quantityBook : ''
    },
    // {
    //   title: 'Inventory Type',
    //   dataIndex: 'inventoryType',
    //   key: 'quantity',
    // },
    // {
    //   title: lang.action,
    //   align: 'center',
    //   width: '8%',
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <Button
    //         type="link"
    //         icon={<EditOutlined />}
    //         size="small"
    //         onClick={() => {
    //           // const detail = products.find((item) => item.masterProductId === record.masterProductId);
    //           setProduct(record);
    //           setIsEditModal(true);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    isIdm && <Button
      key="btn-complete"
      type="primary"
      disabled={products.length === 0}
      onClick={() => {
        form.submit();
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: 'Home',
    },
    {
      path: '/receipt',
      breadcrumbName: 'Kiểm kê',
    },
    {
      path: '/dddd',
      breadcrumbName: 'Physical Inventory',
    },
    {
      path: '/',
      breadcrumbName: 'Tạo Physical Inventory',
    },
  ];

  useEffect(() => {
    setLoading(true);
    productList({ warehouseId: currentWarehouse.id })
      .then(result => {
        setProductMasters(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  return (

    <Layout className="receipt-list layoutContent">
      <PageHeader
        ghost={false}
        title={'Tạo Physical Inventory'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form className="customFormSearch"
            colon={false}
            form={form}
            layout="vertical"
            name="receiptCreateForm"
            onFinish={async (values) => {
              try {
                values.warehouseId = currentWarehouse?.id;
                if (values?.movementDate) {
                  values.movementDate = values.movementDate.format(formatDateAPi);
                }
                values.status = 'CONFIRM';
                values.physicalProduct = products?.map(product => {
                  const { code3A, locatorId, campaign, qtyScrapped, qtyCount, quantityBook, inventoryType, lot, expireDate, printQAcode } = product;
                  return {
                    code3A,
                    locatorCode: locatorId?.value,
                    campaign,
                    qtyScrapped,
                    qtyCount,
                    quantityBook,
                    inventoryType,
                    lot,
                    expireDate,
                    warehouseId: currentWarehouse?.id,
                    printQAcode
                  };
                });
                setLoading(true);
                await physicalInventoryCreate(values)
                  .then( async (result) => {
                    await physicalInventoryConfirm(parseInt(result?.id));
                    message.success(lang.createSuccessfully);
                    setLoading(false);
                    navigate('/dashboard/inventory/physicalinventory');
                  })
                  .catch((error) => {
                    Alert.error(error?.message);
                    setLoading(false);
                  });
                return true;
              } catch (error) {
                Alert.error(error?.message);
                setLoading(false);
              }
            }}
            initialValues={{}}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="company"
                  label={lang.company}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.company} />
                </Form.Item>
              </Col>            
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="perpetualInventory"
                  label={'Perpetual Inventory'}
                >
                  <Input style={{ width: '100%' }} placeholder={'Perpetual Inventory'} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="movementDate"
                  label={lang.movementDate}
                  initialValue={moment()}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="description"
                  label={lang.description}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.description} />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6}>
              <Form.Item
                  name="documentType"
                  label={'Document type'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn Document Type!',
                    }
                  ]}
                >
                <Select placeholder={'Document type'} style={{ width: '100%' }} onChange={(value) => setDocumentType(value)} allowClear>
                  <Option value="Physical Inventory">{'Phy Inventory'}</Option>
                  <Option value="Stocktake Adjustment">{'Stock Adjustment'}</Option>
                </Select>
              </Form.Item>
            </Col>
            </Row>
          </Form>
        </Card>
        <ProTableCusTom
          title={() => <Row>
            <Col sm={12} xs={10}><h3>{lang.productAdded}</h3></Col>
            <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setProduct();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => setUploadModal(true)}
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        setProducts(products.filter(item => !(selectedRowKeys.includes(item.id))));
                        setSelectedRowKeys([]);
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={products}
          // scroll={{ x: 2600 }}
          search={false}
          tableAlertRender={false}
          options={{
            search: false,
            reload: false,
            density: false,
          }}
        // pagination={false}
        />
      </Layout.Content>
      <PhysicalInventoryCreateProductEditForm
        item={product}
        products={productMasters}
        productsSelected={products}
        warehouseId={currentWarehouse?.id}
        documentType={documentType}
        onCallback={(values) => {
          setProducts([...products, values]);
          setIsEditModal(false);
          // const productsfind = products.find(item => item?.key === values?.key); // Tìm product không có trong kho
          // if (productsfind) {
          //   Object.assign(productsfind, values);
          //   setProducts([...products, values]);
          //   setIsEditModal(false);
          //   setItem([...products, values]);
          // } else {
            // const findProductInStored = productsStored.find(result => result?.masterProduct?.id === values?.id && result?.locator?.locator === values?.locatorId.value && result?.lot === values?.lot && moment(result.expireDate, formatDateAPi).format(formatDateAPi) === values?.expireDate);
            // if (findProductInStored) {
            //   findProductInStored.quantityTin = values.qtyScrapped 
            // }
          //   setProducts([values]);
          //   setItem([findProductInStored]);
          //   setProducts([...products, { ...values, key: products.length }]);
          //   setIsEditModal(false);
          //   if (findProductInStored && values?.printQAcode === 'YES') {
          //     setIsPrintModal(true);
          //   }
          // }
        }}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
      />
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file?.originFileObj);
              fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'binary' });
                const sheetName = wb.SheetNames[0];
                const sheet = wb.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                if (data) {
                  const result = data?.reduce((prev, curr, index) => {
                    if (index > 0 && !!curr) {
                      const finded = productMasters?.find(master => master.barCode === curr[0]?.toString());
                      if (finded) {
                        return [...prev, {
                          key: index - 1,
                          barCode: finded.barCode,
                          productName: finded?.name,
                          productCategory: finded?.productCategory?.name,
                          expectedQuantity: curr[1],
                          supplier: finded?.suppliers?.find(supp => supp?.id === curr[2])?.name,
                          purchasePrice: finded?.purchasePrice,
                          salePrice: finded?.salePrice,
                          retailPrice: finded?.retailPrice,
                          expireDate: curr[3] && moment(curr[3], formatDateFull),
                          masterProductId: finded?.id,
                          productCategoryId: finded?.productCategory?.id,
                          productCode: finded?.code,
                          warehouseId: finded?.warehouseId,
                          DVT: finded?.DVT,
                        }];
                      }
                      return prev;
                    }
                    return prev;
                  }, []);
                  setProducts([...products, ...result]);
                }
              };
              setFile();
            }
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ barCode: '', expectedQuantity: 0, supplierId: 0, expireDate: moment().format(formatDate) }], 'TemplateDataPO')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default PhysicalInventoryCreate;
