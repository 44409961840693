import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import moment from 'moment-timezone';
import _get from 'lodash/get';
import 'moment/locale/vi';

import store, { persistor } from '../src/redux/store';

import detectBrowserLanguage from 'detect-browser-language';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'providers/I18nProvider/actions';

// Public
import HomeLayout from 'layouts/home/home.layout';
import Login from 'pages/home/login';

// Dashboard
import DashboardLayout from 'layouts/dashboard/dashboard.layout';
import Welcome from 'pages/dashboard/welcome';

// Inbound
import InboundReceiptList from 'pages/dashboard/inbound/receipt/receipt-list.container';
import InboundReceiptCreate from 'pages/dashboard/inbound/receipt/receipt-create.container';
import InboundReceiptDetail from 'pages/dashboard/inbound/receipt/receipt-detail.container';

import InboundReceiptGiftList from 'pages/dashboard/inbound/receiptGift/receiptGift-list.container';
import InboundReceiptGiftDetail from 'pages/dashboard/inbound/receiptGift/receiptGift-detail.container';

import InboundTemporaryInboundList from 'pages/dashboard/inbound/temporary-inbound/temporary-inbound-list.container';
import ScanReturnProduct from 'pages/dashboard/inbound/temporary-inbound/scan-return-product.container';
import ScanReturnProductComplete from 'pages/dashboard/inbound/temporary-inbound/scan-return-product-complete.container';

// import InboundStoring from 'pages/dashboard/inbound/storing/storing-list.container';
// import InboundStoringScan from 'pages/dashboard/inbound/storing/storing-scan.container';
// import InboundStoringScanComplete from 'pages/dashboard/inbound/storing/storing-scan-complete.container';

import InboundInboundControl from 'pages/dashboard/inbound/inbound-control/inbound-control-list.container';
import InboundInboundControlScan from 'pages/dashboard/inbound/inbound-control/inbound-control-scan.container';
import InboundInboundControlScanComplete from 'pages/dashboard/inbound/inbound-control/inbound-control-scan-complete.container';

import Vas from 'pages/dashboard/inbound/vas/vas-list.container';
import VasCompleted from 'pages/dashboard/inbound/vas-completed/vasCompleted-list.container';
import VasCompletedDetail from 'pages/dashboard/inbound/vas-completed/vasCompleted.detail';
import VasScan from 'pages/dashboard/inbound/vas/vas-scan.container';
import VasScanComplete from 'pages/dashboard/inbound/vas/vas-scan-complete.container';

import ProductionOrder from 'pages/dashboard/inbound/production-order/production-order-list.container';
import ProductionOrderCreate from 'pages/dashboard/inbound/production-order/production-order-create.container';
import ProdcutionOrderDetail from 'pages/dashboard/inbound/production-order/production-order-detail.container';
import ProductionOrderScan from 'pages/dashboard/inbound/production-order/production-order-scan.container';
import ProdcutionOrderScanComplete from 'pages/dashboard/inbound/production-order/production-order-scan-complete.container';

import CustomerReturn from 'pages/dashboard/inbound/customer-return/customer-return-list.container';
import CustomerReturnCreate from 'pages/dashboard/inbound/customer-return/customer-return-create.container';
import CustomerRMADetail from 'pages/dashboard/inbound/customer-return/customer-return-detail.container';
import ScanContainer from 'pages/dashboard/inbound/Scan/scan.container';

//Configuration
import Supplier from 'pages/dashboard/configuration/supplier/supplier-list.container';
import SupplierDetail from 'pages/dashboard/configuration/supplier/supplier-detail.container';
import ProductCategory from 'pages/dashboard/configuration/product-category/product-category-list.container';
import Product from 'pages/dashboard/configuration/product/product-list.container';
import ProductDetail from 'pages/dashboard/configuration/product/product-detail.container';
import Replenishment from 'pages/dashboard/configuration/replenishment/replenishment-list.container';
import Location from 'pages/dashboard/configuration/location/location-list.container';
// import SaleOrder from 'pages/dashboard/configuration/sale-order/sale-order-list.container';
import SaleOrderCreate from 'pages/dashboard/configuration/sale-order/sale-order-create.container';
import SaleOrderDetail from 'pages/dashboard/configuration/sale-order/sale-order-detail.container';
import MasterproductPacking from 'pages/dashboard/configuration/masterproduct-packing/masterproduct-packing.container';
import Divison from 'pages/dashboard/configuration/divison/divison-list.container';
// import Category from 'pages/dashboard/configuration/category/category-list.container';
import Bom from 'pages/dashboard/configuration/bom/bom-list.container';
import BomGift from 'pages/dashboard/configuration/bom-gift/bom-gift-list.container';
import Area from 'pages/dashboard/configuration/area/area-list.container';
import Locator from 'pages/dashboard/configuration/locator/locator-list.container';
import SalesChannel from 'pages/dashboard/configuration/sales-channel/sales-channel-list.container';
import MasterProductsBomGift from 'pages/dashboard/configuration/masster-products-bom-gift/master-products-bom-gift-list.container';

//Inventory
import WarehouseState from 'pages/dashboard/inventory/warehouse-state/warehousestate-list.container';
import WarehouseBlock from 'pages/dashboard/inventory/warehouse-state/warehouseblock.container';
import WarehouseZone from 'pages/dashboard/inventory/warehouse-state/warehousezone.container';
// import Reallocation from 'pages/dashboard/inventory/reallocation/reallocation-list.container';
// import ReallocationScanCode from 'pages/dashboard/inventory/reallocation/reallocation-scancode.container';
import ProductManagement from 'pages/dashboard/inventory/product-management/product-management.container';
import InventoryManagement from 'pages/dashboard/inventory/inventory-management/inventory-managenment.container';
import PhysicalInventory from 'pages/dashboard/inventory/physical-inventory/physical-inventory.container';
import PhysicalInventoryCreate from 'pages/dashboard/inventory/physical-inventory/physical-inventory-create.container';
import PhysicalInventoryDetail from 'pages/dashboard/inventory/physical-inventory/physical-inventory-detail.container';
import GiftInventory from 'pages/dashboard/inventory/giftinventory/inventory-gift-list.container';
import InventoryMaterial from 'pages/dashboard/inventory/inventorymaterial/bom-list.container';

//Outbound
import OutboundPickingList from 'pages/dashboard/outbound/picking/picking-list.container';
import OutboundPickingCreate from 'pages/dashboard/outbound/picking/picking-create.container';
import OutboundPickingCreateScan from 'pages/dashboard/outbound/picking/picking-create-scan.container';
import OutboundPickingCreateScanComplete from 'pages/dashboard/outbound/picking/picking-create-scan-complete.container';

// import OutboundPackagingList from 'pages/dashboard/outbound/packaging/packaging-list.container';
// import OutboundPackagingCreate from 'pages/dashboard/outbound/packaging/packaging-create.container';
// import OutboundPackagingCreateScan from 'pages/dashboard/outbound/packaging/packaging-create-scan.container';
// import OutboundPackagingCreateScanComplete from 'pages/dashboard/outbound/packaging/packaging-create-scan-complete.container';
import OutboundConfirmOrder from 'pages/dashboard/outbound/confirmOrder/confirmOrder-list.container';
import OutboundConfirmOrderDetail from 'pages/dashboard/outbound/confirmOrder/confirmOrder-detail.container';
import OutboundConfirmOrderCreate from 'pages/dashboard/outbound/confirmOrder/confirmOrder-create.container';

import OutboundShippingList from 'pages/dashboard/outbound/shipping/shipping-list.container';
import OutboundShippingScan from 'pages/dashboard/outbound/shipping/shipping-scan.container';
import OutboundShippingScanComplete from 'pages/dashboard/outbound/shipping/shipping-scan-complete.container';

//Reporting
import AuditOrder from 'pages/dashboard/reporting/auditOrder/auditOrderlist.container';
import InventoryWarning from 'pages/dashboard/reporting/inventory-Warning/inventoryWarninglist.container';
import Performance from 'pages/dashboard/reporting/performance/performance.container';

//Admin
import AdminLayout from 'layouts/admin/admin.layout';
import AdminWelcome from 'pages/admin/welcome';
import UserList from 'pages/admin/user/user-list.container';
import WarehouseList from 'pages/admin/warehouse/warehouse-list.container';
import { ConfigProvider } from 'antd';
import enUSIntl from 'antd/lib/locale/en_US';
import viVNIntl from 'antd/lib/locale/vi_VN';
import SplitScanContainer from 'pages/dashboard/outbound/split-product/split-scan.container';
import SplitScanComplete from 'pages/dashboard/outbound/split-product/split-scan-complete';
// import VansDetail from 'pages/dashboard/inventory/vans-state/vans-detail.container';
import ApInvoiceList from 'pages/dashboard/inbound/request-receipt/apinvoice-list.container';
import ApInvoiceDetail from 'pages/dashboard/inbound/request-receipt/apinvoice-detail.container';

import PurchaseOrderList from 'pages/dashboard/inbound/purchase-order/purchaseOrder-list.container';
import PurchaseOrderDetail from 'pages/dashboard/inbound/purchase-order/purchaseOrder-detail.container';

import DeliveryOrderList from 'pages/dashboard/outbound/delivery-order/delivery-order-list.container';
import DeliveryOrderDetail from 'pages/dashboard/outbound/delivery-order/delivery-order-detail.container';
import DeliveryOrderPickingScan from 'pages/dashboard/outbound/delivery-order/picking-create-scan.container';

import ArInvoiceList from 'pages/dashboard/outbound/arInvoice/arInvoice-list.container';
import ArInvoiceDetail from 'pages/dashboard/outbound/arInvoice/arInvoice-detail.container';
import InternalTranferList from 'pages/dashboard/outbound/internalTranfer/internalTranfer-list.container';
import InternalTranferCreate from 'pages/dashboard/outbound/internalTranfer/internalTranfer-create.container';
import InternalTranferCreateScan from 'pages/dashboard/outbound/internalTranfer/internalTranfer-create-scan.container';

import ManueuverCommandList from 'pages/dashboard/inbound/manueuver-command/manuever-command-list.container';
import ManueuverCommandCreate from 'pages/dashboard/inbound/manueuver-command/manuever-command-create.container';
import ManueuverCommandDetail from 'pages/dashboard/inbound/manueuver-command/manuever-command-detail.container';

import GiftManueuverCommandList from 'pages/dashboard/inbound/gift-manueuver-command/gift-manuever-command-list.container';
import GiftCommandManueverCreate from 'pages/dashboard/inbound/gift-manueuver-command/gift-manuever-command-create.container';

import InventorySaleChannel from 'pages/dashboard/inventory/inventory-saleChannel/inventory-saleChannel.container';
import ExportBranchList from 'pages/dashboard/outbound/exportBranch/exportBranch-list.container';
import ExportBranchScan from 'pages/dashboard/outbound/exportBranch/export-branch-scan.container';
import ExportBranchDetail from 'pages/dashboard/outbound/exportBranch/export-branch-detail.container';
import MaterialListContainer from 'pages/dashboard/inbound/material/material-list.container';
import ReceiptMaterialCreate from 'pages/dashboard/inbound/material/receiptMaterial-create.container';
import MaterialDetail from 'pages/dashboard/inbound/material/material-detail.container';
import GiftManueuverCommandDetail from 'pages/dashboard/inbound/gift-manueuver-command/gift-manuever-command-detail.container';

//EXPORT BRANCH GIFT
import ExportBranchGiftList from 'pages/dashboard/outbound/exportBranchGift/exportBranch-list.container';
import ExportBranchGiftScan from 'pages/dashboard/outbound/exportBranchGift/export-branch-scan.container';
import ExportBranchGiftDetail from 'pages/dashboard/outbound/exportBranchGift/export-branch-detail.container';

import MaterialManueuverCommandList from 'pages/dashboard/outbound/material-export/material-manuever-command-list.container';
import MaterialManueuverCommandCreate from 'pages/dashboard/outbound/material-export/material-manuever-command-create.container';
import MaterialManueuverCommandDetail from 'pages/dashboard/outbound/material-export/material-manuever-command-detail.container';

// Production
import ProductionList from 'pages/dashboard/inbound/production/production-list.container';
import ProductionDetail from 'pages/dashboard/inbound/production/production-detail.container';
import UnpackList from 'pages/dashboard/inbound/unpack/unpack-list.container';
import UnpackDetail from 'pages/dashboard/inbound/unpack/unpack-detail.container';


// export internal Gift
import InternalTranferListGift from 'pages/dashboard/outbound/internalTranferGift/internalTranfer-list.container';
import InternalTranferCreateGift from 'pages/dashboard/outbound/internalTranferGift/internalTranfer-create.container';
import InternalTranferDetailGift from 'pages/dashboard/outbound/internalTranferGift/internal-tranfer-gift-detail.container';

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

const App = () => {
  const dispatch = useDispatch();
  moment.tz.setDefault();

  const { i18n } = useTranslation();
  const locale = useSelector((state) => state.i18n.locale);
  const { user } = useSelector((state) => state.auth);

  const isAdmin = _get(user, 'roles', '').includes('admin');
  const isManager = _get(user, 'roles', '').includes('manager');
  // const isSupervisor = _get(user, 'roles', '').includes('supervisor');
  const isStaff = _get(user, 'roles', '').includes('staff');

  const setLanguageHandler = useCallback(
    (languageDetectByBrowser) => dispatch(setLanguage({ locale: languageDetectByBrowser })),
    [dispatch],
  );

  useEffect(() => {
    const languageDetectByBrowser = detectBrowserLanguage();
    if (!languageDetectByBrowser) {
      setLanguageHandler(languageDetectByBrowser);
    }
  }, [setLanguageHandler]);

  useEffect(() => {
    if (!locale || i18n.language === locale) return;
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  return (
    <ConfigProvider locale={locale === 'vi' ? viVNIntl : enUSIntl}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <HelmetProvider>
            <Helmet>
              <title>akaWMS</title>
            </Helmet>
            <Routes>
              <Route path="" element={<HomeLayout />}>
                <Route path="" element={<Login />} />
                <Route path="login" element={<Login />} />
              </Route>
              {isAdmin === true
                ? <Route path="admin" element={<AdminLayout />}>
                  <Route path="" element={<AdminWelcome />} />
                  <Route path="user" element={<UserList />} />
                  <Route path="warehouse" element={<WarehouseList />} />
                </Route>
                : user && <Route path="dashboard" element={<DashboardLayout />}>
                  <Route path="" element={<Welcome />} />

                  <Route path="inbound/apinvoice" element={<ApInvoiceList />} />
                  <Route path="inbound/apinvoice/:id" element={<ApInvoiceDetail />} />

                  <Route path="inbound/receipt" element={<InboundReceiptList />} />
                  <Route path="inbound/receipt/create" element={<InboundReceiptCreate />} />
                  <Route path="inbound/receipt/:receiptId" element={<InboundReceiptDetail />} />

                  <Route path="inbound/purchaseorder" element={<PurchaseOrderList />} />
                  <Route path="inbound/purchaseorder/:id" element={<PurchaseOrderDetail />} />

                  <Route path="inbound/gift-receipt" element={<InboundReceiptGiftList />} />
                  <Route path="inbound/gift-receipt/:id" element={<InboundReceiptGiftDetail />} />

                  <Route path="inbound/temporary-inbound" element={<InboundTemporaryInboundList />} />

                  <Route path="inbound/temporary-inbound/scan-return-product" element={<ScanReturnProduct />} />
                  <Route path="inbound/temporary-inbound/scan-return-product/complete" element={<ScanReturnProductComplete />} />

                  {/* <Route path="inbound/storing" element={<InboundStoring />} />
                  {(isManager || isSupervisor) && (
                    <>
                      <Route path="inbound/storing/storing-scan" element={<InboundStoringScan />} />
                      <Route path="inbound/storing/storing-scan/complete" element={<InboundStoringScanComplete />} />
                    </>
                  )} */}

                  <Route path="inbound/inbound-control" element={<InboundInboundControl />} />
                  <Route path="inbound/inbound-control/inbound-control-scan" element={<InboundInboundControlScan />} />
                  <Route path="inbound/inbound-control/inbound-control-scan/complete" element={<InboundInboundControlScanComplete />} />
                  
                  <Route path="inbound/vas" element={<Vas />} />
                  <Route path="inbound/completed-vas" element={<VasCompleted />} />
                  <Route path="inbound/completed-vas/:saleorderID" element={<VasCompletedDetail />} />
                  <Route path="inbound/vas/vas-scan" element={<VasScan />} />
                  <Route path="inbound/vas/vas-scan/complete" element={<VasScanComplete />} />

                  <Route path="inbound/production-order" element={<ProductionOrder />} />
                  <Route path="inbound/production-order/create" element={<ProductionOrderCreate />} />
                  <Route path="inbound/production-order/:id" element={<ProdcutionOrderDetail />} />
                  <Route path="inbound/production-order/scan" element={<ProductionOrderScan />} />
                  <Route path="inbound/production-order/scan/complete" element={<ProdcutionOrderScanComplete />} />

                  <Route path="inbound/list-production" element={<ProductionList />} />                  
                  <Route path="inbound/list-production/:id" element={<ProductionDetail />} />  

                  <Route path="inbound/list-unpack" element={<UnpackList />} />                  
                  <Route path="inbound/list-unpack/:id" element={<UnpackDetail />} />                 

                  <Route path="inbound/customer-return" element={<CustomerReturn />} />
                  <Route path="inbound/customer-return/create" element={<CustomerReturnCreate />} />
                  <Route path="inbound/customer-return/:id" element={<CustomerRMADetail />} />
                  <Route path="inbound/scan" element={<ScanContainer />} />

                  <Route path="inbound/manuever-command" element={<ManueuverCommandList />} />
                  <Route path="inbound/manuever-command/create" element={<ManueuverCommandCreate />} />
                  <Route path="inbound/manuever-command/:id" element={<ManueuverCommandDetail />} />

                  <Route path="inbound/gift-manuever-command" element={<GiftManueuverCommandList />} />
                  <Route path="inbound/gift-manuever-command/create" element={<GiftCommandManueverCreate />} />
                  <Route path="inbound/gift-manuever-command/:id" element={<GiftManueuverCommandDetail />} />

                  <Route path="inbound/material" element={<MaterialListContainer />} />
                  <Route path="inbound/material/create" element={<ReceiptMaterialCreate />} />
                  <Route path="inbound/material/:id" element={<MaterialDetail />} />

                  {isManager && (
                    <>
                      <Route path="configuration/supplier" element={<Supplier />} />
                      <Route path="configuration/supplier/:supplierId" element={<SupplierDetail />} />
                      <Route path="configuration/product-category" element={<ProductCategory />} />
                      {/* <Route path="configuration/category" element={<Category />} /> */}
                      <Route path="configuration/divison" element={<Divison />} />
                      <Route path="configuration/masterproduct" element={<Product />} />
                      <Route path="configuration/masterproduct/:productId" element={<ProductDetail />} />
                      <Route path="configuration/location" element={<Location />} />
                      <Route path="configuration/replenishment" element={<Replenishment />} />
                      {/* <Route path="configuration/sale-order" element={<SaleOrder />} />
                      <Route path="configuration/sale-order/create" element={<SaleOrderCreate />} />
                      <Route path="configuration/sale-order/:saleorderID" element={<SaleOrderDetail />} /> */}
                      <Route path="configuration/bom" element={<Bom />} />
                      <Route path="configuration/giftbom" element={<BomGift />} />
                      <Route path="configuration/master-product-packing" element={<MasterproductPacking />} />
                      <Route path="configuration/area" element={<Area />} />
                      <Route path="configuration/locator" element={<Locator />} />
                      <Route path="configuration/sales-channel" element={<SalesChannel />} />
                      <Route path="configuration/master-products-bom-gift" element={<MasterProductsBomGift />} />
                    </>
                  )}
                  <Route path="inventory/warehousestate" element={<WarehouseState />} />
                  <Route path="inventory/warehousestate/warehouseblock/:blockId" element={<WarehouseBlock />} />
                  <Route path="inventory/warehousestate/warehousezone/:zoneId" element={<WarehouseZone />} />
                  {/* <Route path="inventory/vansstate" element={<VansState />} />
                  <Route path="inventory/vansstate/vansdetail/:vansId" element={<VansDetail />} /> */}
                  {!isStaff && (
                    <>
                      {/* <Route path="inventory/reallocation" element={<Reallocation />} /> */}
                      {/* <Route path="inventory/reallocation/scancode" element={<ReallocationScanCode />} /> */}
                      <Route path="inventory/productmanagement" element={<ProductManagement />} />
                      <Route path="inventory/inventorymanagement" element={<InventoryManagement />} />
                      <Route path="inventory/physicalinventory" element={<PhysicalInventory />} />
                      <Route path="inventory/physicalinventory/create" element={<PhysicalInventoryCreate />} />
                      <Route path="inventory/physicalinventory/:id" element={<PhysicalInventoryDetail />} />

                      <Route path="inventory/giftinventory" element={<GiftInventory />} />
                      <Route path="inventory/inventorymaterial" element={<InventoryMaterial />} />
                    </>
                  )}
                  <Route path="inventory/inventoryproduct-salechannel" element={<InventorySaleChannel />} />
                  <Route path="outbound/sale-order" element={<OutboundPickingList />} />
                  {!isStaff && (
                    <>
                      <Route path="outbound/sale-order/picking" element={<OutboundPickingCreate />} />
                      <Route path="outbound/sale-order/picking/scan" element={<OutboundPickingCreateScan />} />
                      <Route path="outbound/sale-order/picking/scan/complete" element={<OutboundPickingCreateScanComplete />} />
                      {/* <Route path="outbound/sale-order" element={<SaleOrder />} /> */}
                      <Route path="outbound/sale-order/create" element={<SaleOrderCreate />} />
                      <Route path="outbound/sale-order/:saleorderID" element={<SaleOrderDetail />} />
                      <Route path="outbound/delivery-order/" element={<DeliveryOrderList />} />
                      <Route path="outbound/delivery-order/:id" element={<DeliveryOrderDetail />} />
                      <Route path="outbound/delivery-order/picking/scan" element={<DeliveryOrderPickingScan />} />
                      <Route path="outbound/ar-invoice/" element={<ArInvoiceList />} />
                      <Route path="outbound/ar-invoice/:id" element={<ArInvoiceDetail />} />
                      <Route path="/dashboard/outbound/gift-internal-tranfer" element={<InternalTranferListGift />} />
                      <Route path="outbound/internal-tranfer" element={<InternalTranferList />} />
                      <Route path="outbound/internal-tranfer/:saleorderID" element={<SaleOrderDetail />} />
                      <Route path="outbound/internal-tranfer/create" element={<SaleOrderCreate />} />
                      <Route path="outbound/internal-tranfer/picking" element={<InternalTranferCreate />} />
                      <Route path="outbound/gift-internal-tranfer/create" element={<InternalTranferCreateGift />} />
                      <Route path="outbound/gift-internal-tranfer/:saleorderID" element={<InternalTranferDetailGift />} />
                      <Route path="outbound/gift-internal-tranfer/picking/scan" element={<ExportBranchGiftScan />} />

                      <Route path="outbound/internal-tranfer/picking/scan" element={<InternalTranferCreateScan />} />
                      <Route path="outbound/export-branch" element={<ExportBranchList />} />
                      <Route path="outbound/export-branch/:saleorderID" element={<ExportBranchDetail />} />
                      <Route path="outbound/export-branch/picking/scan" element={<ExportBranchScan />} />
                      {/* EXPORT BARNCH GIFT */}
                      <Route path="outbound/gift-export-branch" element={<ExportBranchGiftList />} />
                      <Route path="outbound/gift-export-branch/:saleorderID" element={<ExportBranchGiftDetail />} />
                      <Route path="outbound/gift-export-branch/picking/scan" element={<ExportBranchGiftScan />} />

                      {/* EXPORT MATERIAL */}
                      <Route path="outbound/material-export" element={<MaterialManueuverCommandList />} />
                      <Route path="outbound/material-export/create" element={<MaterialManueuverCommandCreate />} />
                      <Route path="outbound/material-export/:id" element={<MaterialManueuverCommandDetail />} />
                    </>
                  )}

                  {/* <Route path="outbound/packaging" element={<OutboundPackagingList />} />
                  {!isStaff && (
                    <>
                      <Route path="outbound/packaging/create" element={<OutboundPackagingCreate />} />
                      <Route path="outbound/packaging/create/scan" element={<OutboundPackagingCreateScan />} />
                      <Route path="outbound/packaging/create/scan/complete" element={<OutboundPackagingCreateScanComplete />} />
                    </>
                  )} */}

                  <Route path="outbound/shipping" element={<OutboundShippingList />} />
                  {!isStaff && (
                    <>
                      <Route path="outbound/shipping/scan" element={<OutboundShippingScan />} />
                      <Route path="outbound/shipping/scan/complete" element={<OutboundShippingScanComplete />} />
                    </>
                  )}
                  <Route path="outbound/confirm-order" element={<OutboundConfirmOrder />} />
                  <Route path="outbound/confirm-order/:id" element={<OutboundConfirmOrderDetail />} />
                  <Route path="outbound/confirm-order/create" element={<OutboundConfirmOrderCreate />} />
                  <Route path="outbound/split-product/scan" element={<SplitScanContainer />} />
                  <Route path="outbound/split-product/scan/complete" element={<SplitScanComplete />} />
                  {isManager && (
                    <>
                      <Route path="reporting/auditorder" element={<AuditOrder />} />
                      <Route path="reporting/inventorywarning" element={<InventoryWarning />} />
                      <Route path="reporting/performance" element={<Performance />} />
                    </>
                  )}
                </Route>
              }
            </Routes>
          </HelmetProvider>
        </BrowserRouter>
      </PersistGate>
    </ConfigProvider>
  );
};

export default AppWrapper;
