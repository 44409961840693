import React from 'react';
import { Outlet } from 'react-router-dom';
import './style.less';

const HomeLayout = () => {
  // const navigate = useNavigate();
  //const { user = {} } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (_get(user, 'role') === 'OPERATOR') {
  //     navigate('/dashboard');
  //   }

  //   // Add rediect for new role ex: STAFF, CUSTOMER, ...
  // }, [navigate, user]);

  return (
    <div className="home-layout">
      <Outlet />
    </div>
  );
};

export default HomeLayout;
