import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Modal, InputNumber, Input, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ProFormSelect } from '@ant-design/pro-form';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';
import './style.less';
// import Helper from 'utils/helpers';
// import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
import { ProFormText } from '@ant-design/pro-form';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';

const ProducttionInternal = ({ item, onCallBack, setIsEditModal, isEditModal }) => {
  // console.log(item)
  const [formParent] = Form.useForm();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [locators, setLocators] = useState();
   
  // const totalQuantityCarton = item.reduce((prev, curr) => prev + curr?.quantityCartton, 0);
  // const totalQuantityTin = item.reduce((prev, curr) => prev + curr?.quantityTin, 0);
  const initValues = {
    internalProduct: item?.map(product => ({
      code3A: product?.masterProduct?.code3A,
      locatorName: product?.locator ? `${product?.locator?.locator} - ${product?.locator?.locatorName}` : '',
      warehouseId: product?.warehouseId,
      lot: product?.lot,
      expireDate: product?.expireDate ? moment(product?.expireDate, formatDateFull).format(formatDate) : "",
      name: product?.masterProduct?.nameEN,
      shortName: product?.masterProduct?.shortName,
      id: product?.id,
      quantityInCarton: product?.quantityCartton,
      quantityTin: product?.quantityTin,
    })),
    // code3A: item?.masterProduct?.code3A,
    // locatorName: item[0]?.locator ? `${item[0]?.locator?.locator} - ${item[0]?.locator?.locatorName}` : '',
    // warehouseId: item[0]?.warehouseId,
    // lot: item[0]?.lot,
    // expireDate: item[0]?.expireDate ? moment(item[0]?.expireDate, formatDateFull).format(formatDate) : "",
    // name: item[0]?.masterProduct?.nameEN,
    // shortName: item[0]?.masterProduct?.shortName,
    // id: item[0]?.id,
    // quantityInCarton: totalQuantityCarton,
    // quantityTin: totalQuantityTin,
  };
  useEffect(() => {
    return () => {
      formParent.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id})
      .then(val => {
        setLocators(val.data);
    });
  }, [currentWarehouse]);

  const onCancel = () => {
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${lang.btnCreate} ${lang.EXPORT_INTERNAL}`}
      onCancel={onCancel}
      afterClose={() => {
        formParent.resetFields();
        formParent.setFieldsValue({ internalProduct: undefined });
      }}
      width={1200}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={formParent}
        layout="vertical"
        name="productCategoryEditForm"
        onFinish={(values) => {
          onCallBack(values);
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col span={24}>
            <ProFormSelect style={{ fontWeight: 600 }}
              name='locatorCodeTo'
              label={`${lang.Locator} nhận`}
              rules={[
                {
                  required: true,
                  message: lang.requiredLocator,
                },
              ]}
              options={locators?.map(locator => ({
                label: `${locator.locatorName} - ${locator.locator}`,
                value: locator.locator,
                warehouseId: locator.warehouseId,
                id: locator.id,
              }))}
              placeholder={lang.Locator}
  
              showSearch
            />
          </Col>
          <Col span={24}>
          {/* <Row gutter={[16, 16]} align="middle" style={{ marginBottom: '5px solid #333' }}>
            <Col span={8}>
              <Form.Item
                name={'code3A'}
                wrapperCol={24}
                label={lang.defaultCode}
              >
                <Input style={{ width: '100%' }} placeholder={lang.defaultCode} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={'shortName'}
                wrapperCol={24}
                label={lang.SHORTNAME}
              >
                <Input style={{ width: '100%' }} placeholder={lang.SHORTNAME} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <ProFormText
                name={'name'}
                label={lang.productName}
                placeholder={lang.productName}
                disabled
              />
            </Col>
            <Col span={8}>
              <ProFormText
                name={'lot'}
                label={lang.lot}
                placeholder={lang.lot}
                disabled
              />
            </Col>
            <Col span={8}>
              <ProFormText
                name={'expireDate'}
                label={lang.expireDate}
                placeholder={lang.expireDate}
                disabled
              />
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <ProFormText
                name={'locatorName'}
                label={'Locator'}
                placeholder={'Locator'}
                disabled
              />
            </Col>
            <Col span={8}>
              <Form.Item 
                name={'quantityInCarton'}
                label={'SL thùng'}
              >
                <InputNumber disabled style={{ width: '100%' }} placeholder={lang.quantityCartton} min={0} />
              </Form.Item>
            </Col>
          
            <Col span={8}>
              <Form.Item 
                name={'quantityTin'}
                label={'SL lon'}
              >
                <InputNumber disabled style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
              </Form.Item>
            </Col>
          </Row> */}
          <Row utter={[16, 16]} align="middle" style={{ marginBottom: '5px solid #333' }}>
          <Form.List name="internalProduct" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                      <Row gutter={[16, 16]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'code3A']}
                            wrapperCol={24}
                            label={lang.defaultCode}
                          >
                            <Input style={{ width: '100%' }} placeholder={lang.defaultCode} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'shortName']}
                            wrapperCol={24}
                            label={lang.SHORTNAME}
                          >
                            <Input style={{ width: '100%' }} placeholder={lang.SHORTNAME} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <ProFormText
                            name={[name, 'name']}
                            label={lang.productName}
                            placeholder={lang.productName}
                            disabled
                          />
                        </Col>
                        <Col span={8}>
                          <ProFormText
                            name={[name, 'lot']}
                            label={lang.lot}
                            placeholder={lang.lot}
                            disabled
                          />
                        </Col>
                        <Col span={8}>
                          <ProFormText
                            name={[name, 'expireDate']}
                            label={lang.expireDate}
                            placeholder={lang.expireDate}
                            disabled
                          />
                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                        <ProFormText
                            name={[name, 'locatorName']}
                            label={'Locator'}
                            placeholder={'Locator'}
                            disabled
                          />
                        </Col>
                        <Col span={8}>
                          <Form.Item 
                           name={[name, 'quantityInCarton']}
                           label={'SL thùng'}
                          >
                            <InputNumber disabled style={{ width: '100%' }} placeholder={lang.quantityCartton} min={0} />
                          </Form.Item>
                        </Col>
                      
                        <Col span={8}>
                          <Form.Item 
                           name={[name, 'quantityTin']}
                           label={'SL lon'}
                          >
                            <InputNumber disabled style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                      {name < item?.length - 1 && <Divider />}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Row>
            
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              <Button type="primary" onClick={() => formParent.submit()} style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

ProducttionInternal.propTypes = {
  item: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default ProducttionInternal;
