import {
  SCAN_RETURN_FOR_PRODUCT_CODE_ADD,
  SCAN_RETURN_FOR_PACKAGE_CODE_ADD,
  SCAN_RETURN_COMPLETED,

  SCAN_STORING_FOR_PRODUCT_CODE_ADD,
  SCAN_STORING_FOR_PACKAGE_CODE_ADD,
  SCAN_STORING_COMPLETED,

  SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD,
  SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD,
  SCAN_INBOUND_CONTROL_COMPLETED,
  SCAN_VAS_FOR_PRODUCT_CODE_ADD,
  SCAN_VAS_COMPLETED,
  TYPE_SCAN,
  PRODUCTION_ORDER_PRODUCT,
  SCAN_PRODUCTION_ORDER_FOR_PRODUCT_CODE_ADD,
  SCAN_PRODUCTION_ORDER_COMPLETED,
} from './types';

//Temporary Inbound
export function scanReturnProductCodeAdd(payload = {}) {
  return { type: SCAN_RETURN_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanReturnPackageCodeAdd(payload = {}) {
  return { type: SCAN_RETURN_FOR_PACKAGE_CODE_ADD, payload };
}
export function scanReturnCompleted(payload = {}) {
  return { type: SCAN_RETURN_COMPLETED, payload, meta: { thunk: true } };
}

//Storing
export function scanStoringProductCodeAdd(payload = {}) {
  return { type: SCAN_STORING_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanStoringPackageCodeAdd(payload = {}) {
  return { type: SCAN_STORING_FOR_PACKAGE_CODE_ADD, payload };
}
export function scanStoringCompleted(payload = {}) {
  return { type: SCAN_STORING_COMPLETED, payload, meta: { thunk: true } };
}

//Inbound control
export function scanInboundControlLocationCodeAdd(payload = {}) {
  return { type: SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD, payload };
}
export function scanInboundControlProductCodeAdd(payload = {}) {
  return { type: SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanInboundControlCompleted(payload = {}) {
  return { type: SCAN_INBOUND_CONTROL_COMPLETED, payload, meta: { thunk: true } };
}

//Vas
export function scanVasProductCodeAdd(payload = {}) {
  return { type: SCAN_VAS_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanVasCompleted(payload = {}) {
  return { type: SCAN_VAS_COMPLETED, payload, meta: { thunk: true } };
}

export function setTypeScan(payload = {}) {
  return { type: TYPE_SCAN, payload, meta: { thunk: true } };
}

export function setProductionOrderProduct(payload = {}) {
  return { type: PRODUCTION_ORDER_PRODUCT, payload, meta: { thunk: true } };
}
export function scanProductionOrderProductCodeAdd(payload = {}) {
  return { type: SCAN_PRODUCTION_ORDER_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanProductionOrderCompleted(payload = {}) {
  return { type: SCAN_PRODUCTION_ORDER_COMPLETED, payload, meta: { thunk: true } };
}
