import {
  bookingOrderSetSuccess,
  bookingOrderSetFail,
  pickingListSetSuccess,
} from './actions';
import {
  BOOKING_ORDER_SET,
  PICKING_LIST_SET,
} from './types';

import { put, takeEvery } from 'redux-saga/effects';
import { message as Alert } from 'antd';

function* bookingOrderSet(action) {
  try {
    console.log('action.payload: ', action.payload);
    yield put(bookingOrderSetSuccess(action.payload, action.meta));
  } catch (e) {
    yield put(bookingOrderSetFail(e, action.meta));
    Alert.error(e.error);
  }
}

function* pickingListSet(action) {
  try {
    console.log('action.payload: ', action.payload);
    yield put(pickingListSetSuccess(action.payload, action.meta));
  } catch (e) {
    yield put(bookingOrderSetFail(e, action.meta));
    Alert.error(e.error);
  }
}

export default function* watchOutboundList() {
  yield takeEvery(BOOKING_ORDER_SET, bookingOrderSet);
  yield takeEvery(PICKING_LIST_SET, pickingListSet);
}
