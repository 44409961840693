import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  SET_CURRENT_USER,
  SET_CURRENT_WAREHOUSE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_SUCCESS,
} from './types';

// SALON
export function login(payload = {}) {
  return { type: LOGIN, payload, meta: { thunk: true } };
}

export function loginSuccess(payload = {}, meta) {
  return { type: LOGIN_SUCCESS, payload, meta };
}
export function loginFail(payload = {}, meta) {
  return { type: LOGIN_FAIL, payload, meta, error: true };
}

export function logout(payload = {}) {
  return { type: LOGOUT, payload, meta: { thunk: true } };
}
export function logoutSuccess(payload = {}, meta) {
  return { type: LOGOUT_SUCCESS, payload, meta };
}
export function logoutFail(payload = {}, meta) {
  return { type: LOGOUT_FAIL, payload, meta, error: true };
}

export function setCurrentUser(payload = {}) {
  return { type: SET_CURRENT_USER, payload };
}

export function setCurrentWarehouse(payload = {}) {
  return { type: SET_CURRENT_WAREHOUSE, payload };
}

export function changePassword(payload = {}) {
  return { type: CHANGE_PASSWORD, payload, meta: { thunk: true } };
}
export function changePasswordSuccess(payload = {}, meta) {
  return { type: CHANGE_PASSWORD_SUCCESS, payload, meta };
}
export function changePasswordFail(payload = {}, meta) {
  return { type: CHANGE_PASSWORD_FAIL, payload, meta, error: true };
}

export function forgotPassword(payload = {}) {
  return { type: FORGOT_PASSWORD, payload, meta: { thunk: true } };
}
export function forgotPasswordSuccess(payload = {}, meta) {
  return { type: FORGOT_PASSWORD_SUCCESS, payload, meta };
}
export function forgotPasswordFail(payload = {}, meta) {
  return { type: FORGOT_PASSWORD_FAIL, payload, meta, error: true };
}

// UTIL
export function uploadImage(payload = {}) {
  return { type: UPLOAD_IMAGE, payload, meta: { thunk: true } };
}
export function uploadImageSuccess(payload = {}, meta) {
  return { type: UPLOAD_IMAGE_SUCCESS, payload, meta };
}
export function uploadImageFail(payload = {}, meta) {
  return { type: UPLOAD_IMAGE_FAIL, payload, meta, error: true };
}
