import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Modal, InputNumber, message, Input, Divider } from 'antd';
import {formatDateFull } from 'utils/constants';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
import { ProFormSelect, ProFormText} from '@ant-design/pro-form';
import moment from 'moment-timezone';
import { receiptGiftCreate } from '../receiptGift/receiptGift.service';

const PurchaseOrderForm = ({ item, onCallBack, setIsEditModal, isEditModal, apInvoice }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [formParent] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    warehouseName: item[0]?.warehouseName,
    receiptGiftProducts: item?.map(PO => ({
      id: PO?.id,
      bomGiftId: PO?.bomGift?.id,
      name: `${PO?.bomGift?.name} - ${PO?.bomGift?.code}`,
      codeGift: PO?.bomGift?.code,
      quantity: PO?.quantity ? PO?.quantity - PO?.quantityPicked : undefined,
      warehouseId: PO.warehouseId,
      description: PO?.description ? PO?.description : undefined,
      totalProductERP: PO?.quantity,
    })),
  };
  useEffect(() => {
    return () => {
      formParent.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const onCancel = () => {
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title='Tạo phiếu nhập kho quà'
      onCancel={onCancel}
      afterClose={() => {
        formParent.resetFields();
        formParent.setFieldsValue({ receiptGiftProducts: undefined });
      }}
      width={768}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={formParent}
        layout="vertical"
        name="productCategoryEditForm"
        onFinish={(values) => {
          console.log(values)
          setLoading(true);
          const { receiptGiftProducts } = values;
          values.receiptGiftProducts = receiptGiftProducts?.map(product => ({
            purchaseProductId: product.id,
            codeGift: product.codeGift,
            warehouseId: currentWarehouse?.id,
            quantity: product.quantity,
            contract: product.contractNo,
            poReference: product?.poReference,
            description: product?.description,
          }));
          values.inboundkind = 'IMPORT';
          values.receiptDate = moment().format(formatDateFull);
          values.purchaseOrderId = apInvoice.id;
          values.businessPartner = apInvoice.businessPartner;
          values.warehouseId= currentWarehouse?.id;
          receiptGiftCreate(values).then(() => {
            setLoading(false);
            onCallBack();
            message.success(lang.createSuccessfully);
            setIsEditModal(false);
          })
            .catch((err) => message.error(err.message), setLoading(false));
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <ProFormSelect
              name='warehouseName'
              label={lang.warehouse}
              disabled
            />
          </Col>
          <Col span={24}>
            <Form.List name="receiptGiftProducts" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                    <Row gutter={[16, 16]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                      <Col span={24}>
                        <Form.Item
                          name={[name, 'codeGift']}
                          wrapperCol={24}
                          label={lang.defaultCode}
                        >
                          <Input style={{ width: '100%' }} placeholder={lang.defaultCode} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <ProFormText
                          name={[name, 'name']}
                          label={lang.productName}
                          placeholder={lang.productName}
                          disabled
                        />
                      </Col>
                      <Col span={8}>
                          <Form.Item
                          name={[name, 'totalProductERP']}
                          wrapperCol={24}
                          label={'Tổng SL (Từ ERP)'}
                        >
                          <Input style={{ width: '100%' }} defaultValue={0} placeholder={'Tổng SL (Từ ERP)'} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                          {(form) => {
                            // const productPO = form?.getFieldValue(['receiptProducts', name, 'quantity']);
                            // console.log(productPO)
                            const totalQuantity = item[name].quantity - item[name].quantityPicked
                            return <Form.Item
                              name={[name, 'quantity']}
                              wrapperCol={24}
                              label={`${'SL có thể lấy'}(<=${totalQuantity})`}
                              rules={[
                                {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  validator: (_, value) => (value === 0) || value < 0 || value > totalQuantity ? Promise.reject('Số lượng không tương thích, vui lòng kiểm tra lại!') : Promise.resolve(),
                                },
                              ]}
                              initialValue={totalQuantity}
                            >
                              <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} max={item[name].totalProductERP}/>
                            </Form.Item>;
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    {name < item?.length - 1 && <Divider/>}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => formParent.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

PurchaseOrderForm.propTypes = {
  item: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
  apInvoice: PropTypes.object,
};

export default PurchaseOrderForm;
