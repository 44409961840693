import request from 'utils/request';
import environment from 'environment';
export async function saleorderList(payload) {
  return request(environment.api.Orders + 'get-all', payload, 'GET');
}

export async function saleorderDetail(id) {
  return request(environment.api.Orders + `get-one/${id}`, {}, 'GET');
}

export async function saleorderCreate(payload) {
  return request(environment.api.Orders + 'create', payload, 'POST');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function updateStatusCreateDO(payload) {
  return request(environment.api.Orders + 'sale-order-update-status-DO', payload, 'PUT');
}

export async function receiptDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
    resolve('1');
  });
}
export async function getProudctLocator(payload) {
  return request(environment.api.masterProductLocator + '/get-one', payload, 'GET');
}
export async function getAllProudctLocator(payload) {
  return request(environment.api.masterProductLocator + '/get-all', payload, 'GET');
}
export async function deleteProductOrder (id) {
  return request(environment.api.productOrder + `/delete/${id}`, {}, 'DELETE');
}
export async function updateProductOrder (payload) {
  return request(environment.api.productOrder + `/update`, payload, 'PUT');
}
export async function saleorderUpdate(payload) {
  return request(environment.api.Orders + 'update', payload, 'PUT');
}
export async function deleteOrder (id) {
  return request(environment.api.Orders + `delete/${id}`, {}, 'DELETE');
}