import request from 'utils/request';
import environment from 'environments/environment';

export async function userList(payload) {
    return request(environment.api.userList, payload, 'GET');
}

export async function userDetail(payload) {
    return request(environment.api.userDetail + `/${payload}`, {}, 'GET');
}

export async function userCreate(payload) {
    return request(environment.api.userCreate, payload, 'POST');
}

export async function userUpdate(payload) {
    const user = {
        id: payload.id,
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        email: payload?.email,
        realmRoles: payload.realmRoles.map(item => {
            return {
                id: item.id,
                name: item.name,
            };
        }),
        warehouseIds: payload.warehouseIds,
    };
    return request(environment.api.userUpdate, user, 'PUT');
}

export async function userChangePassword(payload) {
    const user = {
        userId: payload.id,
        value: payload.password,
    };
    return request(environment.api.userChangePassword, user, 'POST');
}

export async function userChangePasswordUser(payload) {
    return request(environment.api.userChangePasswordUser, payload, 'POST');
}

export async function userDelete(payload) {
    return request(environment.api.userDelete + `/${payload?.id}`, {}, 'DELETE');
}

export async function roleList(payload) {
    return request(environment.api.roleList, payload, 'GET');
}
export async function unlockUser(payload) {
    return request(environment.api.userUpdate, payload, 'PUT');
}
