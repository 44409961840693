import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';

export async function receiptList(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.receiptList}?${params}`, {}, 'GET');
}

export async function receiptDetail(payload) {
  return request(`${environment.api.receiptDetail}/${payload?.receiptId}`, {}, 'GET');
}

export async function receiptCreate(payload) {
  return request(environment.api.receiptCreate, payload, 'POST');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptConfirm(payload) {
  return request(environment.api.receiptConfirm, payload, 'PUT');
}

export async function receiptConfirmVirtual(payload) {
  return request(environment.api.receiptConfirmVirtual, payload, 'PUT'); 
}

export async function receiptDelete(payload) {
  return request(environment.api.receiptDelete, payload, 'POST');
}

export async function packageList(payload) {
  return request(`${environment.api.packageList}`, {}, 'GET');
}
export async function updateReceiptProduct(payload) {
  return request(`${environment.api.receiptProduct}/update`, payload, 'PUT');
}
export async function deleteProducts(payload) {
  return request(environment.api.products + 'deletes', payload, 'POST');
}
export async function splitReceiptProduct(payload) {
  return request(environment.api.receiptProduct + '/split', payload, 'POST');
}
export async function deleteReceiptProduct(id) {
  return request(environment.api.receiptProduct + `/delete/${id}`, {}, 'DELETE');
}
export async function splitReceipts(payload) {
  return request(environment.api.receiptSplit , payload, 'POST');
}
