import request from 'utils/request';
import environment from 'environment';

export async function purchaseOrderList(payload) {
  return request(`${environment.api.PurchaseOrder}/get-all?`, payload, 'GET');
}

export async function purchaseOrderDetail(id) {
  return request(`${environment.api.PurchaseOrder}/get-one/${id}`, {}, 'GET');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptConfirm(payload) {
  return request(environment.api.receiptConfirm, payload, 'PUT');
}

export async function receiptDelete(id) {
  return request(`${environment.api.apInvoice}/delete/${id}`, {}, 'DELETE');
}

export async function packageList(payload) {
  return request(`${environment.api.packageList}`, {}, 'GET');
}
export async function receiptCreateAPInvoice(payload) {
  return request(environment.api.receiptCreate, payload, 'POST');
}
export async function createAPInvoice(payload) {
  return request(`${environment.api.apInvoice}/create`, payload, 'POST');
}
export async function updateAPInvoice(payload) {
  return request(`${environment.api.apInvoice}/update`, payload, 'PUT');
}
