import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Form, Card, Row, Col, DatePicker, message as Alert, Input } from 'antd';
import { CheckOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { customerReturnCreate } from './receipt.service';
import ReceiptCreateProductEditForm from './customer-return-create-product-edit.form';
import { formatDate, formatDateFull } from 'utils/constants';
import './style.less';
import { productList } from '../../configuration/product/product.service';
import ProTableCusTom from 'components/TableCustom/Protable';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [isEditModal, setIsEditModal] = useState(false);

  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [productMasters, setProductMasters] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'nameEN',
      width: '15%',
    },
    {
      title: 'RMA',
      dataIndex: 'RMA',
      key: 'RMA',
    },
    {
      title: 'lineNo',
      dataIndex: 'lineNo',
      key: 'lineNo',
    },
    {
      title: lang.origDOLine,
      dataIndex: 'origDOLine',
      key: 'origDOLine',
    },
    // {
    //   title: 'charge',
    //   key: 'charge',
    //   dataIndex: 'charge',
    // },
    {
      title: lang.attributeSetInstance,
      key: 'attributeSetInstance',
      dataIndex: 'attributeSetInstance',
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.unitPrice,
      key: 'unitPrice',
      dataIndex: 'unitPrice',
    },
    // {
    //   title: lang.discount,
    //   key: 'discount',
    //   dataIndex: 'discount',
    // },
    // {
    //   title: lang.taxCode,
    //   key: 'tax',
    //   dataIndex: 'tax',
    // },
    // {
    //   title: 'lineAmount',
    //   dataIndex: 'lineAmount',
    //   key: 'lineAmount',
    // },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              // const detail = products.find((item) => item.masterProductId === record.masterProductId);
              setProduct(record);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={products.length === 0}
      onClick={() => {
        form.submit();
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/customer-return',
      breadcrumbName: lang.customerReturn,
    },
    {
      path: '/',
      breadcrumbName: lang.customerReturnCreate,
    },
  ];

  useEffect(() => {
    setLoading(true);
    productList({ warehouseId: currentWarehouse.id })
      .then(result => {
        setProductMasters(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  return (
    <Layout className="receipt-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.customerReturnCreate}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form className="customFormSearch"
            colon={false}
            form={form}
            layout="vertical"
            name="receiptCreateForm"
            onFinish={async (values) => {
              try {
                if (values?.rmaDate) {
                  values.rmaDate = values.rmaDate.format(formatDateFull);
                }
                if (values?.DODate) {
                  values.DODate = values.DODate.format(formatDateFull);
                }
                values.warehouseId = currentWarehouse?.id;
                values.customerRMAProduct = products?.map(product => {
                  const { RMA, lineNo, origDOLine, charge, attributeSetInstance, description, quantity, unitPrice, discount, tax, lineAmount, masterProduct } = product;
                  return {
                    RMA,
                    lineNo,
                    origDOLine,
                    charge,
                    attributeSetInstance,
                    description,
                    warehouseId: masterProduct.warehouseId,
                    code3A: masterProduct?.code3A,
                    quantity,
                    unitPrice,
                    discount,
                    tax,
                    lineAmount,
                  };
                });
                setLoading(true);
                customerReturnCreate(values)
                  .then(result => {
                    setLoading(false);
                    Modal.success({
                      title: lang.createReceiptSuccessfully,
                      content: `${lang.receiptCode}: ${result?.code}`,
                      icon: <CheckOutlined />,
                      onOk() {
                        navigate('/dashboard/inbound/customer-return');
                      },
                    });
                  })
                  .catch((error) => {
                    Alert.error(error?.message);
                    setLoading(false);
                  });
                return true;
              } catch (error) {
                Alert.error(error?.message);
                setLoading(false);
              }
            }}
            initialValues={{}}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="company"
                  label={lang.company}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.messageReceiveDate,
                //   },
                // ]}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.company} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="branch"
                  label={lang.branch}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.branch} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="documentNo"
                  label={lang.documentNo}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.documentNo} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="orderReference"
                  label={lang.orderReference}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.orderReference} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="description"
                  label={lang.description}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.description} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="targetDocType"
                  label={lang.targetDocType}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.targetDocType} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="rmaCategory"
                  label={lang.rmaCategory}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.rmaCategory} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="origSalesOrder"
                  label={lang.origSalesOrder}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.origSalesOrder} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="origDO"
                  label={lang.origDO}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.origDO} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="rmaDate"
                  label={lang.returnDate}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="DODate"
                  label={lang.DODate}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="businessPartner"
                  label={lang.businessPartner}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.businessPartner} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="billTo"
                  label={lang.billTo}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.billTo} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="partnerLocation"
                  label={lang.partnerLocation}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.partnerLocation} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="billToLocation"
                  label={lang.billToLocation}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.billToLocation} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="repressentative"
                  label={lang.repressentative}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.repressentative} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="businessLine"
                  label={lang.businessLine}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.businessLine} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="warehouse"
                  label={lang.Area}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.Area} />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="priceList"
                  label={'priceList'}
                >
                  <Input style={{ width: '100%' }} placeholder={'priceList'} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="returnPolicy"
                  label={'returnPolicy'}
                >
                  <Input style={{ width: '100%' }} placeholder={'returnPolicy'} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="currency"
                  label={'currency'}
                >
                  <Input style={{ width: '100%' }} placeholder={'currency'} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="subTotal"
                  label={'subTotal'}
                >
                  <Input style={{ width: '100%' }} placeholder={'subTotal'} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="grandTotal"
                  label={'grandTotal'}
                >
                  <Input style={{ width: '100%' }} placeholder={'grandTotal'} />
                </Form.Item>
              </Col> */}
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="campaign"
                  label={lang.campain}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.campain} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="department"
                  label={lang.department}
                >
                  <Input style={{ width: '100%' }} placeholder={lang.department} />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                <Form.Item
                  name="expenseType"
                  label={'expenseType'}
                >
                  <Input style={{ width: '100%' }} placeholder={'expenseType'} />
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </Card>
        <ProTableCusTom
          title={() => <Row>
            <Col sm={12} xs={10}><h3>{lang.productAdded}</h3></Col>
            <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setProduct();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        setProducts(products.filter(item => !selectedRowKeys.includes(item.key)));
                        setSelectedRowKeys([]);
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="key"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={products}
          scroll={{ x: 2600 }}
          search={false}
          tableAlertRender={false}
          options={{
            search: false,
            reload: false,
            density: false,
          }}
        // pagination={false}
        />
      </Layout.Content>
      <ReceiptCreateProductEditForm
        item={product}
        products={productMasters}
        productsSelected={products}
        warehouseId={currentWarehouse?.id}
        onCallback={(values) => {
          const productsfind = products.find(item => item?.key === values?.key);
          if (productsfind) {
            Object.assign(productsfind, values);
            setProducts([...products]);
          } else {
            setProducts([...products, { ...values, key: products.length }]);
          }
          // const productUpdate = products?.find(product => product.key === values[0].key);
          // if (productUpdate) {
          //   productUpdate.expectedQuantity = values[0].expectedQuantity;
          //   productUpdate.supplier = values[0].supplier;
          //   productUpdate.supplierId = values[0].supplierId;
          //   setProducts([...products]);
          //   setIsEditModal(false);
          //   return true;
          // }
          setIsEditModal(false);
        }}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
      />
    </Layout>
  );
};

export default ReceiptCreate;
