import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';

const ReceiptGiftProductDetail = ({ item, onCancel, onCallback, items }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        setLoading(true);
        await onCallback({
          ...values,
          id: item.id,
        });
        setLoading(false);
      }}
      initialValues={{
        quantityReceived: item?.quantityReceived,
        quantity: item?.quantity ? item.quantity : 0,
        quantitied: item?.quantity ? item.quantity - item?.quantityReceived : 0,
        // balletNumber: items?.productGift ? items?.productGift.filter(pro => pro?.status === 'DISABLE').length : undefined
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.defaultCode}>
            <Input disabled value={item?.bomGift?.code}/>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.productName}
          >
            <Input disabled value={item?.bomGift?.name}/>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantity"
            label={lang.quantity}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantityReceived"
            label={'Số lượng đã chia'}
          >
            <Input style={{ width: '100%' }} disabled/>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantitied"
            label={'Số lượng còn lại'}
            disabled
          >
            <Input style={{ width: '100%' }} disabled/>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="balletNumber"
            label={lang.totalPallet}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số lượng!',
              },
              {
                // eslint-disable-next-line prefer-promise-reject-errors
                validator: (_, value) => value <= 0 ? Promise.reject('Vui lòng nhập số lượng chính xác') : Promise.resolve(),
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptGiftProductDetail.propTypes = {
  item: PropTypes.object,
  items: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
};

export default ReceiptGiftProductDetail;
