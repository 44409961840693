import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag, message, Select, Tabs } from 'antd';
import { SearchOutlined, InboxOutlined } from '@ant-design/icons';
import { productList } from '../product.service';
import ProductReportForm from 'components/ProductReportForm/product-report.form';
import './style.less';
import { ProFormSelect } from '@ant-design/pro-form';

import moment from 'moment';
// import jsPdf from 'jspdf';
import { defaultPage, formatDate, formatDateFull, formatNo } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { receiptList } from '../receipt/receipt.service';
import Dragger from 'antd/lib/upload/Dragger';
import Helper from 'utils/helpers';
import { importExel } from './inbound-control.service';
import { blockList } from 'pages/dashboard/configuration/location/location.service';
import { Option } from 'antd/lib/mentions';
// import html2canvas from 'html2canvas';
// import logog3A from 'assets/logoAKAWMS/logo-3A.png';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { productGiffGetAll } from '../Scan/scan.service';

// const tableScrollHeightArea = window.innerHeight - 360; // px

// const defaultSort = {
//   order: 'descend',
//   columnKey: 'createdAt',
// };

// const VIEW_MODE = {
//   CARD_VIEW: 0,
//   LIST_VIEW: 1,
// };

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};
const defaultParams = {
  ...defaultPage,
  status: 'STORED',
};

const InboundControlList = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { currentWarehouse } = useSelector((state) => state.auth);
  // const [mode, setMode] = useState(VIEW_MODE.CARD_VIEW);
  const [isReportModal, setIsReportModal] = useState(false);
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [loadingBtn, setLoadingBtn] = useState(false);
  // const [loadingExport, setLoadingExport] = useState(false);
  //Pagination
  const [params, setParams] = useState({ ...defaultParams });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');
  const [file, setFile] = useState();
  const [uploadModal, setUploadModal] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState();
  const [selectedReceipt, setSelectedReceipt] = useState();
  const [key, setKey] = useState(1);
  const getProducts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    productList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  const getProductsGift = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    productGiffGetAll({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (key == 1) {
      getProducts(params, sortBy, sortDirection, currentWarehouse);
    } else {
      getProductsGift(params, sortBy, sortDirection, currentWarehouse);
    }
  }, [params, sortBy, sortDirection, currentWarehouse, key]);

  useEffect(() => {
    blockList({ warehouseId: currentWarehouse?.id }).then(values => {
      if (values?.data) {
        setBlocks(values.data);
      }
    });
  }, [currentWarehouse]);

  const columnsGift = [
    {
      title: lang.giftCode,
      key: 'giftCode',
      fixed: 'left',
      width: '5%',
      render: (record) => record?.bomGift?.code,
    },
    {
      title: 'QR code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.giftName,
      key: 'giftName',
      render: (record) => record?.bomGift?.name,
    },
    {
      title: lang.shipment,
      key: 'shipment',
      dataIndex: 'shipment',
    },
    {
      title: 'LineNo',
      key: 'lineNo',
      dataIndex: 'lineNo',
    },
    {
      title: lang.orderReference,
      key: 'orderReference',
      dataIndex: 'orderReference',
    },
    {
      title: 'ContractRefNo',
      key: 'contractRefNo',
      dataIndex: 'contractRefNo',
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: ['rack', 'shelf', 'block', 'name'],
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: ['rack', 'shelf', 'name'],
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      render: value => value?.code,
    },
  ];

  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container No',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.UOM,
      key: 'UOM',
      dataIndex: 'masterProduct',
      // width: '5%',
      render: value => value?.UOM_VN,
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
      // width: '5%',
      // render: value => value?.shelf.name,
    },
    {
      title: lang.documentNo,
      key: 'documentNo',
      dataIndex: 'documentNo',
      // width: '5%',
    },
    {
      title: lang.documentType,
      key: 'documentType',
      dataIndex: 'documentType',
      // width: '5%',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            size="small"
            type="link"
            onClick={() => {
              //report
              const detail = {
                id: record?.id,
                productCode: record?.code,
                productName: record?.masterProduct?.nameEN,
                quantityCartton: record?.quantityCartton,
                quantityTin: record?.quantityTin,
              };

              setItem(detail);
              setIsReportModal(true);
            }}
          >{lang.report}</Button>
        </div>
      ),
    },
  ];

  const extraButton = [
    // <Radio.Group
    //   key="segment-control-view-mode"
    //   buttonStyle="solid"
    //   value={mode}
    //   onChange={(val) => setMode(val?.target?.value)}
    // >
    //   <Radio.Button value={VIEW_MODE.CARD_VIEW}>
    //     <AppstoreOutlined />
    //   </Radio.Button>
    //   <Radio.Button value={VIEW_MODE.LIST_VIEW}>
    //     <BarsOutlined />
    //   </Radio.Button>
    // </Radio.Group>,
    // <Button
    //   key="btn-export"
    //   type="primary"
    //   ghost
    //   disabled={items.length === 0} //|| !params?.receiptId
    //   loading={loadingExport}
    //   onClick={async () => {
    //     setLoadingExport(true);
    //     const paramsUnLimit = { ...params };
    //     delete paramsUnLimit.page;
    //     delete paramsUnLimit.limit;
    //     const listItems = await productList({ ...paramsUnLimit, sortBy, sortDirection, warehouseId: currentWarehouse.id });
    //     const rempData = listItems?.data?.reduce((prev, curr) => {
    //       if(curr?.locator?.locatorName?.indexOf('Quarantine') > -1){
    //         curr.sampleProduct = curr.quantityCartton * curr.masterProduct.tinPerCarton + curr.quantityTin;
    //       } else curr.sampleProduct = 0;
    //       const finded = prev?.find(product => product?.masterProduct?.id === curr?.masterProduct?.id);
    //       if (finded) {
    //         finded.sampleProduct += curr.sampleProduct;
    //         finded.pallet = [...finded.pallet, curr.quantityCartton, curr.quantityTin];
    //         return prev;
    //       }
    //       return [...prev, { ...curr, pallet: [curr.quantityCartton, curr.quantityTin] }];
    //     }, [])?.map(product => {
    //       const temp = product?.pallet?.reduce((prev, curr, indx) => {
    //         if (indx % 2 === 0)
    //           prev.arrayCarton = [...prev.arrayCarton, curr];
    //         else prev.tins += curr;
    //         return prev;
    //       }, { arrayCarton: [], tins: 0 });
    //       product.pallet = [...temp.arrayCarton, temp.tins];
    //       return product;
    //     });
    //     const dataTime = new Date();
    //     const date = dataTime.getDate();
    //     const month = dataTime.getMonth() + 1;
    //     const year = dataTime.getFullYear();
    //     let content = `
    //     <div style="display: grid ;line-height: 1;width: 960px; font-size: 16px; font-family: Times New Roman, Times, serif; margin-right:50px">
    //       <div style="display:flex; margin-bottom: 20px;">
    //         <div style=" width: 20%; border-image: initial;">
    //             <img src="${logog3A}" style=" height: 80%; width: 80%" />
    //         </div>
    //         <div style=" margin-top: 40px; padding: 10px 0; line-height: 1.25; width: 30%; font-size: 13px; margin-left: -20px">
    //             <div>
    //                 Công Ty TNHH Dinh Dưỡng 3A(Việt Nam) <br> 3A Nutrition(Việt Nam)Co, Ltd
    //             </div>    
    //             <div>
    //                 ${rempData[0].warehouse.address}
    //             </div>
    //             <div>
    //                 Tel: (84.8) 3823 8222
    //             </div>
    //             <div>
    //                 Fax: (84.8) 3823 8777
    //             </div>
    //         </div>
    //         <div style=" margin-top: 40px; margin-left: 30px; padding: 10px 0px 10px 10px; line-height: 1.25; width: 10%; font-size: 14px;"></div>
    //         <div style=" margin-top: 40px; padding: 10px 0px 10px 10px; line-height: 1.25; width: 40%; font-size: 14px;">
    //             <div style="border: 2px solid #333; width: 40%; padding: 10px">
    //                 Code: P-85-02-F1 <br>
    //                 Issua <br>
    //                 date: 02/04/2016
    //             </div>    
    //         </div>
        
    //       </div>
    //       <div style="display: flex; min-height: 88px; clear: both;">
    //         <div style="width: 100%;  line-height: 1.2; margin-top: -10px">
    //             <div style="font-size: 26px; font-weight:bold; text-align: center;">PHIẾU KIỂM HÀNG/TALLY SHEET</div>
            
    //             <div style="display: flex; ">
    //                 <div style="width: 30.33%;"></div>
    //                 <div style="width: 30.33%; text-align: right; margin-top:20px">
    //                     <div style="font-weight:bold;">Ticket No: ${rempData?.[0]?.receipt?.code} </div>
    //                 </div>
    //                 <div style="width: 30.33%; text-align: right; margin-top:20px">
    //                     <div style="font-weight:bold; ">Ngày: ${date}/${month}/${year}</div>
    //                 </div>
    //             </div>
    //         </div>
    //       </div>

    //       <div style="margin-bottom: 20px">
    //         <table style="border: 1px solid #333; width: 100%; border-collapse: collapse;">
    //             <tbody >
    //                 <tr>
    //                     <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Container/Truck No (Số Cont/ Số xe): ${rempData[0].container}</td>
    //                     <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Day (Ngày): ${date}/${month}/${year}</td>
    //                 </tr>
    //                 <tr>
    //                     <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Seal No (Số Seal):</td>
    //                     <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Receiving time (Giờ nhận):</td>
    //                 </tr>
    //                 <tr>
    //                     <td style="padding: 5px 0px 5px 9px;">Custome clearance No (Tờ khai Hải quan số):</td>
    //                     <td style="padding: 5px 0px 5px 9px;">Finished time (Giờ hoàn thành):</td>
    //                 </tr>
    //             </tbody>
      
    //         </table>
    //       </div>

    //       <table style="border: 2px solid #333; width: 100%; border-collapse: collapse; font-size: 11px">
    //         <thead>
    //             <tr style="text-align:center; font-weight: bold;">
    //                 <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">NO STT</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">PRODUCT NAME <br>Tên sản phẩm</td>
    //                 <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">LOT NO<br>(Số lô)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">MFG.DATE <br>(Ngày sản xuất)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">EXP.DATE <br>(Hạn dùng)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" colspan="2">SAMPLE<br>(Kiểm mẫu)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" colspan="9">QTY RECEIVED<br>(Số lượng thực nhận)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" colspan="2">QUANTITY<br>(Số lượng)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">Remark<br>(Ghi chú)</th>
    //             </tr>
    //             <tr>
    //                 <th style=" border: 1px solid #333; padding: 5px;">Quantity<br>(Số lượng)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;">Accept<br>(Chấp nhận)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;" colspan="7">Số lượng thùng/pallet</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;">Total/Tổng số lượng<br>(Carton/thùng)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;">Total/Tổng số lượng<br>(Tin/lon)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;">Denlted/hàng móp<br>(Tin/lon)</th>
    //                 <th style=" border: 1px solid #333; padding: 5px;">Damaged/hàng hỏng<br>(Tin/lon)</th>
    //             </tr>
    //         </thead>
    //         <tbody>`;
    //     let totalQuantityTin = 0;
    //     let totalQuantityCartton = 0;
    //     let totalCarton = 0;
    //     let totalTinOdd = 0;
    //     let totalQuantityTins = 0;
    //     let totalTinsOdd = 0;
    //     let totalSamp = 0;
    //     rempData.forEach((product, index) => {
    //       const pallet = product?.pallet
    //       totalQuantityCartton = product?.pallet?.reduce((prev, curr, ind, arr) => ind === arr.length -1 ? prev : curr + prev, 0);

    //       totalQuantityTin = product?.pallet?.at(-1);
    //       totalQuantityCartton += Math.floor(totalQuantityTin / product?.masterProduct?.tinPerCarton);
    //       totalCarton += totalQuantityCartton;

    //       totalTinOdd += totalQuantityTin % product?.masterProduct?.tinPerCarton;
    //       totalTinsOdd += totalTinOdd;
    //       totalQuantityTins += (product?.masterProduct?.tinPerCarton * totalQuantityCartton) + totalTinOdd;
    //       totalSamp += product.sampleProduct;
    //       [...Array(parseInt(Math.ceil(pallet.length / 7))).keys()].forEach((value, indexPallet) => {
    //         content += `
    //                 <tr>
    //                   <td style=" width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? 1 + index : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? product.masterProduct.code3A : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? product?.lot : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product?.mfgDate ? moment(product.mfgDate, formatDateFull).format(formatDate) : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product?.expireDate ? moment(product.expireDate, formatDateFull).format(formatDate) : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${product.sampleProduct}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[0 + indexPallet * 7] ? pallet[0 + indexPallet * 7] : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[1 + indexPallet * 7] ? pallet[1 + indexPallet * 7] : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[2 + indexPallet * 7] ? pallet[2 + indexPallet * 7] : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[3 + indexPallet * 7] ? pallet[3 + indexPallet * 7] : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[4 + indexPallet * 7] ? pallet[4 + indexPallet * 7] : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[5 + indexPallet * 7] ? pallet[5 + indexPallet * 7] : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[6 + indexPallet * 7] ? pallet[6 + indexPallet * 7] : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === parseInt(Math.ceil(pallet.length / 7)) - 1 ? `${totalQuantityCartton}T,${totalTinOdd}L` : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === parseInt(Math.ceil(pallet.length / 7)) - 1 ? (totalQuantityCartton * product?.masterProduct?.tinPerCarton) + totalTinOdd : ''}</td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                   <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 </tr>`;
    //       });
    //       totalTinOdd = 0;
    //     });
    //     content += `
    //           <tr>
    //             <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;" colspan="2">TOTAL</td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalSamp}</td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalCarton}T, ${totalTinsOdd}L</td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantityTins}</td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //             <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //           </tr>
    //         </tbody>
    //       </table>

    //       <div style="display: flex">
    //         <div style="width: 33.33%">
    //             <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Manager(Quản lý kho hàng)</p>
    //             <div>
    //                 <div>(Họ & tên):</div> <br>
    //                 <span>Date(Ngày):</span>
    //             </div>
    //         </div>
    //         <div style="width: 33.33%;">
    //             <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Keeper(Thủ kho)</p>
    //             <div>
    //                 <div>(Họ & tên):</div> <br>
    //                 <span>Date(Ngày):</span>
    //             </div>
    //         </div>
    //         <div style="width: 33.33%;">
    //             <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Staff(Nhân viên nhập hàng)</p>
    //             <div>
    //                 <div>(Họ & tên):</div> <br>
    //                 <span>Date(Ngày):</span>
    //             </div>
    //         </div>
    //       </div>

    //       <div style="margin-top: 30px; font-weight: bold; font-size: 14px; width: 100%">
    //           Lưu ý: <i>Ghi vào cột ghi chú nếu Container/xe hàng có các tình trạng sau: màng co mỏng và không phủ lớp trên cùng; Nhiều số lô, hạn dùng trên pallet; Hàng có dấu hiệu bung, hỏng; Pallet hàng xô lệch;
    //             Thùng hàng móp; Thùng hàng bẩn; Số lô trên thùng không in hoặc không rõ ràng; Sàn xe thủng, rò rỉ hoặc ẩm ướt; Xe vận chuyển có mùi hôi của hải sản, gia cầm, thuốc sâu, côn trùng, ...; Pallet cũ, ẩm mốc, mối mọt, 
    //             gãy, nứt,...</i>  
    //       </div>
    //     </div>`;

    //     const template = document.createElement('div');
    //     template.innerHTML = content;
    //     document.body.appendChild(template);
    //     await html2canvas(template, { width: 1254, scale: 2 }).then((canvas) => {
    //       const imgWidth = 210;
    //       const imgHeight = canvas.height * imgWidth / canvas.width;
    //       const imgData = canvas.toDataURL('image/png');
    //       // eslint-disable-next-line new-cap
    //       const pdf = new jsPdf('p', 'mm', 'a4');
    //       pdf.addImage(imgData, 'PNG', 25, 10, imgWidth, imgHeight);
    //       pdf.save(`TallySheet${moment().format('DDMMYYYY')}.pdf`);
    //     });
    //     document.body.removeChild(template);
    //     setLoadingExport(false);
    //   }}
    // >
    //   {lang.exportPDF}
    // </Button>,
    <Button
      key="btn-excel"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          const response = await productList({ warehouseId: currentWarehouse.id, status: 'STORED' });
          const dataRemp = response?.data?.map(product => {
            return {
              'Mã lô': product.code,
              Barcode: product?.masterProduct?.barCode,
              Tên: product?.masterProduct.name,
              'Ngày nhận': product?.receipt?.receiptDate && moment(product?.receipt?.receiptDate, formatDateFull).format(formatDate),
              'Mã PNK': product?.receipt?.code,
              'Số lượng tồn': product.totalQuantity,
              Block: product?.block?.name,
              Shelf: product?.rack?.shelf?.name,
              Rack: product?.rack?.code,
              'Ngày hết hạn': product?.expireDate && moment(product?.expireDate, formatDateFull).format(formatDate),
            };
          });
          Helper.dowloadXLSX(dataRemp, 'Product');
          setLoadingBtn(false);
        } catch (error) {
          setLoadingBtn(false);
          return false;
        }
      }}
    >
      {lang.exportCSV}
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/inbound-control',
      breadcrumbName: lang.inboundControl,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.inboundControl}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
        subTitle={<span className={'spanCustomHead'}>{`*${lang.subTitleInboundControl}`}</span>}
      />
      <Layout.Content>
        <Tabs defaultActiveKey={key} type="card" tabBarStyle={{ marginBottom: 0 }} onChange={activeKey => {
          setKey(activeKey);
          setParams({ ...defaultParams });
        }}>
          <TabPane tab='Sản phẩm lưu trữ' key={1}>
            <Card size="small" className="cardSearch">
              <Row gutter={[16, 16]}>
                <Col style={{ marginTop: 4 }}>
                  <label>{lang.inboundControlDate}</label>
                </Col>
                <Col xxl={{ span: 4 }} sm={6} xs={16}>
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    onChange={(dates) => {
                      if (dates) {
                        setStartDate(dates[0]);
                        setEndDate(dates[1]);
                      } else {
                        setStartDate();
                        setEndDate();
                      }
                    }}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Col>
                <Col style={{ marginTop: 4 }}>
                  <label>{lang.block}</label>
                </Col>
                <Col xxl={{ span: 4 }} sm={6}>
                  <Select placeholder={lang.block} style={{ width: '100%' }} allowClear onChange={(value) => setSelectedBlock(value)}>
                    {blocks?.map(block => <Option key={block.id} value={block.id}>{block.name}</Option>)}
                  </Select>
                </Col>
                <Col xxl={{ span: 5 }} sm={6} xs={16}>
                  <ProFormSelect label={lang.receipt} onChange={(value) => setSelectedReceipt(value)} placeholder={lang.receipt} request={async () => {
                    try {
                      const receipts = await receiptList({ warehouseId: currentWarehouse.id });
                      const dataTime = moment(receipts?.data[0].receiptDate, formatDateFull).format(formatNo);
                      // var code = '';
                      return receipts.data?.map(receipt => {
                        return {
                          label: dataTime + '_' + receipt.id,
                          value: receipt.id,
                          key: receipt.id,
                        };
                      });
                    } catch (error) {
                      return [];
                    }
                  }}
                    fieldProps={{
                      showSearch: true,
                    }}
                  />
                </Col>
                <Col xxl={{ span: 4 }} sm={6}>
                  <Input
                    placeholder={lang.keyword}
                    onChange={(value) => setKeyword(value.target.value)}
                  />
                </Col>
                <Col sm={3}>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    onClick={() => {
                      if (startDate) {
                        params.startDate = startDate.startOf('date').format(formatDateFull);
                      } else {
                        delete params.startDate;
                      }
                      if (endDate) {
                        params.endDate = endDate.endOf('date').format(formatDateFull);
                      } else {
                        delete params.endDate;
                      }
                      if (selectedBlock) {
                        params.blockId = selectedBlock;
                      } else {
                        delete params.blockId;
                      }
                      if (selectedReceipt) {
                        params.receiptId = selectedReceipt;
                      } else {
                        delete params.receiptId;
                      }
                      params.page = 1;
                      setParams({ ...params, keyword });
                    }}
                  >{lang.search}</Button>
                </Col>
              </Row>
            </Card>
            <TableCustom
              title={() => <Row>
                <Col span={12}><h3>{lang.productList}</h3></Col>
              </Row>}
              rowKey="id"
              //rowSelection={rowSelection}
              loading={loading}
              bordered
              columns={columns}
              dataSource={items}
              onChange={(pagination, filters, sorter) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                // if (sorter) {
                //   setSortedInfo({
                //     columnKey: sorter.columnKey,
                //     order: sorter.order,
                //   });
                // }
                if (pagination.pageSize !== params.limit) {
                  params.page = 1;
                } else {
                  params.page = pagination.current;
                }
                params.limit = pagination.pageSize;
                setParams({ ...params });
              }}
              pagination={{
                total: totalItem,
                showSizeChanger: true,
                pageSize: params.limit,
                current: params.page,
              }}
              scroll={{ x: 2600 }}
            />
          </TabPane>
          <TabPane tab='Quà lưu trữ' key={2}>
            <Card size="small" className="cardSearch">
              <Row gutter={[16, 16]}>
                <Col xxl={{ span: 4 }} sm={6}>
                  <Input
                    placeholder={lang.keyword}
                    onChange={(value) => setKeyword(value.target.value)}
                  />
                </Col>
                <Col sm={3}>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    onClick={() => {
                      params.page = 1;
                      setParams({ ...params, keyword });
                    }}
                  >{lang.search}</Button>
                </Col>
              </Row>
            </Card>
            <TableCustom
              title={() => <Row>
                <Col span={12}><h3>{'Danh sách quà tạm nhập'}</h3></Col>
              </Row>}
              rowKey="id"
              loading={loading}
              bordered
              columns={columnsGift}
              dataSource={items}
              onChange={(pagination, filters, sorter) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                // if (sorter) {
                //   setSortedInfo({
                //     columnKey: sorter.columnKey,
                //     order: sorter.order,
                //   });
                // }
                if (pagination.pageSize !== params.limit) {
                  params.page = 1;
                } else {
                  params.page = pagination.current;
                }
                params.limit = pagination.pageSize;
                setParams({ ...params });
              }}
              pagination={{
                total: totalItem,
                showSizeChanger: true,
                pageSize: params.limit,
                current: params.page,
              }}
              scroll={{ x: 2600 }}
            />
          </TabPane>
        </Tabs>
      </Layout.Content>
      <Modal
        visible={isReportModal}
        title={lang.reportErrorProduct}
        onCancel={() => setIsReportModal(false)}
        destroyOnClose={true}
        width={570}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <ProductReportForm
          type={'inbound-control'}
          item={item}
          onCallback={(value) => {
            setParams({ ...defaultParams });
            setIsReportModal(false);
          }}
          onCancel={() => setIsReportModal(false)}
        />
      </Modal>
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(currentWarehouse.id, formData);
              setFile();
            }
            setParams({ ...defaultPage });
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{}], 'TemplateProduct')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default InboundControlList;
