import React, { useRef } from 'react';
import { Col, Row, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { zoneCreate, zoneUpdate } from './location.service';
import { ModalForm, ProFormDigit, ProFormText, ProFormSelect } from '@ant-design/pro-form';
import { CheckCircleOutlined } from '@ant-design/icons';
import './location-style.less';
import { useSelector } from 'react-redux';
import Helper from 'utils/helpers';
import PropTypes from 'prop-types';

const ZoneModalForm = ({ item, setVisible, visible, setVisiblePrint, fetchZones, setZoneSelected }) => {
  // const [form] = Form.useForm();
  const formRef = useRef();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   if(formRef?.current){
  //     return () => {
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       formRef.current.resetFields();
  //     };
  //   }
  // }, [visible]);
  const handleAddZone = async (value) => {
    if (item) {
      await zoneUpdate(value, item);
      message.success(lang.updateSuccessfully);
    } else {
      const result = await zoneCreate(value);
      message.success(lang.createSuccessfully);
      setZoneSelected(result);
    }
  };
  const initValues = {
    name: item ? item?.name : '',
    capcity: item ? item?.capcity : null,
    type: item ? item?.type : undefined,
  };
  const onCancel = () => {
    const valueForm = formRef?.current?.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setVisible(false);
      return true;
    }
    Helper.conFirm(setVisible);
  };
  return (
    <ModalForm
      title={item ? lang.zoneDetail : lang.zoneCreate}
      formRef={formRef}
      width="716px"
      visible={visible}
      requiredMark={false}
      onFinish={async (value) => {
        try {
          await handleAddZone({ ...value, warehouseId: currentWarehouse.id });
          setVisiblePrint(true);
          fetchZones(currentWarehouse.id);
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      initialValues={initValues}
      submitter={{
        render: ({ form }) => {
          return [
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              key="extra-reset"
              onClick={onCancel}
            >
              {lang.btnCancel}
            </Button>,
            item && <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              type="primary"
              key="extra-submit"
              onClick={() => setVisiblePrint(true)}
            >
              {lang.print} <CheckCircleOutlined />
            </Button>,
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              type="primary"
              key="extra-submit"
              onClick={() => form?.submit?.()}
            >
              {lang.btnSave} <CheckCircleOutlined />
            </Button>,
          ];
        },
      }}
      onVisibleChange={setVisible}
      modalProps={{
        closable: false,
        centered: true,
        destroyOnClose: true,
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <ProFormText name="name" label={lang.zoneName} placeholder={lang.placeholderName}
            rules={[
              {
                required: true,
                message: lang.requiredZoneName,
              },
            ]} />
        </Col>
        <Col span={8}>
          <ProFormDigit name="capcity" label={'Số lượng pallet tối đa'} placeholder={lang.placeholderCapacity}
            rules={[
              {
                required: true,
                message: lang.requiredCapacity,
              },
            ]} />
        </Col>
        <Col span={8}>
          <ProFormSelect name="type" label={lang.type} placeholder={lang.type}
            rules={[
              {
                required: true,
                message: lang.type,
              },
            ]}
            options={[
              {
                value: 'IN',
                label: 'TẠM NHẬP',
              },
              {
                value: 'OUT',
                label: 'TẠM XUẤT',
              },
            ]}
            />
        </Col>
      </Row>
    </ModalForm>
  );
};
ZoneModalForm.propTypes = {
  item: PropTypes.object,
  setVisible: PropTypes.func,
  fetchZones: PropTypes.func,
  visible: PropTypes.bool,
  setZoneSelected: PropTypes.func,
  setVisiblePrint: PropTypes.func,
};
export default ZoneModalForm;
