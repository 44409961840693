import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, message, Row, Col, Modal, Tag } from 'antd';

import { ScanOutlined, DeleteOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import PackagingDetailForm from './packaging-detail.form';
import { useDispatch, useSelector } from 'react-redux';
import { getPackageStatus, updateMultiplePackage } from './shipping.service';
import './style.less';
import { productScan, productUpdateStatus } from '../picking/picking.service';
import { scanShippingPackageCodeAdd } from 'providers/OutboundProvider/actions';
import TableCustom from 'components/TableCustom/table';
import { orderConfirm } from '../packaging/packaging.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ShippingScanComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanShippingForPackageCodes } = useSelector((state) => state.outbound);
  const [idsProduct, setIdsProduct] = useState();
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [isPackagingDetailModal, setIsPackagingDetailModal] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.packageCode,
      key: 'packageCode',
      render: (record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setItem({ packageCode: record?.code, orderCode: record?.order.code });
              setIsPackagingDetailModal(true);
            }}
          >{record?.code}</Button>
        );
      },
    },
    {
      title: lang.orderCode,
      key: 'name',
      render: record => record?.order?.code,
    },
    {
      title: lang.orderStatus,
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        return (
          <Tag>{record}</Tag>
        );
      },
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/shipping/scan');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        try {
          const idsPackage = items?.map(item => item.id);
          const conFirm = new Set();
          items.forEach(item => {
            conFirm.add(item.order.id);
          });
          await Promise.all([
            productUpdateStatus({ ids: idsProduct, status: 'TEMPORARY_OUT' }),
            updateMultiplePackage({ id: idsPackage, status: 'WAITING' }),
          ]);
          await Promise.all([...Array.from(conFirm)?.map(conf => orderConfirm({ id: conf }))]);
          dispatch(scanShippingPackageCodeAdd([]));
          navigate('/dashboard/outbound/shipping');
        } catch (error) {
          message.error(error.message);
        }
      }
      }
      disabled={!(items.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];
  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/shipping',
      breadcrumbName: lang.shippingOutboundControl,
    },
    {
      path: '/scan',
      breadcrumbName: lang.scanCode,
    },
    {
      path: '/',
      breadcrumbName: lang.confirmPackageList,
    },
  ];

  const fetchdata = async (scanShippingForPackageCodes, currentWarehouse) => {
    const packages = await productScan({ packageCodes: scanShippingForPackageCodes, type: 'PACKAGE', warehouseId: currentWarehouse.id });
    const { successList, errList } = packages;
    const packageFill = [];
    if (successList.length > 0) {
      setIdsProduct(successList?.map(paka => paka.id));
      const packageCodes = successList?.reduce((prev, current) => {
        if (prev.indexOf(current.packageCode) === -1) {
          prev.push(current.packageCode);
        }
        return prev;
      }, []);
      await Promise.all(packageCodes?.map(async packag => {
        const pkgrs = await getPackageStatus({ packadeCode: packag });
        packageFill.push(...pkgrs.data);
      }));
    }
    if (errList?.length > 0) {
      message.warning(`${lang.invalidCode}: ${errList?.toString()}. ${lang.tryAgain}`);
    }
    setItems(packageFill);
  };
  useEffect(() => {
    fetchdata(scanShippingForPackageCodes, currentWarehouse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWarehouse]);

  return (
    <Layout className="receipt-detail">
      <PageHeader
        ghost={false}
        title={lang.confirmPackageList}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>Package list</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  navigate('/dashboard/outbound/shipping/scan');
                }}
              >
                {lang.scanMore}
                <ScanOutlined />
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk() {
                        const filterItem = items?.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                        setItems(filterItem);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            total: items?.length,
            showSizeChanger: true,
            pageSize: pageSize,
            current: currentPage,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
          }}
        />
      </Layout.Content>
      <Modal
        visible={isPackagingDetailModal}
        title={lang.packagingDetail}
        onCancel={() => setIsPackagingDetailModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <PackagingDetailForm
          item={item}
          onCallback={(value) => {
            setIsPackagingDetailModal(false);
          }}
          onCancel={() => setIsPackagingDetailModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default ShippingScanComplete;
