import environment from 'environments/environment';
import request from 'utils/request';

export async function shelfDetail(id) {
    return request(environment.api.shelves + `get-one/${id}`, {}, 'GET');
}

export async function productOfRack(payload) {
  return request(environment.api.products + 'get-all', payload, 'GET');
}

export async function productGiftOfRack(payload) {
  return request(environment.api.productGifts + '/get-all', payload, 'GET');
}

export async function productMaterialOfRack(payload) {
  return request(environment.api.bomProduction + '/get-all', payload, 'GET');
}

export async function zoneDetail(id) {
  return request(environment.api.zones + `get-one/${id}`, {}, 'GET');
}
