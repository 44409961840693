import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Select, InputNumber, message, Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { productList } from '../../configuration/product/product.service';
import './style.less';
import { useSelector } from 'react-redux';
import { ProFormSelect } from '@ant-design/pro-form';
import _ from 'lodash';
import { getAllProudctLocator } from './sale-order.service';
import { locatorList } from '../locator/locator.service';
import moment from 'moment-timezone';
import { formatDateFull } from 'utils/constants';

const { Option } = Select;

const ReceiptProductEditForm = ({ item, onCancel, onCallback, isOnlyQuantityAllowForUpdate = false, items, type,channel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // const [productListFind, setProductListFind] = useState([]);
  const [products, setProducts] = useState([]);
  const [productFind, setProductFind] = useState();
  const [locatorFind, setLocatorFind] = useState();
  const [locators, setLocators] = useState();
  const [changeTin, setChangeTin] = useState(0);
  const [changeCarton, setChangeCarton] = useState(0);
  const [valueOfExpireDate, setValueOfExpireDate] = useState();
  useEffect(() => {
    setLoading(true);
    productList({ status: 'ENABLE', isAvailableQauntity: true, warehouseId: currentWarehouse?.id })
      .then(result => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
    // locatorList({ warehouseId: currentWarehouse?.id, isAvailable: true }).then(val => {
    //   setLocators(val.data);
    //   setLoading(false);
    // }).catch(e => setLoading(false));
  }, [currentWarehouse, item]);

  useEffect(() => {
    if (item) {
      setProductFind(item?.masterProduct);
      getAllProudctLocator({ masterProductId: item?.masterProduct.id, locatorCode: item?.locator?.locator }).then(res => {
        if (item?.expireDate) {
          setLocatorFind(res?.data)
        }
      })
    }
  }, [item]);

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id, isAvailable: true, masterProductId: productFind?.id }).then(val => {
      setLocators(val.data);
      setLoading(false);
    });
  }, [currentWarehouse, productFind, item]);

  useEffect(() => {
    if (item?.masterProduct) {
      setProductFind(item?.masterProduct);
      // form.setFieldsValue({ locatorId: undefined, lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0 });
    }
  }, [form, item]);

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        try {
          if (values.quantityInCarton === 0 && values.quantity === 0) {
            throw new Error('Số lượng thùng và lon là bắt buộc');
          }
          setLoading(true);
          if(typeof values.locatorId === 'string'){
            const finded = locators?.find(locator=>locator.locator === values.locatorId);
            values.locatorId= {
              label: `${finded.locatorName} - ${finded.locator}`,
              value: finded.locator,
              warehouseId: finded.warehouseId,
              id: finded.id,
            }
          }
          onCallback({
            ...productFind,
            ...values,
            type,
            indx: item?.indx || items?.length + 1,
            productOrderId: item?.id
          });
        } catch (error) {
          message.error(error.message);
        }
      }}
      initialValues={{
        productCode: item ? item?.masterProduct?.id : undefined,
        quantityInCarton: item?.quantityInCarton ? item?.quantityInCarton : 0,
        quantity: item?.quantityTin ? item?.quantityTin : 0,
        //item?.quantityTin + (item?.masterProduct?.tinPerCarton * item?.quantityInCarton)
        locatorId: item?.locator?.locator ? item?.locator?.locator : undefined,
        lot: item ? item?.lot : undefined,
        expireDate: item ? item?.expireDate : undefined,
        shelfLife: item?.expireDate ? moment(item?.expireDate, formatDateFull).diff(moment().startOf('date'), 'day') * 100 / (item?.masterProduct?.shelfLifeMonths * 30)?.toFixed(2) : undefined
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.product}
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              disabled={item}
              placeholder={lang.placeholderSelectProduct}
              onChange={(value) => {
                const itemFind = products.find(product => product.id === value);
                setProductFind(itemFind);
                form.setFieldsValue({ locatorId: undefined, lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0, shelfLife: undefined });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
            >
              {products?.map(item => (
                <Option key={item.id} value={item.id}>{item.code3A} - {item.shortName} - {item.nameEN}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name='locatorId'
            label={lang.Locator}
            disabled={item?.expireDate}
            rules={[
              {
                required: true,
                message: lang.requiredLocator,
              },
            ]}
            options={locators?.filter(locator => locator.warehouseId === currentWarehouse.id && !locator.warehouseToId)?.map(locator => ({
              label: `${locator.locatorName} - ${locator.locator}`,
              value: locator.locator,
              warehouseId: locator.warehouseId,
              id: locator.id,
            }))}
            placeholder={lang.Locator}
            fieldProps={{
              labelInValue: true,
              onChange: async value => {
                if ((productFind && !_.isEmpty(value))) {
                  const locator = await getAllProudctLocator({ masterProductId: productFind.id, locatorCode: value?.value,shelfLife: channel?.shelfLife });
                  if (locator?.data) {
                    setLocatorFind(locator?.data);
                  } else setLocatorFind();
                } else setLocatorFind();
                form.setFieldsValue({ lot: undefined, expireDate: undefined, shelfLife: undefined });
              },
            }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              // return true;
              return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            showSearch
          />
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="lot"
            label={lang.lot}
            rules={[
              {
                required: true,
                message: lang.requiredLot,
              },
            ]}
          >
            <Select
              placeholder={lang.lot}
              // onChange={(value) => {
              //   const itemFind = products.find(product => product.id === value);
              //   setProductFind(itemFind);
              // }}
              disabled={item?.expireDate}
              showSearch

              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
              onChange={(val) => {
                const find = locatorFind?.find(value => value.lot === val);
                // form.setFieldsValue({ expireDate: find?.expireDate, quantityInCarton: 0, quantity: 0 });
                const shelfLife = moment(find?.expireDate, formatDateFull).diff(moment().startOf('date'), 'day') * 100 / (find?.masterProduct?.shelfLifeMonths * 30);
                const shelfLifeFixed = shelfLife.toFixed(2);
                form.setFieldsValue({ expireDate: find?.expireDate, shelfLife: shelfLifeFixed });
              }}
            >
              {locatorFind?.reduce((prev, curr) => {
                if (!prev.some(lot => lot === curr.lot)) {
                  return [...prev, curr.lot];
                }
                return prev;
              }, [])?.map(item => (
                <Option key={item} value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => <Form.Item
              name="expireDate"
              label={lang.expireDate}
              rules={[
                {
                  required: true,
                  message: lang.requiredExpirationDate,
                },
              ]} 
            >
              <Select
                placeholder={lang.expireDate}
                disabled={item?.expireDate}
                // onChange={(value) => {
                //   const itemFind = products.find(product => product.id === value);
                //   setProductFind(itemFind);
                // }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // return true;
                  return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                }
                // onChange={() => form.setFieldsValue({ quantityInCarton: 0, quantity: 0 })}
              >
                {locatorFind?.filter(val => val.lot === getFieldValue('lot'))?.map(item => (
                  <Option key={item.expireDate} value={item.expireDate}>{item.expireDate}</Option>
                ))}
              </Select>
            </Form.Item>}
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name='shelfLife' label={'Shelf Life(%)'}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {(form) => {
              setValueOfExpireDate(form.getFieldValue('expireDate'));
              if (locatorFind || valueOfExpireDate) {
                let totalQuantity = locatorFind?.filter(item => !(item?.locators?.locator.includes('ITS')))?.reduce((prev, cur) => prev + cur.totalQuantityCarttonForSale, 0);
                if (form.getFieldValue('lot')) {
                  totalQuantity = locatorFind?.find(locator => locator.lot === form.getFieldValue('lot'))?.totalQuantityCarttonForSale;
                }
                if (form.getFieldValue('expireDate')) {
                  totalQuantity = locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityCarttonForSale;
                }
                if (items) {
                  totalQuantity -= items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantityInCarton, 0);
                } else if(item?.expireDate) {
                  totalQuantity = locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityCarttonForSale - changeCarton; //- (item?.quantityTin > 0 ? 1 : 0)
                } else {
                  totalQuantity -= item?.quantityInCarton + changeCarton;
                }
                return <Form.Item
                  name="quantityInCarton"
                  label={totalQuantity > 0 ? `${'Số lượng thùng khả dụng'}(<=${totalQuantity})` : `${'Số lượng thùng khả dụng'}(=${totalQuantity})`}
                  rules={[
                    // {
                    //   // eslint-disable-next-line prefer-promise-reject-errors
                    //   validator: (_, value) => value ? (totalQuantity < 0 ? Promise.reject('Nhỏ hơn số lượng thùng tồn kho') : Promise.resolve())
                    //   : Promise.resolve()
                    // },
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => value ? (
                        value <= locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityCarttonForSale ? Promise.resolve() :
                        Promise.reject('Nhỏ hơn số lượng thùng tồn kho'))
                     : Promise.resolve(),
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} min={0} onChange={(value) => setChangeCarton(value-item?.quantityInCarton)} placeholder={lang.placeholderQuantity} />
                </Form.Item>;
              }
              return <Form.Item
                name="quantityInCarton"
                label={'Số lượng thùng'}
              >
                <InputNumber style={{ width: '100%' }} min={0} max={productFind?.capacity}  placeholder={'Số lượng thùng'} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {(form) => {
              if (locatorFind) {
                let totalQuantityTin = locatorFind?.filter(item => !(item?.locators?.locator.includes('ITS')))?.reduce((prev, cur) => prev + cur.totalQuantityForSale, 0);
                if (form.getFieldValue('lot')) {
                  totalQuantityTin = locatorFind?.find(locator => locator.lot === form.getFieldValue('lot'))?.totalQuantityForSale;
                }
                if (form.getFieldValue('expireDate')) {
                  totalQuantityTin = locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityForSale;
                }
                if (items) {
                  const cartons = items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantityInCarton, 0);
                  const tins = items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantity, 0)
                  totalQuantityTin -= (form.getFieldValue('quantityInCarton') + cartons) * parseInt(productFind.tinPerCarton) - tins;
                } 
                else if (item?.expireDate) {
                  totalQuantityTin = locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityForSale - changeTin - (changeCarton * item?.masterProduct?.tinPerCarton);
                }else {
                  // totalQuantityTin += item?.quantityInCarton * parseInt(item?.masterProduct?.tinPerCarton) - changeTin;
                  totalQuantityTin = totalQuantityTin - (form.getFieldValue('quantityInCarton') * parseInt(productFind.tinPerCarton)) - changeTin - item?.quantityTin;
                }
                return <Form.Item
                  name="quantity"
                  label={totalQuantityTin > 0 ? `${'Số lượng lon khả dụng'}(<=${totalQuantityTin})` : `${'Số lượng lon khả dụng'}(=${totalQuantityTin})`}
                  rules={[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => value ? (
                        (value >= item?.masterProduct?.tinPerCarton) ? Promise.reject('Nhỏ hơn số lượng của 1 thùng') :
                        value <= locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityForSale ? Promise.resolve() :
                        Promise.reject('Nhỏ hơn số lượng lon tồn kho'))
                     : Promise.resolve(),
                    },
                  ]}
                  
                >
                  <InputNumber style={{ width: '100%' }} min={0} onChange={(value) => setChangeTin(value-item?.quantityTin) } placeholder={'Số lượng lon khả dụng'} value={totalQuantityTin} />
                </Form.Item>;
              }
              return <Form.Item
                name="quantity"
                label={'Số lượng lon'}
              >
                <InputNumber style={{ width: '100%' }} min={0} max={productFind?.capacity}  placeholder={'Số lượng lon'} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
      </Row>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  items: PropTypes.array,
  type: PropTypes.string,
  locators: PropTypes.array,
  channel: PropTypes.object,
};

export default ReceiptProductEditForm;
