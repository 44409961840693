import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Form, Card, Row, Col, Input, message as Alert, message, Spin } from 'antd';
import { CheckOutlined, DownOutlined, ScissorOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { bookingList, orderUpdateStatus, productUpdateStatus } from '../picking/picking.service';
import './style.less';
import TableCustom from 'components/TableCustom/table';
import moment from 'moment-timezone';
import { productPicking } from '../packaging/packaging.service';
import { formatDate } from 'utils/constants';
import BookingOrderForm from './booking-order.form';
import ProductEditForm from '../picking/product-edit.form';
import _get from 'lodash/get';

const tableScrollHeightArea = window.innerHeight - 200; // px

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ConfirmOrderCreate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const lang = getLanguages(t);
  const [isBookingOrderModal, setIsBookingOrderModal] = useState(false);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [productOrder, setProductOrder] = useState([]);
  const [productPicked, setProductPicked] = useState([]);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [listPicking, setListPicking] = useState({});
  const [isSplit, setIsSplit] = useState(false);
  const [item, setItem] = useState();
  const isManager = _get(user, 'roles', '').includes('manager');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isIdm = _get(user, 'roles', '').includes('idm');
  // const onSelectChange = selectedRowKeys => {
  //   setSelectedRowKeys(selectedRowKeys);
  // };
  useEffect(() => {
    if (productOrder[0]?.group) {
      productPicking({ orderId: productOrder[0]?.orderId, warehouseId: currentWarehouse.id, status: 'TEMPORARY_OUT' })
        .then(result => {
          setProductPicked(result.data);
        })
        .catch((e) => { });
    } else {
      setProductPicked([]);
    }
  }, [productOrder, currentWarehouse]);
  useEffect(() => {
    bookingList({ status: 'READY', sortDirection: 'desc', sortBy: 'createDate', warehouseId: currentWarehouse?.id }).then((values) => {
      setListPicking(values?.data?.reduce((prev, curr) => {
        if (curr?.group in prev) {
          prev[curr?.group].push(curr);
          return prev;
        }
        prev[curr?.group] = [curr];
        return prev;
      }, {}));
    }).catch((err) => message.error(err?.message));
  }, [currentWarehouse]);
  useEffect(() => {
    if (location?.state?.productOrder) {
      setProductOrder([...location?.state?.productOrder]);
    }
  }, [location]);
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  const columnsBookingOrders = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      width: '15%',
      dataIndex: 'orderCode',
    },
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
      width: '15%',
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      width: '20%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.quantityCartton,
      dataIndex: 'quantityInCarton',
      key: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
    // {
    //   title: lang.pickingquantity,
    //   dataIndex: 'pickingQuantity',
    //   key: 'pickingQuantity',
    //   width: '15%',
    //   render: (value) => value ? value : 0,
    // },
  ];
  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
    },
    {
      title: lang.productCode,
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => record?.masterProduct?.nameEN,
      width: '20%',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      render: rec => 'splitQuantityCartton' in rec ? rec.splitQuantityCartton : rec.quantityCartton,
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: rec => 'splitQuantityTin' in rec ? rec.splitQuantityTin : rec.quantityTin,
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: 'expireDate',
      render: val => val ? moment(val).format(formatDate) : '',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      render: value => value?.code,
    },
    {
      title: lang.action,
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<ScissorOutlined />}
            onClick={() => {
              setItem(record);
              setIsSplit(true);
            }}
          />
        </div>
      ),
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        try {
          setLoading(true);
          if (location?.state?.order?.status === 'READY') {
            await orderUpdateStatus({ ids: [location?.state?.order?.id], status: 'CONFIRM' });
          } else if (location?.state?.order?.status === 'CONFIRM') {
            await orderUpdateStatus({ ids: [location?.state?.order?.id], status: 'CONFIRM1' });
          } else {
            const orderUpdatesPending = {
              ids: [location?.state?.order?.id],
              status: 'EXPORTED',
            };
            const orderCode = productOrder?.[0]?.orderCode;
            const locatorCodeTo = productOrder?.[0]?.locatorCodeTo;
            const locatorTransitCode = location?.state?.order?.locatorTransitCode;
            await orderUpdateStatus(orderUpdatesPending);
            await productUpdateStatus({ codes: productPicked?.map(product => product.code), status: 'EXPORTED', warehouseId: currentWarehouse.id, orderCode, locatorCodeTo, locatorTransitCode });
          }
          setLoading(false);
          navigate(-1);
        } catch (error) {
          Alert.error(error.message);
          setLoading(false);
        }
      }}
    disabled={productPicked.length === 0 || (isStocker && location?.state?.order?.status !== 'READY') || (isIdm && location?.state?.order?.status !== 'CONFIRM') || (isManager && location?.state?.order?.status !== 'CONFIRM1')}
    >
      {lang.confirm}
      <CheckOutlined />
    </Button>,
  ];
  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/confirm-order',
      breadcrumbName: lang.orderConfirm,
    },
    {
      path: '/',
      breadcrumbName: lang.confirmOrder,
    },
  ];
  return (
    <Layout className="receipt-list">
      <Spin spinning={loading}>
        <PageHeader
          ghost={false}
          title={lang.confirmOrder}
          extra={extraButton}
          breadcrumb={{
            routes,
            itemRender: breadcrumbItemRender,
          }}
          className="customPageHeader"
        //onBack={onBack}
        />
        <Layout.Content style={{ margin: '12px 24px' }}>
          <Card size="small" className="cardSearch">
            <Form
              colon={false}
              layout="vertical"
              name="productEditForm"
              initialValues={{}}
            >
              <Row gutter={24}>
                <Col>
                  <h3>{`${lang.labelBooking}`}</h3>
                </Col>
                <Col lg={{ span: 6 }} xs={{ span: 12 }}>
                  <Input value={productOrder?.reduce((prev, cur) => {
                    if (prev.indexOf(cur.orderCode) === -1) {
                      return [...prev, cur.orderCode];
                    }
                    return prev;
                  }, []).toString()} placeholder={lang.orderCode} style={{ width: '100%' }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setIsBookingOrderModal(true);
                    // }}
                    suffix={<DownOutlined />} />
                </Col>
              </Row>
              <Row><span className="spanCustomHeadForOutBound">{lang.subTitleOutboundCreatePickUpNotify}</span></Row>
            </Form>
          </Card>
          <TableCustom
            rowKey="id"
            bordered
            columns={columnsBookingOrders}
            dataSource={productOrder}
            scroll={{ x: 600, y: tableScrollHeightArea }}
            pagination={{
              showSizeChanger: true,
            }}
          />
          <TableCustom
            title={() => <Row>
              <Col span={12}><h3>{lang.pickingList}</h3></Col>
            </Row>}
            rowKey="id"
            // rowSelection={rowSelection}
            bordered
            columns={columnsPickingList}
            dataSource={productPicked}
            pagination={{
              showSizeChanger: true,
            }}
            scroll={{ x: 1200, y: tableScrollHeightArea }}
          />
        </Layout.Content>
      </Spin>
      <Modal
        visible={isBookingOrderModal}
        title={lang.titleModalBooking}
        onCancel={() => setIsBookingOrderModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <BookingOrderForm
          onCallback={(value) => {
            setProductOrder(value);
            setIsBookingOrderModal(false);
          }}
          onCancel={() => setIsBookingOrderModal(false)}
          listPicking={listPicking}
          setProductOrder={setProductOrder}
        />
      </Modal>
      <Modal
        visible={isSplit}
        title={lang.splitproduct}
        onCancel={() => setIsSplit(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ProductEditForm
          isOnlyQuantityAllowForUpdate={true}
          item={item}
          onCallback={async () => {
            productPicking({ group: productOrder[0]?.group, warehouseId: currentWarehouse.id, status: 'TEMPORARY_OUT' })
              .then(result => {
                setProductPicked(result.data);
              })
              .catch((e) => { });
            setIsSplit(false);
          }}
          onCancel={() => setIsSplit(false)}
        />
      </Modal>
    </Layout>
  );
};

export default ConfirmOrderCreate;
