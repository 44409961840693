export default function ({
  // API_BASE_URL = 'http://dev.wms.pinnow.vn/api/',
  NODE_ENV = 'development',
  MAX_IMAGE_SIZE,
  isMockData,
  // PUBLISHED_URL = 'https://akawms.io',
  urlChangePassWord = 'http://wms.pinnow.vn/auth/realms/akawms-dev/account/#/security/signingin',
} = {}) {
  return {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    MAX_IMAGE_SIZE: MAX_IMAGE_SIZE,
    isMockData: isMockData,
    urlChangePassWord: urlChangePassWord,
    api: {
      login: 'auth/login',
      getCurrentUser: 'auth/authenticate',
      changePassword: 'api/functions/changePassword',
      forgotPassword: 'api/functions/forgotPassword',

      receiptList: '/receipts/get-all',
      receiptDetail: '/receipts/get-one',
      receiptUpdate: '/receipts/update',
      receiptConfirm: '/receipts/confirm',
      receiptConfirmVirtual: '/receipts/confirm-transfer-virtual-locator',
      receiptCreate: '/receipts/create',
      receiptDelete: '/receipts/deletes',
      receiptSplit: '/receipts/split-receipt',

      packageList: '/packages/get-all',

      productCategoryList: 'product-categorys/get-all',
      productCategoryGet: 'product-categorys/get-one',
      productCategoryCreate: 'product-categorys/create',
      productCategoryUpdate: 'product-categorys/update',
      productCategoryDelete: 'product-categorys/delete',
      productCategoryImport: 'product-categorys/create/excel/',
      replenishments: 'replenishments/',

      supplierList: '/suppliers/get-all',
      supplierDetail: '/suppliers/get-one',
      supplierUpdate: '/suppliers/update',
      supplierCreate: '/suppliers/create',
      supplierDelete: '/suppliers/delete',

      productTrxList: '/products/get-all',
      productTrxScan: '/products/scan',
      productTrxUpdate: '/products/update',
      productTrxUpdates: '/products/updates',
      productTrxSplit: '/products/split',
      productTrxUpdateLocation: '/products/update/location',

      productList: '/master-products/get-all',
      productCreate: '/master-products/create',
      productUpdate: '/master-products/update',
      productDelete: '/master-products/delete',
      productDetail: '/master-products/get-one',

      Orders: '/orders/',
      OrdersGift: '/orders-gift/',
      products: '/products/',
      packages: '/packages/',

      masterProduct: 'mocks/baonh3/baonh3:main/12120090/api/v1/master-products',
      product: 'mocks/baonh3/baonh3:main/12120090/api/v1/products',

      zones: '/zones/',
      blocks: '/blocks/',
      shelves: '/shelves/',

      fileUpload: '/file-upload',

      userList: '/auth/get-users',
      userDetail: '/auth/get-users',
      userUpdate: '/auth/update-users',
      userCreate: '/auth/create-user',
      userDelete: '/auth/delete-users',
      userChangePassword: '/auth/change-password',
      userChangePasswordUser: '/auth/change-password-user',
      roleList: '/auth/roles',

      warehouseList: '/warehouses/get-all',
      warehouseDetail: '/warehouses/get-one',
      warehouseCreate: '/warehouses/create',
      warehouseUpdate: '/warehouses/update',
      warehouseDelete: '/warehouses/delete',

      reports: '/reports/get-all',
      divison: '/divison',
      category: '/parent-product-categorys',
      bomProduction: '/bom-production',
      bomGift: '/bom-gift',
      productPacking: '/master-products-packing',
      area: '/area',
      locator: '/locator',
      apInvoice: '/apInvoice',
      racks: '/racks',
      receiptProduct: '/receiptProduct',
      masterProductsBomGift: '/master-products-bom-gift',
      salesChannel: '/sales-channel',
      masterProductsCombo: '/master-products-combo',
      productOrder: '/product-orders',
      masterProductLocator: '/master-products-locators',
      customerReturn: '/customerRMAReturn',
      receiptGift: '/receiptGift',
      receiptMaterial: '/receiptMaterial',
      receiptMaterialProduct: '/receiptMaterialProduct',
      productGifts: '/product-gifts',
      physicalInventory: '/physicalInventory',
      deliveryOrder: '/delivery-order',
      arInvoice: '/ar-invoice',

      //Master Product Gift 
      masterProductsGiftLocators: '/master-products-gift-locators/',
      productGiftOrders: '/product-gift-orders/',

      //Material command manuever
      OrdersMaterial: '/orders-material/',
      ProductMaterialOrders: '/product-material-orders/',
      PurchaseOrder: '/purchaseOrder',

      //Production - Order
      productionOrder: '/production-order/',

      //Unpack
      unpack: 'unpack/'
    },
    // PUBLISHED_URL,
    isProduction: NODE_ENV === 'production',
    isDevelopment: NODE_ENV === 'development',
  };
}
