import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, message, Modal } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import moment from 'moment-timezone';
import { supplierCreate, supplierUpdate } from './supplier.service';
// import { productCategoryList } from '../product-category/product-category.service';
import { formatDate, formatDateFull, MODE } from 'utils/constants';
import Helper from 'utils/helpers';
import { divisonList } from '../divison/divison.service';

const { Option } = Select;

const SupplierEditForm = ({ item, onCallback, isEditModal, setIsEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productDivisons, setProductDivisons] = useState();
  // const [listCate,setListCate]=useState();
  useEffect(() => {
    divisonList({ warehouseId: 1, status: 'ENABLE' }).then(result => {
      setProductDivisons(result.data);
    });
  }, []);
  const listCate = productDivisons?.map(productcate => {
    return {
      value: productcate.id,
      label: productcate.name,
    };
  });
  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);
  const initValues = {
    divisonIds: item ? item?.divisons?.map(cate => cate.id) : [],
    name: item ? item?.name : '',
    phoneNumber: item ? item?.phoneNumber : undefined,
    address: item ? item?.address : '',
    // cooperationDay: item?.cooperationDay ? moment(item?.cooperationDay, formatDateFull) : undefined,
    // contractNumber: item ? item?.contractNumber : undefined,
    // taxCode: item? item?.taxCode : undefined,
    // isActive: item ? item?.isActive : true,
    status: item ? item?.status : undefined,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };
  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateSupplier : lang.createSupplier}
      onCancel={onCancel}
      width={780}
      centered
      footer={null}
      wrapClassName="supplier-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        requiredMark={false}
        name="supplierEditForm"
        onFinish={async (values) => {
          if (values?.cooperationDay) {
            values.cooperationDay = moment(values?.cooperationDay, formatDate).format(formatDateFull);
          }
          if (!item) { // Create
            setLoading(true);
            await supplierCreate({ ...values, warehouseId: 1 })
              .then(result => {
                message.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { message.error(e.message); setLoading(false); });
          } else { // Update
            setLoading(true);
            await supplierUpdate(values, item)
              .then(result => {
                message.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { message.error(e.message); setLoading(false); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item name="divisonIds" label={lang.Divison}>
              <Select placeholder={lang.Divison} mode="multiple" options={listCate} showArrow showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                } />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label={lang.supplierName}
              rules={[
                {
                  required: true,
                  message: lang.requiredSupplierName,
                },
                {
                  max: 100,
                  message: lang.messageLong,
                },
              ]}
            >
              <Input placeholder={lang.placeholderName} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="phoneNumber"
              label={lang.phone}
            >
              <Input placeholder={lang.phone} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="address"
              label={lang.address}
            >
              <Input placeholder={lang.placeholderAddress} />
            </Form.Item>
          </Col>
          {/* <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="phoneNumber"
              label={lang.phone}
            >
              <Input placeholder={lang.placeholderPhoneNumber} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="cooperationDay"
              label={lang.cooperationDate}
            >
              <DatePicker style={{ width: '100%' }} format={formatDate} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="contractNumber"
              label={lang.contractNumber}
            >
              <Input placeholder={lang.contractNumber} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="taxCode"
              label={lang.taxCode}
            >
              <Input placeholder={lang.taxCode} />
            </Form.Item>
          </Col> */}
          {item && <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item name="status" label={lang.status} >
              <Select placeholder={lang.status} disabled={true}>
                <Option value="ENABLE">{lang.ENABLE}</Option>
                <Option value="DISABLE">{lang.DISABLE}</Option>
              </Select>
            </Form.Item>
          </Col>}
          {/* <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="isActive"
              valuePropName="checked"
              label={!item ? undefined : ' '}
            >
              <Checkbox>{lang.isActive}</Checkbox>
            </Form.Item>
          </Col> */}
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

SupplierEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default SupplierEditForm;
