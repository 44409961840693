import request from 'utils/request';
import environment from 'environment';

export async function materialCommandManueverList(payload) {
  return request(environment.api.OrdersMaterial + 'get-all', payload, 'GET');
}

export async function materialCommandManueverCreate(payload) {
  return request(environment.api.OrdersMaterial + 'create', payload, 'POST');
}

export async function materialManueuverCommandDetail(id) {
  return request(environment.api.OrdersMaterial + `get-one/${id}`, {}, 'GET');
}

export async function ordersMaterialProductUpdate(payload) {
  return request(environment.api.ProductMaterialOrders + 'update', payload, 'PUT');
}

export async function ordersMaterialUpdate(payload) {
  return request(environment.api.OrdersMaterial + 'update', payload, 'PUT');
}


export async function materialDelete(id) {
  return request(environment.api.OrdersMaterial + `delete/${id}`, {}, 'DELETE');
}


