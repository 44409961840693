import environment from 'environments/environment';
import request from 'utils/request';

const mockData = [
    {
        id: 1,
        image: 'https://cdn.tgdd.vn/Products/Images/2289/79053/bhx/nuoc-mam-nam-ngu-10-do-dam-chai-900ml-202104191509147755_300x300.jpg',
        code: 'SL_01',
        name: 'Nước mắm chinsu',
        productCategory: {
            id: 3,
            code: 'PC_03',
            name: 'Hàng tiêu dùng thiết yếu',
            exportStrategy: 'FIFO',
            createDate: '2021-08-18',
            status: 'ENABLE',
        },
        createDay: '18/8/2021',
        supplier: {
            id: 1,
            code: 'SL_01',
            name: 'NHÀ PHÂN PHỐI CHINSU',
        },
        capacity: 1200,
        stogareTime: 90,
        status: 'ENABLE',
        method: 'FIFO',
    },
    {
        id: 2,
        image: 'https://cdn.tgdd.vn/Products/Images/2386/216197/bhx/thung-12-hop-sua-tuoi-nguyen-chat-khong-duong-vinamilk-nhap-khau-100-1-lit-202104081055075538.jpg',
        code: 'SL_02',
        name: 'Sữa tươi vinamilk',
        productCategory: {
            id: 2,
            code: 'PC_02',
            name: 'Đồ uống',
        },
        createDay: '18/8/2021',
        supplier: {
            id: 2,
            code: 'SL_02',
            name: 'NHÀ PHÂN PHỐI SỮA VINAMILK',
        },
        capacity: 1200,
        stogareTime: 90,
        status: 'ENABLE',
        method: 'FIFO',
    },
    {
        id: 3,
        image: 'https://cdn.tgdd.vn/Products/Images/2282/171020/bhx/6-lon-bia-tiger-crystal-330ml-202103162333030856.jpg',
        code: 'SL_03',
        name: 'Bia tiger',
        productCategory: {
            id: 5,
            code: 'PC_05',
            name: 'Rượu, bia',
        },
        createDay: '18/8/2021',
        supplier: {
            id: 4,
            code: 'SL_04',
            name: 'NHÀ PHÂN PHỐI TIGER TẠI TPHCM',
        },
        capacity: 1200,
        stogareTime: 90,
        status: 'ENABLE',
        method: 'FIFO',
    },
    {
        id: 4,
        image: 'https://cdn.tgdd.vn/Products/Images/9838/239383/bhx/but-bi-thien-long-tl-089-vi-3-cay-202106181426207298.jpg',
        code: 'SL_04',
        name: 'Bút bi thiên long',
        productCategory: {
            id: 6,
            code: 'PC_06',
            name: 'Văn phòng phẩm',
        },
        createDay: '18/8/2021',
        supplier: {
            id: 4,
            code: 'SL_04',
            name: 'NHÀ PHÂN PHỐI TIGER TẠI TPHCM',
        },
        capacity: 1200,
        stogareTime: 90,
        status: 'ENABLE',
        method: 'LIFO',
    },
    {
        id: 5,
        image: 'https://cdn.tgdd.vn/Products/Images/8965/96107/bhx/revive-vi-chanh-muoi-390ml-201912101318119749.JPG',
        code: 'SL_05',
        name: 'Revive chanh muối',
        productCategory: {
            id: 2,
            code: 'PC_02',
            name: 'Đồ uống',
        },
        createDay: '18/8/2021',
        supplier: {
            id: 3,
            code: 'SL_03',
            name: 'NHÀ PHÂN PHỐI HÀNG TIÊU DÙNG UNILEVER',
        },
        capacity: 1200,
        stogareTime: 90,
        status: 'ENABLE',
        method: 'LIFO',
    },
    {
        id: 6,
        image: 'https://cdn.tgdd.vn/Products/Images/2945/84361/bhx/thuc-uong-dd-milo-180ml-loc-3-org.jpg',
        code: 'SL_06',
        name: 'Sữa milo',
        productCategory: {
            id: 2,
            code: 'PC_02',
            name: 'Đồ uống',
        },
        createDay: '18/8/2021',
        supplier: {
            id: 3,
            code: 'SL_03',
            name: 'NHÀ PHÂN PHỐI HÀNG TIÊU DÙNG UNILEVER',
        },
        capacity: 1200,
        stogareTime: 90,
        status: 'ENABLE',
        method: 'LIFO',
    },
];

export async function productList(payload) {
    if (environment.isMockData) {
        return Promise.resolve(mockData);
    }
    return request(environment.api.productList, payload, 'GET');
}

export async function productDetail(id) {
    if (environment.isMockData) {
        return Promise.resolve(mockData.filter(mock => mock.id.toString() === id)[0]);
    }
    return request(environment.api.productDetail + `/${id}`, {}, 'GET');
}

export async function productCreate(payload) {
    if (environment.isMockData) {
        const newData = {
            id: mockData.length + 1,
            image: null,
            code: `SL_0${mockData.length + 1}`,
            name: payload.name,
            productCategory: {
                id: 2,
                code: 'PC_02',
                name: 'Đồ uống',
            },
            createDay: '18/8/2021',
            supplier: {
                id: 3,
                code: 'SL_03',
                name: 'NHÀ PHÂN PHỐI HÀNG TIÊU DÙNG UNILEVER',
            },
            capacity: payload.capacity,
            stogareTime: payload.stogareTime,
            status: 'ENABLE',
            method: payload.method,
        };
        mockData.push(newData);
        return Promise.resolve('ok');
    }
    return request(environment.api.productCreate, payload, 'POST');
}

export async function productUpdate(values, item) {
    if (environment.isMockData) {
        mockData.map(mock => {
            if (mock.id === item.id) {
                Object.assign(mock, values);
            }
            return mock;
        });
        return Promise.resolve('ok');
    }
    delete values.code;
    const updateValue = {
        ...values,
        id: item.id,
        warehouseId: item.warehouseId,
    };
    return request(environment.api.productUpdate, updateValue, 'PUT');
}

export async function productDelete(id) {
    if (environment.isMockData) {
        const mockfil = mockData.filter(mock => id.indexOf(mock.id) === -1);
        return Promise.resolve(mockfil);
    }
    return request(environment.api.productDelete + `/${id}`, {}, 'DELETE');
}
export async function fileUpload(data) {
    return request(environment.api.fileUpload, data, 'POST');
}
export async function importExel(warehouseId, formData) {
    return request(environment.api.productCreate + `/excel/${warehouseId}`, formData, 'POST');
}
