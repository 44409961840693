import React, { useState, useEffect } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, message as Alert, Modal, Collapse } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { productCreate, productUpdate } from './product.service';
import { CaretRightOutlined } from '@ant-design/icons';
import { supplierList } from '../supplier/supplier.service';
// import { replenishmentCreate, replenishmentUpdate } from '../replenishment/replenishment.service';
import './style.less';
import { productCategoryList } from '../product-category/product-category.service';
import { useSelector } from 'react-redux';
import { MODE } from 'utils/constants';
const { Option } = Select;
const { Panel } = Collapse;

const ProductEditForm = ({ item, onCallback, isEditModal, setIsEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [suppliers, setSuppliers] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // const [haveImage, setHaveImage] = useState(false);
  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };
  // const onFileUploadChange = (file) => {
  //   return new Promise(resolve => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const img = document.createElement('img');
  //       img.src = reader.result;
  //       img.onload = () => {
  //         const canvas = document.createElement('canvas');
  //         canvas.width = 1500;
  //         canvas.height = 1000;
  //         const ctx = canvas.getContext('2d');
  //         ctx.drawImage(img, 0, 0, 1500, 1000);
  //         canvas.toBlob(resolve);
  //       };
  //     };
  //     setHaveImage(true);
  //   });
  // };
  // const fakeUpload = async (options) => {
  //   const { onSuccess } = options;
  //   onSuccess('OK');
  // };
  const onchangeCategory = (value) => {
    const categoryfind = productCategory.find(cate => cate.id === value);
    form.setFieldsValue({ method: categoryfind?.parentProductCategory.exportStrategy });
  };
  // const onRemove = () => {
  //   setHaveImage(false);
  // };
  useEffect(() => {
    productCategoryList({ warehouseId: 1, status: 'ENABLE' }).then(result => {
      setProductCategory(result.data);
    });
    supplierList({ warehouseId: 1, status: 'ENABLE' })
      .then(result => {
        setSuppliers(result.data);
      });
  }, [currentWarehouse, isEditModal, item]);

  // useEffect(() => {
  //   if(item?.id){
  //     replenishmentList({warehouseId: currentWarehouse?.id, masterProductId: item.id}).then(result=>{
  //       if(result.totalItem > 0){
  //         item.replenishments= result?.data;
  //       }
  //     })
  //   }

  // }, [currentWarehouse, item]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);
  // const initValues = {
  //   // image: item?.image ? [{
  //   //   url: item.image,
  //   // }] : [],
  //   name: item ? item?.name : '',
  //   productCategoryId: item ? item?.productCategory?.id : undefined,
  //   supplierIds: item ? item?.suppliers?.map(sup => sup.id) : undefined,
  //   capacity: item ? item?.capacity : null,
  //   stogareTime: item ? item?.stogareTime : 100,
  //   method: item ? item?.productCategory?.parentProductCategory?.exportStrategy : undefined,
  //   barCode: item ? item?.barCode : undefined,
  //   purchasePrice: item ? item?.purchasePrice : undefined,
  //   salePrice: item ? item?.salePrice : undefined,
  //   retailPrice: item ? item?.retailPrice : undefined,
  //   VAT: item ? item?.VAT : undefined,
  //   DVT: item ? item.DVT : undefined,
  //   packing: item ? item.packing : undefined,
  //   isResources: item ? item.isResources : undefined,
  //   length: item ? item.length : undefined,
  //   width: item ? item.width : undefined,
  //   height: item ? item.height : undefined,
  //   discount: item ? item.discount : undefined,
  //   isActive: item ? item.isActive : true,
  //   code: item ? item?.code : undefined,
  //   status: item?.status,
  //   min: item ? item?.replenishments[0]?.min : undefined,
  //   max: item ? item?.replenishments[0]?.max : undefined,
  // };
  const onCancel = () => {
    // const valueForm = form.getFieldsValue();
    // if (valueForm?.image) {
    //   const image = valueForm?.image?.map(img => {
    //     if (img?.url) {
    //       return {
    //         url: img.url,
    //       };
    //     }
    //     return img;
    //   });
    //   valueForm.image = image;
    // }
    // const isDefined = Object.values(valueForm).some(value => value !== undefined);
    // const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    // // console.log(JSON.stringify(initValues))
    // // console.log(JSON.stringify(valueForm))
    // if (!isDefined || isUnChange) {
    //   setIsEditModal(false);
    //   return true;
    // }
    // Helper.conFirm(setIsEditModal);
    setIsEditModal(false);
    return true;
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateProduct : lang.createProduct}
      onCancel={onCancel}
      width={1300}
      centered
      footer={null}
      // wrapClassName="product-modal"
      afterClose={() => {
        // setHaveImage(false);
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        name="productEditForm"
        onFinish={async (values) => {
          try {
            setLoading(true);
            // if (values?.image[0]?.originFileObj) {
            //   if (values?.image[0].size > environment.MAX_IMAGE_SIZE) {
            //     throw new Error('updateImageLess');
            //   }
            //   const formda = new FormData();
            //   formda.append('image', values?.image[0]?.originFileObj);
            //   formda.append('type', values?.image[0]?.type);
            //   const res = await fileUpload(formda);
            //   values.image = res.image_url;
            // } else if (values.image[0]?.url) {
            //   values.image = values.image[0].url;
            // }
            if (!item) { // Create
              await productCreate({ ...values, warehouseId: currentWarehouse.id });
              // if (values?.min || values?.max) {
              //   const { name, min, max, productCategoryId } = values;
              //   replenishmentCreate({ productName: name, min, max, productCategoryId, masterProductId: response?.id, warehouseId: currentWarehouse.id })
              //     .then(() => {
              //       setLoading(false);
              //     })
              //     .catch((e) => setLoading(false));
              // }
              Alert.success(lang.createSuccessfully);
              setLoading(false);
              onCallback(MODE.CREATE);
            } else { // Update
              // const { name, min, max, productCategoryId } = values;
              // if (item?.replenishments[0] && (values?.min !== item?.replenishments[0]?.min || values?.max !== item?.replenishments[0]?.max)) {
              //   await replenishmentUpdate({ productName: name, min, max, productCategoryId, masterProductId: item.id, warehouseId: currentWarehouse.id }, item?.replenishments[0]).then(result => {
              //     setLoading(false);
              //   })
              //     .catch((e) => setLoading(false));
              // }
              // else {
              //   replenishmentCreate({ productName: name, min, max, productCategoryId, masterProductId: item?.id, warehouseId: currentWarehouse.id })
              //     .then(() => {
              //       setLoading(false);
              //     })
              //     .catch((e) => setLoading(false));
              // }
              // replenishmentCreate({ productName: name, min, max, productCategoryId, masterProductId: item?.id, warehouseId: currentWarehouse.id })
              //     .then(() => {
              //       setLoading(false);
              //     })
              //     .catch((e) => setLoading(false));
              // delete values.min;
              // delete values.max;
              productUpdate(values, item)
                .then(result => {
                  Alert.success(lang.updateSuccessfully);
                  onCallback(MODE.EDIT);
                  setLoading(false);
                })
                .catch((e) => { Alert.error(e.message); setLoading(false); });
            }
          } catch (error) {
            if (error.message === 'updateImageLess') {
              Alert.error(lang.updateImageLess);
              setLoading(false);
              return false;
            }
            Alert.error(error.message);
            setLoading(false);
          }
        }}
        initialValues={{ ...item, supplierIds: item?.suppliers?.map(supp => supp.id) }}
      >
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
          accordion
        >
          <Panel header="Data Interface" key="1" className="site-collapse-custom-panel">
            <Row gutter={[16, 16]}>
              {/* <Col span={4}>
            <Form.Item
              name="image"
              label={lang.productImage}
              // extra={lang.imageExtra}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                beforeUpload={(file) => onFileUploadChange(file)}
                accept="image/*"
                listType="picture-card"
                maxCount={1}
                showUploadList={true}
                customRequest={fakeUpload}
                onRemove={onRemove}
                className="UploadImage"
              >
                {!haveImage && `+ ${lang.upload}`}
              </Upload>
            </Form.Item>
          </Col> */}
              {/* <Col span={20}>
            <Row gutter={[16, 16]} type="flex"> */}
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  label={'Abbott Code16'}
                  name="abbottCode16"
                // rules={[
                //   {
                //     required: true,
                //     message: lang.messageInput,
                //   },
                // ]}
                >
                  <Input placeholder={'Abbott Code16'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  label={'Code3A'}
                  name="code3A"
                // rules={[
                //   {
                //     required: true,
                //     message: lang.messageInput,
                //   },
                // ]}
                >
                  <Input placeholder={'Code3A'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="shortName"
                  label={'Short Name'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'Short Name'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="nameEN"
                  label={'NameEN'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'NameEN'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="UOM_EN"
                  label={'UOM_EN'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'UOM_EN'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="nameVN"
                  label={'NameVN'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'NameVN'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="UOM_VN"
                  label={'UOM_VN'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'UOM_VN'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="tinPerCarton"
                  label={'Tin Per Carton'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <InputNumber placeholder={'Tin Per Carton'} style={{ width: '100%' }} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="keyBrand5"
                  label={'Key Brand5'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'Key Brand5'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="countryOfOrigin"
                  label={'Country Of Origin'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'Country Of Origin'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="size" label={'SIZE'} >
                  <Select placeholder={'SIZE'} disabled>
                    <Option value="Big Size">Big Size</Option>
                    <Option value="Large">Large</Option>
                    <Option value="Small">Small</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="shelfLifeMonths" label={'Shelf Life Months'}>
                  <InputNumber style={{ width: '100%' }} placeholder={'Shelf Life Months'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="status" label={'Status'} >
                  <Select placeholder={'Status'} disabled>
                    <Option value="Active">Active</Option>
                    <Option value="Deactive">Deactive</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="typeOfProduct" label={'Type Of Product'} >
                  <Select placeholder={'Type Of Product'} disabled>
                    <Option value="Milk Powder">Milk Powder</Option>
                    <Option value="Liquid Milk">Liquid Milk</Option>
                    <Option value="Sachet">Sachet</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="isPackagedSku"
                  label={'Is Packaged Sku'}
                >
                  <Select placeholder={'Is Packaged Sku'} disabled>
                    <Option key="1" value={true}>{lang.yes}</Option>
                    <Option key="2" value={false}>{lang.no}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="milkCode"
                  label={'Milk Code'}
                >
                  <Select placeholder={'Milk Code'} disabled>
                    <Option key="1" value={true}>{lang.yes}</Option>
                    <Option key="2" value={false}>{lang.no}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="grossWeightKGPerCase" label={'Gross Weight KG Per Case'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Gross Weight KG Per Case'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="VAT" label={'VAT'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'VAT'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="barCodeTins"
                  label={'Bar Code Tins'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'Bar Code Tins'} disabled />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="barCodeCarton" label={'Bar Code Carton'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Bar Code Carton'} disabled/>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="productGroupName" label={'Product Group Name'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Product Group Name'} disabled/>
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel header="Manually WMS" key="2" className="site-collapse-custom-panel">
            <Row gutter={[16, 16]}>
              <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item name="supplierIds" label={'Supplier Ids'}>
                  <Select placeholder={'Supplier Ids'} showSearch mode='multiple'
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    }>
                    {suppliers?.map(item => (
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="flavor"
                  label={'Flavor'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'Flavor'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="brand"
                  label={'Brand'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'brand'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="skuGroup" label={'Sku Group'}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredCategory,
                    },
                  ]}>
                  <Select placeholder={lang.placeholderSelectProductCate} onChange={onchangeCategory} showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    }>
                    {productCategory?.map(item => (
                      <Option key={item.id} value={item.id.toString()}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="CTNSPerLayer3A" label={'CTNS Per Layer 3A'}>
                  <InputNumber style={{ width: '100%' }} /*formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/ placeholder={'CTNS Per Layer 3A'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="layersPerPallet3A" label={'Layers Per Pallet 3A'}>
                  <InputNumber style={{ width: '100%' }} placeholder={'Layers Per Pallet 3A'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="CTMSPerPalletStorage3A" label={'CTMS Per Pallet Storage 3A'}>
                  <InputNumber style={{ width: '100%' }} placeholder={'CTMS Per Pallet Storage 3A'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="CTNSPerLayer3AFloor" label={'CTNS Per Layer 3A Floor'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'CTNS Per Layer 3A Floor'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="layersPerPallet3AFloor3" label={'Layers Per Pallet 3A Floor3'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Layers Per Pallet 3A Floor 3'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="CTNSPerPalletStorage3AFloor4" label={'CTNS Per Pallet Storage 3A Floor 4'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'CTNS Per Pallet Storage 3A Floor 4'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="CTNSPerLayer2Import" label={'CTNS Per Layer2 Import'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'CTNS Per Layer2 Import'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="layersPerPallet3Import" label={'Layers Per Pallet3 Import'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Layers Per Pallet3 Import'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="CTNSPerPalletImport" label={'CTNS Per Pallet Import'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'CTNS Per Pallet Import'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="subBrandFollowADS"
                  label={'Sub Brand Follow ADS'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'Sub Brand Follow ADS'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item
                  name="productGroupName"
                  label={'Product Group Name'}
                // rules={[
                //   {
                //     required: true,
                //     message: lang.requiredProductName,
                //   },
                //   {
                //     max: 100,
                //     message: lang.messageLong,
                //   },
                // ]}
                >
                  <Input placeholder={'Product Group Name'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="netWeightGramPerTins" label={'Net Weight Gram PerTins'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Net Weight Gram Per Tins'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="netWeightKGPerCase" label={'Net Weight KG Per Case'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Net Weight KG Per Case'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="volumeCM3" label={'Volume CM3'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Volume CM3'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="grossWeightKGPerPallet" label={'Gross Weight KG Per Pallet'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Gross Weight KG Per Pallet'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="netWeightKGPerPallet" label={'Net Weight KG Per Pallet'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Net Weight KG Per Pallet'} />
                </Form.Item>
              </Col>
              {/* <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Form.Item name="netWeightKGPerPallet" label={lang.NET_WEIGHT_KG_PER_PALLET} >
              <InputNumber style={{ width: '100%' }} placeholder={lang.VAT} />
            </Form.Item>
          </Col> */}
              {/* <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Form.Item name="LENGTH_CARTON_CM" label={lang.LENGTH_CARTON_CM} >
              <InputNumber style={{ width: '100%' }} placeholder={lang.VAT} />
            </Form.Item>
          </Col> */}
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="lengthCartonCM" label={'Length Carton CM'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Length Carton CM'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="widthCartonCM" label={'Width Carton CM'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Width Carton CM'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="heightCartonCM" label={'Height Carton CM'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Height Carton CM'} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name="volumeCartonCM" label={'Volume Carton CM'} >
                  <InputNumber style={{ width: '100%' }} placeholder={'Volume Carton CM'} />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

ProductEditForm.propTypes = {
  item: PropTypes.object,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
  setIsEditModal: PropTypes.func,
};

export default ProductEditForm;
