import environment from 'environments/environment';
import request from 'utils/request';

const mockData = [
  {
    id: 1,
    productCategory: {
      id: 3,
      code: 'PC_03',
      name: 'Hàng tiêu dùng thiết yếu',
    },
    productName: 'Nước mắm chinsu',
    onHand: 50,
    min: 100,
    max: 200,
    toOrder: 150,
    status: 'ENABLE',
  },
  {
    id: 2,
    productCategory: {
      id: 2,
      code: 'PC_02',
      name: 'Đồ uống',
    },
    productName: 'Sữa tươi vinamilk',
    onHand: 50,
    min: 100,
    max: 200,
    toOrder: 150,
    status: 'ENABLE',
  },
  {
    id: 3,
    productCategory: {
      id: 5,
      code: 'PC_05',
      name: 'Rượu, bia',
    },
    productName: 'Bia tiger',
    onHand: 70,
    min: 100,
    max: 200,
    toOrder: 130,
    status: 'ENABLE',
  },
  {
    id: 4,
    productCategory: {
      id: 6,
      code: 'PC_06',
      name: 'Văn phòng phẩm',
    },
    productName: 'Bút bi thiên long',
    onHand: 80,
    min: 100,
    max: 200,
    toOrder: 120,
    status: 'ENABLE',
  },
];

export async function replenishmentList(payload) {
  if (environment.isMockData) {
    return Promise.resolve(mockData);
  }
  return request(environment.api.replenishments + 'get-all', payload, 'GET');
}

export async function replenishmentDetail(payload) {
  //return request(environment.api.replenishmentDetail, payload, 'GET');
  const result = {
    id: 0,
    productName: 'string',
    min: 0,
    max: 0,
    onHand: 0,
    toOrder: 0,
    status: 'string',
    wareHouseId: 0,
  };

  return new Promise((resolve, reject) => {
    resolve(result);
  });
}

export async function replenishmentCreate(values) {
  if (environment.isMockData) {
    const newValue = {
      id: mockData.length + 1,
      productCategory: {
        id: 6,
        code: 'PC_06',
        name: 'Văn phòng phẩm',
      },
      productName: values.productName,
      onHand: values.totalInventory,
      min: values.min,
      max: values.max,
      toOrder: values.totalReplenishment,
      status: 'ENABLE',
    };
    mockData.push(newValue);
    return Promise.resolve('Ok');
  }
  delete values.totalInventory;
  delete values.totalReplenishment;
  return request(environment.api.replenishments + 'create', values, 'POST');
}

export async function replenishmentUpdate(values, item) {
  if (environment.isMockData) {
    mockData.map(mock => {
      if (mock.id === item.id) {
        Object.assign(mock, values);
      }
      return mock;
    });
    return Promise.resolve('Ok');
  }
  delete values.totalInventory;
  delete values.totalReplenishment;
  const updateValue = {
    ...values,
    id: item.id,
    warehouseId: item.warehouseId,
  };
  return request(environment.api.replenishments + 'update', updateValue, 'PUT');
}

export async function replenishmentDelete(id) {
  if (environment.isMockData) {
    const mockfil = mockData.filter(mock => id.indexOf(mock.id) === -1);
        return Promise.resolve(mockfil);
  }
  return request(environment.api.replenishments + `delete/${id}`, {}, 'DELETE');
}
