import {
  zoneListFail,
  zoneListSuccess,
  zoneCreateFail,
  zoneCreateSuccess,
  zoneDeleteSuccess,
  zoneDeleteFail,
  blockListFail,
  blockListSuccess,
  zoneUpdateSuccess,
  zoneUpdateFail,
  blockUpdateSuccess,
  blockUpdateFail,
} from './actions';
import {
  ZONE_LIST,
  ZONE_CREATE,
  ZONE_DELETE,
  BLOCK_CREATE,
  BLOCK_DELETE,
  BLOCK_LIST,
  ZONE_UPDATE,
  SHELF_CREATE,
  SHELF_CREATE_SUCCESS,
  SHELF_CREATE_FAIL,
  BLOCK_UPDATE,
  SHELF_UPDATE_SUCCESS,
  SHELF_UPDATE_FAIL,
  SHELF_UPDATE,
} from './types';

import { put, takeLeading, takeEvery } from 'redux-saga/effects';
import { message as Alert } from 'antd';

const result = [
  {
    id: 1,
    name: 'Zone A',
    code: 'string',
    capcity: 5,
    createDate: '2019-08-24',
    status: 'string',
    warehouseId: 1,
  },
  {
    id: 2,
    code: 'string',
    name: 'Zone B',
    capcity: 5,
    createDate: '2019-08-24',
    status: 'string',
    warehouseId: 1,
  },
  {
    id: 3,
    code: 'string',
    name: 'Zone C',
    capcity: 5,
    createDate: '2019-08-24',
    status: 'string',
    warehouseId: 1,
  },
];
const blocks = [
  {
    id: 1,
    name: 'Block A',
    totalshelf: 3,
    shelfs: [],
  },
];

function* zoneList(action) {
  //const { order = 'descend', columnKey = 'createdAt' } = action.payload.sortedInfo || {};
  try {
    //const { stylistId } = action.payload;
    // const { result } = yield call(request, environment.api.getPostList, {
    //   page: action.payload.currentPage,
    //   limit: action.payload.pageSize || 20,
    //   orderBy: columnKey,
    //   order: `${order}ing`,
    // });
    yield put(zoneListSuccess(result, action.meta));
  } catch (e) {
    yield put(zoneListFail(e, action.meta));
    Alert.error(e.error);
  }
}
function* zoneCreate(action) {
  try {
    const { payload } = action;
    const newZone = {
      id: result.length + 1,
      code: 'string',
      name: payload.zoneName,
      capcity: payload.capacity,
      createDate: '2019-08-24',
      status: 'string',
      warehouseId: 1,
    };
    result.push(newZone);
    yield put(zoneCreateSuccess(newZone, action.meta));
  } catch (e) {
    yield put(zoneCreateFail(e, action.meta));
    Alert.error(e.error);
  }
}
function* zoneUpdate(action) {
  try {
    const { payload } = action;
    const rs = result.filter(rs => rs.id === payload.id)[0];
    rs.name = payload.zoneName;
    rs.capcity = payload.capacity;
    yield put(zoneUpdateSuccess(rs, action.meta));
  } catch (e) {
    yield put(zoneUpdateFail(e, action.meta));
    Alert.error(e.error);
  }
}
function* zoneDelete(action) {
  try {
    const { payload } = action;
    const deleteZone = result.filter(zone => zone.id !== payload.zoneId);
    // const st = result.splice(payload.zoneId - 1, 1);
    yield put(zoneDeleteSuccess(deleteZone, action.meta));
  } catch (e) {
    yield put(zoneDeleteFail(e, action.meta));
    Alert.error(e.error);
  }
}

function* blockList(action) {
  //const { order = 'descend', columnKey = 'createdAt' } = action.payload.sortedInfo || {};
  try {
    //const { stylistId } = action.payload;
    // const { result } = yield call(request, environment.api.getPostList, {
    //   page: action.payload.currentPage,
    //   limit: action.payload.pageSize || 20,
    //   orderBy: columnKey,
    //   order: `${order}ing`,
    // });
    yield put(blockListSuccess(blocks, action.meta));
  } catch (e) {
    yield put(blockListFail(e, action.meta));
    Alert.error(e.error);
  }
}

function* blockCreate(action) {
  try {
    const { payload } = action;
    const newBlock = {
      id: blocks.length + 1,
      name: payload.blockName,
      totalshelf: payload.totalShelf,
      shelfs: [],
    };
    blocks.push(newBlock);
    yield put(zoneCreateSuccess(newBlock, action.meta));
  } catch (e) {
    yield put(zoneCreateFail(e, action.meta));
    Alert.error(e.error);
  }
}
function* blockUpdate(action) {
  try {
    const { payload } = action;
    const rs = blocks.filter(rs => rs.id === payload.id)[0];
    rs.name = payload.blockName;
    rs.totalshelf = payload.totalShelf;
    yield put(blockUpdateSuccess(rs, action.meta));
  } catch (e) {
    yield put(blockUpdateFail(e, action.meta));
    Alert.error(e.error);
  }
}
function* blockDelete(action) {
  try {
    const { payload } = action;
    const deleteBlock = blocks.filter(block => block.id === payload.blockId);
    // const st = blocks.splice(payload.blockId - 1, 1);
    yield put(zoneDeleteSuccess(deleteBlock[0], action.meta));
  } catch (e) {
    yield put(zoneDeleteFail(e, action.meta));
    Alert.error(e.error);
  }
}

function* shelfCreate(action) {
  try {
    const { payload } = action;
    const rs = blocks.filter(rs => rs.id === payload.blockId)[0];
    rs.shelfs.push({
      ...payload,
      id: rs.shelfs.length + 1,
    });
    yield put(SHELF_CREATE_SUCCESS(rs, action.meta));
  } catch (e) {
    yield put(SHELF_CREATE_FAIL(e, action.meta));
    Alert.error(e.error);
  }
}

function* shelfUpdate(action) {
  try {
    const { payload } = action;
    const rs = blocks.filter(rs => rs.id === payload.blockId)[0];
    rs.shelfs.push({
      ...payload,
    });
    yield put(SHELF_UPDATE_SUCCESS(rs, action.meta));
  } catch (e) {
    yield put(SHELF_UPDATE_FAIL(e, action.meta));
    Alert.error(e.error);
  }
}

export default function* watchPostList() {
  yield takeEvery(ZONE_LIST, zoneList);
  yield takeEvery(BLOCK_LIST, blockList);
  yield takeLeading(ZONE_CREATE, zoneCreate);
  yield takeLeading(ZONE_DELETE, zoneDelete);
  yield takeLeading(ZONE_UPDATE, zoneUpdate);
  yield takeLeading(BLOCK_CREATE, blockCreate);
  yield takeLeading(BLOCK_UPDATE, blockUpdate);
  yield takeLeading(BLOCK_DELETE, blockDelete);
  yield takeLeading(SHELF_CREATE, shelfCreate);
  yield takeLeading(SHELF_UPDATE, shelfUpdate);
}
