/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, Modal, Radio } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { ProFormSelect, ProFormDatePicker } from '@ant-design/pro-form';
import { productList } from '../../configuration/product/product.service';
import './style.less';
import { locatorList } from '../product-management/product-management.service';
import { formatDate, formatDateAPi } from 'utils/constants';
import _ from 'lodash';
import { getAllProudctLocator } from 'pages/dashboard/configuration/sale-order/sale-order.service';
import moment from 'moment-timezone';
const { Option } = Select;

const PhysicalInventoryEditForm = ({ item, documentType, warehouseId, setIsEditModal, onCallback, isOnlyQuantityAllowForUpdate = false, isEditModal, onPrint }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('inWarehouse');
  const [productFind, setProductFind] = useState();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [locators, setLocators] = useState([]);
  const [locatorFind, setLocatorFind] = useState();
  const [value, setValue] = useState('YES');

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal, selectedProduct]);
  useEffect(() => {
    setLoading(true);
    productList({ status: 'ENABLE', 
    isAvailableQauntity: selectedProduct === 'inWarehouse' ? true : '',
    warehouseId: currentWarehouse?.id })
      .then(result => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse, selectedProduct]);
  useEffect(() => {
    if (item?.masterProduct) {
      setProductFind(item?.masterProduct);
      form.setFieldsValue({ locatorId: undefined, lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0 });
    }
  }, [form, item]);

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id, isAvailable: selectedProduct === 'inWarehouse' ? true : '', masterProductId: productFind?.id }).then(val => {
      if (selectedProduct === 'outWarehouse' && documentType === 'Stocktake Adjustment') {
        const filterData = val.data.filter(item => item?.area?.warehouseID === 'SPS');
        setLocators(filterData);
      } else {
        setLocators(val.data);
      }
      setLoading(false);
    });
  }, [currentWarehouse, productFind, selectedProduct, documentType]);
  const initValues = {
    productCode: item ? item?.id : undefined,
    locatorId: item?.locatorId ? item?.locatorId?.value : undefined,
    lot: item ? item?.lot : undefined,
    expireDate: item ? item?.expireDate : undefined,
    qtyCount: item ? item?.qtyCount : undefined,
    quantityBook: item ? item?.quantityBook : 0,
    qtyScrapped: item ? item?.qtyScrapped : undefined,
    inventoryType: item ? item?.inventoryType : undefined,
    campaign: item ? item?.campaign : undefined,
    printQAcode: value
  };
  const onCancel = () => {
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
      onCancel={onCancel}
      width={980}
      centered
      footer={null}
      wrapClassName="receipt-product-edit-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="receiptProductEditForm"
        onFinish={(values) => {
          values.expireDate = moment(values.expireDate, formatDateAPi).format(formatDateAPi)
          setLoading(true);
          onCallback({ 
            ...values,
            ...productFind,
            products
          });
          setLoading(false);
        }}
        initialValues={initValues}
      >
          <Row gutter={[16, 16]}>
            <Col lg={{ span: 16 }} xs={{ span: 24 }}>
              <Form.Item name="productCode" label={lang.product}
                rules={[
                  {
                    required: true,
                    message: lang.requiredProduct,
                  },
                ]}
              >
                <Select
                  placeholder={lang.placeholderSelectProduct}
                  allowClear
                  onChange={(value) => {
                    const itemFind = products.find(product => product.id === value);
                    setProductFind(itemFind);
                    form.setFieldsValue({ locatorId: undefined, lot: undefined, expireDate: undefined, qtyCount: 0 });
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  }
                >
                  {products?.map(item => (
                    <Option key={item.id} value={item.id}>{item.code3A} - {item.shortName} - {item.nameEN}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <ProFormSelect style={{ fontWeight: 600 }}
                name='locatorId'
                label={lang.Locator}
                rules={[
                  {
                    required: true,
                    message: lang.requiredLocator,
                  },
                ]}
                options={locators?.filter(locator => locator.warehouseId === currentWarehouse.id)?.map(locator => ({
                  label: `${locator.locatorName} - ${locator.locator}`,
                  value: locator.locator,
                  warehouseId: locator.warehouseId,
                  id: locator.id,
                }))}
                placeholder={lang.Locator}
                fieldProps={{
                  labelInValue: true,
                  onChange: async value => {
                    if (productFind && !_.isEmpty(value)) {
                      const locator = await getAllProudctLocator({ masterProductId: productFind.id, locatorCode: value?.value });
                      if (locator?.data) {
                        setLocatorFind(locator?.data);
                      } else setLocatorFind();
                    } else setLocatorFind();
                    form.setFieldsValue({ lot: undefined, expireDate: undefined, qtyCount: 0 });
                  },
                }}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // return true;
                  return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
              />
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                {
              selectedProduct === 'inWarehouse' ? 
              <Form.Item
                name="lot"
                label={lang.lot}
                rules={[
                  {
                    required: true,
                    message: lang.requiredLot,
                  },
                ]}
              >
                <Select
                  placeholder={lang.lot}
                  // onChange={(value) => {
                  //   const itemFind = products.find(product => product.id === value);
                  //   setProductFind(itemFind);
                  // }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  }
                  onChange={() => form.setFieldsValue({ expireDate: undefined, qtyCount: 0 })}
                >
                  {locatorFind?.reduce((prev, curr) => {
                    if (!prev.some(lot => lot === curr.lot)) {
                      return [...prev, curr.lot];
                    }
                    return prev;
                  }, [])?.map(item => (
                    <Option key={item} value={item}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
              : <Form.Item
                  name='lot'
                  wrapperCol={24}
                  label={'Lot'}
                  rules={[
                    {
                      required: true,
                      message: 'Lot cần phải nhập!',
                    },
                  ]}
                >
                  <Input style={{ width: '100%' }} placeholder={'Nhập Lot'} />
                </Form.Item>
                }
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            {
              selectedProduct === 'inWarehouse' ? 
              <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
              {({ getFieldValue }) => <Form.Item
                name="expireDate"
                label={lang.expireDate}
                rules={[
                  {
                    required: true,
                    message: lang.requiredExpirationDate,
                  },
                ]}
              >
                <Select
                  placeholder={lang.expireDate}
                  // onChange={(value) => {
                  //   const itemFind = products.find(product => product.id === value);
                  //   setProductFind(itemFind);
                  // }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  }
                  onChange={(val) => {
                    if (locatorFind) {
                      const finded = locatorFind?.find(locator => locator.lot === getFieldValue('lot') && locator.expireDate === val);
                      form.setFieldsValue({ quantityBook: finded?.totalQuantity });
                    } else form.setFieldsValue({ qtyCount: 0 });
                  }}
                >
                  {locatorFind?.filter(val => val.lot === getFieldValue('lot'))?.map(item => (
                    <Option key={item.expireDate} value={item.expireDate}>{item.expireDate}</Option>
                  ))}
                </Select>
              </Form.Item>
              }
            </Form.Item>
              : 
              <Form.Item 
               name='expireDate' 
               label={lang.expireDate}
               rules={[
                  {
                  required: true,
                  message: 'HSD cần phải nhập!',
                  },
                  ]}
               >
                 <ProFormDatePicker  fieldProps={{
                    style: { width: '100%' },
                    format: formatDate,
                  }} />
              </Form.Item>
            }
              
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              {
                selectedProduct === 'inWarehouse' ? 
                <Form.Item
                name="qtyScrapped"
                label={'QtyScrapped'}
                rules={[
                  {
                    required: true,
                    message: 'Bạn cần nhập số lượng!',
                  },
                ]}
              >
                <InputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  style={{ width: '100%' }}
                  placeholder='Nhập số lượng'
                  onChange={val => {
                    form.setFieldsValue({ qtyCount: form.getFieldValue('quantityBook') - val });
                  }}
                />
              </Form.Item>
              : <Form.Item
              name="qtyScrapped"
              label={'QtyScrapped'}
              rules={[
                {
                  required: true,
                  message: 'Bạn cần nhập số lượng!',
                },
              ]}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                style={{ width: '100%' }}
                placeholder='Nhập số lượng'
                max={0}
                onChange={val => {
                  form.setFieldsValue({ qtyCount: form.getFieldValue('quantityBook') - val });
                }}
              />
            </Form.Item>
              }
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="qtyCount"
                label={lang.quantityTin}
              >
                <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} disabled formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
              </Form.Item>
            </Col>
            
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
                name="quantityBook"
                label={'Quantity Book'}
              >
                <InputNumber style={{ width: '100%' }} min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} disabled />
              </Form.Item>
              
            </Col>
            {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="inventoryType"
                label={'InventoryType (Không bắt buộc nhập)'}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col> */}
            {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="campaign"
                label={'Campaign (Không bắt buộc nhập)'}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col> */}
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item name="selectedProduct" label={'Lựa chọn sản phẩm'}>
                <Radio.Group name='selectedProduct' defaultValue={selectedProduct} onChange = {(val) => {setSelectedProduct(val.target.value)} }>
                    <Radio value='inWarehouse' >Trong kho</Radio>
                    <Radio value='outWarehouse'>Không có trong kho</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item name="printQAcode" label={'In QRcode?'}>
                <Radio.Group 
                name='selectedQAcode'
                onChange={(e) => setValue(e.target.value)}
                defaultValue={value}
                >
                  <Radio value='YES'>Có</Radio>
                  <Radio value='NO'>Không</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>    
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

PhysicalInventoryEditForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsSelected: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
  warehouseId: PropTypes.number,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  isEditModal: PropTypes.bool,
  kind: PropTypes.string,
};

export default PhysicalInventoryEditForm;
