import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, message, Modal } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import { userChangePassword } from './user.service';
import Helper from 'utils/helpers';

const UserResetPasswordForm = ({ item, onCallback, setIsResetPasswordModal, isResetPasswordModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetPasswordModal]);

  const initValues = {
    username: item ? item?.username : undefined,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsResetPasswordModal(false);
      return true;
    }
    Helper.conFirm(setIsResetPasswordModal);
  };

  return (
    <Modal
        visible={isResetPasswordModal}
        title={lang.resetPassword}
        onCancel={onCancel}
        width={570}
        centered
        footer={null}
        wrapClassName="user-modal"
        afterClose={() => {
          form.resetFields();
        }}
        forceRender={true}
      >
    <Form
      colon={false}
      form={form}
      layout="vertical"
      requiredMark={false}
      name="userchangePasswordForm"
      onFinish={values => {
        values.id = item.id;
        setLoading(true);
        userChangePassword(values)
          .then(result => {
            message.success(lang.createSuccessfully);
            setLoading(false);
            onCallback();
          })
          .catch((e) => {
            message.error(e.message);
            setLoading(false);
          });
      }}
      initialValues={initValues}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="username"
            label={lang.username}
            rules={[
              {
                required: true,
                message: lang.requiredSupplierName,
              },
            ]}
          >
            <Input placeholder={lang.username} disabled={item} />
          </Form.Item>
        </Col>

        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="password"
            label={lang.passwordNew}
            rules={[
              {
                required: true,
                message: lang.requiredPassword,
              },
              {
                // pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g),
                min: 8,
                message: lang.passwordRegex,
              },
            ]}
          >
            <Input.Password
              placeholder={lang.password}
              iconRender={(visible) =>
                visible ? (
                  <EyeOutlined style={{ color: 'black' }} />
                ) : (
                  <EyeInvisibleOutlined style={{ color: 'black' }} />
                )
              }
              autoComplete = "new-password"
            />
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
    </Modal>
  );
};

UserResetPasswordForm.propTypes = {
  item: PropTypes.object,
  setIsResetPasswordModal: PropTypes.func,
  onCallback: PropTypes.func,
  isResetPasswordModal: PropTypes.bool,
};

export default UserResetPasswordForm;
