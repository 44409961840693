import {
  PICKING_ORDER_CREATE,
  BOOKING_ORDER_SET,
  BOOKING_ORDER_SET_SUCCESS,
  BOOKING_ORDER_SET_FAIL,
  PICKING_LIST_SET,
  PICKING_LIST_SET_SUCCESS,
  PICKING_LIST_SET_FAIL,
  PACKAGING_ORDER_SET,
  //Scan
  SCAN_PICKING_FOR_PRODUCT_CODE_ADD,
  SCAN_PACKAGING_FOR_PRODUCT_CODE_ADD,
  SCAN_SHIPPING_FOR_PACKAGE_CODE_ADD,
  SCAN_SPLIT_PRODUCT,
  PICKED_PRODUCT_SET,
} from './types';

const INITIAL_STATE = {
  pickingOrder: [],
  pickingProducts: [],
  pickingScanProducts: [],
  pickedProducts: [],
  packagingOrder: '',
  packagingOrderProducts: [],
  packagingPickProducts: [],
  packagingScanProducts: [],

  //scan
  scanPickingForProductCodes: [],
  scanPackagingForProductCodes: [],
  scanShippingForPackageCodes: [],
  scanSplitProductCode: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PICKING_ORDER_CREATE:
      return {
        ...state,
        pickingOrder: [],
        pickingProducts: [],
        pickingScanProducts: [],
    };

    case PACKAGING_ORDER_SET:
      return {
        ...state,
        packagingOrder: action.payload?.packagingOrder,
        pickingProducts: action.payload?.pickingProducts,
        pickingScanProducts: action.payload?.pickingScanProducts,
    };

    case BOOKING_ORDER_SET:
      return {
        ...state,
        pickingOrder: [],
        pickingProducts: [],
      };
    case BOOKING_ORDER_SET_SUCCESS:
      return {
        ...state,
        pickingOrder: action.payload?.pickingOrder,
        pickingProducts: action.payload?.pickingProducts,
        pickingScanProducts: [],
      };
    case BOOKING_ORDER_SET_FAIL:
      return {
        ...state,
      };

    case PICKING_LIST_SET:
      return {
        ...state,
        pickingScanProducts: [],
      };
    case PICKING_LIST_SET_SUCCESS:
      return {
        ...state,
        pickingScanProducts: action.payload,
      };
    case PICKING_LIST_SET_FAIL:
      return {
        ...state,
      };

    //Scan
    case SCAN_PICKING_FOR_PRODUCT_CODE_ADD:
      return {
        ...state,
        scanPickingForProductCodes: action.payload,
    };
    case SCAN_PACKAGING_FOR_PRODUCT_CODE_ADD:
      return {
        ...state,
        scanPackagingForProductCodes: action.payload,
    };
    case SCAN_SHIPPING_FOR_PACKAGE_CODE_ADD:
      return {
        ...state,
        scanShippingForPackageCodes: action.payload,
    };
    case SCAN_SPLIT_PRODUCT:
      return {
        ...state,
        scanSplitProductCode: action.payload,
    };
    case PICKED_PRODUCT_SET:
      return {
        ...state,
        pickedProducts: action.payload,
      };
    default:
      return state;
  }
};
