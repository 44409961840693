import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Button, Layout, PageHeader, Tabs, Form, message, Card, Row, Col, Modal } from 'antd';
import { ProFormSelect, ProFormDateRangePicker, ProFormText } from '@ant-design/pro-form';
import './style.less';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { blockList, productcategoryList, productsGiftList, productsList, receiptList, zoneList } from './product-management.service';
import { error, hashStatusInbound, hashStatusPending, lost } from './product-management.constants';
import { pickBy } from 'lodash';
import { useSelector } from 'react-redux';
// import jsPdf from 'jspdf';
import { defaultPage, formatDate, formatDateFull, formatNo} from 'utils/constants';
import Helper from 'utils/helpers';
import ProTableCusTom from 'components/TableCustom/Protable';
import ProductModalForm from './productModalForm';
import moment from 'moment-timezone';
// import html2canvas from 'html2canvas';
// import logog3A from 'assets/logoAKAWMS/logo-3A.png';
import ReceiptProductsPrint from '../../inbound/receipt/receipt-products-print';
import ReceiptGiftProductsPrint from '../../inbound/receiptGift/receiptGift-products-print';
const defaultSort = {
    sortDirection: 'desc',
    sortBy: 'id',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const ProductManagement = () => {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [tab, setTab] = useState('2');
    const [items, setItems] = useState([]);
    const [itemsGift, setItemsGift] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [form] = Form.useForm();
    const [productCategory, setProductCategory] = useState([]);
    const [listZone, setListZone] = useState([]);
    const [listBlock, setListBlock] = useState([]);
    const [selectedReceipt, setSelectedReceipt] = useState();
    // const [loadingExport, setLoadingExport] = useState(false);
    // const [status, setStatus] = useState('TEMPORARY');
    const [totalItems, setTotalItems] = useState();
    const [loading, setLoading] = useState(false);
    const { currentWarehouse } = useSelector((state) => state.auth);
    //Pagination
    const [params, setParams] = useState({ status: 'TEMPORARY', ...defaultPage });
    const [isPrintModal, setIsPrintModal] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isReload, setIsReload] = useState(false);
    const onSelectChange = (_, record) => {
        setSelectedRowKeys(record);
    };
    const rowSelection = {
        selectedRowKeys: selectedRowKeys?.map(key => key.id),
        onChange: onSelectChange,

    };
    useEffect(() => {
        productcategoryList({ warehouseId: currentWarehouse.id, status: 'ENABLE' }).then(result => {
            setProductCategory(result.data);
        });
        zoneList({ warehouseId: currentWarehouse.id }).then(result => {
            setListZone(result.data);
        });
        blockList({ warehouseId: currentWarehouse.id }).then(result => {
            setListBlock(result.data);
        });
    }, [currentWarehouse]);
    const columns = [
        {
            title: lang.defaultCode,
            dataIndex: 'masterProduct',
            key: 'barcode',
            render: value => value?.code3A,
            fixed: true,
            width: '7%',
        },
        {
            title: lang.SHORTNAME,
            dataIndex: ['masterProduct', 'shortName'],
            key: 'shortName',
        },
        {
            title: lang.productName,
            key: 'productname',
            render: (record) => {
                return record?.masterProduct?.nameEN;
            },
            width: '20%',
        },
        {
            title: lang.productCode,
            dataIndex: 'code',
            key: 'productcode',
            render: (text, record) => {
                return (
                    <Button size="small" type="link" onClick={() => {
                        setSelectedItem(record);
                        setVisible(true);
                    }
                    }>
                        {text}
                    </Button>
                );
            },
            // fixed: true,
            width: '8%',
        },
        {
            title: lang.productCategory,
            key: 'productCategory',
            render: (record) => {
                return record?.masterProduct?.productCategory?.name;
            },
        },
        {
            title: lang.Locator,
            dataIndex: 'locator',
            render: val => `${val.locatorName} - ${val.locator}`,
            with: '10%',
        },
        {
            title: lang.lot,
            key: 'lot',
            dataIndex: 'lot',
        },
        {
            title: lang.expireDate,
            dataIndex: 'expireDate',
            render: val => moment(val).format(formatDate),
        },
        {
            title: lang.status,
            dataIndex: 'status',
            key: 'status',
            render: (record) => {
                return lang[record];
            },
        },
        {
            title: lang.quantityCartton,
            key: 'quantityCartton',
            dataIndex: 'quantityCartton',
        },
        {
            title: lang.quantityTin,
            key: 'quantityTin',
            dataIndex: 'quantityTin',
        },
        {
            title: lang.totalQuantityTins,
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            render: value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        },
        {
            title: lang.zone,
            key: 'zone',
            dataIndex: 'zone',
            render: value => value?.name,
            hideInTable: !(['TEMPORARY', 'TEMPORARY_OUT'].indexOf(params?.status) > -1),
        },
        {
            title: params?.status === 'TEMPORARY' ? lang.block + ' (Gợi ý)' : lang.block,
            key: 'block',
            dataIndex: 'block',
            render: value => value?.name,
        },
        {
            title: params?.status === 'TEMPORARY' ? lang.shelf + ' (Gợi ý)' : lang.shelf,
            key: 'shelf',
            dataIndex: 'rack',
            render: value => value?.shelf?.name,
        },
        {
            title: params?.status === 'TEMPORARY' ? lang.rack + ' (Gợi ý)' : lang.rack,
            key: 'rack',
            dataIndex: 'rack',
            render: value => value?.code,
        },
        {
            title: lang.reportContent,
            hideInTable: tab !== '1',
            key: 'reportContent',
            dataIndex: 'description',
        },

    ];

    const columnsGift = [
        {
            title: lang.defaultCode,
            dataIndex: 'bomGift',
            key: 'barcode',
            render: value => value?.code,
            fixed: true,
            width: '7%',
        },
        {
            title: lang.productName,
            dataIndex: ['bomGift', 'name'],
            key: 'name',
            width: '20%',
        },
        {
            title: lang.productCode,
            dataIndex: 'code',
            key: 'productcode',
            render: (text, record) => {
                return (
                    <Button size="small" type="link" onClick={() => {
                        setSelectedItem(record);
                        setVisible(true);
                    }
                    }>
                        {text}
                    </Button>
                );
            },
            // fixed: true,
            width: '8%',
        },
        // {
        //     title: lang.productCategory,
        //     key: 'productCategory',
        //     render: (record) => {
        //         return record?.masterProduct?.productCategory?.name;
        //     },
        // },
        {
            title: lang.Locator,
            dataIndex: 'locator',
            render: val => `${val.locatorName} - ${val.locator}`,
            with: '10%',
        },
        {
            title: lang.status,
            dataIndex: 'status',
            key: 'status',
            render: (record) => {
                return lang[record];
            },
        },
        {
            title: lang.quantity,
            key: 'quantity',
            dataIndex: 'quantity',
        },
        {
            title: lang.zone,
            key: 'zone',
            dataIndex: 'zone',
            render: value => value?.name,
            hideInTable: !(['TEMPORARY', 'TEMPORARY_OUT'].indexOf(params?.status) > -1),
        },
        {
            title: params?.status === 'TEMPORARY' ? lang.block + ' (Gợi ý)' : lang.block,
            key: 'block',
            dataIndex: 'rack',
            render: value => value?.shelf?.block?.name,
        },
        {
            title: params?.status === 'TEMPORARY' ? lang.shelf + ' (Gợi ý)' : lang.shelf,
            key: 'shelf',
            dataIndex: 'rack',
            render: value => value?.shelf?.name,
        },
        {
            title: params?.status === 'TEMPORARY' ? lang.rack + ' (Gợi ý)' : lang.rack,
            key: 'rack',
            dataIndex: 'rack',
            render: value => value?.code,
        },
        {
            title: lang.reportContent,
            hideInTable: tab !== '1',
            key: 'reportContent',
            dataIndex: 'description',
        },

    ];
    const fetchProduct = (params, currentWarehouse) => {
        setLoading(true);
        if (tab === '4') {
            productsGiftList({ ...params, ...defaultSort, warehouseId: currentWarehouse.id }).then(result => {
                if (result.data.length === 0 && tab === '3') {
                    message.error(`${lang.noRecordsFound}`);
                    setLoading(false);
                } else {
                    setItemsGift(result.data);
                    setTotalItems(result.totalItem);
                    setItems([])
                    setLoading(false);
                }
            }).catch(error => { message.log(error.message); setLoading(false); });
        } else {
            productsList({ ...params, ...defaultSort, warehouseId: currentWarehouse.id }).then(result => {
                if (result.data.length === 0 && tab === '3') {
                    message.error(`${lang.noRecordsFound}`);
                    setLoading(false);
                } else {
                    setItemsGift([]);
                    setItems(result.data);
                    setTotalItems(result.totalItem);
                    setLoading(false);
                }
            }).catch(error => { message.log(error.message); setLoading(false); });
        }
    };
    useEffect(() => {
        fetchProduct(params, currentWarehouse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, currentWarehouse, isReload]);
    // const extraButton = [
    //     <Button
    //       key="btn-export"
    //       type="primary"
    //       ghost
    //       disabled={!selectedReceipt} //|| !params?.receiptId
    //       loading={loadingExport}
    //       onClick={async () => {
    //         setLoadingExport(true);
    //         const paramsUnLimit = { ...params };
    //         delete paramsUnLimit.page;
    //         delete paramsUnLimit.limit;
    //         const listItems = await productsList({ ...paramsUnLimit, warehouseId: currentWarehouse.id });
    //         const rempData = listItems?.data?.reduce((prev, curr) => {
    //           if(curr?.locator?.locatorName?.indexOf('Suspense') > -1){
    //             curr.sampleProduct = curr.quantityCartton * curr.masterProduct.tinPerCarton + curr.quantityTin;
    //           } else curr.sampleProduct = 0;
    //           const finded = prev?.find(product => product?.masterProduct?.id === curr?.masterProduct?.id);
    //           if (finded) {
    //             if(curr.sampleProduct){
    //             finded.sampleProduct += curr.sampleProduct;
    //             return prev;
    //             }
    //             finded.sampleProduct += curr.sampleProduct;
    //             finded.pallet = [...finded.pallet, curr.quantityCartton, curr.quantityTin];
    //             return prev;
    //           }
    //           return [...prev, { ...curr, pallet: [curr.quantityCartton, curr.quantityTin] }];
    //         }, [])?.map(product => {
    //           const temp = product?.pallet?.reduce((prev, curr, indx) => {
    //             if (indx % 2 === 0 && curr > 0)
    //               prev.arrayCarton = [...prev.arrayCarton, curr];
    //             else prev.tins += curr;
    //             return prev;
    //           }, { arrayCarton: [], tins: 0 });
    //           product.pallet = [...temp.arrayCarton, temp.tins];
    //           return product;
    //         });
    //         let content = `
    //         <div style="display: grid ;line-height: 1;width: 960px; font-size: 16px; font-family: Times New Roman, Times, serif; margin-right:50px">
    //           <div style="display:flex; margin-bottom: 20px;">
    //             <div style=" width: 20%; border-image: initial;">
    //                 <img src="${logog3A}" style=" height: 80%; width: 80%" />
    //             </div>
    //             <div style=" margin-top: 40px; padding: 10px 0; line-height: 1.25; width: 30%; font-size: 13px; margin-left: -20px">
    //                 <div>
    //                     Công Ty TNHH Dinh Dưỡng 3A(Việt Nam) <br> 3A Nutrition(Việt Nam)Co, Ltd
    //                 </div>    
    //                 <div>
    //                     ${rempData[0].warehouse.address}
    //                 </div>
    //                 <div>
    //                     Tel: (84.8) 3823 8222
    //                 </div>
    //                 <div>
    //                     Fax: (84.8) 3823 8777
    //                 </div>
    //             </div>
    //             <div style=" margin-top: 40px; margin-left: 30px; padding: 10px 0px 10px 10px; line-height: 1.25; width: 10%; font-size: 14px;"></div>
    //             <div style=" margin-top: 40px; padding: 10px 0px 10px 10px; line-height: 1.25; width: 40%; font-size: 14px;">
    //                 <div style="border: 2px solid #333; width: 40%; padding: 10px">
    //                     Code: P-85-02-F1 <br>
    //                     Issua <br>
    //                     date: 02/04/2016
    //                 </div>    
    //             </div>
            
    //           </div>
    //           <div style="display: flex; min-height: 88px; clear: both;">
    //             <div style="width: 100%;  line-height: 1.2; margin-top: -10px">
    //                 <div style="font-size: 26px; font-weight:bold; text-align: center;">PHIẾU KIỂM HÀNG/TALLY SHEET</div>
                
    //                 <div style="display: flex; ">
    //                     <div style="width: 30.33%;"></div>
    //                     <div style="width: 30.33%; text-align: right; margin-top:20px">
    //                         <div style="font-weight:bold;">Ticket No: ${rempData?.[0]?.receipt?.code} </div>
    //                     </div>
    //                     <div style="width: 30.33%; text-align: right; margin-top:20px">
    //                         <div style="font-weight:bold; ">Ngày: ${moment(rempData?.[0]?.receipt?.updateDate, formatDateFull).format(formatDate)}</div>
    //                     </div>
    //                 </div>
    //             </div>
    //           </div>
    
    //           <div style="margin-bottom: 20px">
    //             <table style="border: 1px solid #333; width: 100%; border-collapse: collapse;">
    //                 <tbody >
    //                     <tr>
    //                         <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Container/Truck No (Số Cont/ Số xe): ${rempData[0].container}</td>
    //                         <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Day (Ngày): ${moment(rempData?.[0]?.receipt?.createDate, formatDateFull).format(formatDate)}</td>
    //                     </tr>
    //                     <tr>
    //                         <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Seal No (Số Seal):</td>
    //                         <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Receiving time (Giờ nhận):</td>
    //                     </tr>
    //                     <tr>
    //                         <td style="padding: 5px 0px 5px 9px;">Custome clearance No (Tờ khai Hải quan số):</td>
    //                         <td style="padding: 5px 0px 5px 9px;">Finished time (Giờ hoàn thành):</td>
    //                     </tr>
    //                 </tbody>
          
    //             </table>
    //           </div>
    
    //           <table style="border: 2px solid #333; width: 100%; border-collapse: collapse; font-size: 11px">
    //             <thead>
    //                 <tr style="text-align:center; font-weight: bold;">
    //                     <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">NO STT</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">PRODUCT NAME <br>Tên sản phẩm</td>
    //                     <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">LOT NO<br>(Số lô)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">MFG.DATE <br>(Ngày sản xuất)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">EXP.DATE <br>(Hạn dùng)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" colspan="2">SAMPLE<br>(Kiểm mẫu)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" colspan="9">QTY RECEIVED<br>(Số lượng thực nhận)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" colspan="2">QUANTITY<br>(Số lượng)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">Remark<br>(Ghi chú)</th>
    //                 </tr>
    //                 <tr>
    //                     <th style=" border: 1px solid #333; padding: 5px;">Quantity<br>(Số lượng)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;">Accept<br>(Chấp nhận)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;" colspan="7">Số lượng thùng/pallet</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;">Total/Tổng số lượng<br>(Carton/thùng)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;">Total/Tổng số lượng<br>(Tin/lon)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;">Denlted/hàng móp<br>(Tin/lon)</th>
    //                     <th style=" border: 1px solid #333; padding: 5px;">Damaged/hàng hỏng<br>(Tin/lon)</th>
    //                 </tr>
    //             </thead>
    //             <tbody>`;
    //         let totalQuantityTin = 0;
    //         let totalQuantityCartton = 0;
    //         let totalCarton = 0;
    //         let totalTinOdd = 0;
    //         let totalQuantityTins = 0;
    //         let totalTinsOdd = 0;
    //         let totalSamp = 0;
    //         rempData.forEach((product, index) => {
    //           const pallet = product?.pallet;
    //           totalQuantityCartton = product?.pallet?.reduce((prev, curr, ind, arr) => ind === arr.length -1 ? prev : curr + prev, 0);
    
    //           totalQuantityTin = product?.pallet?.at(-1);
    //           totalQuantityCartton += Math.floor(totalQuantityTin / product?.masterProduct?.tinPerCarton);
    //           totalCarton += totalQuantityCartton;
    
    //           totalTinOdd += totalQuantityTin % product?.masterProduct?.tinPerCarton;
    //           totalTinsOdd += totalTinOdd;
    //           totalQuantityTins += (product?.masterProduct?.tinPerCarton * totalQuantityCartton) + totalTinOdd;
    //           totalSamp += product.sampleProduct;
    //           [...Array(parseInt(Math.ceil(pallet.length / 7))).keys()].forEach((value, indexPallet) => {
    //             content += `
    //                     <tr>
    //                       <td style=" width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? 1 + index : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? product.masterProduct.shortName : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? product?.lot : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product?.mfgDate ? moment(product.mfgDate, formatDateFull).format(formatDate) : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product?.expireDate ? moment(product.expireDate, formatDateFull).format(formatDate) : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product.sampleProduct ? `${product.sampleProduct}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[0 + indexPallet * 7] ? (indexPallet * 7 < pallet.length - 1) ? `${pallet[0 + indexPallet * 7]}T` : `${pallet[0 + indexPallet * 7]}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[1 + indexPallet * 7] ? (1 + indexPallet * 7 < pallet.length - 1) ? `${pallet[1 + indexPallet * 7]}T` : `${pallet[1 + indexPallet * 7]}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[2 + indexPallet * 7] ? (2 + indexPallet * 7 < pallet.length - 1) ? `${pallet[2 + indexPallet * 7]}T` : `${pallet[2 + indexPallet * 7]}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[3 + indexPallet * 7] ? (3 + indexPallet * 7 < pallet.length - 1) ? `${pallet[3 + indexPallet * 7]}T` : `${pallet[3 + indexPallet * 7]}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[4 + indexPallet * 7] ? (4 + indexPallet * 7 < pallet.length - 1) ? `${pallet[4 + indexPallet * 7]}T` : `${pallet[4 + indexPallet * 7]}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[5 + indexPallet * 7] ? (5 + indexPallet * 7 < pallet.length - 1) ? `${pallet[5 + indexPallet * 7]}T` : `${pallet[5 + indexPallet * 7]}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[6 + indexPallet * 7] ? (6 + indexPallet * 7 < pallet.length - 1) ? `${pallet[6 + indexPallet * 7]}T` : `${pallet[6 + indexPallet * 7]}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === parseInt(Math.ceil(pallet.length / 7)) - 1 ? `${totalQuantityCartton}T,${totalTinOdd}L` : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === parseInt(Math.ceil(pallet.length / 7)) - 1 ? (totalQuantityCartton * product?.masterProduct?.tinPerCarton) + totalTinOdd : ''}</td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                       <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                     </tr>`;
    //           });
    //           totalTinOdd = 0;
    //         });
    //         content += `
    //               <tr>
    //                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;" colspan="2">TOTAL</td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalSamp}L</td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalCarton}T, ${totalTinsOdd}L</td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantityTins}</td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //                 <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
    //               </tr>
    //             </tbody>
    //           </table>
    
    //           <div style="display: flex">
    //             <div style="width: 33.33%">
    //                 <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Manager(Quản lý kho hàng)</p>
    //                 <div>
    //                     <div>(Họ & tên):</div> <br>
    //                     <span>Date(Ngày):</span>
    //                 </div>
    //             </div>
    //             <div style="width: 33.33%;">
    //                 <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Keeper(Thủ kho)</p>
    //                 <div>
    //                     <div>(Họ & tên):</div> <br>
    //                     <span>Date(Ngày):</span>
    //                 </div>
    //             </div>
    //             <div style="width: 33.33%;">
    //                 <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Staff(Nhân viên nhập hàng)</p>
    //                 <div>
    //                     <div>(Họ & tên):</div> <br>
    //                     <span>Date(Ngày):</span>
    //                 </div>
    //             </div>
    //           </div>
    
    //           <div style="margin-top: 30px; font-weight: bold; font-size: 14px; width: 100%">
    //               Lưu ý: <i>Ghi vào cột ghi chú nếu Container/xe hàng có các tình trạng sau: màng co mỏng và không phủ lớp trên cùng; Nhiều số lô, hạn dùng trên pallet; Hàng có dấu hiệu bung, hỏng; Pallet hàng xô lệch;
    //                 Thùng hàng móp; Thùng hàng bẩn; Số lô trên thùng không in hoặc không rõ ràng; Sàn xe thủng, rò rỉ hoặc ẩm ướt; Xe vận chuyển có mùi hôi của hải sản, gia cầm, thuốc sâu, côn trùng, ...; Pallet cũ, ẩm mốc, mối mọt, 
    //                 gãy, nứt,...</i>  
    //           </div>
    //         </div>`;
    
    //         const template = document.createElement('div');
    //         template.innerHTML = content;
    //         document.body.appendChild(template);
    //         await html2canvas(template, { width: 1254, scale: 2 }).then((canvas) => {
    //           const imgWidth = 210;
    //           const imgHeight = canvas.height * imgWidth / canvas.width;
    //           const imgData = canvas.toDataURL('image/png');
    //           // eslint-disable-next-line new-cap
    //           const pdf = new jsPdf('p', 'mm', 'a4');
    //           pdf.addImage(imgData, 'PNG', 25, 10, imgWidth, imgHeight);
    //           pdf.save(`TallySheet${moment().format('DDMMYYYY')}.pdf`);
    //         });
    //         document.body.removeChild(template);
    //         setLoadingExport(false);
    //       }}
    //     >
    //       {'Xuất TallySheet'}
    //     </Button>,
    //   ];

    const routes = [
        {
            path: '/dashboard',
            breadcrumbName: 'Home',
        },
        {
            path: '/dashboard',
            breadcrumbName: lang.inventory,
        },
        {
            path: '/productmanagement',
            breadcrumbName: lang.productManagement,
        },
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.productManagement}
                // extra={extraButton}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                footer={
                    <Tabs onTabClick={(value) => {
                        form.resetFields();
                        if (value === '1') {
                            setParams({ status: 'ERROR', ...defaultPage });
                            form.setFieldsValue({ status: 'ERROR' });
                        } else if (value === '3') {
                            setItems([]);
                            setTotalItems(0);
                            form.resetFields();
                            setTab(value);
                            return true;
                        } else {
                            setParams({ status: 'TEMPORARY', ...defaultPage });
                            form.setFieldsValue({ status: 'TEMPORARY' });
                        }
                        setTab(value);
                        // setIslist(true);
                    }}
                        defaultActiveKey={tab}
                        tabBarExtraContent={false}
                        className="tabCustom"
                    >
                        <TabPane tab={lang.inboundState} key="2" />
                        <TabPane tab={lang.inboundStateGift} key="4" />
                        <TabPane tab={lang.pendingState} key="1" />
                        <TabPane tab={lang.lostState} key="3" />
                    </Tabs>
                }
                className="customPageHeader"
            />
            <Layout.Content>
                <Card size="small" className="cardSearch">
                    <Form form={form}
                        onFinish={(value) => {
                            const cleanValue = pickBy(value, v => v !== undefined && v !== '' && v !== null);
                            if (cleanValue?.reportDate) {
                                cleanValue.startReportDate = cleanValue.reportDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endReportDate = cleanValue.reportDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.reportDate;
                            }
                            if (cleanValue?.lostDate) {
                                cleanValue.startLostDate = cleanValue.lostDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endLostDate = cleanValue.lostDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.lostDate;
                            }
                            if (cleanValue?.importDate) {
                                cleanValue.startDate = cleanValue.importDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endDate = cleanValue.importDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.importDate;
                            }
                            if (tab === '3') {
                                if (Object.keys(cleanValue).length !== 0) {
                                    cleanValue.status = lost;
                                    setParams({ ...cleanValue, page: 1, limit: params.limit });
                                }
                                return true;
                            }
                            if (!cleanValue?.status) {
                                if (tab === '1') {
                                    cleanValue.status = error;
                                } else {
                                    cleanValue.status = 'TEMPORARY';
                                }
                            }
                            if (cleanValue?.status === 'ALL') {
                                if (selectedReceipt) {
                                    // params.receiptId = selectedReceipt;
                                    cleanValue.status = undefined;
                                    cleanValue.multipleStatus = tab === '1' ? Object.values(hashStatusPending).map(value => value.text).join(',') : ('TEMPORARY,STORED');
                                  } else {
                                    delete params.receiptId;
                                    cleanValue.status = undefined;
                                    cleanValue.multipleStatus = tab === '1' ? Object.values(hashStatusPending).map(value => value.text).join(',') : Object.values(hashStatusInbound).map(value => value.text).join(',');
                                  }
                                
                            }
                            setParams({ ...cleanValue, receiptId: selectedReceipt, page: 1, limit: params.limit });
                        }}
                        className="customFormSearch"
                    >
                        {tab !== '3' ? <Row gutter={16}>
                            {/* <Col xxl={4} xl={5} sm={10} xs={24} className="customItem">
                                <ProFormSelect label={lang.productCategory} name="productCategoryId" placeholder={lang.productCategory}
                                    options={productCategory?.map(cate => {
                                        return {
                                            value: cate.id,
                                            label: cate.name,
                                        };
                                    })}
                                />
                            </Col> */}
                            {/* <Col xxl={8} xl={6} sm={6} xs={24} className="customItem">
                                <ProFormSelect label={lang.receipt} name="receiptId" placeholder={lang.receipt} request={async () => {
                                    try {
                                        const receipts = await receiptList({ warehouseId: currentWarehouse.id, status: 'RECEIVE' });
                                        return receipts.data?.map(receipt => {
                                            return {
                                                label: receipt.code,
                                                value: receipt.id,
                                                key: receipt.id,
                                            };
                                        });
                                    } catch (error) {
                                        return [];
                                    }
                                }}
                                    fieldProps={{
                                        showSearch: true,
                                    }}
                                />
                            </Col> */}
                            <Col xxl={8} xl={8} sm={8} xs={24} className="customItem">
                                {tab === '2' ? <ProFormDateRangePicker label={lang.receiveDate} name="importDate" placeholder={[lang.startDate, lang.endDate]}
                                    fieldProps={{
                                        format: formatDate,
                                    }}
                                />
                                    : <ProFormDateRangePicker label={lang.reportDate} name="reportDate" placeholder={[lang.startDate, lang.endDate]}
                                        fieldProps={{
                                            format: formatDate,
                                        }}
                                    />}
                            </Col>
                            <Col xxl={8} xl={6} sm={6} xs={24} className="customItem">
                                <ProFormSelect label={lang.status} initialValue={'TEMPORARY'} name="status" placeholder={lang.status}
                                    options={tab === '1' ? Object.values(hashStatusPending).map(value => { return { value: value.text, label: lang[value.text] }; }) : Object.values(hashStatusInbound).map(value => { return { value: value.text, label: lang[value.text] }; })} 
                                    // onChange={(value) => {setStatus(value)} }
                                    />
                            </Col>
                            <Col xxl={8} xl={6} sm={6} xs={24}>
                            <ProFormSelect label={lang.receipt} onChange={(value) => setSelectedReceipt(value)} placeholder={lang.receipt} request={async () => {
                                try {
                                const receipts = await receiptList({ warehouseId: currentWarehouse.id});
                                const dataReceipts = receipts.data.filter((item) => item.status !== 'NEW' && item.status !== 'REJECT');
                                const dataTime = moment(receipts?.data[0].receiptDate, formatDateFull).format(formatNo);
                                // var code = '';
                                return dataReceipts?.map(receipt => {
                                    return {
                                    label: dataTime + '_' + receipt.id,
                                    value: receipt.id,
                                    key: receipt.id,
                                    };
                                });
                                } catch (error) {
                                return [];
                                }
                            }}
                                fieldProps={{
                                showSearch: true,
                                }}
                            />
                            </Col>
                            
                            <Col xxl={8} xl={8} sm={8} xs={14}>
                                <ProFormText name="keyword" placeholder={lang.keyword} />
                            </Col>
                            <Col >
                                <Button type={'primary'} style={{ borderRadius: '4px', width: '111px' }} htmlType="submit" ghost >{lang.btnSearch}</Button>
                            </Col>
                        </Row> : <Row gutter={16}>
                            {/* <Col xxl={5} md={6} xs={24} className="customItem">
                                <ProFormSelect label={lang.productCategory} name="productCategoryId" placeholder={lang.productCategory}
                                    options={productCategory?.map(cate => {
                                        return {
                                            value: cate.id,
                                            label: cate.name,
                                        };
                                    })}
                                />
                            </Col> */}
                            <Col xxl={8} md={8} xs={24} className="customItem">
                                <ProFormText label={lang.productCode} name="keyword" placeholder={lang.productCode} />
                            </Col>
                            <Col xxl={8} md={8} xs={16} className="customItem">
                                <ProFormDateRangePicker label={lang.lostDate} name="lostDate" placeholder={[lang.startDate, lang.endDate]}
                                    fieldProps={{
                                        format: formatDate,
                                    }}
                                />
                            </Col>
                            <Col >
                                <Button type={'primary'} style={{ borderRadius: '4px', width: '111px' }} onClick={() => form?.submit()} ghost >{lang.btnSearch} </Button>
                            </Col>
                        </Row>}
                    </Form>
                </Card>
                <ProTableCusTom
                    rowKey="id"
                    rowSelection={tab === '2' || tab === '4' ? rowSelection : false}
                    headerTitle={lang.productList}
                    columns={itemsGift?.length > 0 ? columnsGift : columns}
                    loading={loading}
                    scroll={{ x: 2000 }}
                    dataSource={itemsGift?.length > 0 ? itemsGift : items || null}
                    search={false}
                    options={{
                        search: false,
                        reload: false,
                        density: false,
                    }}
                    onChange={(pagination) => {
                        if (pagination.pageSize !== params.limit) {
                            params.page = 1;
                        } else {
                            params.page = pagination.current;
                        }
                        params.limit = pagination.pageSize;
                        setParams({ ...params });
                    }}
                    pagination={{
                        total: totalItems,
                        showSizeChanger: true,
                        pageSize: params?.limit,
                        current: params?.page,
                        showTotal: false,
                    }}
                    tableAlertRender={false}
                    toolbar={{
                        actions: [
                            selectedRowKeys.length > 0 && (tab === '2' || tab === '4') && <Button
                                onClick={() => {
                                    setIsPrintModal(true);
                                }}
                                type={'primary'}
                                style={{ width: 110, borderRadius: 4 }}>{lang.print}</Button>,
                            tab !== '2' && <Button
                                onClick={() => {
                                    const itemCSV = items?.map(item => {
                                        const { id, code, expireDate, expectedQuantity, description, totalQuantity } = item;
                                        const { DVT, VAT, barCode, itemCode, height, width, length, method, name, packing, purchasePrice, retailPrice, salePrice } = item?.masterProduct;
                                        return {
                                            id,
                                            code,
                                            expireDate,
                                            expectedQuantity,
                                            description,
                                            DVT,
                                            VAT,
                                            barCode,
                                            totalQuantity,
                                            itemCode,
                                            height,
                                            width,
                                            length,
                                            method,
                                            name,
                                            packing,
                                            purchasePrice,
                                            retailPrice,
                                            salePrice,
                                            zoneName: item?.zone?.name,
                                        };
                                    });
                                    Helper.dowloadXLSX(itemCSV, 'ErrorData');
                                }}
                                type={'primary'}
                                style={{ width: 110, borderRadius: 4 }}>{lang.exportCSV}</Button>,
                        ],
                    }}
                />
                <ProductModalForm
                    visible={visible}
                    setVisible={setVisible}
                    selectedItem={selectedItem}
                    listZone={listZone}
                    listBlock={listBlock}
                    productCategory={productCategory}
                    setIsReload={setIsReload}
                    isReload={isReload}
                    warehouseId={currentWarehouse.id}
                />
                <Modal
                    visible={isPrintModal}
                    title={lang.print}
                    onCancel={() => setIsPrintModal(false)}
                    destroyOnClose={true}
                    width={980}
                    centered
                    footer={null}
                >
                   { tab === '2' ? <ReceiptProductsPrint
                        items={selectedRowKeys?.map(key => ({ ...key, receiptDate: key?.receipt?.receiptDate }))}
                        onCallback={(values) => {
                            Helper.dowloadXLSX(values?.map(item => {
                                return {
                                    code: item.code,
                                    name: item.masterProduct.name,
                                    receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
                                };
                            }), 'productCode');
                            setIsPrintModal(false);
                        }}
                        onPrint={() => {
                            var content = document.getElementById('print');
                            var myWindow = window.open('', '');
                            myWindow.document.write(content.innerHTML);
                            myWindow.document.close(); //missing code
                            myWindow.focus();
                            myWindow.print();
                            setIsPrintModal(false);
                        }}
                        onCancel={() => {
                            setIsPrintModal(false);
                        }}
                    /> : <ReceiptGiftProductsPrint
                        items={selectedRowKeys?.map(key => ({ ...key, receiptDate: key?.receipt?.receiptDate }))}
                        // onCallback={(values) => {
                        //     Helper.dowloadXLSX(values?.map(item => {
                        //         return {
                        //             code: item.code,
                        //             name: item.masterProduct.name,
                        //             receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
                        //         };
                        //     }), 'productCode');
                        //     setIsPrintModal(false);
                        // }}
                        onPrint={() => {
                            var content = document.getElementById('print');
                            var myWindow = window.open('', '');
                            myWindow.document.write(content.innerHTML);
                            myWindow.document.close(); //missing code
                            myWindow.focus();
                            myWindow.print();
                            setIsPrintModal(false);
                        }}
                        onCancel={() => {
                            setIsPrintModal(false);
                        }}
                    /> 
                    }
                </Modal>
            </Layout.Content>
        </Layout>
    );
};

export default ProductManagement;
