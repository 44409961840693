import React, { useState } from 'react';
import { Col, Row, Button } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import Helper from 'utils/helpers';

const ReceiptGiftProductPrint = ({ items, onCancel, onCallback, onPrint, containerNo, warehouseName }) => {
  const [loading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  let dataId = items[0].bomGift?.code;
  let newIndex = 0;
  return (
    <>
      <Row gutter={[16, 16]} className="scrollYStyle" id='print'>
        {
        items.map((value, index) => {
          if (dataId === value?.bomGift.code) {
            newIndex++;
          } else {
            dataId = value?.bomGift.code
            newIndex = 1
          }
          
          let name = '';
            if (value?.locator?.area?.warehouseID === 'AVS') {
              name = 'Available for Sales';
            } else {
              name = 'ONHOLD';
            };
          return (
            <>
              <div style={{ width: '1380px', height: '900px' }}>
                <table style={{border: '2px solid #333', width: '100%', borderCollapse: 'collapse', textAlign: 'center'}}>
                <tr>
                    <td style={{padding: '20px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '40px'}}>ITEM</td>
                    <td style={{padding: '10px 0px', borderBottom: '1px solid #333', fontSize: '100px'}} colspan="4">{value?.bomGift?.name}</td>
                </tr>
                <tr>
                    <td style={{padding: '20px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '40px'}}>QTY</td>
                    <td style={{padding: '20px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '70px'}} colspan="2">{value?.quantity}</td>
                    <td style={{padding: '10px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333'}} colspan="2" rowspan="3">
                    {Helper.genarateQRcode([value?.code])}
                      <div style={{ fontWeight: 'bold', marginTop: '8px', fontSize: '45px' }}>{value?.code}</div>
                    </td>
                </tr>
                <tr>
                    <td style={{padding: '20px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '30px'}}>KHO: <span style={{ fontSize: '24px' }}>{warehouseName}</span></td>
                    <td style={{padding: '20px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '30px'}}>Pallet: {newIndex}</td>
                    <td style={{padding: '10px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '30px'}}>Nhóm/<br />Chuyền số</td>
                </tr>
                <tr>
                    <td style={{padding: '10px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '40px'}} colspan="2" rowspan="2">{name}</td>
                    <td style={{padding: '10px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333'}}></td>
                </tr>
                    <tr>
                    <td style={{padding: '10px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '30px'}}>CA:</td>
                    <td style={{padding: '10px 0px', borderRight: '1px solid #333', fontWeight: 'bold', borderBottom: '1px solid #333', fontSize: '30px'}}>Số CONT:</td>
                    <td style={{padding: '10px 0px', fontSize: '30px'}}>{containerNo ? containerNo : ' '}</td>
                </tr>
            </table>
              </div>
              {index < items.length - 1 && <div style={{ breakBefore: 'always', pageBreakBefore: 'always', pageBreakInside: 'avoid' }}></div>}
            </>
          );
        })
        }
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutterRow" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (<>
              <Button type="primary" onClick={onPrint} style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
              {/* <Button type="primary" onClick={() => onCallback(items)} style={{ fontWeight: 'bold' }}>
                {lang.exportCSV}
              </Button> */}
            </>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

ReceiptGiftProductPrint.propTypes = {
  items: PropTypes.array,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  onPrint: PropTypes.func,
};

export default ReceiptGiftProductPrint;
