import request from 'utils/request';
import environment from 'environment';

export async function bookingList(payload) {
    payload.orderGiftType = 'EXPORT_INTERNAL';
    return request(environment.api.OrdersGift + 'get-all', payload, 'GET');
}

export async function createExportInternalGift(payload) {
    return request(environment.api.OrdersGift + 'create-export-internal', payload, 'POST');
}

export async function exportInternalGiftDetail(id) {
    return request(environment.api.OrdersGift + `get-one/${id}`, {}, 'GET');
  }
  
  export async function orderUpdate(payload) {
    return request(environment.api.OrdersGift + 'update', payload, 'PUT');
}

export async function getProductTakenByOrderId(payload) {
    return request(environment.api.productGifts + '/get-all', payload, 'GET');
}

// export async function bookingDetail(id) {
//     return request(environment.api.OrdersGift + `get-one/${id}`, {}, 'GET');
// }
// export async function pickingList(id) {
//     return true;
// }
export async function suggestLocationByOrder(payload) {
    return request(environment.api.products + 'recommend', payload, 'GET');
}

// export async function pickingDetail(payload) {
//     return request(environment.api.receiptDetail, payload, 'GET');
// }

// export async function pickingCreate(payload) {
//     return request(environment.api.receiptCreate, payload, 'POST');
// }

// export async function pickingUpdate(payload) {
//     return request(environment.api.receiptUpdate, payload, 'PUT');
// }
// export async function pickingDelete(payload) {
//     //return request(environment.api.productDelete, payload, 'DELETE');
//     return new Promise((resolve, reject) => {
//         resolve('1');
//     });
// }
// export async function productScan(payload) {
//     return request(environment.api.products + 'scan', payload, 'POST');
// }
export async function productUpdateStatus(payload) {
    return request(environment.api.products + 'updates', payload, 'POST');
}


export async function productSplit(payload) {
    return request(environment.api.products + 'split', payload, 'POST');
}
// export async function productsList(payload) {
//     return request(environment.api.productTrxList, payload, 'GET');
// }
export async function orderCheck(payload) {
    return request(environment.api.OrdersGift + 'check/picking', payload, 'POST');
}
export async function suggestLock(payload) {
    return request(environment.api.products + 'lock-recommend', payload, 'POST');
}
export async function createProduct(payload) {
    return request(environment.api.products + 'create', payload, 'POST');
}
export async function importExel(payload, warehouseId) {
    return request(environment.api.OrdersGift + 'create/excel/' + warehouseId, payload, 'POST');
}
export async function getAllProudctLocator(payload) {
    return request(environment.api.masterProductsGiftLocators + 'get-all', payload, 'GET');
  }
// export async function suggestUnLock(payload) {
//     return request(environment.api.products + 'unlock-recommend', payload, 'POST');
// }
// export async function productPicking(payload) {
//     return request(environment.api.products + 'productPicking', payload, 'POST');
// }
