import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Form, Card, Row, Col, Input, message as Alert, message, Spin } from 'antd';
import { CheckOutlined, DownOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bookingList, createProduct, orderCheck, orderUpdateStatus, productSplit, productUpdateStatus, suggestLock } from './picking.service';
import BookingOrderForm from './booking-order.form';
import SuggestLocationForm from './suggest-location.form';
import './style.less';
import { bookingOrderSet, pickedProductSet, pickingListSet } from 'providers/OutboundProvider/actions';
import TableCustom from 'components/TableCustom/table';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment-timezone';
import { saleorderDetail } from 'pages/dashboard/configuration/sale-order/sale-order.service';
import { productPicking } from '../packaging/packaging.service';
import { receiptList } from 'pages/dashboard/inbound/receipt/receipt.service';
import { formatDate } from 'utils/constants';

const tableScrollHeightArea = window.innerHeight - 200; // px

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PickingCreate = () => {
  const navigate = useNavigate();
  const { pickingOrder, pickingProducts, pickingScanProducts } = useSelector((state) => state.outbound);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isBookingOrderModal, setIsBookingOrderModal] = useState(false);
  const dispatch = useDispatch();
  const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [item, setItem] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [loadingExport, setLoadingExport] = useState(false);
  const [productPicked, setProductPicked] = useState([]);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [listBooking, setListBooking] = useState([]);
  const [listPicking, setListPicking] = useState({});
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  useEffect(() => {
    if (pickingOrder[0]?.group) {
      productPicking({ group: pickingOrder[0]?.group, warehouseId: currentWarehouse.id, status: 'PICKING' })
        .then(result => {
          setProductPicked(result.data);
          dispatch(pickedProductSet(result.data));
        })
        .catch((e) => { });
    } else {
      setProductPicked([]);
    }
  }, [pickingOrder, currentWarehouse, dispatch]);
  useEffect(() => {
    Promise.all([bookingList({ multipleStatus: 'NEW,LOCK', sortDirection: 'desc', sortBy: 'createDate', warehouseId: currentWarehouse?.id }),
    bookingList({ status: 'PENDING', sortDirection: 'desc', sortBy: 'createDate', warehouseId: currentWarehouse?.id })]).then((values) => {
      setTotalItems(values[0]?.totalItem);
      setListBooking(values[0]?.data);
      setListPicking(values[1]?.data?.reduce((prev, curr) => {
        if (curr?.group in prev) {
          prev[curr?.group].push(curr);
          return prev;
        }
        prev[curr?.group] = [curr];
        return prev;
      }, {}));
    }).catch((err) => message.error(err?.message));
  }, [currentWarehouse]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columnsBookingOrders = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      width: '15%',
      dataIndex: 'orderCode',
    },
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
      width: '15%',
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      width: '20%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.quantityCartton,
      dataIndex: 'quantityInCarton',
      key: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      dataIndex: 'quantityTin',
      key: 'quantityTin',
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
    // {
    //   title: lang.pickingquantity,
    //   dataIndex: 'pickingQuantity',
    //   key: 'pickingQuantity',
    //   width: '15%',
    //   render: (value) => value ? value : 0,
    // },
  ];
  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
    },
    {
      title: lang.productCode,
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => record?.masterProduct?.nameEN,
      width: '20%',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      render: rec => 'splitQuantityCartton' in rec ? rec.splitQuantityCartton : rec.quantityCartton,
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: rec => 'splitQuantityTin' in rec ? rec.splitQuantityTin : rec.quantityTin,
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: 'expireDate',
      render: val => val ? moment(val).format(formatDate) : '',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      render: value => value?.code,
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={async () => {
        await orderCheck({ ids: pickingOrder.map(Order => Order.id), userId: null });
        dispatch(bookingOrderSet({
          pickingOrder: [],
          pickingProducts: [],
        }));
        dispatch(pickingListSet([]));
        navigate('/dashboard/outbound/sale-order');
      }}
    >
      {lang.btnCancel}
    </Button>,
    // <Button
    //   key="btn-export"
    //   type="primary"
    //   ghost
    //   loading={loadingExport}
    //   onClick={async () => {
    //     setLoadingExport(true);
    //     const dataProduct = [...pickingScanProducts, ...productPicked]?.reduce((prev, curr) => {
    //       const finded = prev?.find(pre => pre?.masterProduct?.barCode === curr?.masterProduct?.barCode);
    //       if (finded) {
    //         if (curr?.splitQuantity) {
    //           finded.total += curr.splitQuantity;
    //         } else finded.total += curr.total;
    //         return prev;
    //       }
    //       if (curr?.splitQuantity) {
    //         const objectTemp = Object.assign({}, curr, { totalQuantity: curr.splitQuantity });
    //         return [...prev, objectTemp];
    //       }
    //       return [...prev, curr];
    //     }, []);
    //     const content = `
    //   <div style="display: grid ;line-height: 0.5; width: 900px; font-family: "Times New Roman", Times, serif;">
    //   <div style="display: flex; justify-content: space-between;">
    //     <div>
    //       <h3>Công ty TNHH PinNow Việt Nam</h3>
    //       <p>134 Bạch Đằng, Phường 2, Q.Tân Bình,</p>
    //       <p>0316954732</p>
    //     </div>
    //     <div style="text-align: center;">
    //       <h3>Mẫu số: 01-VT*</h3>
    //       <p>Ban hành theo thông tư</p>
    //       <p>số 200/2014/TT-BTC</p>
    //       <p>ngày 22/12/2014 của Bộ</p>
    //     </div>
    //   </div>
    //   <div style="text-align: center;">
    //     <h1>PHIẾU XUẤT KHO</h1>
    //     <p>Ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')}</p>
    //     <p>Số phiếu:</p>
    //   </div>
    //   <div style="display: flex; flex-flow: wrap">
    //     <div style="width: 33%; margin-bottom: 10px;">Họ và tên người giao:</div>
    //     <div style="width: 33%; margin-bottom: 10px;">MS Tài xế:</div>
    //     <div style="width: 33%; margin-bottom: 10px;">MS xe: ${[...new Set(pickingOrder?.filter(order => order?.boothCode)?.map(order => order.boothCode))]?.join()}</div>
    //     <div style="width: 33%; margin-bottom: 10px;">Xuất tại kho:</div>
    //     <div style="width: 33%; margin-bottom: 10px;">Ngày giờ in:</div>
    //   </div>
    // <div style="margin-bottom: 10px">Nội dung: Xuất háng bán ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')} hàng theo menu….</div>
    //   <div>
    //     <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
    //       <thead>
    //         <tr style="height: 30px;">
    //           <th>STT</th>
    //           <th>Tên hàng hóa</th>
    //           <th>Mã hàng/ Itemcode</th>
    //           <th>Đơn vị tính</th>
    //           <th>Số lượng</th>
    //           <th>Block</th>
    //           <th>Shelf</th>
    //           <th>Rack</th>
    //           <th>Ghi chú</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr style="text-align: center; height: 20px;">
    //           <td>1</td>
    //           <td>2</td>
    //           <td>3</td>
    //           <td>4</td>
    //           <td>5</td>
    //           <td>6</td>
    //           <td>7</td>
    //           <td>8</td>
    //           <td>9</td>
    //         </tr>
    //         ${dataProduct?.map((product, index) => {
    //       return `<tr style="text-align: center; height: 30px;">
    //               <td>${index + 1}</td>
    //               <td>${product.masterProduct.name}</td>
    //               <td>${product.masterProduct.barCode}</td>
    //               <td>${product.masterProduct.DVT}</td>
    //               <td>${product.totalQuantity}</td>
    //               <td>${product?.block?.name}</td>
    //               <td>${product?.rack?.shelf?.name}</td>
    //               <td>${product?.rack?.code}</td>
    //               <td></td>
    //           </tr>`
    //     }).join(' \n')}
    //   <tr style="text-align: center; height: 30px;">
    //           <td></td>
    //           <td>Cộng</td>
    //           <td></td>
    //           <td></td>
    //           <td>${dataProduct?.reduce((prev, curr) => prev + curr.totalQuantity, 0)}</td>
    //           <td></td>
    //           <td></td>
    //           <td></td>
    //         </tr>
    //       </tbody>
    //     </table>
    //     <div style="margin-top: 10px">
    //       <b><u>Xuất hàng theo SO:</u></b> Tổng ${pickingOrder.length} SO
    //     </div>
    //     <div style="display: flex; flex-flow: wrap; margin-top: 10px; text-align: center">
    //       ${pickingOrder?.map(order => `<div style="width: 33%; margin-bottom: 10px;">${order.code}</div>`).join(' \n')}
    //     </div>
    //     <div style="display: flex; justify-content: space-between; margin-top: 10px">
    //         <div style="text-align: center;">
    //           <p style="font-weight: bold;">Người lập phiếu</p>
    //           <p style="font-style: italic;">(Ký, họ tên)</p>
    //         </div>
    //         <div style="text-align: center;">
    //           <p style="font-weight: bold;">Người giao hàng</p>
    //           <p style="font-style: italic;">(Ký, họ tên)</p>
    //         </div>
    //         <div style="text-align: center;">
    //           <p style="font-weight: bold;">Thủ kho</p>
    //           <p style="font-style: italic;">(Ký, họ tên)</p>
    //         </div>
    //         <div style="text-align: center;">
    //           <p style="font-weight: bold;">Giám đốc nhà máy</p>
    //           <p style="font-style: italic;">(Ký, họ tên)</p>
    //         </div>
    //       </div>
    //   </div>
    // </div>`;
    //     var template = document.createElement('div');
    //     template.innerHTML = content;
    //     document.body.appendChild(template);
    //     await html2canvas(template, { width: 1000, scale: 3, }).then((canvas) => {
    //       let imgWidth = 208;
    //       let imgHeight = canvas.height * imgWidth / canvas.width;
    //       var imgData = canvas.toDataURL("image/png");
    //       const pdf = new jsPdf("p", "mm", "a4");
    //       pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
    //       pdf.save(`ProductsPicking${moment().format('DDMMYYYY')}.pdf`);
    //     });
    //     document.body.removeChild(template);
    //     setLoadingExport(false);
    //   }}
    //   disabled={!(pickingScanProducts?.length > 0) && !(productPicked?.length > 0)}
    // >
    //   {lang.exportPDF}
    // </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        try {
          setLoading(true);
          const productsSplit = pickingScanProducts?.filter(product => 'splitQuantityCartton' in product && 'splitQuantityTin' in product)?.map(product => {
            return {
              id: product.id,
              quantityTin: product.splitQuantityTin,
              quantityCartton: product.splitQuantityCartton,
              orderId: product.orderId,
              locator: product.locator,
              locatorTo: product.locatorTo,
            };
          });
          // console.log(pickingScanProducts)
          if (productsSplit.length > 0) {
            const response = await Promise.all(productsSplit.map(splitProduct => productSplit(splitProduct).then(result => {
              result.orderId = splitProduct.orderId;
              result.locator = splitProduct.locator;
              result.locatorTo = splitProduct.locatorTo;
              return result;
            })));
            pickingScanProducts.push(...response);
          }
          const orderPending = pickingOrder?.filter(order => order?.productOrders?.some(productorder => {
            const total = [...productPicked, ...pickingScanProducts]?.filter(product => product?.masterProduct?.code3A === productorder?.masterProduct?.code3A && !('splitQuantityCartton' in product) && !('splitQuantityTin' in product) && product?.orderId === order.id)?.reduce((prev, cur) => {
              prev.cartton += cur.quantityCartton;
              prev.tins += cur.quantityTin;
              return prev;
            }, { cartton: 0, tins: 0 });
            if (total.cartton < productorder.quantityInCarton || total.tins < productorder.quantity) return true;
            return false;
          }));
          if (orderPending.length > 0 && !orderPending[0]?.group) {
            const orderUpdatesPending = { ids: orderPending.map(Order => Order.id), status: 'PENDING' };
            await orderUpdateStatus(orderUpdatesPending);
          }
          const ids = orderPending.length === 0 ? pickingOrder : pickingOrder?.filter(order => !orderPending.some(orderP => orderP.id === order.id));
          if (ids.length > 0) {
            const orderUpdates = { ids: ids.map(Order => Order.id), status: 'PICKING' };
            await orderUpdateStatus(orderUpdates);
          }
          const internalOrders = pickingOrder?.filter(order => order.orderType === 'EXPORT_INTERNAL' || order.orderType === 'EXPORT_BRANCH');
          if (internalOrders.length > 0) {
            const orderDetail = await saleorderDetail(internalOrders[0]?.id);
            // const products = await productUpdateStatus({ ids: pickingScanProducts?.filter(product => !('splitQuantityCartton' in product) && !('splitQuantityTin' in product))?.map(product => product.id), status: 'PICKING', group: orderDetail?.group });
            const receipt = await receiptList({ orderNumber: orderDetail?.code });
            const products = await Promise.all(pickingScanProducts?.filter(product => !('splitQuantityCartton' in product) && !('splitQuantityTin' in product))?.reduce((prev, curr) => {
              if (prev?.orderId === curr?.orderId) {
                prev.ids = [...prev.ids, curr.id];
                return prev;
              }
              return [...prev, { orderId: curr.orderId, ids: [curr.id] }];
            }, []).map(orders => productUpdateStatus({ ids: orders.ids, status: 'PICKING', group: orderDetail?.group, orderId: orders.orderId })));
            await Promise.all(products?.reduce((prev, curr) => [...prev, ...curr])?.map(product => {
              delete product.warehouseId;
              delete product.status;
              delete product.id;
              const receiptId = receipt?.data[0].id;
              delete product?.receipt;
              delete product.rack;
              delete product.zone;
              delete product.block;
              const pickingScanProduct = pickingScanProducts?.find(productscan => productscan?.code === product?.code);
              product.locatorFromId = pickingScanProduct?.locator?.id;
              return createProduct({
                ...product,
                receiptId,
                warehouseId: pickingScanProduct?.locatorTo?.warehouseId,
              });
            }));
          } else {
            const orderDetail = await saleorderDetail(pickingOrder[0]?.id);
            productUpdateStatus({ ids: pickingScanProducts?.filter(product => !('splitQuantityCartton' in product) && !('splitQuantityTin' in product))?.map(product => product.id), status: 'PICKING', group: orderDetail?.group, orderId: orderDetail?.id });
          }
          dispatch(bookingOrderSet({
            pickingOrder: [],
            pickingProducts: [],
          }));
          dispatch(pickingListSet([]));
          setLoading(false);
          navigate('/dashboard/outbound/sale-order');
        } catch (error) {
          Alert.error(error.message);
          setLoading(false);
        }
      }}
      disabled={!(pickingScanProducts?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];
  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '/',
      breadcrumbName: lang.pickingList,
    },
  ];
  return (
    <Layout className="receipt-list">
      <Spin spinning={loading}>
        <PageHeader
          ghost={false}
          title={lang.createPicking}
          extra={extraButton}
          breadcrumb={{
            routes,
            itemRender: breadcrumbItemRender,
          }}
          className="customPageHeader"
        //onBack={onBack}
        />
        <Layout.Content style={{ margin: '12px 24px' }}>
          <Card size="small" className="cardSearch">
            <Form
              colon={false}
              layout="vertical"
              name="productEditForm"
              initialValues={{}}
            >
              <Row gutter={24}>
                <Col>
                  <h3>{`${lang.labelBooking}`}</h3>
                </Col>
                <Col lg={{ span: 6 }} xs={{ span: 12 }}>
                  <Input value={pickingOrder?.map(order => order?.code)} placeholder={lang.orderCode} style={{ width: '100%' }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setIsBookingOrderModal(true);
                    // }}
                    suffix={<DownOutlined />} />
                </Col>
              </Row>
              <Row><span className="spanCustomHeadForOutBound">{lang.subTitleOutboundCreatePickUpNotify}</span></Row>
            </Form>
          </Card>
          <TableCustom
            rowKey="id"
            bordered
            columns={columnsBookingOrders}
            dataSource={pickingProducts}
            scroll={{ x: 600, y: tableScrollHeightArea }}
            pagination={{
              showSizeChanger: true,
            }}
          />
          <TableCustom
            title={() => <Row>
              <Col span={12}><h3>{lang.pickingList}</h3></Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {/* <Button
                  type="primary"
                  onClick={() => {
                    dispatch(bookingOrderSet({
                      pickingOrder: pickingOrder,
                      pickingProducts: pickingProducts?.map(product => {
                        const productFill = productPicked?.filter(productpick => productpick?.masterProduct?.code3A === product?.masterProduct?.code3A);
                        const total = productFill?.reduce((prev, curr) => {
                          prev.tins += curr.quantityTin;
                          prev.cartton += curr.quantityCartton;
                          return prev;
                        }, { tins: 0, cartton: 0 });
                        product.pickedQuantityCartton = total.cartton;
                        product.pickedQuantityTin = total.tins;
                        return product;
                      }),
                    }));
                    navigate('scan');
                  }}
                >
                  {lang.scan}
                  <ScanOutlined />
                </Button> */}
                <Button
                  type="primary"
                  onClick={() => {
                    setItem(pickingOrder);
                    setIsSuggestLocationModal(true);
                  }}
                  style={{ marginLeft: 8 }}
                  disabled={!(pickingProducts?.length > 0)}
                >
                  {lang.suggestLocation}
                  <PlusOutlined />
                </Button>
                {/* <Button
                type="primary"
                ghost
                onClick={() => console.log('Import CSV')}
                style={{ marginLeft: 8 }}
              >
                Import CSV
              </Button> */}
                {selectedRowKeys.length > 0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: lang.doYouWantToDeleteTheseItems,
                        icon: <ExclamationCircleOutlined />,
                        //content: 'Some descriptions',
                        onOk() {
                          const productFill = pickingScanProducts.filter(product => selectedRowKeys.indexOf(product.id) === -1);
                          dispatch(pickingListSet(productFill));
                          setSelectedRowKeys([]);
                        },
                        onCancel() {

                        },
                        okText: lang.okText,
                        cancelText: lang.cancelText,
                      });
                    }}
                    style={{ marginLeft: 8 }}
                  >
                    {lang.btnDelete}
                    <DeleteOutlined />
                  </Button>
                )}
              </Col>
            </Row>}
            rowKey="id"
            rowSelection={rowSelection}
            bordered
            columns={columnsPickingList}
            dataSource={pickingScanProducts ? [...pickingScanProducts, ...productPicked] : [...productPicked]}
            pagination={{
              showSizeChanger: true,
            }}
            scroll={{ x: 1200, y: tableScrollHeightArea }}
          />

        </Layout.Content>
      </Spin>
      <Modal
        visible={isBookingOrderModal}
        title={lang.titleModalBooking}
        onCancel={() => setIsBookingOrderModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <BookingOrderForm
          onCallback={(value) => {
            setIsBookingOrderModal(false);
          }}
          onCancel={() => setIsBookingOrderModal(false)}
          listBooking={listBooking}
          listPicking={listPicking}
        />
      </Modal>
      <Modal
        visible={isSuggestLocationModal}
        title={lang.suggestLocation}
        onCancel={() => setIsSuggestLocationModal(false)}
        destroyOnClose={true}
        width={1220}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <SuggestLocationForm
          item={item}
          totalItems={totalItems}
          productOrders={pickingProducts}
          onCallback={async (values) => {
            const content = `
        <div style="display: grid ;line-height: 0.5; width: 900px; font-family: "Times New Roman", Times, serif;">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <h3>Công ty TNHH PinNow Việt Nam</h3>
            <p>134 Bạch Đằng, Phường 2, Q.Tân Bình,</p>
            <p>0316954732</p>
          </div>
          <div style="text-align: center;">
            <h3>Mẫu số: 01-VT*</h3>
            <p>Ban hành theo thông tư</p>
            <p>số 200/2014/TT-BTC</p>
            <p>ngày 22/12/2014 của Bộ</p>
          </div>
        </div>
        <div style="text-align: center;">
          <h1>DANH SÁCH GỢI Ý SẢN PHẨM</h1>
          <p>Ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')}</p>
        </div>
        <div>
          <table border="1" style="width: 100%; border-collapse: collapse;margin-top: 10px; line-height: 1;">
            <thead>
              <tr style="height: 40px;">
                <th>STT</th>
                <th>Mã sản phẩm</th>
                <th>Mã lô sản phẩm</th>
                <th>Nhóm hàng</th>
                <th>Tên sản phẩm</th>
                <th>Block</th>
                <th>Shelf</th>
                <th>Rack</th>
                <th>Số lượng</th>
              </tr>
            </thead>
            <tbody>
              ${values?.map((product, index) => {
              return `<tr style="text-align: center; height: 40px;">
                    <td>${index + 1}</td>
                    <td>${product.masterProduct.barCode}</td>
                    <td>${product.code}</td>
                    <td>${product.masterProduct.productCategory?.name}</td>
                    <td>${product.masterProduct.name}</td>
                    <td>${product?.block?.name}</td>
                    <td>${product?.rack?.shelf?.name}</td>
                    <td>${product?.rack?.code}</td>
                    <td>${product?.totalQuantity}</td>
                </tr>`;
            }).join(' \n')}
            </tbody>
          </table>
        </div>
      </div>`;
            var template = document.createElement('div');
            template.innerHTML = content;
            document.body.appendChild(template);
            await html2canvas(template, { width: 1000, scale: 3 }).then((canvas) => {
              const imgWidth = 208;
              const imgHeight = canvas.height * imgWidth / canvas.width;
              var imgData = canvas.toDataURL('image/png');
              // eslint-disable-next-line new-cap
              const pdf = new jsPdf('p', 'mm', 'a4');
              pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
              pdf.save(`SuggestProducts${moment().format('DDMMYYYY')}.pdf`);
            });
            document.body.removeChild(template);
            setIsSuggestLocationModal(false);
          }}
          onCancel={() => setIsSuggestLocationModal(false)}
          onLock={async (values) => {
            try {
              await Promise.all(values?.map(value => suggestLock(value)), orderUpdateStatus({ ids: pickingOrder?.map(order => order?.id), status: 'LOCK' }));
              message.success(lang.lockproductSuccessfully);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          }}
          productPicked={productPicked}
        />
      </Modal>
    </Layout>
  );
};

export default PickingCreate;
