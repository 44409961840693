import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  SET_CURRENT_USER,
  SET_CURRENT_WAREHOUSE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_SUCCESS,
} from './types';

const INITIAL_STATE = {
  user: {},
  currentWarehouse: {},
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN: case LOGIN_SUCCESS: case LOGIN_FAIL:
    case LOGOUT: case LOGOUT_FAIL:
    case CHANGE_PASSWORD: case CHANGE_PASSWORD_SUCCESS: case CHANGE_PASSWORD_FAIL:
    case FORGOT_PASSWORD: case FORGOT_PASSWORD_SUCCESS: case FORGOT_PASSWORD_FAIL:
    case UPLOAD_IMAGE: case UPLOAD_IMAGE_SUCCESS: case UPLOAD_IMAGE_FAIL:
    return {
      state,
    };
    case LOGOUT_SUCCESS:
      return {
        user: undefined,
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_CURRENT_WAREHOUSE:
      return {
        ...state,
        currentWarehouse: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
