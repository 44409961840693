import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';

//Receipt Material
export async function receiptMaterial(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.receiptMaterial}/get-all/?${params}`, {}, 'GET');
}
export async function receiptMaterialCreate(payload) {
    return request(environment.api.receiptMaterial + '/create', payload, 'POST');
}
export async function receiptMaterialDetail(id) {
    return request(`${environment.api.receiptMaterial}/get-one/${id}`, {}, 'GET');
}
export async function confirmReceiptMatrial(id) {
  return request(`${environment.api.receiptMaterial}/confirm/${id}`, {}, 'GET');
}
export async function deleteReceiptBomProduction(id) {
  return request(`${environment.api.receiptMaterial}/delete/${id}`, {}, 'DELETE');
}
export async function updateReceiptMater(payload) {
  return request(`${environment.api.receiptMaterial}/update`, payload, 'PUT');
}

//Receipt Material product
export async function updateReceiptMaterProduct(payload) {
  return request(`${environment.api.receiptMaterialProduct}/update`, payload, 'PUT');
}



