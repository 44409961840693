import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import exportFromJSON from 'export-from-json';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Tag, Input } from 'antd';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import './style.less';
import {  updateProductOrder,  } from '../../configuration/sale-order/sale-order.service';
import { defaultPage, formatDate, formatDateFull, STATUS_INTERNAL_TRANFER, STATUS_OUTBOUND,  } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import ReceiptCreateProductEditForm from '../../configuration/sale-order/sale-order-create-product-edit.form';
import { exportInternalGiftDetail ,orderUpdate,getProductTakenByOrderId } from './picking.service';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};
const ReceiptDetail = () => {
  const { saleorderID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [item] = useState();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [params] = useState({ ...defaultPage });
  const [productLock, setProductLock] = useState([]);
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isManager = _get(user, 'roles', '').includes('manager');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isOutboundStaff = _get(user, 'roles', '').includes('outboundStaff');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  const [description, setDescription] = useState();
  const [sortedInfo] = useState(defaultSort);

  useEffect(() => {
    setLoading(true);
    exportInternalGiftDetail(saleorderID).then(result =>{
      setSaleorder(result);
      setDescription(result?.description);
      setProductOrder(result?.productGiftOrders)
      setLoading(false)
    });

    getProductTakenByOrderId({ warehouseId: currentWarehouse?.id, orderId: saleorderID })
    .then(data => {
      setProductLock(data?.data)
      setLoading(false)
    })
    .catch((e) => setLoading(false));
  }, [saleorderID, deleteProduct, sortedInfo, params, currentWarehouse]);

      const columns = [
        {
          title: lang.defaultCode,
          dataIndex: ['bomGift', 'code'],
          key: 'code'},
        {
          title: lang.productName,
          dataIndex: ['bomGift', 'name'],
          key: 'name',
        },
        {
          title: lang.Locator,
          key: 'locator',
          dataIndex: ['locator', 'locator'],
        },
        {
          title: lang.quantity,
          key: 'quantity',
          dataIndex: 'quantity',
        },


      ];
    
  
  const columnsProduct = [
    {
      title: lang.defaultCode,
      dataIndex: ['bomGift', 'code'],
      key: 'code'},
    {
      title: lang.productName,
      dataIndex: ['bomGift', 'name'],
      key: 'name',
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: `${lang.pickingquantity} `,
      key: 'quantityTinTake',
      dataIndex:'quantity',
    },

    
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      // dataIndex: 'rack',
      // width: '6%',
      render: record => {
        if (record?.status === 'STORED') {
          return record?.rack?.code
        } if (['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1 && record?.status === 'PICKING') {
          return record?.rack?.code
        } else {
          return `${record?.rack?.code} (Gợi ý)`
        }
      },
    },
    {
      title: lang.status + ' QR Code',
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (value) => {
        if (['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1) {
          if (value === 'PICKING') {
            return (
              <Tag>{'Trong kho'}</Tag>
            );
          } else if (value === 'VAS') {
            return (
              <Tag>{'VAS'}</Tag>
            );
          }
          
        } else {
          return (
            <Tag>{lang[value]}</Tag>
          );
        }
      },
    },
    {
      title: 'User Picked',
      key: 'userPicked',
      dataIndex: 'userName',
      hideInTable: ['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1
    },
  ];
  const extraButton = [
    <Button
      key="btn-back"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1)
      }}
    >
      {'Back'}
    </Button>,
    ['NEW', 'CONFIRM'].indexOf(saleorder?.status) > -1 && saleorder?.orderGiftType !== 'EXPORT_SALE' && <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        Modal.confirm({
          title: 'Bạn có chắc chắn muốn hủy lệnh sản xuất?',
          async onOk() {
            try {
              if (saleorder?.status !== 'NEW')
                await orderUpdate({ id: saleorder?.id, status: 'NEW' });
              else await orderUpdate({ id: saleorder?.id, status: 'CANCELLED', internalTranferCode: saleorder?.internalTranferCode });
              message.success(lang.updateSuccessfully);
              navigate(-1);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          },
        });
      }}
    >
      {lang.btnCancel}
    </Button>,
    !isPicker && <Button
      key="btn-save"
      type="primary"
      disabled={!description}
      onClick={async () => {
        try {
          setLoading(true);
          await orderUpdate({ id: saleorder?.id, description });
          exportInternalGiftDetail(saleorderID)
            .then(result => {
              setSaleorder(result);
              setDescription(result?.description);
              setLoading(false);
            })
            .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
            .catch((e) => setLoading(false));
          message.success(lang.updateSuccessfully);
          return true;
        } catch (error) {
          message.error(error.message);
          setLoading(false);
          return false;
        }
      }}
    >
      {lang.btnSave}
    </Button>,
    saleorder?.orderGiftType === 'EXPORT_SALE' && <Button
      key="btn-spicking-slip"
      type="primary"
      // ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const content = `
        <div style="display: grid ;line-height: 1.5;width: 950px; font-size: 18px; font-family: 'Times New Roman', Times, serif;">
        <!-- <div style="text-align:right; width: 100%; padding: 10px">
          <p><b style="padding: 10px">Ngày in: 22/08/2022 </b></p>
        </div> -->
        <div style="display: flex; height: 120px;">
          <div style="width: 80%; text-align: center; ">
            <h1>Picking Slip</h1>
          </div>
        </div>
        <div style="display: flex; width: 100%;">
          <div style="width: 25%;">
            <p><b>Số đơn hàng:</b> </p>
            <p><b>Ngày</b>: </p>
            <p><b>Họ tên khách hàng</b>: </p>
            <p><b>Nhân viên bán hàng</b>: </p>
          </div>
          <div style="width: 25%;">
            <p>${saleorder?.documentNo ? saleorder?.documentNo : ''}</p>
            <p>${saleorder?.dateOrdered ? moment(saleorder?.dateOrdered, formatDateFull).format(formatDate) : ''}</p>
            <p>${saleorder?.nameCustomer ? saleorder?.nameCustomer : ''}</p>
            <p>${saleorder?.userContact ? saleorder?.userContact : ''}</p>
          </div>
          <div style="width: 25%;">
            <p><b>Delivery Note No:</b> </p>
            <p><b>Order Type</b>: </p>
            <p><b>Order Reference</b>: </p>
            <p><b>Locator</b>: </p>
          </div>
          <div style="width: 25%;">
            <p>${saleorder?.code ? saleorder?.code : ''}</p>
            <p>${saleorder?.orderGiftType ? saleorder?.orderGiftType : ''}</p>
            <p>${saleorder?.orderReference ? saleorder?.orderReference : ''}</p>
            <p>${saleorder?.locator?.locatorName ? saleorder?.locator?.locatorName : ''} - ${saleorder?.locator?.locator ? saleorder?.locator?.locator : ''}</p>
          </div>
        </div>
        <div>
          <p> <b>Địa chỉ: ${saleorder?.shipAddress}</b> </p>
        </div>
        <div style="min-height: 1000px;">
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style=" background-color: #f5f5f5; ">
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">No</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Code</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Name</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Short</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInCart</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInTin</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lot & Exp Date</div></th>
              </tr>
            </thead>
            <tbody>

            ${productOrder?.map((product, index) => {
          return `<tr style="text-align: center;">
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${index + 1}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.code3A}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.nameEN}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.shortName}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton ? product?.quantityInCarton : ''}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityTin ? product?.quantityTin : ''}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">&lt;${product?.lot ? product?.lot : ''}&gt;_${product?.expireDate ? moment(product?.expireDate, formatDateFull).format(formatDate) : ''}</div></td>
                      </tr>`;
        }).join(' \n')}
            </tbody>
          </table>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PhieuPickingSlip${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    // disabled={saleorder?.orderGiftType !== 'EXPORT_INTERNAL'}
    >
      {lang.exportSpickingSlip}
    </Button>,
    // saleorder?.orderGiftType === 'EXPORT_INTERNAL' && !isPicker && <Button
    //   // disabled={saleorder?.status !== 'EXPORTED'}
    //   key="btn-export"
    //   type="primary"
    //   // ghost
    //   loading={loadingExport}
    //   onClick={async () => {
    //     setLoadingExport(true);
    //     const content = `
    //   <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
    //     <div style="display: flex; height: 120px;">
    //       <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
    //         <img src="${logog3A}" style=" height: 100%; width: 100%" />
    //       </div>
    //       <div style="width: 60%; text-align: center; padding-top: 7vh; ">
    //         <h1>PHIẾU CHUYỂN KHO NỘI BỘ</h1>
    //       </div>
    //     </div>
    //     <div style="display: flex; justify-content: space-between;">
    //       <div style="display: grid;">
    //         <p> <b>Số phiếu chuyển kho nội bộ: ${saleorder?.internalTranferCode ? saleorder?.internalTranferCode : ''} </b> </p>
    //         <p> <b>Số PXKKVCNB: </b></p>
    //         <p> <b>Diễn dãi:${saleorder?.description ? saleorder?.description : ''}</b></p>
    //       </div>
    //       <div style="display: grid;">
    //         <p> <b>Ngày in: ${moment().format(formatDatetime)} </b> </p>
    //         <p> <b>Ngày chuyển: ${moment().format(formatDate)}</b> </p>
    //         <p> <b>Ngày hoàn thành:</b> </p>
    //       </div>
    //     </div>
    //     <div style="min-height: 1000px;">
    //       <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
    //         <thead>
    //           <tr style=" background-color: #009ada; ">
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Mã Abbott</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Mã 3A</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">ShortName</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Tên sản phẩm</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Số lô & Hạn dùng</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Từ kho</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Đến kho</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Thùng</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lon</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Hỏng</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Móp</div></th> 
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lỗi</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Thiếu</div></th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //         ${saleorder?.productOrders.map((product) => {
    //       // const productfind = saleorder?.productOrders?.find(productOrder => productOrder.masterProduct.id === product.masterProduct.id);
    //       return `<tr style="text-align: center;">
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.abbottCode16 ? product?.masterProduct?.abbottCode16 : ''}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.code3A ? product?.masterProduct?.code3A : ''}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.shortName}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.nameEN}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">&lt;${product?.lot}&gt;_${moment(product?.expireDate, formatDateFull).format(formatDate)}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${`${product?.locator ? product?.locator.locatorName : ''} - ${product?.locator ? product?.locator.locator : ''}`}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${`${saleorder?.locator ? saleorder?.locator.locatorName : ''} - ${saleorder?.locator ? saleorder?.locator.locator : ''}`}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton ? product?.quantityInCarton : '' || product.quantityCartton ? product.quantityCartton : ''}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;">${product.quantityTin ? product.quantityTin : ''}</div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td> 
    //                       <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
    //                   </tr>`;
    //     }).join(' \n')}
    //           <tr>
    //             <td colSpan="6">
    //               <b style="min-height: 30px; display: flex;">Tổng</b>
    //             </td>
    //             <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
    //             <td style="font-weight: bold;">${productLock?.reduce((prev, curr) => { prev += curr.quantityCartton; return prev; }, 0)}</td>
    //             <td style="font-weight: bold;">${productLock?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0)}</td>
    //           </tr>
    //         </tbody>
    //       </table>
    //     </div>
    //     <div style="display: flex; justify-content: space-between;">
    //       <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
    //         <p> <b>Nhân viên soạn hàng</b> </p>
    //         <p> <b>(Ký, Họ tên)</b> </p>
    //       </div>
    //       <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
    //         <p> <b>Thủ kho</b> </p>
    //         <p> <b>(Ký, Họ tên)</b> </p>
    //       </div>
    //       <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
    //         <p> <b>Người lập bảng</b> </p>
    //         <p> <b>(Ký, Họ tên)</b> </p>
    //       </div>
    //     </div>
    //   </div>`;
    //     var template = document.createElement('div');
    //     template.innerHTML = content;
    //     document.body.appendChild(template);
    //     await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
    //       const imgWidth = 208;
    //       const imgHeight = canvas.height * imgWidth / canvas.width;
    //       var imgData = canvas.toDataURL('image/png');
    //       // eslint-disable-next-line new-cap
    //       const pdf = new jsPdf('p', 'mm', 'a4');
    //       pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
    //       pdf.save(`PhieuNoiBo${moment().format('DDMMYYYY')}.pdf`);
    //     });
    //     document.body.removeChild(template);
    //     setLoadingExport(false);
    //   }}
    // >
    //   {lang.exportPDF}
    // </Button>,
    saleorder?.orderGiftType === 'EXPORT_INTERNAL' && !isPicker && saleorder?.status === 'NEW' && <Button
      key="btn-confirm"
      type="primary"
      onClick={async () => {
        Modal.confirm({
          title: 'Bạn có chắc chắn muốn xác nhận không?',
          async onOk() {
            try {
              setLoading(true);
              await orderUpdate({ id: saleorder?.id, status: saleorder?.status === 'NEW' ? 'LOCK' : 'CONFIRM', assignAll: true });
              message.success('Xác nhận thành công!');
              navigate(-1)
              // saleorderDetail(saleorderID)
              //   .then(result => {
              //     setSaleorder(result);
              //     if (result?.status === 'LOCK') {
              //       productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
              //     } else if (result.orderGiftType === 'EXPORT_INTERNAL') {
              //       productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
              //     }
              //     if (result.status !== 'EXPORT') {
              //       setProductOrder(result?.productOrders);
              //       setLoading(false);
              //     } else {
              //       return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
              //     }
              //   })
              //   .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
             //   .catch((e) => setLoading(false));
            } catch (err) {
              setLoading(false);
              message.error(err.message);
            }
          },
          onCancel() { },

        });
      }}
      disabled={['CONFIRM1'].indexOf(saleorder?.status) !== -1 || (isIdm && saleorder?.status === 'NEW') || (isOutboundStaff && saleorder?.status !== 'NEW') || (isManager && saleorder?.status !== 'PICKING') || (isIdm && saleorder?.status === 'PICKING') || (isStocker && saleorder?.status === 'CONFIRM')}
    >
      {lang.btnConfirm}
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? {
      path: '/gift-internal-tranfer',
      breadcrumbName: lang.EXPORT_INTERNAL_GIFT,
    } : {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '',
      breadcrumbName: document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? `Chi tiết ${lang.EXPORT_INTERNAL_GIFT}` : lang.saleOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? `Chi tiết ${lang.EXPORT_INTERNAL}` : lang.saleOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 5, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.orderCode}>{saleorder?.documentNo}</Descriptions.Item>
              <Descriptions.Item span={1} label={lang.locatorTo}>{ `${saleorder?.locatorTransitCode?.locatorName} - ${saleorder?.locatorTransitCode?.locator}`}</Descriptions.Item>
              <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate ? saleorder?.movementDate : moment()).format(formatDate)}</Descriptions.Item>
              <Descriptions.Item label={lang.tranferReason}>{saleorder?.tranferReason}</Descriptions.Item>         

            <Descriptions.Item label={lang.status}>
              {saleorder?.orderGiftType === 'EXPORT_INTERNAL' ? STATUS_INTERNAL_TRANFER[saleorder?.status] : STATUS_OUTBOUND[saleorder?.status]}
            </Descriptions.Item>
            <Descriptions.Item label={lang.description}>
              <Input onChange={(val) => setDescription(val.target.value)} value={description}></Input>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          // columns={columns}
          dataSource={productOrder}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          columns={columns}
          // expandable={saleorder?.typeProductionOrder === 'PACKING' && {
          //   expandedRowRender,
          // }}
          scroll={{ x: 467 }}
        />
        {(!saleorder?.isConfirm || saleorder?.orderGiftType === 'EXPORT_INTERNAL' || saleorder?.orderGiftType === 'EXPORT_SALE') && <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{
            !saleorder?.isConfirm ? `${lang.productList} đã lấy` : 
            lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsProduct}
          dataSource={productLock}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 2600 }}
        />}
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={lang.editProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptCreateProductEditForm
          item={item}
          onCallback={async (value) => {
            try {
              await updateProductOrder({ id: value?.id, quantityInCarton: value?.quantityInCarton, quantityTin: value?.quantity });
              message.success(lang.updateSuccessfully);
              setDeleteProduct(!deleteProduct);
              setIsEditModal(false);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          }}
          onCancel={() => setIsEditModal(false)}
        // type={type}
        // locators={locators}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptDetail;
