import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';

export async function customerReturnList(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.customerReturn}/get-all?${params}`, {}, 'GET');
}

export async function customerReturnDetail(payload) {
  return request(`${environment.api.customerReturn}/get-one/${payload?.id}`, {}, 'GET');
}

export async function customerReturnCreate(payload) {
  return request(environment.api.customerReturn + '/create', payload, 'POST');
}

export async function customerReturnUpdate(payload) {
  return request(environment.api.customerReturn + '/update', payload, 'PUT');
}

export async function receiptConfirm(payload) {
  return request(environment.api.receiptConfirm, payload, 'PUT');
}

export async function customerReturnDelete(payload) {
  return request(environment.api.customerReturn, payload, 'POST');
}

export async function packageList(payload) {
  return request(`${environment.api.packageList}`, {}, 'GET');
}
export async function updateReceiptProduct(payload) {
  return request(`${environment.api.receiptProduct}/update`, payload, 'PUT');
}
export async function deleteProducts(payload) {
  return request(environment.api.products + 'deletes', payload, 'POST');
}
