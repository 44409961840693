import React, { useState } from 'react';
import { Col, Row, Button } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import Helper from 'utils/helpers';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';

const ReceiptProductPrint = ({ items, onCancel, onCallback, onPrint, physicalInventory }) => {
  // console.log(items)
  const [loading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  let dataId = items[0].masterProduct?.code3A;
  let newIndex = 0;
  return (
    <>
      <Row gutter={[16, 16]} className="scrollYStyle" id='print'>
        {
        items.map((value, index) => {
          if (dataId === value?.masterProduct.code3A) {
            newIndex++;
          } else {
            dataId = value?.masterProduct.code3A
            newIndex = 1
          }
          
          let name = '';
          if (physicalInventory === true) {
            name = '';
          } else {
            if (value?.locator?.area?.warehouseID === 'AVS') {
              name = 'Available for Sales';
            } else {
              name = 'ONHOLD';
            };
          }
          return (
            <>
              <div style={{ width: '1380px', height: '900px' }}>
                <table style={{ border: '2px solid #333', width: 'auto', borderCollapse: 'collapse', height: '100%' }} >
                  <tr>
                    <td style={{ width: '5%', borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', fontSize: '40px' }} rowSpan="2">ITEM</td>
                    <td style={{ width: '95%', borderBottom: '1px solid #333', borderRight: '1px solid #333', textAlign: 'center', fontSize: '100px', fontWeight: 'bold' }} colSpan="9">{value?.masterProduct?.shortName}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '95%', minHeight: '600px', borderBottom: '1px solid #333', borderRight: '1px solid #333', textAlign: 'center', fontSize: '60px', fontWeight: 'bold' }} colSpan="9">{value?.masterProduct?.nameEN}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '5%', borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', fontSize: '40px' }}>LOT NO:</td>
                    <td style={{ width: '65%', borderBottom: '1px solid #333', borderRight: '1px solid #333', textAlign: 'center', fontSize: '60px', fontWeight: 'bold' }} colSpan="7">{value?.lot}</td>
                    <td style={{ textAlign: 'center', borderTop: '1px solid #333', width: '30%' }} rowSpan="7" colSpan="2" >
                      {Helper.genarateQRcode([value?.code])}
                      <div style={{ fontWeight: 'bold', marginTop: '8px', fontSize: '45px' }}>{value?.code}</div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '5%', borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', fontSize: '40px' }}>DATE:</td>
                    <td style={{ width: '65%', borderBottom: '1px solid #333', textAlign: 'center', fontSize: '60px', fontWeight: 'bold', borderRight: '1px solid #333' }} colSpan="7">{value?.expireDate ? moment(value?.expireDate, formatDateFull).format(formatDate) : ''}</td>
                  </tr>
                  <tr>
                    <td rowSpan="2" style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', width: '5%', fontSize: '40px' }}>QTY:</td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '40px', width: '15%' }}>{value?.masterProduct?.tinPerCarton}</td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '40px', width: '7.5%' }} rowSpan="2">x</td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '40px', width: '15%' }} colSpan="2">{value?.quantityCartton}</td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '40px', width: '7.5%' }} rowSpan="2">+</td>
                    <td style={{ borderBottom: '1px solid #333', textAlign: 'center', borderRight: '1px solid #333', width: '15%', fontWeight: 'bold', fontSize: '40px' }} colSpan="2">{value?.quantityTin}</td>
                  </tr>
                  <tr>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '40px', width: '15%' }}>Tins</td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '40px', width: '15%' }} colSpan="2">Carton</td>
                    <td style={{ borderBottom: '1px solid #333', textAlign: 'center', fontWeight: 'bold', fontSize: '40px', borderRight: '1px solid #333', width: '15%' }} colSpan="2">Tins</td>
                  </tr>
                  <tr>
                    {/* <td style= {{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'left', fontSize: '18px', width: '30%' }} colSpan="2">Ngày nhập: {moment(value?.importDate, formatDateFull).format(formatDate)}</td> */}
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '30px', width: '15%' }} colSpan="4">KHO: <span style={{ fontSize: '24px' }}>{value?.warehouse?.name}</span></td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'left', fontSize: '30px', width: '20%' }} colSpan="3">Pallet: {newIndex}</td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '30px', width: '10%' }}>Nhóm/<br />Chuyền số</td>
                  </tr>
                  <tr>
                    <td colSpan="5" rowSpan="3" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '40px', padding: '30px 0px' }}>{name}</td>
                    <td style={{ borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '20px' }} colSpan="2"></td>
                    <td style={{ borderBottom: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'center', borderLeft: '1px solid #333' }} rowSpan="2"></td>

                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }} colSpan="2" rowSpan="2"></td>
                  </tr>
                  <tr>
                    <td style={{ borderLeft: '1px solid #333', borderRight: '1px solid #333', fontWeight: 'bold', textAlign: 'left', fontSize: '30px' }}>CA:</td>
                    <td style={{ borderLeft: '1px solid #333', borderRight: '1px solid #333', borderTop: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '30px' }}>SỐ CONT:</td>
                    <td style={{ borderLeft: '1px solid #333', borderRight: '1px solid #333', borderTop: '1px solid #333', fontWeight: 'bold', textAlign: 'center', fontSize: '30px' }}>{value?.container}</td>
                  </tr>
                </table>
              </div>
              {index < items.length - 1 && <div style={{ breakBefore: 'always', pageBreakBefore: 'always', pageBreakInside: 'avoid' }}></div>}
            </>
          );
        })
        }
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutterRow" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (<>
              <Button type="primary" onClick={onPrint} style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
              <Button type="primary" onClick={() => onCallback(items)} style={{ fontWeight: 'bold' }}>
                {lang.exportCSV}
              </Button>
            </>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

ReceiptProductPrint.propTypes = {
  items: PropTypes.array,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  onPrint: PropTypes.func,
};

export default ReceiptProductPrint;
