import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Input, Form, Select, Tag } from 'antd';
import React, { useEffect, useState } from "react";
import { pickBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';

import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import moment from 'moment-timezone';
import { physicalInventoryList } from './physical-inventory.service';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

const PhysicalInventory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();
  const [params, setParams] = useState({ ...defaultPage });
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems,setTotalItems] = useState();
  const [startDate, setStartDate] =useState();
  const [endDate, setEndDate] =useState();
  const [keyword, setKeyword] = useState();
  const [documentType, setDocumentType] = useState();
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const isIdm = _get(user, 'roles', '').includes('idm');

  const fetchProduct = (params, currentWarehouse) => {
    setLoading(true);
    physicalInventoryList({ ...params, warehouseId: currentWarehouse?.id })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
        // console.log(listProducts);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    fetchProduct(params, currentWarehouse);
  }, [params, currentWarehouse]);

  const columns = [
    {
      title: lang.code,
      key: 'code',
      dataIndex: 'code',
      render: (val,rec) => <Button type="link" size="small" onClick={() => navigate(`${rec?.id}`)}>{val}</Button>
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      render: (val) => {
        if (val) {
          return (
            <Tag>{'Đã xác nhận'}</Tag>
          );
        } 
      },
    },
    {
      title: lang.company,
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: lang.branch,
      key: 'branch',
      dataIndex: 'branch',
    },
    // {
    //   title: lang.businessLine,
    //   key: 'businessLine',
    //   dataIndex: 'businessLine',
    // },
    {
      title: lang.documentType,
      key: 'documentType',
      dataIndex: 'documentType',
    },
    {
      title: 'perpetualInventory',
      key: 'perpetualInventory',
      dataIndex: 'perpetualInventory',
    },
    
    {
      title: lang.movementDate,
      key: 'movementDate',
      dataIndex: 'movementDate',
      render: val => val ? moment(val).format(formatDate) : undefined,
    },
  ];

  const extraButton = [
    isIdm && <Button
      key="btn-complete"
      // disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('create');
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ]

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/inventory',
      breadcrumbName: lang.inventory,
    },
    {
      path: 'dashboard/inventory/inventorymanagement',
      breadcrumbName: 'Physical Inventory',
    },
  ];
  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        extra={extraButton}
        title={'Physical Inventory'}
        breadcrumb={{
          routes,
        }}
        className="customPageHeader"
      >
      </PageHeader>
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} layout='horizontal' className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            // setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}
          >
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    format={formatDate}
                    style={{ width: '100%' }}
                    onChange={(dates) => {
                      if (dates) {
                        setStartDate(dates[0]);
                        setEndDate(dates[1]);
                      } else {
                        setStartDate();
                        setEndDate();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
              <Form.Item
                  name="documentType"
                  label={'Document type'}
                >
                <Select 
                  placeholder={'Document type'} 
                  style={{ width: '100%' }} 
                  onChange={(val) => setDocumentType(val)} 
                  allowClear
                  options={[
                    {
                      value: 'Physical Inventory',
                      label: 'Phy Adj',
                    },
                    {
                      value: 'Stocktake Adjustment',
                      label: 'Stocktake Adjustment',
                    },
                  ]}
                
                >
                </Select>
              </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
                <Form.Item name="masterProductName">
                  <Input placeholder={lang.keyword} onChange={(val) => setKeyword(val.target.value)} allowClear />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    onClick={() => {
                      if (startDate) {
                        params.startDate = startDate.startOf('date').format(formatDateFull)
                      } else {
                        delete params.startDate
                      }

                      if (endDate) {
                        params.endDate = endDate.endOf('date').format(formatDateFull)
                      } else {
                        delete params.endDate;
                      }

                      params.page = 1;
                      setParams({...params, keyword, documentType})
                      if (keyword) {
                        setParams({keyword})
                      } else {
                        delete params.keyword
                      }
                      if (documentType) {
                        setParams({documentType})
                      } else {
                        delete params.documentType
                      }
                      if (keyword && documentType) {
                        setParams({keyword, documentType})
                      } else {
                        delete params.keyword
                        delete params.documentType
                      }
                      
                    }}
                  >{lang.btnSearch}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{'Danh sách Physical Inventory'}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItems,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 1600 }}
        />
      </Layout.Content>
    </Layout>
  )
}

export default PhysicalInventory;