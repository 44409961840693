import React, { } from 'react'; //useEffect
import { Form, Col, Row, Button, message, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import ProForm, { ModalForm, ProFormDigit, ProFormText, ProFormSelect, ProFormDatePicker, ProFormTextArea } from '@ant-design/pro-form';
import { CheckCircleOutlined } from '@ant-design/icons';
import './style.less';
import Helper from 'utils/helpers';
import PropTypes from 'prop-types';
import { pickBy } from 'lodash';
import moment from 'moment';
import { formatDate, formatDateFull } from 'utils/constants';
import { blockDetail, productUpdate, shelfDetail } from './product-management.service';
import { error, hashStatusInbound, hashStatusPending, hasStatusLost, lost } from './product-management.constants';
import { locatorList } from 'pages/dashboard/configuration/locator/locator.service';

const ProductModalForm = ({ selectedItem, setVisible, visible, listZone, listBlock, productCategory, setIsReload, isReload, warehouseId }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);

    // console.log(selectedItem)
  const initValues = {
    zoneId: selectedItem?.zone?.id || undefined,
    blockId: selectedItem?.block?.id ? selectedItem?.block?.id : selectedItem?.rack?.shelf?.block?.id || undefined,
    shelfId: selectedItem?.rack?.shelf?.id || undefined,
    rackId: selectedItem?.rack?.id || undefined,
    code: selectedItem?.code ? selectedItem?.code : selectedItem?.bomGift?.code,
    name: selectedItem?.masterProduct?.nameEN ? selectedItem?.masterProduct?.nameEN : selectedItem?.bomGift?.name,
    productCategoryId: selectedItem?.masterProduct?.productCategory.id,
    code3A: selectedItem?.masterProduct?.code3A,
    codeGift: selectedItem?.bomGift?.code || undefined,
    shortName: selectedItem?.masterProduct?.shortName,
    expireDate: selectedItem?.expireDate ? moment(selectedItem?.expireDate, formatDateFull) : undefined,
    quantityCartton: selectedItem?.quantityCartton,
    quantityTin: selectedItem?.quantityTin,
    quantity: selectedItem?.bomGift ? selectedItem?.quantity : undefined,
    locatorId: selectedItem ? selectedItem?.locator?.id : undefined,
    lot: selectedItem?.lot,
    status: selectedItem?.status,
    description: selectedItem?.status === error ? selectedItem?.description : undefined,
    lostDate: selectedItem?.status === lost ? moment(selectedItem?.lostDate, formatDateFull) : undefined,
  };
  // console.log(initValues)
  form.setFieldsValue(initValues)
  // useEffect(() => {
  //   form.resetFields()
  // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // ,[selectedItem]);
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setVisible(false);
      form.resetFields()
      return true;
    }
    Helper.conFirm(setVisible);
  };

  return (
    <ModalForm
      title={lang.updateProduct}
      visible={visible}
      form={form}
      onFinish={async (value) => {
        try {
          const { lostDate, expireDate } = value;
          const cleanValue = pickBy(value, v => v !== undefined && v !== '' && v !== null);
          const dataUpdate = {
            id: selectedItem?.id,
            zoneId: cleanValue.zoneId || null,
            blockId: cleanValue.blockId,
            rackId: cleanValue.rackId,
            lot: cleanValue?.lot,
            locatorId: cleanValue.locatorId || undefined,
            totalQuantity: cleanValue.quantityCartton * selectedItem?.masterProduct?.tinPerCarton + cleanValue.quantityTin,
            status: cleanValue.status,
            expireDate: expireDate ? moment(expireDate, formatDate).format(formatDateFull) : undefined,
            description: cleanValue.description,
            lostDate: selectedItem?.lostDate,
            quantityCartton: cleanValue.quantityCartton,
            quantityTin: cleanValue.quantityTin,
          };
          if (lostDate) {
            dataUpdate.lostDate = moment(lostDate, formatDate).format(formatDateFull);
          }
          await productUpdate(dataUpdate);
          setIsReload(!isReload);
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      requiredMark={false}
      submitter={{
        render: ({ form }) => {
          return [
            <Button
              style={{ width: '130px', height: '32px', borderRadius: 4 }}
              key="extra-reset"
              onClick={onCancel}
            >
              {lang.btnCancel}
            </Button>,
            <Button
              style={{ width: '130px', height: '32px', borderRadius: 4 }}
              type="primary"
              key="extra-submit"
              onClick={() => form?.submit?.()}
            >
              {lang.btnComplete} <CheckCircleOutlined />
            </Button>,
          ];
        },
      }}
      initialValues={initValues}
      onVisibleChange={setVisible}
      modalProps={{
        closable: false,
        centered: true,
        destroyOnClose: true,
      }}
    >
      <Card title={`${lang.capacity}: ${selectedItem?.masterProduct?.capacity}`} bodyStyle={{ padding: '12px 0px' }} bordered={false} headStyle={{ padding: 0, borderBottom: 0 }}>
        <Row gutter={16}>
          <Col span={6}>
            <ProForm.Item noStyle shouldUpdate>
              {(form) => <ProFormSelect name="zoneId" label={lang.zone} placeholder={lang.zone}
                options={
                  listZone?.map(zone => {
                    return {
                      value: zone.id,
                      label: zone.name,
                    };
                  })
                }
              />}
            </ProForm.Item>
          </Col>
          <Col span={6}>
            <ProForm.Item noStyle shouldUpdate>
              {(form) => (<ProFormSelect name="blockId" label={lang.block} placeholder={lang.block}
                fieldProps={{
                  onChange: () => form.setFieldsValue({ shelfId: undefined, rackId: undefined }),
                }}
                options={
                  listBlock?.map(block => {
                    return {
                      value: block.id,
                      label: block.name,
                    };
                  })
                }
                rules={[
                  {
                    required: true,
                    message: lang.requiredBlock,
                  },
                ]} />)
              }
            </ProForm.Item>
          </Col>
          <Col span={6}>
            <ProForm.Item noStyle shouldUpdate={(prev, current) => prev.blockId !== current.blockId}>
              {(form) => {
                return (<ProFormSelect name="shelfId" label={lang.shelf} placeholder={lang.shelf}
                  fieldProps={{
                    onChange: () => form.setFieldsValue({ rackId: undefined }),
                  }}

                  params={{ blockId: form?.getFieldValue('blockId') }}
                  request={async ({ blockId }) => {
                    if (blockId) {
                      const blockfinded = await blockDetail(blockId);
                      return blockfinded?.shelfs?.map(shelf => {
                        return {
                          value: shelf.id,
                          label: shelf.name,
                        };
                      });
                    }
                    return [];
                  }}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredShelf,
                    },
                  ]}
                />);
              }}
            </ProForm.Item>
          </Col>
          <Col span={6}>

            <ProForm.Item noStyle shouldUpdate={(prev, current) => prev.shelfId !== current.shelfId}>
              {(form) => {
                return (<ProFormSelect name="rackId" label={lang.rack} placeholder={lang.rack}
                  params={{ shelfId: form?.getFieldValue('shelfId') }}
                  request={async ({ shelfId }) => {
                    if (shelfId) {
                      const shelf = await shelfDetail(shelfId);
                      return shelf?.racks?.map(rack => {
                        return {
                          value: rack.id,
                          label: rack.code,
                        };
                      });
                    }
                    return [];
                  }}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredRack,
                    },
                  ]}
                />);
              }}
            </ProForm.Item>
          </Col>
        </Row>
      </Card>
      <Row gutter={16}>
       {(selectedItem?.bomGift) ? <Col span={8}>
          <ProFormText name="codeGift" label={lang.defaultCode} placeholder={lang.defaultCode} width="lg" disabled={true} />
        </Col> : 
        <Col span={8}>
        <ProFormText name="code3A" label={lang.defaultCode} placeholder={lang.defaultCode} width="lg" disabled={true} />
      </Col>}
        {
          !(selectedItem?.bomGift) && <Col span={8}>
          <ProFormText name="shortName" label={lang.SHORTNAME} placeholder={lang.SHORTNAME} disabled={true} />
        </Col>
        }
        <Col span={8}>
          <ProFormText name="name" label={lang.productName} placeholder={lang.productName} disabled={true} />
        </Col>
        <Col span={8}>
          <ProFormSelect name="productCategoryId" label={lang.productCategory} placeholder={lang.productCategory} disabled={true}
            options={productCategory?.map(cate => {
              return {
                value: cate.id,
                label: cate.name,
              };
            })}
          />
        </Col>
        <Col span={8}>
          <ProFormText name="code" label={lang.productCode} placeholder={lang.productCode} disabled={true} />
        </Col>
        {!(selectedItem?.bomGift) && <Col span={8}>
          <ProFormDatePicker name="expireDate" label={lang.expirationDate} placeholder={lang.expirationDate} width="lg"
            fieldProps={{
              // locale: locale.dateFormat,
              format: formatDate,
            }}
          />
        </Col>}
        {!(selectedItem?.bomGift) && <Col span={8}>
          <ProFormDigit
            name="quantityCartton"
            label={lang.quantityCartton}
            placeholder={lang.quantityCartton}
          />
        </Col>}
        {(selectedItem?.bomGift) ? <Col span={8}>
          <ProFormDigit
            name="quantity"
            label={lang.quantityTin}
            placeholder={lang.quantityTin}
          />
        </Col> :
        <Col span={8}>
          <ProFormDigit
            name="quantityTin"
            label={lang.quantityTin}
            placeholder={lang.quantityTin}
          />
      </Col>
        }
        <Col span={8}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name="locatorId" label={lang.Locator}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.requiredLocator,
            //   },
            // ]}
            placeholder={lang.Locator}
            params={{ warehouseId }}
            request={async (params) => {
              const response = await locatorList(params);
              return response?.data?.map(locator => {
                return {
                  value: locator.id,
                  label: locator.locatorName,
                };
              });
            }}
          />
        </Col>
       { !(selectedItem?.bomGift) && <Col span={8}>
          <ProFormText
            name="lot"
            label='Lot'
            placeholder='Lot'
          />
        </Col>}
        <Col span={8}>
          <ProFormSelect name="status" label={lang.status} placeholder={lang.status} valueEnum={Object.assign({}, hashStatusPending, hashStatusInbound, hasStatusLost)}
            rules={[
              {
                required: true,
                message: lang.requiredStatus,
              },
            ]} />
        </Col>
        <Col span={8}>
          <ProForm.Item noStyle shouldUpdate>
            {(form) => {
              if (form.getFieldValue('status') === lost) {
                return (<ProFormDatePicker name="lostDate" label={lang.lostDate} placeholder={lang.lostDate} width="lg"
                  fieldProps={{
                    format: formatDate,
                  }}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredLostDate,
                    },
                  ]} />);
              }
              return null;
            }}
          </ProForm.Item>
        </Col>
        <Col span={24}>
          <ProForm.Item noStyle shouldUpdate>
            {(form) => {
              if (form.getFieldValue('status') === error) {
                return (<ProFormTextArea
                  name="description"
                  label={lang.reportContent}
                  placeholder={lang.messageReportContent}
                  fieldProps={{
                    autoSize: { minRows: 8, maxRows: 8 },
                  }}
                  rules={
                    [{
                      required: true,
                      message: lang.requiredReportContent,
                    }]
                  }
                />);
              }
              return null;
            }}
          </ProForm.Item>
        </Col>
      </Row>
    </ModalForm>
  );
};
ProductModalForm.propTypes = {
  selectedItem: PropTypes.object,
  setVisible: PropTypes.func,
  setIsReload: PropTypes.func,
  visible: PropTypes.bool,
  isReload: PropTypes.bool,
  listZone: PropTypes.array,
  listBlock: PropTypes.array,
  productCategory: PropTypes.array,
  warehouseId: PropTypes.number,
};
export default ProductModalForm;
