export const ZONE_LIST = 'ZONE_LIST';
export const ZONE_LIST_SUCCESS = 'ZONE_LIST_SUCCESS';
export const ZONE_LIST_FAIL = 'ZONE_LIST_FAIL';

export const BLOCK_LIST = 'BLOCK_LIST';
export const BLOCK_LIST_SUCCESS = 'BLOCK_LIST_SUCCESS';
export const BLOCK_LIST_FAIL = 'BLOCK_LIST_FAIL';

export const ZONE_CREATE = 'ZONE_CREATE';
export const ZONE_CREATE_SUCCESS = 'ZONE_CREATE_SUCCESS';
export const ZONE_CREATE_FAIL = 'ZONE_CREATE_FAIL';

export const ZONE_UPDATE = 'ZONE_UPDATE';
export const ZONE_UPDATE_SUCCESS = 'ZONE_UPDATE_SUCCESS';
export const ZONE_UPDATE_FAIL = 'ZONE_UPDATE_FAIL';

export const BLOCK_CREATE = 'BLOCK_CREATE';
export const BLOCK_CREATE_SUCCESS = 'BLOCK_CREATE_SUCCESS';
export const BLOCK_CREATE_FAIL = 'BLOCK_CREATE_FAIL';

export const SHELF_CREATE = 'SHELF_CREATE';
export const SHELF_CREATE_SUCCESS = 'SHELF_CREATE_SUCCESS';
export const SHELF_CREATE_FAIL = 'SHELF_CREATE_FAIL';

export const SHELF_UPDATE = 'SHELF_UPDATE';
export const SHELF_UPDATE_SUCCESS = 'SHELF_UPDATE_SUCCESS';
export const SHELF_UPDATE_FAIL = 'SHELF_UPDATE_FAIL';

export const ZONE_DELETE = 'ZONE_DELETE';
export const ZONE_DELETE_SUCCESS = 'ZONE_DELETE_SUCCESS';
export const ZONE_DELETE_FAIL = 'ZONE_DELETE_FAIL';

export const BLOCK_DELETE = 'BLOCK_DELETE';
export const BLOCK_DELETE_SUCCESS = 'BLOCK_DELETE_SUCCESS';
export const BLOCK_DELETE_FAIL = 'BLOCK_DELETE_FAIL';

export const BLOCK_UPDATE = 'BLOCK_UPDATE';
export const BLOCK_UPDATE_SUCCESS = 'BLOCK_UPDATE_SUCCESS';
export const BLOCK_UPDATE_FAIL = 'BLOCK_UPDATE_FAIL';
