import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Row, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import { suggestLocationByOrder } from '../picking/picking.service';

import './style.less';
import TableCustom from 'components/TableCustom/table';
import { formatDate } from 'utils/constants';
import moment from 'moment-timezone';

const tableScrollHeightArea = window.innerHeight - 200; // px

const SuggestLocationForm = ({ item, onCancel, locator, productOrders, masterProductSuggest, onCallback, totalItems }) => {
  console.log(productOrders)
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [items, setItems] = useState([]);
  const columnsBookingOrders = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct ? record?.masterProduct?.code3A : record?.code3A,
      key: 'productCode',
    },
    {
      title: lang.SHORTNAME,
      key: 'productCategory',
      render: record => {
        if (record?.masterProduct) {
          return record?.masterProduct?.code3A
        } else { 
          const findMasterProduct = masterProductSuggest.find(product => product?.code3A === record?.code3A)
          return findMasterProduct?.shortName
        }
      }
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => {
        if (record?.masterProduct) {
          return record?.masterProduct?.nameEN
        } else { 
          const findMasterProduct = masterProductSuggest.find(product => product?.code3A === record?.code3A)
          return findMasterProduct?.nameEN
        }
      }
    },
    {
      title: lang.quantityCartton,
      dataIndex: 'quantityInCarton',
      key: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      dataIndex: 'quantityInTin',
      key: 'quantityInTin',
    },
    {
      title: `${lang.pickingquantity} (thùng)`,
      key: 'pickingQuantityCartton',
      dataIndex: 'pickingQuantityCartton',
    },
    {
      title: `${lang.pickingquantity} (lon)`,
      key: 'pickingQuantityTin',
      dataIndex: 'pickingQuantityTin',
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
      hideInTable: item[0]?.orderType === 'EXPORT_SALE',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (val) => val ? moment(val)?.format(formatDate) : '',
    },
    {
      title: lang.Locator,
      key: 'locator',
      render: record => {
        const findLocator = locator?.find(locator => locator.locator === record?.locator);
        if (findLocator) {
          return `${findLocator?.locatorName} - ${findLocator?.locator}`
        } else {
          return ''
        }
      },
      hideInTable: item[0]?.orderType === 'EXPORT_SALE',
    },
  ];
  useEffect(() => {
    setLoading(true);
    setProductOrder(productOrders);
    Promise.all(productOrders?.map(async (product) => {
      const findLocatorId = locator?.find(item => item?.locator === product?.locator );
      if (product?.masterProduct) {
        const { masterProduct, quantityInCarton, quantityInTin, warehouseId, code, orderId, pickingQuantityCartton, pickingQuantityTin } = product;
        const reponse = await suggestLocationByOrder({ masterProductId: masterProduct.id, quantityCarton: quantityInCarton - pickingQuantityCartton, quantityTin: quantityInTin - pickingQuantityTin, warehouseId, orderId, screenLock: true, locatorId: product?.locator?.id, subChannel: product.saleChannel}); //, lot: product.lot, expireDate: product.expireDate 
        const result = reponse?.data?.map(productSuggest => ({ ...productSuggest, codeProductOrder: code }));
        return Promise.resolve(result);
      } else {
        const masterProId = masterProductSuggest.find(masterId => masterId?.code3A === product.code3A)
        const { quantityInCarton, quantityInTin, warehouseId, code, orderId, pickingQuantityCartton, pickingQuantityTin } = product;
        const reponse = await suggestLocationByOrder({ masterProductId: masterProId.id, quantityCarton: quantityInCarton - pickingQuantityCartton, quantityTin: quantityInTin - pickingQuantityTin, warehouseId, orderId, screenLock: true, locatorId: findLocatorId.id, subChannel: product.saleChannel }); //, lot: product.lot, expireDate: product.expireDate, locatorId: product?.locator?.id 
        const result = reponse?.data?.map(productSuggest => ({ ...productSuggest, codeProductOrder: code }));
        return Promise.resolve(result);
      }  
      
    })).then((results) => {
      const ctx = results?.reduce((prev, current) => {
        return prev.concat(current);
      }, []);
      setItems([...ctx]);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [productOrders, item, masterProductSuggest, locator]);
  const columns = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
      fixed: true,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'productCode',
      fixed: true,
    },
    {
      title: lang.SHORTNAME,
      key: 'productCategory',
      render: record => record?.masterProduct?.shortName,
    },
    {
      title: lang.productName,
      key: 'productName',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.zone,
      dataIndex: 'zone',
      key: 'zone',
      render: value => value?.name,
    },
    {
      title: lang.block,
      dataIndex: 'block',
      key: 'block',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      render: record => record?.rack?.shelf?.name,
    },
    {
      title: lang.rack,
      dataIndex: 'rack',
      key: 'rack',
      render: value => value?.code,
    },
    {
      title: lang.quantityCartton,
      dataIndex: 'quantityCartton',
      key: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      dataIndex: 'quantityTin',
      key: 'quantityTin',
    },
    {
      title: `${lang.quantityCartton} có thể lấy`,
      // dataIndex: 'quantityCartonSubmit',
      key: 'quantityCarttonLock',
      render: rec => rec?.quantityCartton - rec?.quantityCartonSubmit,
    },
    {
      title: `${lang.quantityTin} có thể lấy`,
      // dataIndex: 'quantityTinSubmit',
      key: 'quantityCarttonLock',
      render: rec => (rec?.quantityCartton - rec?.quantityCartonSubmit) * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTin - rec?.quantityTinSubmit,
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (val) => moment(val).format(formatDate),
    },
    {
      title: lang.Locator,
      dataIndex: 'locator',
      key: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
    },
  ];

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      initialValues={{}}
    >
      <Row gutter={24} >
        <Col>
          <h3>{`${lang.labelBooking}`}</h3>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 12 }}>
          <Input value={item?.map(ite => ite.code)} disabled />
        </Col>
      </Row>
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columnsBookingOrders}
        dataSource={productOrder}
        scroll={{ x: 1300, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 1600, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {/* {loading === false ? (
              <Button type="primary" htmlType='submit' style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )} */}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

SuggestLocationForm.propTypes = {
  item: PropTypes.array,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  productOrders: PropTypes.array,
  onLock: PropTypes.func,
  totalItems: PropTypes.number,
};

export default SuggestLocationForm;
