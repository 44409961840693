import request from 'utils/request';
import environment from 'environment';

export async function productionList(payload) {
  return request(environment.api.productionOrder + 'get-all', payload, 'GET');
}

export async function productionDetail(id) {
  return request(environment.api.productionOrder + `get-one/${id}`, {}, 'GET');
}

export async function productionDelete(id) {
  return request(environment.api.productionOrder + `delete/${id}`, {}, 'DELETE');
}

