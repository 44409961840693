import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';

export async function productList(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.productTrxList}?${params}`, {}, 'GET');
}
export async function productListInStored(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.productTrxList}?${params}`, {}, 'GET');
}

export async function productScan(payload) {
  return request(`${environment.api.productTrxScan}`, payload, 'POST');
}

export async function productGiftScan(payload) {
  return request(environment.api.productGifts + '/scan', payload, 'GET');
}

export async function productUpdate(payload) {
  return request(`${environment.api.productTrxUpdate}`, payload, 'PUT');
}

export async function productUpdates(payload) {
  return request(`${environment.api.productTrxUpdates}`, payload, 'POST');
}

export async function productSplit(payload) {
  return request(`${environment.api.productTrxSplit}`, payload, 'POST');
}

export async function productUpdateLocation(payload) {
  return request(environment.api.productTrxUpdateLocation, payload, 'POST');
}
