import environment from 'environments/environment';
import request from 'utils/request';

export async function locatorList(payload) {
  return request(environment.api.locator + '/get-all', payload, 'GET');
}

export async function locatorDetail(payload) {
  return request(`${environment.api.locator}/get-one/${payload?.locatorId}`, {}, 'GET');
}

export async function locatorCreate(payload) {
  return request(environment.api.locator + '/create', payload, 'POST');
}

export async function locatorUpdate(payload) {
  return request(environment.api.locator + '/update', payload, 'PUT');
}
export async function locatorDelete(id) {
  return request(environment.api.locator + `/delete/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.locator + `/create/excel/${warehouseId}`, formData, 'POST');
}
export async function getInventory(data) {
  return request(environment.api.locator + '/get-inventory', data, 'GET');
}
