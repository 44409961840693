import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Row, Col, message, DatePicker, Card, Form, Input, InputNumber } from 'antd';
import { ProFormSelect } from '@ant-design/pro-form';
import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { giftCommandManueverCreate } from './production-order.service';
import './style.less';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import { locatorList } from 'pages/dashboard/configuration/locator/locator.service';
import GiftReceiptCreateProductEditForm from './gift-production-order-create-product-edit.form';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';
import { salesChannelList } from 'pages/dashboard/configuration/sales-channel/sale-channel.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const GiftManueuverCommandCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const [form] = Form.useForm();
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [locators, setLocators] = useState([]);
  const [saleChannels, setSaleChannels] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse.id }).then(results => setLocators(results?.data));
    salesChannelList({ warehouseId: currentWarehouse.id }).then(rs => setSaleChannels(rs?.data));
  }, [currentWarehouse]);
  const columns = [
    {
      title: 'Mã sản phẩm quà tặng',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên sản phẩm quà tặng',
      dataIndex: 'name',
      key: 'productName',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          const formData = { ...form.getFieldsValue(true) };
          const dataFiledForm = form.getFieldsValue()
          formData.orderGiftType = 'COMMAND_MANUEVER';
          // console.log(items)
          const payload = items.map(item => {
            return {
              quantity: item.quantity,
              codeGift: item?.code,
              locatorCodeTo: formData?.locatorTransit?.value,
              warehouseId: currentWarehouse.id,
            };
          });
          // console.log(lot)
          if (formData?.movementDate) {
            formData.movementDate = formData.movementDate.format(formatDateFull);
          }
          formData.locatorTransitCode = formData.locatorTransit?.value;
          formData.creatorId = user?.userInfo?.name;
          delete formData.locatorTransit;
          if ((!dataFiledForm?.truckType)) {
            Modal.warning({
              title: 'Vui lòng chọn loại xe!'
            });
            setLoadingBtn(false);
          } else if((!dataFiledForm?.locatorTransit)) {
            Modal.warning({
              title: 'Vui lòng chọn locator transit!'
            });
            setLoadingBtn(false);
          } else {
            await giftCommandManueverCreate({ ...formData, productGiftOrders: payload, warehouseId: currentWarehouse.id });
            message.success(lang.createSuccessfully);
            setLoadingBtn(false);
            navigate('/dashboard/inbound/gift-manuever-command');
          }
         
        } catch (error) {
          message.error(error.message);
          setLoadingBtn(false);
        }
      }
      }
      disabled={!(items?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/manuever-command',
      breadcrumbName: 'Lệnh điều động',
    },
    {
      path: '/',
      breadcrumbName: 'Tạo lệnh điều động',
    },
  ];

  return (
    <Layout className="Sale-order-list layoutContent">
      <PageHeader
        ghost={false}
        title={'Tạo lệnh điều động Gift'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content>
        <Card bodyStyle={{ padding: 12 }}>
          <Form
            colon={false}
            layout="vertical"
            name="productEditForm"
            initialValues={{
              movementDate: moment(),
            }}
            form={form}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect
                required={true}
                  name="truckType"
                  label={lang.truckType}
                  options={[
                    {
                      value: 'Container 20FT',
                      label: 'Container 20FT',
                    },
                    {
                      value: 'Container 40FT',
                      label: 'Container 40FT',
                    },
                    {
                      value: 'Motorbike',
                      label: 'Motorbike',
                    },
                    {
                      value: 'Truck 1.25T',
                      label: 'Truck 1.25T',
                    },
                    {
                      value: 'Truck 1.5T',
                      label: 'Truck 1.5T',
                    },
                    {
                      value: 'Truck 10T',
                      label: 'Truck 10T',
                    },
                    {
                      value: 'Truck 15T',
                      label: 'Truck 15T',
                    },
                    {
                      value: 'Truck 2.0T',
                      label: 'Truck 2.0T',
                    },
                    {
                      value: 'Truck 2.5T',
                      label: 'Truck 2.5T',
                    },
                    {
                      value: 'Truck 5.0T',
                      label: 'Truck 5.0T',
                    },
                    {
                      value: 'Truck 8.0T',
                      label: 'Truck 8.0T',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                    },
                  ]}
                  placeholder={lang.truckType}
                  rules={[{ required: true, message: 'Please select truck type!' }]}
                />
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="truckWeight"
                  label={'Tải trọng xe (tấn)'}
                >
                  <InputNumber placeholder={'Tải trọng xe'} style={{width: '100%'}}/>
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="locatorTransit" label={`${lang.Locator} (Transit)`}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredLocator,
                    },
                  ]}
                  placeholder={lang.Locator}
                  options={locators?.filter(locator => locator.warehouseId === currentWarehouse?.id && locator.warehouseToId && locator?.locatorName === 'HCM Gift In Transit Dong Nai' ? locator.warehouseId === currentWarehouse?.id && locator.warehouseToId && locator?.locatorName === 'HCM Gift In Transit Dong Nai' : locator.warehouseId === currentWarehouse?.id && locator.warehouseToId && locator?.locatorName === 'CDC Gift In Transit Ho Chi Minh')?.map(locator => ({
                    label: `${locator.locatorName} - ${locator.locator}`,
                    value: locator.locator,
                    warehouseToId: locator.warehouseToId,
                  }))}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                  fieldProps={{
                    labelInValue: true,
                  }}
                />
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="movementDate"
                  label={lang.movementDate}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="tranferReason"
                  label={lang.tranferReason}
                >
                  <Input placeholder={lang.tranferReason} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productAdded}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setItem();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk() {
                        const dataFilter = items.filter(item => selectedRowKeys.indexOf(item.indx) === -1);
                        setItems(dataFilter);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="indx"
          rowSelection={rowSelection}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            showSizeChanger: true,
            total: items.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
              setCurrentPage(1);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>

      <Modal
        visible={isEditModal}
        title={item ? lang.editProduct : lang.addProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <GiftReceiptCreateProductEditForm
          item={item}
          items={items}
          onCallback={(value) => {
            setItems([...items, value]);
            setIsEditModal(false);
          }}
          onCancel={() => setIsEditModal(false)}
          channel={saleChannels?.find(saleChannel => saleChannel.id === form?.getFieldValue('channel'))}
        />
      </Modal>
    </Layout>
  );
};

export default GiftManueuverCommandCreate;
