import request from 'utils/request';
import environment from 'environment';

export async function unpackList(payload) {
  return request(environment.api.unpack + 'get-all', payload, 'GET');
}

export async function unpackDetail(id) {
  return request(environment.api.unpack + `get-one/${id}`, {}, 'GET');
}

export async function unpackDelete(id) {
  return request(environment.api.unpack + `delete/${id}`, {}, 'DELETE');
}

