import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Form, Row, Col, DatePicker, Input, Tag, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { productList } from '../product.service';
import ProductReportForm from 'components/ProductReportForm/product-report.form';
import './style.less';
import moment from 'moment';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { productGiffGetAll } from '../Scan/scan.service';

//const tableScrollHeightArea = window.innerHeight - 360; // px
// const VIEW_MODE = {
//   CARD_VIEW: 0,
//   LIST_VIEW: 1,
// };
const defaultParams = {
  ...defaultPage,
  status: 'TEMPORARY',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const TemporaryInboundList = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [isReportModal, setIsReportModal] = useState(false);

  //Roles
  const { currentWarehouse } = useSelector((state) => state.auth);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  //Pagination
  const [params, setParams] = useState({ ...defaultParams });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');
  const [key, setKey] = useState(1);
  const getProducts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    productList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  const getProductsGift = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    productGiffGetAll({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (key == 1) {
      getProducts(params, sortBy, sortDirection, currentWarehouse);
    } else {
      getProductsGift(params, sortBy, sortDirection, currentWarehouse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, sortBy, sortDirection, currentWarehouse, key]);
  const columnsGift = [
    {
      title: lang.giftCode,
      key: 'giftCode',
      fixed: 'left',
      width: '5%',
      render: (record) => record?.bomGift?.code,
    },
    {
      title: 'QR code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.giftName,
      key: 'giftName',
      render: (record) => record?.bomGift?.name,
    },
    {
      title: lang.shipment,
      key: 'shipment',
      dataIndex: 'shipment',
    },
    {
      title: 'LineNo',
      key: 'lineNo',
      dataIndex: 'lineNo',
    },
    {
      title: lang.orderReference,
      key: 'orderReference',
      dataIndex: 'orderReference',
    },
    {
      title: 'ContractRefNo',
      key: 'contractRefNo',
      dataIndex: 'contractRefNo',
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
    },
    {
      title: lang.block + '(Gợi ý)',
      key: 'block',
      dataIndex: ['rack', 'shelf', 'block', 'name'],
    },
    {
      title: lang.shelf + '(Gợi ý)',
      key: 'shelf',
      dataIndex: ['rack', 'shelf', 'name'],
    },
    {
      title: lang.rack + '(Gợi ý)',
      key: 'rack',
      dataIndex: 'rack',
      render: value => value?.code,
    },
  ];
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container No',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      // width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.rack + '(Gợi ý)',
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.UOM,
      key: 'UOM',
      dataIndex: 'masterProduct',
      // width: '5%',
      render: value => value?.UOM_VN,
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
      // width: '5%',
      // render: value => value?.shelf.name,
    },
    {
      title: lang.documentNo,
      key: 'documentNo',
      dataIndex: 'documentNo',
      // width: '5%',
    },
    {
      title: lang.documentType,
      key: 'documentType',
      dataIndex: 'documentType',
      // width: '5%',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            size="small"
            type="link"
            onClick={() => {
              //report
              const detail = {
                id: record?.id,
                productCode: record?.code,
                productName: record?.masterProduct?.nameEN,
                quantityCartton: record?.quantityCartton,
                quantityTin: record?.quantityTin,
              };
              setItem(detail);
              setIsReportModal(true);
            }}
          >
            {lang.report}
          </Button>
        </div>
      ),
    },
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/temporary-inbound',
      breadcrumbName: lang.temporaryInbound,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.temporaryInbound}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Tabs defaultActiveKey={key} type="card" tabBarStyle={{ marginBottom: 0 }} onChange={activeKey => {
          setKey(activeKey);
          setParams({ ...defaultParams });
        }}>
          <TabPane tab='Sản phẩm tạm nhập' key={1}>
            <Card size="small" className="cardSearch">
              <Row gutter={[16, 16]}>
                <Col xxl={{ span: 6 }} sm={6} xs={16}>
                  <Form.Item
                    name="importDate"
                    label={lang.importDate}
                    wrapperCol={{ xxl: 16 }}
                    labelCol={{ xxl: 8 }}
                  >
                    <DatePicker.RangePicker
                      placeholder={[lang.from, lang.to]}
                      onChange={(dates) => {
                        if (dates) {
                          setStartDate(dates[0]);
                          setEndDate(dates[1]);
                        } else {
                          setStartDate();
                          setEndDate();
                        }
                      }}
                      // style={{ width: '100%' }}
                      format={formatDate}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 6 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="shipment"
                    label={lang.shipmentNo}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.shipment} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 6 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="order"
                    label={lang.orderNo}

                  >
                    <Input style={{ width: '100%' }} placeholder={lang.orderNo} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 6 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="contract"
                    label={lang.contractNo}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.contractNo} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 6 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="invoice"
                    label={lang.invoiceNo}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.invoiceNo} />
                  </Form.Item>
                </Col>

                <Col xxl={{ span: 4 }} sm={6}>
                  <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
                </Col>
                <Col sm={3}>
                  <Button
                    type={'primary'}
                    ghost
                    onClick={() => {
                      if (startDate) {
                        params.startDate = startDate.startOf('date').format(formatDateFull);
                      } else {
                        delete params.startDate;
                      }
                      if (endDate) {
                        params.endDate = endDate.endOf('date').format(formatDateFull);
                      } else {
                        delete params.endDate;
                      }
                      params.page = 1;
                      setParams({ ...params, keyword });
                    }}
                    icon={<SearchOutlined />}
                  >
                    {lang.search}
                  </Button>
                </Col>
              </Row>
            </Card>
            <TableCustom
              title={() => <Row>
                <Col span={12}><h3>{lang.productList}</h3></Col>
              </Row>}
              rowKey="id"
              loading={loading}
              bordered
              columns={columns}
              dataSource={items}
              onChange={(pagination, filters, sorter) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                // if (sorter) {
                //   setSortedInfo({
                //     columnKey: sorter.columnKey,
                //     order: sorter.order,
                //   });
                // }
                if (pagination.pageSize !== params.limit) {
                  params.page = 1;
                } else {
                  params.page = pagination.current;
                }
                params.limit = pagination.pageSize;
                setParams({ ...params });
              }}
              pagination={{
                total: totalItem,
                showSizeChanger: true,
                pageSize: params.limit,
                current: params.page,
              }}
              scroll={{ x: 2600 }}
            />
          </TabPane>
          <TabPane tab='Quà tạm nhập' key={2}>
            <Card size="small" className="cardSearch">
              <Row gutter={[16, 16]}>
                <Col xxl={{ span: 4 }} sm={6}>
                  <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
                </Col>
                <Col sm={3}>
                  <Button
                    type={'primary'}
                    ghost
                    onClick={() => {
                      if (startDate) {
                        params.startDate = startDate.startOf('date').format(formatDateFull);
                      } else {
                        delete params.startDate;
                      }
                      if (endDate) {
                        params.endDate = endDate.endOf('date').format(formatDateFull);
                      } else {
                        delete params.endDate;
                      }
                      params.page = 1;
                      setParams({ ...params, keyword });
                    }}
                    icon={<SearchOutlined />}
                  >
                    {lang.search}
                  </Button>
                </Col>
              </Row>
            </Card>
            <TableCustom
              title={() => <Row>
                <Col span={12}><h3>{'Danh sách quà tạm nhập'}</h3></Col>
              </Row>}
              rowKey="id"
              loading={loading}
              bordered
              columns={columnsGift}
              dataSource={items}
              onChange={(pagination, filters, sorter) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                // if (sorter) {
                //   setSortedInfo({
                //     columnKey: sorter.columnKey,
                //     order: sorter.order,
                //   });
                // }
                if (pagination.pageSize !== params.limit) {
                  params.page = 1;
                } else {
                  params.page = pagination.current;
                }
                params.limit = pagination.pageSize;
                setParams({ ...params });
              }}
              pagination={{
                total: totalItem,
                showSizeChanger: true,
                pageSize: params.limit,
                current: params.page,
              }}
              scroll={{ x: 2600 }}
            />
          </TabPane>
        </Tabs>
      </Layout.Content>
      <Modal
        visible={isReportModal}
        title={lang.reportErrorProduct}
        onCancel={() => setIsReportModal(false)}
        destroyOnClose={true}
        width={768}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <ProductReportForm
          type={'temporary-inbound'}
          item={item}
          onCallback={(value) => {
            // setPage(1);
            // getProducts(1);
            setParams({ ...defaultParams });
            setIsReportModal(false);
          }}
          onCancel={() => setIsReportModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default TemporaryInboundList;
