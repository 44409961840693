import environment from 'environments/environment';
import request from 'utils/request';

const mockData = [
  {
    id: 1,
    code: 'PC_01',
    name: 'Mỹ phẩm',
    exportStrategy: 'FEFO',
    createDate: '2021-08-18',
    status: 'ENABLE',
  },
  {
    id: 2,
    code: 'PC_02',
    name: 'Đồ uống',
    exportStrategy: 'FEFO',
    createDate: '2021-08-18',
    status: 'ENABLE',
  },
  {
    id: 3,
    code: 'PC_03',
    name: 'Hàng tiêu dùng thiết yếu',
    exportStrategy: 'FIFO',
    createDate: '2021-08-18',
    status: 'ENABLE',
  },
  {
    id: 4,
    code: 'PC_04',
    name: 'Hàng gia dụng',
    exportStrategy: 'FIFO',
    createDate: '2021-08-18',
    status: 'ENABLE',
  },
  {
    id: 5,
    code: 'PC_05',
    name: 'Rượu, bia',
    exportStrategy: 'FIFO',
    createDate: '2021-08-18',
    status: 'ENABLE',
  },
  {
    id: 6,
    code: 'PC_06',
    name: 'Văn phòng phẩm',
    exportStrategy: 'LIFO',
    createDate: '2021-08-18',
    status: 'ENABLE',
  },
];

export async function productCategoryList(payload) {
  if (environment.isMockData) {
    return new Promise((resolve, reject) => {
      resolve(mockData);
    });
  }
  return request(environment.api.productCategoryList, payload, 'GET');
}

export async function productCategoryDetail(payload) {
  if (environment.isMockData) {
    return Promise.resolve(mockData.filter(mock => mock.id === payload?.categoryId)[0]);
  }

  return request(`${environment.api.productCategoryDetai}/${payload?.categoryId}`, {}, 'GET');
}

export async function productCategoryCreate(payload) {
  if (environment.isMockData) {
    const result = {
      id: mockData.length + 1,
      code: `PC_0${mockData.length + 1}`,
      name: payload.name,
      exportStrategy: payload.exportStrategy,
      createDate: '2019-08-24',
      status: 'ENABLE',
      wareHouseId: payload.wareHouseId,
    };
    mockData.push(result);
    return Promise.resolve('ok');
  }
  return request(environment.api.productCategoryCreate, payload, 'POST');
}

export async function productCategoryUpdate(payload) {
  if (environment.isMockData) {
    mockData.map(mock => {
      if (mock.id === payload.id) {
        Object.assign(mock, payload);
      }
      return mock;
    });
    return Promise.resolve('ok');
  }
  return request(environment.api.productCategoryUpdate, payload, 'PUT');
}
export async function productCategoryDelete(id) {
  if (environment.isMockData) {
    const mockfil = mockData.filter(mock => id.indexOf(mock.id) === -1);
    return Promise.resolve(mockfil);
  }
  return request(environment.api.productCategoryDelete + `/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.productCategoryImport + `${warehouseId}`, formData , 'POST');
}

