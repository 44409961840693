import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row, Input, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import { productPicking } from '../packaging/packaging.service';
import TableCustom from 'components/TableCustom/table';

const tableScrollHeightArea = window.innerHeight - 200; // px

const PackagingDetailForm = ({ item, onCancel, onCallback }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);
    productPicking({ packageCode: item?.packageCode })
      .then(result => {
        setItems(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [item]);

  const columns = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
      width: '15%',
      fixed: true,
    },
    {
      title: lang.productCode,
      key: 'code',
      width: '15%',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '15%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: value => value?.masterProduct?.nameEN,
    },
    {
      title: lang.quantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '15%',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '10%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.code,
    },
  ];

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        Modal.success({
          title: lang.titleModalSuccessSuggestLocation,
          //content: 'Receipt ID: REP3131231',
          icon: <CheckOutlined />,
          onOk() {
            onCallback();
          },
        });
      }}
      initialValues={{}}
    >
      <Row gutter={24} style={{ marginBottom: 24 }}>
        <Col>
          <label>{lang.packagingCode}</label>
        </Col>
        <Col>
          <span style={{ color: '#EF4036' }}>{item?.packageCode}</span>
        </Col>
        <Col>
          <label>{lang.orderCode}</label>
        </Col>
        <Col>
          <Input value={item?.orderCode} disabled />
        </Col>
      </Row>
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 600, y: tableScrollHeightArea }}
        onChange={(pagination, filters, sorter) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setCurrentPage(pagination.current);
        }}
        pagination={{
          showSizeChanger: true,
          total: items.length,
          onShowSizeChange: (current, size) => {
            SetPageSize(size);
            setCurrentPage(1);
          },
          pageSize: pageSize,
          current: currentPage,
        }}
      />
    </Form>
  );
};

PackagingDetailForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
};

export default PackagingDetailForm;
