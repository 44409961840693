import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, message, Row, Col, Modal, Checkbox } from 'antd';
import { ScanOutlined, DeleteOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { scanVasCompleted } from 'providers/InboundProvider/actions';
import moment from 'moment';
import { formatDateFull, formatDatetime } from 'utils/constants';
import { productScan, productUpdates } from '../product.service';
import './style.less';
import TableCustom from 'components/TableCustom/table';
import ProductComboForm from './product-edit.form';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const InboundControlScanComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanVasForProductCodes } = useSelector((state) => state.inbound);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [items, setItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [type, setType] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const [visible, setVisible] = useState(false);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [locatorId, setLocatorId] = useState();
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '12%',
      render: value => value?.code3A,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '6%',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      // width: '10%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.packageCode,
      dataIndex: 'packageCode',
      key: 'packageCode',
      // width: '6%',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'productName',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.receipt,
      dataIndex: ['receipt', 'code'],
      key: 'receipt',
    },
    {
      title: 'Lot',
      dataIndex: 'lot',
      key: 'lot',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: ['locator', 'locatorName'],
      // width: '6%',
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      // width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      // width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      // width: '5%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      // width: '18%',
      render: (value) => value?.name,
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: value => value ? moment(value, formatDateFull).format(formatDatetime) : undefined,
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={items?.length === 0}
      onClick={() => {
        try {
          const data = {
            ids: items.map(item => item.id),
            warehouseId: currentWarehouse?.id,
            locatorId,
          };
          if (type.length > 0) {
            if (type.length === 2) {
              data.type = 3;
              data.status = 'PACK';
            } else {
              if (type[0] === 'VAS') {
                data.type = 1;
                data.status = 'VAS';
              } else {
                data.type = 2;
                data.status = 'PACK';
              }
            }
          } else {
            throw new Error('Vui lòng chọn loại đóng gói');
          }
          productUpdates(data).then(result => {
            dispatch(scanVasCompleted());
            Modal.success({
              title: lang.completeVasScanSuccessfully,
              icon: <CheckOutlined />,
              onOk() {
                if (window.history.state.usr?.prevUrl) {
                  navigate(window.history.state.usr?.prevUrl);
                } else {
                  navigate('/dashboard/inbound/vas/');
                }
              },
            });
          })
            .catch((e) => message.error(e.message));
        } catch (error) {
          message.error(error.message);
        }
      }}
    >
      {lang.btnComplete}
      < CheckOutlined />
    </Button >,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/vas',
      breadcrumbName: lang.Vas,
    },
    {
      path: '/dashboard/inbound/vas/vas-scan',
      breadcrumbName: lang.scanProduct,
    },
    {
      path: '',
      breadcrumbName: lang.btnComplete,
    },
  ];

  //STATUS = INBOUND_CONTROL
  useEffect(() => {
    setLoading(true);
    productScan({
      productCodes: scanVasForProductCodes,
      type: 'VAS',
      warehouseId: currentWarehouse.id,
    })
      .then(result => {
        if (result?.errList.length > 0) {
          message.warning(`${lang.invalidCode}: ${result?.errList?.toString()}. ${lang.tryAgain}`);
        }

        if (result?.successList.length !== 0) {
          setItems(result?.successList);
        }
        setLoading(false);
      })
      .catch((e) => { setLoading(false); message.error(e.message); });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWarehouse]);

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.inboundControlScanProductComplete}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <TableCustom
          title={() => <Row justify="space-between">
            <Col>
              <label style={{ marginRight: '10px' }}>Loại đóng gói:</label>
              <Checkbox.Group
                layout="horizontal"
                options={['VAS', 'PACK']}
                onChange={(values) => { setType(values); setVisible(true); }}
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {lang.scanMore}
                <ScanOutlined />
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        const itemsDeleted = items.filter(item => selectedRowKeys.includes(item.id) === false);
                        setItems(itemsDeleted);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          scroll={{ x: 2000 }}
        />
      </Layout.Content>
      <Modal
        visible={visible}
        title={lang.createComboProduct}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}
        width={576}
        centered
        footer={null}
      >
        <ProductComboForm
          item={null}
          onCallback={(values) => {
            setLocatorId(values?.locatorId);
            setVisible(false);
          }}
          onCancel={() => {
            setVisible(false);
          }}
          warehouseId={currentWarehouse?.id}
        />
      </Modal>
    </Layout>
  );
};

export default InboundControlScanComplete;
