import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Select, InputNumber, message, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import { useSelector } from 'react-redux';
import { ProFormText } from '@ant-design/pro-form';
import { productList } from 'pages/dashboard/configuration/product/product.service';
// import { getAllProudctLocator } from './production-order.service';
// import { locatorList } from 'pages/dashboard/configuration/locator/locator.service';
// import moment from 'moment-timezone';
import { defaultPage } from 'utils/constants';
import { bomList } from 'pages/dashboard/configuration/masster-products-bom-gift/master-products-bom-gift.service';
import { bomGiftList } from 'pages/dashboard/inventory/giftinventory/inventory-gift.service';
import { masterproductPackingFindOne } from 'pages/dashboard/configuration/masterproduct-packing/masterproduct-packing.service';

// import { getAllProudctLocator } from './production-order.service';

const { Option } = Select;
const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};
const ReceiptProductPackEditForm = ({ item, onCancel, onCallback, items, type, orderType }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [productsLocatorGift, setProductsLocatorGift] = useState([]);
  const [productFind, setProductFind] = useState('');
  const [totalQuantityPack, setTotalQuantityPack] = useState(0);
  const [productsPack, setProductsPack] = useState([]);
  const [params] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [dataVas, setDataVas] = useState([]);
  const [products, setProducts] = useState([]);
  const [locatorGiftFind, setLocatorGiftFind] = useState([]);
  const [dataProductChildPack, setDataProductChildPack] = useState();
  const [dataProductGiftChildPack, setDataProductGiftChildPack] = useState();
  const [dataListBomGiftId, setDataListBomGiftId] = useState();
  const [locatorFind, setLocatorFind] = useState();
  const [dataGetLot, setDataGetLot] = useState([]);


  useEffect(() => {
    setLoading(true);
    bomList({warehouseId: currentWarehouse?.id})
      .then(result => {
        setProductsPack(result?.data);
        // setTotalItems(result.length);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
      // Lấy dsach bomGift trong kho
      bomGiftList({ ...params, ...sortedInfo, warehouseId: currentWarehouse.id, isAvailable: true })
      .then(result => {
        setProductsLocatorGift(result.data)
        setLoading(false);
      })
      .catch((e) => setLoading(false));

  }, [currentWarehouse, sortedInfo, params]);

  useEffect(() => {
    masterproductPackingFindOne({ masterProductId: productFind?.id, warehouseId: currentWarehouse?.id })
        .then(dataPack => {
          if (dataPack) {
            setDataVas(dataPack);
          }
        })
        .catch((e) => {
          message.error(e);
          setLoading(false)
        });
        productList({ status: 'ENABLE', isAvailableQauntity: true, warehouseId: currentWarehouse?.id })
        .then(result => {
          setProducts(result.data);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
  }, [productFind, currentWarehouse])
// console.log(quantityProGiftDefault)
// console.log(quantityMasterProDefault)
// console.log(totalQuantityPack)
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        // console.log(values)
        try {
          if (values.totalQuantity === 0) {
            throw new Error('Số lượng cần lấy là bắt buộc');
          }
          const listBomproductionIds = dataVas?.map(item => {
            return {
              bomProductionId: item?.bomProduction?.id,
              quantity: item?.using * values?.totalQuantity,
              warehouseId: currentWarehouse?.id
            }
          });

          const listBundledGift = values?.productGiftChildPack?.map((itemGift, index) => {
            return {
              locatorId: itemGift?.locatorGift,
              bomGiftId: dataListBomGiftId[index],
              quantity: itemGift?.quantityGift ,
              warehouseId: currentWarehouse?.id
            }
          });

          const listBundledProduct = values?.productChildPack?.map((itemPro, index) => {
            const listLocatorTaken = [];
            const listCode3APack = [];
            dataProductChildPack.forEach(itemLocator => {
              let listLocator = itemLocator?.locator;
              let locatorFinded = listLocator.find(locaFind => locaFind?.id === itemPro?.locator)?.locators?.locator;
              listLocatorTaken.push(locatorFinded);
              listCode3APack.push(itemLocator?.code3APack)
              // return true
            });
           
            return {
              masterProductLocatorId: itemPro?.locator,
              quantity: itemPro?.quantityMaster,
              warehouseId: currentWarehouse?.id,
              lot: itemPro?.lot,
              expireDate: itemPro?.expireDateOfMaster,
              locatorCode: listLocatorTaken[index],
              code3APack: listCode3APack[index]
            }
          });

          values.bomProductionIds = listBomproductionIds;
          values.bundledGift = listBundledGift;
          values.bundledProduct = listBundledProduct;
          onCallback({
            ...productFind,
            ...values,
            type,
            indx: item?.indx || items?.length + 1,
          });
        } catch (error) {
          message.error(error.message);
        }
      }}
      initialValues={{
        productCode: item ? item?.masterProduct?.id : undefined,
        quantity: item ? item?.quantityTin : 0,
        locatorId: item ? item?.locator?.locator : undefined,
        lot: item ? item?.lot : undefined,
        expireDate: item ? item?.expireDate : undefined,
        // shelfLife: item ? moment(item?.expireDate, formatDateFull).diff(moment().startOf('date'), 'day') * 100 / (item?.masterProduct?.shelfLifeMonths * 30)?.toFixed(2) : undefined
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item name="productPack" label={lang.product + ' Packing'}
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              placeholder={lang.placeholderSelectProduct}
              onChange={async (value) => {
                const itemFind = productsPack.find(product => product.id === value);
                setProductFind(itemFind);

                // Product GIFT
                const listBomGiftId = [];
                const productGiftChildPack = itemFind?.comboData?.bomGift?.map((productGift) => {
                  listBomGiftId.push(productGift?.id)
                let dataLocatorGiftFinded = productsLocatorGift?.find((itemProGiftLoctator) => itemProGiftLoctator?.code === productGift?.code)
                  return {
                    productionGift: `${productGift?.name} - ${productGift?.code}`,
                    locatorGift : dataLocatorGiftFinded?.masterProductsGiftLocators,
                    quantityGift : orderType === 'PACKING' ? productGift?.quantity : 0, // SL SP đã đc cấu hình
                    quantityGiftAvaliableForSale : 0, 
                  }
                });
                setDataListBomGiftId(listBomGiftId);
                  form.setFieldsValue({ productGiftChildPack: productGiftChildPack});
                setDataProductGiftChildPack(productGiftChildPack)

                // Product Base
                const productChildPack = itemFind?.comboData?.masterProduct?.map((masterProduction, index) => {
                  let masterProLocatorFind =  products?.find(itemProLoctator =>  itemProLoctator?.code3A === masterProduction?.code3A);
                    return {
                      productionMaster: `${masterProduction?.nameEN} - ${masterProduction?.shortName} - ${masterProduction?.code3A}`,
                      code3APack: masterProduction?.code3A,
                      locator : masterProLocatorFind?.masterProductsLocators,
                      quantityMaster : orderType === 'PACKING' ? masterProduction?.quantity : 0, // SL SP đã đc cấu hình
                      quantityMasterAvaliableForSale : 0,
                    }
                  });
                    form.setFieldsValue({ productChildPack: productChildPack});
                setDataProductChildPack(productChildPack)
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
            >
              {productsPack?.map(item => (
                <Option key={item.id} value={item.id}>{item.code3A} - {item.shortName} - {item.nameEN}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={{span: 24}}  xs={{ span: 24 }}>
          <h3>Combo Quà Tặng</h3>
        </Col>        

        {/* ========== Quà tặng Gift ==========*/}

        <Form.List name="productGiftChildPack">
            {(fields) => (
              <>
                {fields.map(({key, name}) => (
                  <>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <ProFormText style={{ fontWeight: 600 }}
                        name={[name, 'productionGift']}
                        label={'Danh sách Gift'}
                        placeholder={'Danh sách quà tặng'}
                        disabled
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // return true;
                          return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      />
                    </Col>

                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item 
                      name={[name, 'locatorGift']}
                      label={'Locator Gift'}
                      rules={[
                        {
                          required: true,
                          message: 'Locator là bắt buộc'
                        }
                      ]}
                      >
                        <Select
                          placeholder={lang.placeholderSelectProduct}
                          showSearch
                          optionFilterProp="children"
                          rules={[
                            {
                              required: true,
                              message: 'Locator là bắt buộc',
                            },
                          ]}
                          onChange={(value) => {
                            const a = dataProductGiftChildPack?.map(item => item?.locatorGift);
                            a.forEach((itemLocator, index) => {
                              if (index === key) {
                                // Lọc ra danh sách Locator đã chọn
                                const locatorFilter = itemLocator.filter(itemLoca => itemLoca?.locator?.id === value );
                                const totalQuantityForSale = locatorFilter.reduce((prev, curr) => prev + curr?.quantityForSale, 0)
                                setLocatorGiftFind(locatorFilter)
                                const changeLocator = form?.getFieldValue(['productGiftChildPack'])
                                changeLocator[name].quantityGiftAvaliableForSale = totalQuantityForSale
                                form.setFieldsValue({productGiftChildPack: changeLocator})
                              }
                            })
                          }}
                          filterOption={(input, option) => {
                            return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }
                          }
                        >
                        {
                          dataProductGiftChildPack?.map(item => item?.locatorGift)?.map((itemLocator, index) => { 
                            if (index === key) {
                              // XỬ LÝ KHI CÓ LOCATOR TRÙNG NHAU
                              if (itemLocator) {
                                const dataSet = [];
                                itemLocator.forEach((item, keyLocator) => {
                                  if (keyLocator === 0) {
                                    dataSet.push(item);
                                  } 
                                  else {
                                    // 1. Lấy ra danh sách idLocator trong dataSet
                                    const listIdLocator = dataSet?.map(idLoca => idLoca?.locator?.id);
                                    // 2. Kiểm tra xem id hiện tại có tồn lại trong mảng idLocator ở b1 hay k?
                                    if (!listIdLocator.includes(item?.locator?.id)) {
                                    // 3. Nếu không có thì push vào còn có thì thôi
                                      dataSet.push(item);
                                    }
                                  }
                                });
                                return dataSet?.map((dataUsing, idx) => <Option key={idx} value={dataUsing?.locator?.id}>{`${dataUsing?.locator?.locator} - ${dataUsing?.locator?.locatorName}`}</Option>)
                              }
                            }
                            return true
                          })
                        }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                        {(form) => {
                          if (orderType === 'PACKING' && totalQuantityPack > 0) {
                            return <Form.Item
                              name={[name, "quantityGift"]}
                              label={`SL lấy (SL cấu hình: ${productFind?.comboData?.bomGift[name]?.quantity})`}
                            >
                              <InputNumber disabled style={{ width: '100%' }}  min={0} />
                            </Form.Item>
                          } else if (orderType === 'UNPACK' && totalQuantityPack === 0) {
                            return <Form.Item
                            name={[name, "quantityGift"]}
                            label={'SL trả lại'}
                          >
                            <InputNumber disabled style={{ width: '100%' }} min={0} />
                          </Form.Item>
                          }
                          return <Form.Item
                            name={[name, "quantityGift"]}
                            label={'SL cấu hình' }
                          >
                            <InputNumber disabled style={{ width: '100%' }}  min={0} />
                          </Form.Item>
                        }}
                      </Form.Item>
                    </Col>
                    {orderType === 'PACKING' && <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                      <Form.Item
                        name={[name, "quantityGiftAvaliableForSale"]}
                        label={'SL khả dụng (Lon)'}
                      >
                        <InputNumber disabled style={{ width: '100%' }}  min={0} />
                      </Form.Item>
                    </Col>}
                  </>
                ))}
              </>
            )}
        </Form.List>
        {productFind && <Divider />}

        {/* ==========End Quà tặng Gift ==========*/}



        {/* ========== Quà tặng hàng Base ==========*/}

        <Form.List name="productChildPack">
            {(fields) => (
              <>
                {fields.map(({key, name}) => (
                  <>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <ProFormText style={{ fontWeight: 600 }}
                        name={[name, 'productionMaster']}
                        label={'Sản phẩm hàng Base'}
                        placeholder={'Sản phẩm hàng Base'}
                        disabled
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // return true;
                          return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      />
                    </Col>

                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item 
                      name={[name, 'locator']}
                      label={'Locator'}
                      rules={[
                        {
                          required: true,
                          message: 'Locator là bắt buộc'
                        }
                      ]}
                      >
                        <Select
                          placeholder={lang.placeholderSelectProduct}
                          showSearch
                          onChange={(value) => {
                            // value phải là id của locator để lấy ra được tất cả các locator có cùng id để xử lý việc lấy ra các lot nếu 1 locator có nhiều lot
                            const a = dataProductChildPack?.map(item => item?.locator);
                            let totalQuantityForSale = 0;
                            a.forEach((itemLocator, index) => {
                              if (index === key) {
                                // Lọc ra danh sách Locator để xử lý tìm lot
                                const locatorFilter = itemLocator.filter(itemLoca => itemLoca?.locators?.id === value );
                                totalQuantityForSale = locatorFilter.reduce((prev, curr) => prev + curr?.totalQuantityForSale, 0);
                                setLocatorFind(locatorFilter)
                                // console.log(locatorFilter)
                              }
                            });
                            const changeLocator = form?.getFieldValue(['productChildPack'])
                            changeLocator[name].lot = undefined
                            changeLocator[name].expireDateOfMaster = undefined
                            changeLocator[name].quantityMasterAvaliableForSale = totalQuantityForSale ;
                            form.setFieldsValue({productChildPack: changeLocator})
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }
                          }
                        >
                        {
                          dataProductChildPack?.map(item => item?.locator)?.map((itemLocator, index) => { 
                            if (index === key) {
                              // XỬ LÝ KHI CÓ LOCATOR TRÙNG NHAU
                              if (itemLocator) {
                                const dataSet = [];
                                itemLocator.forEach((item, keyLocator) => {
                                  if (keyLocator === 0) {
                                    dataSet.push(item);
                                  } 
                                  else {
                                    // 1. Lấy ra danh sách idLocator trong dataSet
                                    const listIdLocator = dataSet?.map(idLoca => idLoca?.locators?.id);
                                    // 2. Kiểm tra xem id hiện tại có tồn lại trong mảng idLocator ở b1 hay k?
                                    if (!listIdLocator.includes(item?.locators?.id)) {
                                    // 3. Nếu không có thì push vào còn có thì thôi
                                      dataSet.push(item);
                                    }
                                  }
                                });
                                return dataSet?.map((dataUsing, idx) => <Option key={idx} value={dataUsing?.locators?.id}>{`${dataUsing?.locators?.locator} - ${dataUsing?.locators?.locatorName}`}</Option>)
                              }
                            }
                            return true
                          })
                        }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item
                        name={[name, 'lot']}
                        label={lang.lot}
                        rules={[
                            {
                              required: true,
                              message: 'Lot là bắt buộc'
                            }
                          ]}
                      >
                        <Select
                          placeholder={lang.lot}
                          showSearch
                          // optionFilterProp="children"
                          // filterOption={(input, option) => {
                          //   return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          // }
                          // }
                          onChange={(value) => {
                            const changeLot = form?.getFieldValue(['productChildPack'])
                            changeLot[name].expireDateOfMaster = undefined
                            form.setFieldsValue({productChildPack: changeLot})

                            const locatorFilter1 = locatorFind.filter(itemLoca => itemLoca?.lot === value);
                            const totalQuantityForSale = locatorFilter1.reduce((prev, curr) => prev + curr?.totalQuantityForSale, 0)
                            const changeLocator = form?.getFieldValue(['productChildPack'])
                            changeLocator[name].quantityMasterAvaliableForSale = totalQuantityForSale
                            form.setFieldsValue({productChildPack: changeLocator})
                          }}
                        >
                          {
                          locatorFind?.reduce((prev, curr) => {
                            // Chỗ này còn đang sai nếu như quà tặng hàng base thứ 1, thứ 2 và thứ n có cùng locator
                            // Nếu k có dòng if này thì khi chọn xong lot t2 và đi chọn lại lot t1 sẽ hiển thị ra lot t2 dó nó set lại cái locatorFind
                            if (curr?.locators?.id === form?.getFieldValue(['productChildPack', name, 'locator'])) {
                              // Nếu lot trc đó bằng lot hiện tại mà không xảy ra thì lấy cả 2 lot. TH này do locator có 2 lot
                              if (!prev.some(lot => lot === curr.lot)) {
                                return [...prev, curr.lot]; // ...prev tức là nếu locator này có 2 lot nó sẽ giải thêm vào
                              }
                              return prev;
                            }
                            return prev
                          }, [])?.map((item) => (
                            <Option key={item} value={item}>{item}</Option>
                          ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item shouldUpdate>
                        {() => 
                       <Form.Item
                          name={[name, 'expireDateOfMaster']}
                          label={lang.expireDate}
                          rules={[
                            {
                              required: true,
                              message: 'ExpireDate là bắt buộc'
                            }
                          ]}
                        >
                          <Select
                            placeholder={lang.expireDate}
                            showSearch
                            onChange={(value) => {
                              const locatorFilter1 = locatorFind.filter(itemLoca => itemLoca?.expireDate === value && itemLoca?.lot === form?.getFieldValue(['productChildPack', name, 'lot']));
                              const totalQuantityForSale = locatorFilter1.reduce((prev, curr) => prev + curr?.totalQuantityForSale, 0)
                              const changeLocator = form?.getFieldValue(['productChildPack'])
                              setDataGetLot(changeLocator)
                              changeLocator[name].quantityMasterAvaliableForSale = totalQuantityForSale - (totalQuantityPack * productFind?.comboData?.masterProduct[name]?.quantity)
                              form.setFieldsValue({productChildPack: changeLocator})
                            }}
                          >
                            {
                             locatorFind?.filter(val => val.lot === form?.getFieldValue(['productChildPack', name, 'lot']))?.map(item => (
                                <Option key={item.expireDate} value={item.expireDate}>{item.expireDate}</Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                        }
                      </Form.Item>
                    </Col>

                    <Col lg ={{span: orderType === 'PACKING' ? 8 : 16 }} xs={{ span: 24 }}>
                      <Form.Item
                        name={[name, "quantityMaster"]}
                        label={orderType === 'PACKING' && totalQuantityPack === 0 ? 'SL cấu hình' : 
                        orderType === 'PACKING' && totalQuantityPack > 0 ? `Số lượng lấy (SL cấu hình: ${productFind?.comboData?.masterProduct[name]?.quantity})` :
                        'Số lượng trả lại'
                        }
                      >
                        <InputNumber disabled style={{ width: '100%' }}  min={0} max={productFind?.capacity} />
                      </Form.Item>
                    </Col>

                    {orderType === 'PACKING' && <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item
                        name={[name, "quantityMasterAvaliableForSale"]}
                        label={'SL khả dụng (Lon)'}
                      >
                        <InputNumber disabled style={{ width: '100%' }}  min={0} />
                      </Form.Item>
                    </Col>}
                  </>
                ))}
              </>
            )}
        </Form.List>
        {productFind && <Divider />}

        {/* ==========End Quà tặng hàng Base ==========*/}

        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
         <Form.Item
            name={'lotPacking'}
            wrapperCol={24}
            label={lang.lot + ' của SP đóng Pack'}
           
          >
            <Select
              placeholder={lang.lot + ' của SP đóng Pack'}
              showSearch
              onChange={(value) => {
                const dataLot = form.getFieldValue('productChildPack');
                const expireByLot = dataLot?.find(itemExpireDate => itemExpireDate?.lot === value)?.expireDateOfMaster;
                form.setFieldsValue({expireDatePacking: expireByLot})
              }}
            >
              {
                dataGetLot?.map((itemLot, index) => (
                  <Option key={index} value={itemLot.lot}>{itemLot.lot}</Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
         <Form.Item
            name={'expireDatePacking'}
            wrapperCol={24}
            label={lang.expireDate + ' của SP đóng Pack'}
           
          >
            <Select
              placeholder={lang.expireDate + ' của SP đóng Pack'}
              showSearch
              onChange={(value) => {}}
            >
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
         <Form.Item
            name={'totalQuantity'}
            wrapperCol={24}
            label={'Số lượng Packing (lon)'}
            rules={[
              {
                required: true,
                message: 'Số lượng là bắt buộc',
              },
              {
                // eslint-disable-next-line prefer-promise-reject-errors
                validator: async (_, value) => {
                  let findProductVas = dataVas.map(item => item?.bomProduction);
                  let nameVas = [];
                  findProductVas.forEach((bomPro, index) => {
                    if ((value * dataVas[index]?.using) > bomPro?.stockAvailableForSale) {
                      nameVas.push(bomPro?.name);
                    }
                    return '';
                  })
                  return value ? (
                    nameVas?.length > 0 ?  Promise.reject(`${nameVas.toString()} không đủ`) :
                    // (value <= locatorFind.reduce((prev, curr) => prev + curr?.totalQuantityForSale, 0) || value <= locatorGiftFind.reduce((prev, curr) => prev + curr?.quantityForSale, 0)) ? Promise.resolve() :
                    // Promise.reject('SL đóng Pack phải nhỏ hơn hoặc bằng SL khả dụng của sản phẩm quà tặng')
                    Promise.resolve()
                   ) : 
                   Promise.resolve()
                }
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} 
              onChange={(value) => {
                if (value) {
                  setTotalQuantityPack(value);

                  const DataProChildPack = form?.getFieldValue('productChildPack');
                    DataProChildPack.forEach((item, index) => {
                       // Gán SL lấy
                      DataProChildPack[index].quantityMaster = value * productFind?.comboData?.masterProduct[index]?.quantity;
                      // Gán SL khả dụng
                      DataProChildPack[index].quantityMasterAvaliableForSale = locatorFind.reduce((prev, curr) => prev + curr?.totalQuantityForSale, 0) - (value * productFind?.comboData?.masterProduct[index]?.quantity);
                      form.setFieldsValue({productChildPack: DataProChildPack})
                    });

                  const DataProGiftChildPack = form?.getFieldValue('productGiftChildPack');
                    DataProGiftChildPack.forEach((item, index) => {
                       // Gán SL lấy
                      DataProGiftChildPack[index].quantityGift = value * productFind?.comboData?.bomGift[index]?.quantity;
                       // Gán SL khả dụng
                      DataProGiftChildPack[index].quantityGiftAvaliableForSale = locatorGiftFind.reduce((prev, curr) => prev + curr?.quantityForSale, 0) - (value * productFind?.comboData?.bomGift[index]?.quantity);
                      form.setFieldsValue({productGiftChildPack: DataProGiftChildPack})
                    });
                }
              }} 
              defaultValue={0} 
              placeholder={'Số lượng Packing'} min={0} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Col lg={{ span: 12 }} xs={{ span: 8 }}>
            <Row>
              <h3>{'Thông tin nguyên phụ liệu:'}</h3>
            </Row>
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item shouldUpdate>
              {(form) => {
                if (productFind) {
                  // const 
                  if (orderType === 'PACKING') {
                    return dataVas?.length > 0 ? dataVas.map((item) => {
                      // let quantity = quantityPack + quantityMaster;
                      let totalQuantity = form.getFieldValue('totalQuantity');
                      let totalStockAvailableForSale = item?.bomProduction?.stockAvailableForSale;
                      let totalQuantityTinTake = ( totalQuantity * item?.using > 0 ?  totalQuantity * item?.using : 0);
                      // const StockAvailableForSale = totalStockAvailableForSale - totalQuantityTinTake;
                      //Tính toán để sd lại SL khả dụng của BomProduction khi có SP thứ 2 trở đi
                      // if (StockAvailableForSale > 0) {
                      //   if (sessionStorage) {
                      //     sessionStorage.setItem('StockAvailableForSale', StockAvailableForSale)
                      //     sessionStorage.setItem('idBomProduction', item?.bomProduction?.id)
                      //     sessionStorage.setItem('nameBomProduction', item?.bomProduction?.name)
                      //   }
                      //   // TODO
                      // }
                      return (
                        <div>{item?.bomProduction?.name} - <span>Using: {item?.using}</span> - <span>Khả dụng: { totalQuantity > 0 ? totalStockAvailableForSale - totalQuantityTinTake > 0 ? totalStockAvailableForSale - totalQuantityTinTake : 0 : totalStockAvailableForSale}</span> - <span>Số lượng cần lấy: {totalQuantityTinTake}</span></div>
                      )
                    }) : <div style={{color: 'red'}}>Sản phẩm chưa có cấu hình</div>
                  } else {
                    return dataVas?.length > 0 ? dataVas.map((item) => {
                      // let quantity = quantityPack + quantityMaster;
                      let totalQuantity = form.getFieldValue('totalQuantity');
                      let totalStockAvailableForSale = item?.bomProduction?.stockAvailableForSale;
                      let totalQuantityTinTake = ( totalQuantity * item?.using > 0 ?  totalQuantity * item?.using : 0);
                      const totalQuantityUnPacking = parseInt(totalStockAvailableForSale) + parseInt(totalQuantityTinTake);
                      // const StockAvailableForSale = totalStockAvailableForSale - totalQuantityTinTake;
                      //Tính toán để sd lại SL khả dụng của BomProduction khi có SP thứ 2 trở đi
                      // if (StockAvailableForSale > 0) {
                      //   if (sessionStorage) {
                      //     sessionStorage.setItem('StockAvailableForSale', StockAvailableForSale)
                      //     sessionStorage.setItem('idBomProduction', item?.bomProduction?.id)
                      //     sessionStorage.setItem('nameBomProduction', item?.bomProduction?.name)
                      //   }
                      //   // TODO
                      // }
                      return (
                        <div>{item?.bomProduction?.name} - <span>Using: {item?.using}</span> - <span>Khả dụng: { totalQuantity > 0 ? `${totalQuantityUnPacking}` : totalStockAvailableForSale}</span> - <span>Số lượng trả lại: {totalQuantityTinTake}</span></div>
                      )
                    }) : <div style={{color: 'red'}}>Sản phẩm chưa có cấu hình</div>
                  }
                  
                }
              }}
            </Form.Item>
          </Col>
        </Col>
        </Col>
      </Row>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductPackEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  items: PropTypes.array,
  type: PropTypes.string,
  orderType: PropTypes.string,
};

export default ReceiptProductPackEditForm;
