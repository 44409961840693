import request from 'utils/request';
import environment from 'environment';

const mockData = [
  {
    id: 1,
    recieptId: 'REP12343',
    confirmDate: '24/8/2021',
    productCode: 'PD_01',
    productCategorys: 'Hàng tiêu dùng thiết yếu',
    packageCode: 'PK01',
    productName: 'Nước mắm chinsu',
    totalQuantity: 6,
    zone: 'Zone A',
    block: 'Block A',
    shelf: 'Shefl A1',
    rack: 'R_01',
    supplier: 'NHÀ PHÂN PHỐI CHINSU',
    cost: 30000,
    salesPrice: 40000,
    expiratinDate: '18/8/2022',
    status: 'STORED',
  },
  {
    id: 2,
    recieptId: 'REP12343',
    confirmDate: '24/8/2021',
    productCode: 'PD_02',
    productCategorys: 'Đồ uống',
    packageCode: 'PK02',
    productName: 'Sữa tươi vinamilk',
    totalQuantity: 6,
    zone: 'Zone A',
    block: 'Block A',
    shelf: 'Shefl A2',
    rack: 'R_02',
    supplier: 'NHÀ PHÂN PHỐI SỮA VINAMILK',
    cost: 24000,
    salesPrice: 30000,
    expiratinDate: '18/8/2022',
    status: 'STORED',
  },
  {
    id: 3,
    recieptId: 'REP12344',
    confirmDate: '24/8/2021',
    productCode: 'PD_03',
    productCategorys: 'Rượu, bia',
    packageCode: 'PK03',
    productName: 'Bia tiger',
    totalQuantity: 24,
    zone: 'Zone A',
    block: 'Block A',
    shelf: 'Shefl A3',
    rack: 'R_03',
    supplier: 'NHÀ PHÂN PHỐI TIGER TẠI TPHCM',
    cost: 315000,
    salesPrice: 350000,
    expiratinDate: '18/8/2022',
    status: 'STORED',
  },
  {
    id: 4,
    recieptId: 'REP12344',
    confirmDate: '24/8/2021',
    productCode: 'PD_04',
    productCategorys: 'Văn phòng phẩm',
    packageCode: 'PK04',
    productName: 'Bút bi thiên long',
    totalQuantity: 20,
    zone: 'Zone A',
    block: 'Block A',
    shelf: 'Shefl A4',
    rack: 'R_04',
    supplier: 'CÔNG TY VĂN PHÒNG PHẨM THIÊN LONG',
    cost: 100000,
    salesPrice: 150000,
    expiratinDate: '18/8/2022',
    status: 'STORED',
  },
  {
    id: 5,
    recieptId: 'REP12345',
    confirmDate: '24/8/2021',
    productCode: 'PD_05',
    productCategorys: 'Đồ uống',
    packageCode: 'PK05',
    productName: 'Revive chanh muối',
    totalQuantity: 6,
    zone: 'Zone A',
    block: 'Block A',
    shelf: 'Shefl A5',
    rack: 'R_05',
    supplier: 'NHÀ PHÂN PHỐI HÀNG TIÊU DÙNG',
    cost: 6000,
    salesPrice: 10000,
    expiratinDate: '18/8/2022',
    status: 'STORED',
  },
  {
    id: 6,
    recieptId: 'REP12345',
    confirmDate: '24/8/2021',
    productCode: 'PD_06',
    productCategorys: 'Đồ uống',
    packageCode: 'PK06',
    productName: 'Sữa milo',
    totalQuantity: 6,
    zone: 'Zone A',
    block: 'Block A',
    shelf: 'Shefl A6',
    rack: 'R_06',
    supplier: 'NHÀ PHÂN PHỐI HÀNG TIÊU DÙNG',
    cost: 80000,
    salesPrice: 80000,
    expiratinDate: '18/8/2022',
    status: 'STORED',
  },
  {
    id: 7,
    recieptId: 'REP12346',
    confirmDate: '24/8/2021',
    productCode: 'PD_07',
    productCategorys: 'Đồ uống',
    packageCode: 'PK06',
    productName: 'Sữa milo',
    totalQuantity: 6,
    zone: 'Zone A',
    block: 'Block A',
    shelf: 'Shefl A6',
    rack: 'R_06',
    supplier: 'NHÀ PHÂN PHỐI HÀNG TIÊU DÙNG',
    cost: 80000,
    salesPrice: 80000,
    expiratinDate: '18/8/2022',
    status: 'STORED',
  },
];

export async function inboundControlList(payload) {
  //return request(environment.api.receiptList, payload, 'GET');
  return new Promise((resolve, reject) => {
    resolve(mockData);
  });
}

export async function receiptDetail(payload) {
  return request(environment.api.receiptDetail, payload, 'GET');
}

export async function receiptCreate(payload) {
  return request(environment.api.receiptCreate, payload, 'POST');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
    resolve('1');
  });
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.products + `/create/excel/${warehouseId}`, formData, 'POST');
}
export async function recommendRack(payload) {
  return request(environment.api.racks + '/recommend', payload, 'GET');
}

export async function productGiftUpdates(payload) {
  return request(environment.api.productGifts + "/updates", payload, 'POST');
}

export async function productGiffGetAll(params) {
  return request(environment.api.productGifts + "/get-all", params, 'GET');
}

