import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, message, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { customerReturnDetail } from './receipt.service';
import moment from 'moment';

import './style.less';
import TableCustom from 'components/TableCustom/table';
import { formatDate, formatDateFull } from 'utils/constants';
import { receiptCreate } from '../receipt/receipt.service';
import CustomerRMAProductForm from './customer-return-edit.edit';
// import CustomerRMAProductForm from './customer-return-edit.edit';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const CustomerRMADetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [itemsReceiptProduct, setItemsReceiptProduct] = useState([]);
  const [receipt, setReceipt] = useState();
  const [isModalLocator, setIsModalLocator] = useState(false);
  useEffect(() => {
    setLoading(true);
    customerReturnDetail({ id: parseInt(id) })
      .then(result => {
        setReceipt(result);
        setItemsReceiptProduct(result?.customerRMAProduct);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [id]);

  const columnsReceiptProduct = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'nameEN',
      width: '15%',
    },
    // {
    //   title: 'RMA',
    //   dataIndex: 'RMA',
    //   key: 'RMA',
    // },
    {
      title: 'lineNo',
      dataIndex: 'lineNo',
      key: 'lineNo',
    },
    {
      title: 'origDOLine',
      dataIndex: 'origDOLine',
      key: 'origDOLine',
    },
    // {
    //   title: 'charge',
    //   key: 'charge',
    //   dataIndex: 'charge',
    // },
    {
      title: 'attributeSetInstance',
      key: 'attributeSetInstance',
      dataIndex: 'attributeSetInstance',
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'unitPrice',
      key: 'unitPrice',
      dataIndex: 'unitPrice',
    },
    // {
    //   title: lang.action,
    //   align: 'center',
    //   width: '8%',
    //   fixed: 'right',
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<EditOutlined />}
    //         onClick={() => {
    //           setIsEditModalReceiptProduct(true);
    //           setReceiptProduct(record);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      // disabled={itemsReceiptProduct.length > 0}
      key="btn-complete"
      type="primary"
      onClick={() => setIsModalLocator(true)}
      disabled={receipt?.receipt}
    >
      {lang.createReceiptReturn}
    </Button>,
  ];
  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/customer-return',
      breadcrumbName: lang.customerReturn,
    },
    {
      path: '/',
      breadcrumbName: lang.customerReturnDetail,
    },
  ];
  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.customerReturnDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions className="descriptions-class" column={{ xl: 5, md: 4, sm: 2, xs: 1 }}>
            <Descriptions.Item label={'Mã ' + lang.customerReturn}>{receipt?.documentNo}</Descriptions.Item>
            <Descriptions.Item label={lang.company}>{receipt?.company}</Descriptions.Item>
            <Descriptions.Item label={lang.branch}>{receipt?.branch}</Descriptions.Item>
            {/* <Descriptions.Item label={lang.documentNo}>{receipt?.documentNo}</Descriptions.Item> */}
            <Descriptions.Item label={lang.orderReference}>{receipt?.orderReference}</Descriptions.Item>
            <Descriptions.Item label={lang.description}>{receipt?.description}</Descriptions.Item>
            <Descriptions.Item label={lang.targetDocType}>{receipt?.targetDocType}</Descriptions.Item>
            <Descriptions.Item label={lang.rmaCategory}>{receipt?.rmaCategory}</Descriptions.Item>
            <Descriptions.Item label={lang.origSalesOrder}>{receipt?.origSalesOrder}</Descriptions.Item>
            <Descriptions.Item label={lang.origDO}>{receipt?.origDO}</Descriptions.Item>
            <Descriptions.Item label={lang.returnDate}>{moment(receipt?.rmaDate, formatDateFull).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.DODate}>{moment(receipt?.DODate, formatDateFull).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.businessPartner}>{receipt?.businessPartner}</Descriptions.Item>
            <Descriptions.Item label={lang.billTo}>{receipt?.billTo}</Descriptions.Item>
            <Descriptions.Item label={lang.partnerLocation}>{receipt?.partnerLocation}</Descriptions.Item>
            <Descriptions.Item label={lang.billToLocation}>{receipt?.billToLocation}</Descriptions.Item>
            <Descriptions.Item label={lang.repressentative}>{receipt?.repressentative}</Descriptions.Item>
            <Descriptions.Item label={lang.businessLine}>{receipt?.businessLine}</Descriptions.Item>
            <Descriptions.Item label={lang.Area}>{receipt?.warehouse}</Descriptions.Item>
            <Descriptions.Item label={lang.campain}>{receipt?.campaign}</Descriptions.Item>
            <Descriptions.Item label={lang.department}>{receipt?.department}</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() =>
            <Row>
              <Col span={12}><h3>{lang.productList}</h3></Col>
              <Col span={12} style={{ textAlign: 'right' }}></Col>
            </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsReceiptProduct}
          dataSource={itemsReceiptProduct}
          pagination={false}
          scroll={{ x: 1800 }}
        />
      </Layout.Content>
      <Modal
        visible={isModalLocator}
        title={lang.createReceiptReturn}
        onCancel={() => setIsModalLocator(false)}
        destroyOnClose={true}
        width={1400}
        centered
        footer={null}
      >
        <CustomerRMAProductForm
          items={itemsReceiptProduct}
          onCallBack={(products) => {
            try {
              setLoading(true);
              const { orderReference, rmaDate, description, documentNo, partnerLocation, origSalesOrder, businessPartner, rmaCategory, warehouseId, code } = receipt;
              const values = { orderReference, rmaDate, description, documentNo, partnerLocation, origSalesOrder, businessPartner, rmaCategory, receiptDate: moment().format(formatDateFull), warehouseId, inboundkind: 'RETURN', rma: code };
              values.locatorCode = products?.customerRMAProducts[0]?.locator;
              values.receiptProducts = receipt?.customerRMAProduct?.map(product => {
                const { origDOLine, lineNO, masterProduct, quantity, warehouseId, attributeSetInstance } = product;
                const finded = products?.customerRMAProducts?.find(pro => pro.id === product.id);
                const split = attributeSetInstance.split('_');
                return {
                  DO: origDOLine,
                  masterProductId: masterProduct?.id,
                  lineNO,
                  expireDate: split?.[1] ? moment(split[1],'DD-MM-YYYY').format('YYYY-MM-DD'): undefined,
                  warehouseId,
                  quantityTin: quantity,
                  lot: split?.[0],
                  quantity,
                  quantityCartton: 0,
                  locatorCode: finded?.locator,
                };
              });
              receiptCreate(values)
                .then(result => {
                  setLoading(false);
                  Modal.success({
                    title: lang.createReceiptSuccessfully,
                    content: `${lang.receiptCode}: ${result?.code}`,
                    icon: <CheckOutlined />,
                    onOk() {
                      navigate('/dashboard/inbound/receipt');
                    },
                  });
                })
                .catch((error) => {
                  message.error(error?.message);
                  setLoading(false);
                });
              setIsModalLocator(false);
              return true;
            } catch (error) {
              if (error?.message) {
                setIsModalLocator(false);
                setLoading(false);
                message.error(error.message);
              }
            }
          }}
          onCancel={() => {
            setIsModalLocator(false);
          }}
          item={receipt}
        />
      </Modal>
    </Layout>
  );
};

export default CustomerRMADetail;
