import React, { useEffect, useState } from 'react';
import { Card, Input, Col, Row, Button, Tag, Modal, Form, InputNumber, message } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import PropTypes from 'prop-types';
import { ScanOutlined, PlusOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { scanReturnProductCodeAdd, scanStoringProductCodeAdd, scanInboundControlProductCodeAdd, scanVasProductCodeAdd, scanProductionOrderProductCodeAdd } from 'providers/InboundProvider/actions';
import { scanPickingProductCodeAdd, scanPackagingProductCodeAdd, scanSplitProduct, packagingOrderSet } from 'providers/OutboundProvider/actions';
import { ProFormSelect } from '@ant-design/pro-form';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { scanReallocationProduct } from 'providers/InventoryProvider/actions';
// import soundOn from '../../assets/audio/SpeechOn.wav';
// import soundOff from '../../assets/audio/SpeechOff.wav';
import { BASE64_NO, BASE64_YES } from 'utils/constants';
import { parseInt } from 'lodash';
import { orderUpdateStatus, productPicking, productPickingDO, productUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service';
import { productGiftScan, productScan } from 'pages/dashboard/inbound/product.service';
import { productGiftPicking } from 'pages/dashboard/outbound/exportBranchGift/export-branch-gift.service';
import { productSplit } from 'pages/dashboard/outbound/picking/picking.service';
const ScanProductForm = ({ type, selectOrderProduct, pickingOrder, setSelectedOrder, selectedOrder, productionOrderProduct, inputRef, onCallbackPrint, onCallBackEffect, inputRefPro }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [countModal, setCountModal] = useState(false);
  const [modalGift, setModalGift] = useState(false);
  // const [modalVas, setModalVas] = useState(false);
  const [modalSplitProduct, setModalSplitProduct] = useState(false);
  const {currentWarehouse} = useSelector(state => state.auth)
  const { scanReturnForProductCodes, scanStoringForProductCodes, scanInboundControlForProductCodes, scanVasForProductCodes, scanProductionOrderForProductCodes } = useSelector((state) => state.inbound);
  const { scanPickingForProductCodes, scanPackagingForProductCodes, scanSplitProductCode } = useSelector((state) => state.outbound);
  const { scanReallocationProductCode } = useSelector((state) => state.inventory);
  const audioOn = new Audio('data:audio/wav;base64,' + BASE64_YES);
  const audioOff = new Audio('data:audio/wav;base64,' + BASE64_NO);
  const [productPick, setProductPick] = useState();
  const [dataProductSplit, setDataProductSplit] = useState();
  const [productGiftPick, setProductGiftPick] = useState();
  const [productTags, setProductTags] = useState([]);
  useEffect(() => {
    if (type === 'SCAN_RETURN') {
      setProductTags(scanReturnForProductCodes);
    }

    if (type === 'STORING') {
      setProductTags(scanStoringForProductCodes);
    }

    if (type === 'INBOUND_CONTROL') {
      setProductTags(scanInboundControlForProductCodes);
    }

    if (type === 'VAS') {
      setProductTags(scanVasForProductCodes);
    }

    if (type === 'PRODUCTORDER') {
      setProductTags(scanProductionOrderForProductCodes);
    }

    if (type === 'PICKING') {
      setProductTags(scanPickingForProductCodes);
    }

    if (type === 'PACKAGING') {
      setProductTags(scanPackagingForProductCodes || []);
    }
    if (type === 'INVENTORY') {
      setProductTags(scanReallocationProductCode || []);
    }
    if (type === 'SPLIT') {
      setProductTags(scanSplitProductCode || []);
    }
    if (type !== 'INBOUND_CONTROL') {
      return inputRefPro?.current?.focus({
        preventScroll: true,
      });
    }
  }, [scanReturnForProductCodes, scanStoringForProductCodes, scanInboundControlForProductCodes, scanReallocationProductCode, scanSplitProductCode,
    scanVasForProductCodes, scanProductionOrderForProductCodes, scanPickingForProductCodes, scanPackagingForProductCodes, type, inputRefPro]);
  useEffect(() => {
    return inputRefPro?.current?.focus({
      preventScroll: true,
    });
  }, [type, inputRefPro])
  const [productInputValue, setProductInputValue] = useState();
  const forMapProduct = tag => {
    const tagElem = (
      <Tag
        style={{ marginBottom: 10 }}
        closable
        onClose={e => {
          e.preventDefault();
          handleCloseProduct(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };
  const handleCloseProduct = removedTag => {
    const tagsHasRemoved = ['PICKING', 'PACKAGING', 'PRODUCTORDER'].includes(type) ? productTags.filter(tag => tag?.code !== removedTag) : productTags.filter(tag => tag !== removedTag);

    if (type === 'SCAN_RETURN') {
      dispatch(scanReturnProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'STORING') {
      dispatch(scanStoringProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'INBOUND_CONTROL') {
      dispatch(scanInboundControlProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'VAS') {
      dispatch(scanVasProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'PICKING') {
      // dispatch(scanPickingProductCodeAdd(tagsHasRemoved));
      dispatch(scanPickingProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'PRODUCTORDER') {
      // dispatch(scanPickingProductCodeAdd(tagsHasRemoved));
      dispatch(scanProductionOrderProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'PACKAGING') {
      dispatch(scanPackagingProductCodeAdd(tagsHasRemoved));
    }
    if (type === 'INVENTORY') {
      dispatch(scanReallocationProduct(tagsHasRemoved));
    }
    if (type === 'SPLIT') {
      dispatch(scanSplitProduct(tagsHasRemoved));
    }

    setProductTags(tagsHasRemoved);
  };

  const handleInputConfirmProduct = async () => {
    let tagsHasInput = productTags;
    if (type !== 'PICKING' && type !== 'PACKAGING' && type !== 'PRODUCTORDER') {
      if (productInputValue && productTags.indexOf(productInputValue) === -1) {
        tagsHasInput = [...productTags, productInputValue];
      }

      if (type === 'SCAN_RETURN') {
        dispatch(scanReturnProductCodeAdd(tagsHasInput));
      }

      if (type === 'STORING') {
        dispatch(scanStoringProductCodeAdd(tagsHasInput));
      }

      if (type === 'INBOUND_CONTROL') {
        dispatch(scanInboundControlProductCodeAdd(tagsHasInput));
      }

      if (type === 'VAS') {
        dispatch(scanVasProductCodeAdd(tagsHasInput));
      }

      if (type === 'SPLIT') {
        dispatch(scanSplitProduct(tagsHasInput));
      }
      if (type === 'INVENTORY') {
        dispatch(scanReallocationProduct(tagsHasInput));
      }
    } else if (productInputValue && type === 'PACKAGING') {
      try {
        const { orders, pickingProducts } = pickingOrder;
        const locatorCodeTo = selectedOrder?.productOrders?.[0]?.locatorCodeTo;
        const locatorTransitCode = selectedOrder?.locatorTransitCode;
        await productUpdateStatus({ codes: [productInputValue], status: 'TEMPORARY_OUT', warehouseId: selectedOrder.warehouseId, orderCode: selectedOrder?.code, locatorCodeTo, locatorTransitCode });
        if (pickingProducts?.filter(pro => pro.code !== productInputValue).length === 0) {
          const orderUpdatesReady = { ids: [selectedOrder.id], status: 'READY' };
          await orderUpdateStatus(orderUpdatesReady);
        }
        const tagsHasInput = [...productTags, { code: productInputValue, orderId: selectedOrder?.id }];
        setProductTags(tagsHasInput);
        setProductInputValue('');
        dispatch(scanPackagingProductCodeAdd(tagsHasInput));
        dispatch(packagingOrderSet({
          packagingOrder: { orders },
          pickingProducts: pickingProducts?.filter(pro => pro.code !== productInputValue),
        }));
        message.success(lang.success);
        return true;
      } catch (error) {
        message.error(error.message);
        return false;
      }

    } else if (productInputValue && type === 'PRODUCTORDER') {
      const product = productionOrderProduct?.find(product => product.code === productInputValue);
      if (product) {
        audioOn.play();
        // tagsHasInput = [...productTags, productInputValue];
        // dispatch(scanPackagingProductCodeAdd(tagsHasInput));
        setCountModal(true);
        return true;
      } else {
        audioOff.play();
        Modal.confirm({
          title: 'Bạn có muốn thêm sản phẩm?',
          icon: <ExclamationCircleOutlined />,
          content: 'Mã QR code bạn vừa nhập không có trong danh sách sản phẩm đã lấy',
          okText: lang.okText,
          cancelText: lang.cancelText,
          onOk() {
            // tagsHasInput = [...productTags, productInputValue];
            // setProductTags(tagsHasInput);
            // setProductInputValue('');
            // dispatch(scanPackagingProductCodeAdd(tagsHasInput));
            setCountModal(true);
          },
        });
        return true;
      } 
    } else {
      if (productInputValue && selectOrderProduct.length > 0) {
        // GIFT
        if (selectOrderProduct[0]?.bomGift) {
          // Quét mã để tìm SP trong kho
          const response = await productGiftScan({sortBy: 'id', sortDirection: 'desc', warehouseId: selectOrderProduct?.[0]?.warehouseId, productCodes: productInputValue});
          // Tìm SP trong đơn hàng trùng với SP quét đc vì đơn hàng có thể có nhiều sp
          const productSelectFind = selectOrderProduct?.find(pro => pro?.bomGift?.id === response?.bomGift?.id);

          if (response) {
            audioOn.play();
            setProductGiftPick({
            quantity: response.quantity,
            quantityPick: productSelectFind?.quantity,
            groupOrder: response?.groupOrder,
            orderId: productSelectFind?.orderId,
            pickedQuantity: productSelectFind?.pickedQuantity,
            userName: productSelectFind?.userName,
            id: response?.id
          });
          setModalGift(true);
          return true;
          } else {
              audioOff.play();
              Modal.confirm({
              title: 'Sản phẩm không hợp lệ',
              icon: <CloseCircleOutlined />,
              content: 'Mã QR code bạn vừa nhập không có trong danh sách sản phẩm gợi ý',
              okText: lang.okText,
              cancelText: lang.cancelText,
              okButtonProps: {
                hidden: true
              }});
              return true;
            }
        }
        const response = await productScan({ productCodes: [productInputValue], type: 'PICKING', warehouseId: selectOrderProduct?.[0]?.locator?.warehouseId || selectOrderProduct?.[0]?.warehouseId });
        const rsProductScan = response?.successList[0];
        const finded =  selectOrderProduct?.[0]?.orderType === 'EXPORT_SALE' ? selectOrderProduct?.find(product => product?.masterProduct ? product?.masterProduct?.code3A  === rsProductScan?.masterProduct?.code3A : 
          product?.code3A === rsProductScan?.masterProduct?.code3A)  : 
          selectOrderProduct?.find(product => product?.masterProduct?.code3A === rsProductScan?.masterProduct?.code3A && rsProductScan?.lot === product?.lot && product?.locator?.id === rsProductScan?.locator?.id);
          console.log(finded)
        if (finded) {
          audioOn.play();
          setProductPick({
            ...rsProductScan,
            quanittyCartonOrder: finded.quantityInCarton,
            quanittyTinOrder: finded?.quantityTin,
            quanittyTinOrder1: finded?.quantityInTin,
            groupOrder: finded?.groupOrder,
            orderSearchId: finded?.orderSearchId,
            doId: finded?.orderId,
            orderCode: finded?.orderCode,
            quanittyCartonPickingOrder: finded?.pickingQuantityCartton,
            quanittyTinPickingOrder: finded?.pickingQuantityTin,
            orderType: finded?.orderType,
            locatorTo: finded?.locatorTo,
            saleChannel: finded?.saleChannel,
            orderSubmitId: finded?.orderSubmitId,
            quantityCartonSubmitArr: finded?.quantityCartonSubmitArr,
            quantityTinSubmitArr: finded?.quantityTinSubmitArr,
            userName: finded?.userName
          });
          setCountModal(true);
          return true;
        } 
       
        else {
          audioOff.play();
          Modal.confirm({
            title: 'Sản phẩm không hợp lệ',
            icon: <CloseCircleOutlined />,
            content: 'Mã QR code bạn vừa nhập không có trong danh sách sản phẩm gợi ý',
            okText: lang.okText,
            cancelText: lang.cancelText,
            okButtonProps: {
              hidden: true
            }
          });
          return true;
        }
      }
    } 

    if (productInputValue && type === 'SPLIT') {
      const response = await productScan({ productCodes: [productInputValue], type: 'SPLIT', warehouseId: currentWarehouse?.id});
      const rsProductScan = response?.successList[0];
      if (rsProductScan) {
        audioOff.play();
        setDataProductSplit(rsProductScan);
      } else {
        audioOff.play();
          Modal.confirm({
            title: 'Sản phẩm không hợp lệ',
            icon: <CloseCircleOutlined />,
            content: 'Mã QR code bạn vừa nhập không có trong kho!',
            okText: lang.okText,
            cancelText: lang.cancelText,
            okButtonProps: {
              hidden: true
            }
          });
        return true;
      }
      // console.log(rsProductScan)
      setModalSplitProduct(true)
    } 

    setProductTags(tagsHasInput);
    setProductInputValue('');
    if (inputRef) {
      return inputRef?.current?.focus({
        preventScroll: true,
      });
    }
  };
  const productTagChild = ['PICKING', 'PACKAGING', 'PRODUCTORDER'].includes(type) ? productTags?.map(item => forMapProduct(item.code)) : productTags?.map(item => forMapProduct(item));
  return (
    <>
      <Card title={type === 'PACKAGING' ? <>
        <Row>
          <Col>
            <ProFormSelect label={lang.orderCode} required={true} placeholder={lang.orderCode}
              options={pickingOrder?.orders?.map(order => {
                return {
                  label: order.code,
                  value: order.id,
                };
              })}
              // initialValue={}
              fieldProps={{
                onChange: async (value) => {
                  const order = pickingOrder?.orders.find(order => order.id === value);
                  setSelectedOrder(order);
                },
                defaultValue: selectedOrder?.id,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {lang.defaultCode}
          </Col>
        </Row>
      </> : lang.productCode
      }>
        <Row>
          <Col><label>{lang.inputCode}</label></Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
          <Col col={12} style={{ flexDirection: 'row', display: 'flex' }}>
            <Input
              type="text"
              size="small"
              value={productInputValue}
              onChange={(item) => setProductInputValue(item.target.value)}
              onBlur={handleInputConfirmProduct}
              onPressEnter={handleInputConfirmProduct}
              placeholder={lang.productCode}
              ref={inputRefPro}
              allowClear
            />
          </Col>
          <Col col={6}>
            <Button type={'primary'} ghost icon={<ScanOutlined />} />
          </Col>
          <Col col={6}>
            <Button key="btn-add-product" type={'primary'} icon={<PlusOutlined />}>{lang.btnAdd}</Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <div style={{ marginBottom: 16 }}>
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: 'from',
                  duration: 100,
                  onComplete: e => {
                    e.target.style = '';
                  },
                }}
                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                appear={false}
              >
                {productTagChild}
              </TweenOneGroup>
            </div>
          </Col>
        </Row>
      </Card>
      <Modal
        visible={countModal}
        title={'Số lượng sản phẩm'}
        onCancel={() => setCountModal(false)}
        destroyOnClose={true}
        width={716}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <Form onFinish={async (values) => {
          try {
            if (type === 'PACKAGING') {
              const product = selectedOrder?.productOrders?.find(product => product?.masterProduct?.code3A === productInputValue);
              if (product?.total < values?.count) {
                Modal.confirm({
                  title: 'Waring!',
                  content: 'Số lượng đang lấy lớn hơn đơn hàng. Vui lòng kiểm tra lại!',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
                    setProductTags(tagsHasInput);
                    setProductInputValue('');
                    dispatch(scanPackagingProductCodeAdd(tagsHasInput));
                    setCountModal(false);
                  },
                  onCancel() {
                  },
                });
                return true;
              } else {
                // const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
                // setProductTags(tagsHasInput);
                // setProductInputValue('');
                // dispatch(scanPackagingProductCodeAdd(tagsHasInput));
                setCountModal(false);
                return true;
              }
            }
            if (type === 'PRODUCTORDER') {
              const product = productionOrderProduct?.find(product => product?.code === productInputValue);
              if (product?.quantityCartton < values?.quantityCartton || product?.quantityTin < values?.quantityTin) {
                Modal.confirm({
                  title: 'Waring!',
                  content: 'Mặt hàng số lượng đang lấy lớn hơn đơn hàng. Vui lòng kiểm tra lại!',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin }];
                    setProductTags(tagsHasInput);
                    setProductInputValue('');
                    dispatch(scanProductionOrderProductCodeAdd(tagsHasInput));
                    setCountModal(false);
                  },
                  onCancel() {
                  },
                });
                return true;
              } else {
                const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin }];
                setProductTags(tagsHasInput);
                setProductInputValue('');
                dispatch(scanProductionOrderProductCodeAdd(tagsHasInput));
                setCountModal(false);
                return true;
              }
            }
            // const product = selectOrderProduct?.find(product => product.code === productInputValue);
            // console.log(productPick)
            // console.log(values)
            // console.log(productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder)
            if (values?.quantityCartton === 0 && values?.quantityTin === 0) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng thùng và lon không thể bằng 0. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setCountModal(false);
                },
                onCancel() {
                },
              });
            } else if (values?.quantityCartton > (productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder) || values?.quantityTin > (productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder)) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng thùng/lon đang lấy không thể lớn hơn số lượng thùng/lon cần lấy. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin }];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setCountModal(false);
                },
                onCancel() {
                },
              });
            } else if (productPick?.quantityCartton < values?.quantityCartton || parseInt(productPick?.masterProduct?.tinPerCarton) * (productPick?.quantityCartton - values?.quantityCartton) + productPick?.quantityTin < values?.quantityTin) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng thùng/lon đang lấy lớn hơn số lượng thùng/lon còn lại trên Pallet. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setCountModal(false);
                },
                onCancel() {
                },
              });
            } else {
              const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin || values.quantityInTin}];
              const payload = {
                id: productPick?.id, group: productPick?.groupOrder, orderId: productPick?.orderSearchId, doId: productPick?.doId, locatorId: productPick?.locator?.id, locatorIdTo: productPick?.locatorTo?.id, warehouseIdTo: productPick?.locatorTo?.warehouseId,
                orderNumber: productPick?.orderNumber, orderType: productPick?.orderType, saleChannel: productPick?.saleChannel, orderSubmitId: productPick?.orderSubmitId,
                quantityCartonSubmitArr: productPick?.quantityCartonSubmitArr,
                quantityTinSubmitArr: productPick?.quantityTinSubmitArr, userName: productPick?.userName
              };
              if (!(productPick?.quantityCartton === values?.quantityCartton && productPick.quantityTin === values?.quantityTin)) {
                // console.log("SL thùng callback:", productPick?.quantityCartton)
                // console.log("SL thùng nhập:", values?.quantityCartton)
                // console.log("SL lon callback:", productPick.quantityTin)
                // console.log("SL lon nhập:", values?.quantityTin)

                payload.quantityCartonSplit = values?.quantityCartton;
                payload.quantityTinSplit = values?.quantityTin;
              }
              setProductTags(tagsHasInput);
              setProductInputValue('');
              dispatch(scanPickingProductCodeAdd(tagsHasInput));
              if (productPick?.orderType === 'EXPORT_SALE') {
                const resProDO = await productPickingDO(payload)
                message.success(lang.success);
                if (payload?.quantityCartonSplit || payload?.quantityTinSplit) {
                  onCallbackPrint(resProDO);
                } else onCallBackEffect();
                setCountModal(false);
              } 
              else {
                const res = await productPicking(payload);
                message.success(lang.success);
                if (payload?.quantityCartonSplit || payload?.quantityTinSplit) {
                  onCallbackPrint(res);
                } else onCallBackEffect();
                setCountModal(false);
              }
            }
            return true;
          } catch (error) {
            message.error(error.message);
            return false;
          }
          // dispatch(scanPickingProductCodeAdd(tagsHasInput));
        }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name='quantityCarttonPallet'
                initialValue={productPick?.quantityCartton}
                label={`${lang.quantityCartton} (Pallet)`}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityTinPallet'
                label={`${lang.quantityTin} (Pallet)`}
                initialValue={productPick?.quantityTin}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityCartton'
                initialValue={productPick?.quantityCartton < productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder ? 
                  productPick?.quantityCartton : 
                  productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder}
                label={lang.quantityCartton}
                rules={[
                  {
                    required: true,
                    message: 'Số lượng thùng là bắt buộc',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityTin'
                label={lang.quantityTin}
                initialValue={
                  // console.log(" quantityCartton:", productPick?.quantityCartton),
                  // console.log(" quanittyCartonOrder:", productPick?.quanittyCartonOrder),
                  // console.log(" quanittyCartonPickingOrder:", productPick?.quanittyCartonPickingOrder),
                  // console.log(" quantityTin:", productPick?.quantityTin),
                  // console.log(" productPick:", productPick)
                  // console.log(" quanittyTinPickingOrder:", productPick?.quanittyTinPickingOrder),
                  productPick?.quanittyTinOrder !== undefined ?
                    productPick?.quantityCartton < productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder ? 
                      productPick?.quantityTin < productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder ? 
                        productPick?.quantityTin 
                      : productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder
                    : productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder
                  :
                    productPick?.quantityCartton < productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder ? 
                      productPick?.quantityTin < productPick?.quanittyTinOrder1 - productPick?.quanittyTinPickingOrder ? 
                        productPick?.quantityTin 
                      : productPick?.quanittyTinOrder1 - productPick?.quanittyTinPickingOrder 
                    : productPick?.quanittyTinOrder1 - productPick?.quanittyTinPickingOrder
                }
                rules={[
                  {
                    required: true,
                    message: 'Số lượng lon là bắt buộc',
                  },
                ]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>
            <Col span={4} offset={16} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" onClick={() => setCountModal(false)}>{lang.btnCancel}</Button>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" disabled={(productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder === 0 && productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder === 0) || (productPick?.quantityCartton === 0 &&  productPick?.quantityTin === 0)}>{lang.btnSubmit}</Button>

            </Col>
          </Row>

        </Form>
      </Modal>
      {/* <Modal
        visible={modalVas}
        title={'Số lượng sản phẩm Vas'}
        onCancel={() => setModalVas(false)}
        destroyOnClose={true}
        width={716}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <Form onFinish={async (values) => {
          try {
            if (type === 'PACKAGING') {
              const product = selectedOrder?.productOrders?.find(product => product?.masterProduct?.code3A === productInputValue);
              if (product?.total < values?.count) {
                Modal.confirm({
                  title: 'Waring!',
                  content: 'Số lượng đang lấy lớn hơn đơn hàng. Vui lòng kiểm tra lại!',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
                    setProductTags(tagsHasInput);
                    setProductInputValue('');
                    dispatch(scanPackagingProductCodeAdd(tagsHasInput));
                    setModalVas(false);
                  },
                  onCancel() {
                  },
                });
                return true;
              } else {
                // const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
                // setProductTags(tagsHasInput);
                // setProductInputValue('');
                // dispatch(scanPackagingProductCodeAdd(tagsHasInput));
                setModalVas(false);
                return true;
              }
            }
            if (type === 'PRODUCTORDER') {
              const product = productionOrderProduct?.find(product => product?.code === productInputValue);
              if (product?.quantityCartton < values?.quantityCartton || product?.quantityTin < values?.quantityTin) {
                Modal.confirm({
                  title: 'Waring!',
                  content: 'Mặt hàng số lượng đang lấy lớn hơn đơn hàng. Vui lòng kiểm tra lại!',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin }];
                    setProductTags(tagsHasInput);
                    setProductInputValue('');
                    dispatch(scanProductionOrderProductCodeAdd(tagsHasInput));
                    setModalVas(false);
                  },
                  onCancel() {
                  },
                });
                return true;
              } else {
                const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin }];
                setProductTags(tagsHasInput);
                setProductInputValue('');
                dispatch(scanProductionOrderProductCodeAdd(tagsHasInput));
                setModalVas(false);
                return true;
              }
            }
            // const product = selectOrderProduct?.find(product => product.code === productInputValue);
            // console.log(productPick)
            // console.log(values)
            // console.log(productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder)
            if (values?.quantityCartton === 0 && values?.quantityTin === 0) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng thùng và lon không thể bằng 0. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setModalVas(false);
                },
                onCancel() {
                },
              });
            } else if (values?.quantityCartton > (productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder) || values?.quantityTin > (productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder)) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng thùng/lon đang lấy không thể lớn hơn số lượng thùng/lon cần lấy. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin }];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setModalVas(false);
                },
                onCancel() {
                },
              });
            } else if (productPick?.quantityCartton < values?.quantityCartton || parseInt(productPick?.masterProduct?.tinPerCarton) * (productPick?.quantityCartton - values?.quantityCartton) + productPick?.quantityTin < values?.quantityTin) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng thùng/lon đang lấy lớn hơn số lượng thùng/lon còn lại trên Pallet. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setModalVas(false);
                },
                onCancel() {
                },
              });
            } else {
              console.log(productPick)
              const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin || values.quantityInTin}];
              const payload = {
                id: productPick?.id, group: productPick?.groupOrder, orderId: productPick?.orderSearchId, doId: productPick?.doId, locatorId: productPick?.locator?.id, locatorIdTo: productPick?.locatorTo?.id, warehouseIdTo: productPick?.locatorTo?.warehouseId,
                orderNumber: productPick?.orderNumber, orderType: productPick?.orderType, saleChannel: productPick?.saleChannel, orderSubmitId: productPick?.orderSubmitId,
                quantityCartonSubmitArr: productPick?.quantityCartonSubmitArr,
                quantityTinSubmitArr: productPick?.quantityTinSubmitArr, userName: productPick?.userName
              };
              if (!(productPick?.quantityCartton === values?.quantityCartton && productPick.quantityTin === values?.quantityTin)) {
                // console.log("SL thùng callback:", productPick?.quantityCartton)
                // console.log("SL thùng nhập:", values?.quantityCartton)
                // console.log("SL lon callback:", productPick.quantityTin)
                // console.log("SL lon nhập:", values?.quantityTin)

                payload.quantityCartonSplit = values?.quantityCartton;
                payload.quantityTinSplit = values?.quantityTin;
              }
              setProductTags(tagsHasInput);
              setProductInputValue('');
              dispatch(scanPickingProductCodeAdd(tagsHasInput));
              if (productPick?.orderType === 'EXPORT_SALE') {
                const resProDO = await productPickingDO(payload)
                message.success(lang.success);
                if (payload?.quantityCartonSplit || payload?.quantityTinSplit) {
                  onCallbackPrint(resProDO);
                } else onCallBackEffect();
                setModalVas(false);
              } 
              else {
                // const res = await productPicking(payload);
                message.success(lang.success);
                if (payload?.quantityCartonSplit || payload?.quantityTinSplit) {
                  // onCallbackPrint(res);
                } else onCallBackEffect();
                setModalVas(false);
              }
            }
            return true;
          } catch (error) {
            message.error(error.message);
            return false;
          }
          // dispatch(scanPickingProductCodeAdd(tagsHasInput));
        }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name='quantityCarttonPallet'
                initialValue={productPick?.quantityCartton}
                label={`${lang.quantityCartton} (Pallet)`}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityTinPallet'
                label={`${lang.quantityTin} (Pallet)`}
                initialValue={productPick?.quantityTin}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityCartton'
                initialValue={
                  // Nếu thùng trên pallet < thùng đặt
                  productPick?.quantityCartton * productPick?.tinPerCarton < productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder ? 
                 // Thì lấy thùng trên pallet
                  productPick?.quantityCartton : 
                // Ngược lại nếu thùng đặt nhở hơn thùng trên pallet
                // 1. Quy lon ra thùng đặt trừ cho thùng pick đc
                // 2. Quy lon ra thùng mà có số lẻ lúc này sẽ tính vào lon
                  Math.floor(productPick?.quanittyTinOrder / (productPick?.tinPerCarton)) - productPick?.quanittyCartonPickingOrder
                }
                label={lang.quantityCartton}
                rules={[
                  {
                    required: true,
                    message: 'Số lượng thùng là bắt buộc',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityTin'
                label={lang.quantityTin}
                initialValue={
                  // console.log(" quantityCartton:", productPick?.quantityCartton),
                  // console.log(" quanittyCartonOrder:", productPick?.quanittyCartonOrder),
                  // console.log(" quanittyCartonPickingOrder:", productPick?.quanittyCartonPickingOrder),
                  // console.log(" quantityTin:", productPick?.quantityTin),
                  // console.log(" productPick:", productPick)
                  // console.log(" quanittyTinPickingOrder:", productPick?.quanittyTinPickingOrder),
                  // productPick?.quanittyTinOrder !== undefined ?
                    // productPick?.quantityCartton  * productPick?.tinPerCarton < productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder ? 
                      productPick?.quantityCartton * productPick?.tinPerCarton < productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder ? 
                        // productPick?.quantityCartton  * productPick?.tinPerCarton
                        0
                      : ((productPick?.quanittyTinOrder % (productPick?.tinPerCarton))) - productPick?.quanittyTinPickingOrder
                    // : productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder
                  // :
                  //   productPick?.quantityCartton < productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder ? 
                  //     productPick?.quantityTin < productPick?.quanittyTinOrder1 - productPick?.quanittyTinPickingOrder ? 
                  //       productPick?.quantityTin 
                  //     : productPick?.quanittyTinOrder1 - productPick?.quanittyTinPickingOrder 
                  //   : productPick?.quanittyTinOrder1 - productPick?.quanittyTinPickingOrder
                }
                rules={[
                  {
                    required: true,
                    message: 'Số lượng lon là bắt buộc',
                  },
                ]}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>
            <Col span={4} offset={16} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" onClick={() => setModalVas(false)}>{lang.btnCancel}</Button>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" disabled={(productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder === 0 && productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder === 0) || (productPick?.quantityCartton === 0 &&  productPick?.quantityTin === 0)}>{lang.btnSubmit}</Button>

            </Col>
          </Row>

        </Form>
      </Modal> */}
      <Modal
        visible={modalGift}
        title={'Số lượng sản phẩm'}
        onCancel={() => setModalGift(false)}
        destroyOnClose={true}
        width={716}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <Form onFinish={async (values) => {
          try {
            if (productGiftPick?.quantity < values?.quantityPick) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng đang lấy lớn hơn số lượng có trên pallet. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [...productTags, { code: productInputValue, quantity: values.quantityPick }];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setModalGift(false);
                },
                onCancel() {
                },
              });
            } else if (values?.quantityPick === 0) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng đang lấy không thể bằng 0. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [...productTags, { code: productInputValue, quantity: values.quantityPick }];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setModalGift(false);
                },
                onCancel() {
                },
              });
            } 
            // Nếu SL lấy lớn hơn SL còn lại cần lấy
            else if (values?.quantityPick > productGiftPick?.quantityPick - productGiftPick?.pickedQuantity) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng lấy không thể lớn hơn sô lượng cần lấy. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [...productTags, { code: productInputValue, quantity: values.quantityPick }];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanPickingProductCodeAdd(tagsHasInput));
                  setModalGift(false);
                },
                onCancel() {
                },
              });
            } else {
              const tagsHasInput = [...productTags, { code: productInputValue, quantity: values.quantityPick}];
              const payload = {
               id: productGiftPick?.id,
               quantity: values.quantityPick,
               orderId: productGiftPick?.orderId,
               userName: productGiftPick?.userName
              };
              // console.log(productPick)
              // Nếu SL Hiện có = SL lấy mà không xảy ra tức là SL lấy nhỏ hơn SL hiện có thì làm
              if (!(productGiftPick?.quantity === values?.quantityPick)) {
                payload.quantity = values.quantityPick      
              } 

              setProductTags(tagsHasInput);
              setProductInputValue('');
              dispatch(scanPickingProductCodeAdd(tagsHasInput));
              //Gọi hàm để trừ số lượng
                const res = await productGiftPicking(payload)
                if (!(productGiftPick?.quantity === values?.quantityPick)) {
                  // console.log([res])
                  onCallbackPrint(res);
                } else onCallBackEffect();
                setModalGift(false);
                message.success(lang.success);
            }
            return true;
          } catch (error) {
            message.error(error.message);
            return false;
          }
          // dispatch(scanPickingProductCodeAdd(tagsHasInput));
        }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name='quantity'
                label={`${lang.quantity} (Pallet)`}
                initialValue={productGiftPick?.quantity}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityPick'
                initialValue={productGiftPick?.quantityPick >= productGiftPick?.quantity ? 
                  (productGiftPick?.quantityPick - productGiftPick?.pickedQuantity) > productGiftPick?.quantity ? 
                  productGiftPick?.quantity : 
                  productGiftPick?.quantity - productGiftPick?.pickedQuantity : 
                  productGiftPick?.quantityPick - productGiftPick?.pickedQuantity
                }
                label={lang.quantity}
                rules={[
                  {
                    required: true,
                    message: 'Số lượng là bắt buộc',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>
            <Col span={4} offset={16} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" onClick={() => setModalGift(false)}>{lang.btnCancel}</Button>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" disabled={(productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder === 0 && productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder === 0) || (productPick?.quantityCartton === 0 &&  productPick?.quantityTin === 0)}>{lang.btnSubmit}</Button>

            </Col>
          </Row>

        </Form>
      </Modal>
      <Modal
        visible={modalSplitProduct}
        title={'Số lượng sản phẩm'}
        onCancel={() => setModalSplitProduct(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <Form onFinish={async (values) => {
          console.log(values)
          try {
            if (values?.quantityCartton === 0 && values?.quantityTin === 0) {
              Modal.confirm({
                title: 'Waring!',
                content: 'Số lượng thùng và lon không thể bằng 0. Vui lòng kiểm tra lại!',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  dispatch(scanSplitProduct(tagsHasInput));
                  setModalSplitProduct(false);
                },
                onCancel() {
                },
              });
            } else {
              // const tagsHasInput = [...productTags, { code: productInputValue, quantityCartton: values.quantityCartton, quantityTin: values.quantityTin}];
              // setProductTags(tagsHasInput);
              // setProductInputValue('');
              // dispatch(scanSplitProduct(tagsHasInput));
              const result = await productSplit({ id: dataProductSplit.id, quantityTin: values.quantityTin, quantityCartton: values.quantityCartton });
              onCallbackPrint(result);
              setModalSplitProduct(false);
            }
            return true;
          } catch (error) {
            message.error(error.message);
            return false;
          }
          // dispatch(scanPickingProductCodeAdd(tagsHasInput));
        }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name='quantityCarttonPallet'
                initialValue={dataProductSplit?.quantityCartton}
                label={`${lang.quantityCartton} (Pallet)`}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityTinPallet'
                label={`${lang.quantityTin} (Pallet)`}
                initialValue={dataProductSplit?.quantityTin}
              >
                <InputNumber disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityCartton'
                initialValue={dataProductSplit?.quantityCartton - dataProductSplit?.masterProduct?.CTMSPerPalletStorage3A > 0 ? dataProductSplit?.quantityCartton - dataProductSplit?.masterProduct?.CTMSPerPalletStorage3A : 0}
                label={lang.quantityCartton + ' có thể lấy'}
                rules={[
                  {
                    required: true,
                    message: 'Số lượng thùng là bắt buộc',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0} max={dataProductSplit?.quantityCartton - dataProductSplit?.masterProduct?.CTMSPerPalletStorage3A}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='quantityTin'
                label={lang.quantityTin + ' có thể lấy'}
                initialValue={dataProductSplit?.quantityTin}
                rules={[
                  {
                    required: true,
                    message: 'Số lượng lon là bắt buộc',
                  },
                ]}>
                <InputNumber disabled style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>
            <Col span={4} offset={16} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" onClick={() => setModalSplitProduct(false)}>{lang.btnCancel}</Button>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" disabled={(productPick?.quanittyCartonOrder - productPick?.quanittyCartonPickingOrder === 0 && productPick?.quanittyTinOrder - productPick?.quanittyTinPickingOrder === 0) || (productPick?.quantityCartton === 0 &&  productPick?.quantityTin === 0)}>{lang.btnSubmit}</Button>

            </Col>
          </Row>

        </Form>
      </Modal>
    </>
  );
};

ScanProductForm.propTypes = {
  type: PropTypes.string,
  selectOrderProduct: PropTypes.object,
  pickingOrder: PropTypes.object,
  setSelectedOrder: PropTypes.func,
  selectedOrder: PropTypes.object,
  productionOrderProduct: PropTypes.array,
  inputRef: PropTypes.object,
  onCallbackPrint: PropTypes.func,
  onCallBackEffect: PropTypes.func,
};

export default ScanProductForm;
