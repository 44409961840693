import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, message, InputNumber } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import { productUpdate } from 'pages/dashboard/inbound/product.service';

const ProductReportForm = ({ item, onCancel, onCallback, type }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="temporaryInboundProductReportForm"
      onFinish={(values) => {
        setLoading(true);
        productUpdate({
          id: item.id,
          status: 'ERROR',
          description: values.reportContent,
          lostQuantityCarton: values.lostQuantityCarton,
          lostQuantityTin: values.lostQuantityTin,
        })
          .then(result => {
            onCallback(result);
            message.success(lang.reportSuccessfully);
            setLoading(false);
          })
          .catch((e) => {
            message.error(e.message);
            setLoading(false);
          });
      }}
      initialValues={{
        productCode: item ? item?.productCode : '',
        productName: item ? item?.productName : '',
        reportContent: item ? '' : '',
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.productCode}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="productName"
            label={lang.productName}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="lostQuantityCarton"
            label={`${lang.quantityTin} (<=${item.quantityCartton})`}
            rules={[
              {
                required: true,
                message: lang.requiredQuantity,
              }]
            }
          >
            <InputNumber max={item.totalQuantity} style={{ width: '100%' }} min={0}/>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="lostQuantityTin"
            label={`${lang.quantity} (<=${item.quantityTin})`}
            rules={[
              {
                required: true,
                message: lang.requiredQuantity,
              }]
            }
          >
            <InputNumber max={item.totalQuantity} style={{ width: '100%' }} min={0}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item
            name="reportContent"
            label={lang.reportContent}
            rules={[
              {
                required: true,
                message: lang.messageReportContent,
              },
            ]}
          >
            <Input.TextArea placeholder={lang.messageReportContent} />
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ProductReportForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  type: PropTypes.string,
};

export default ProductReportForm;
