import environment from 'environments/environment';
import request from 'utils/request';

export async function categoryList(payload) {
  return request(environment.api.category + '/get-all', payload, 'GET');
}

export async function categoryDetail(payload) {
  return request(`${environment.api.category}/get-one/${payload?.categoryId}`, {}, 'GET');
}

export async function categoryCreate(payload) {
  return request(environment.api.category + '/create', payload, 'POST');
}

export async function categoryUpdate(payload) {
  return request(environment.api.category + '/update', payload, 'PUT');
}
export async function categoryDelete(id) {
  return request(environment.api.category + `/delete/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.category + `/create/excel/${warehouseId}`, formData , 'POST');
}