/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, Modal } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
// import { ProFormSelect } from '@ant-design/pro-form';
import './style.less';
import Helper from 'utils/helpers';

const { Option } = Select;

const ReceiptProductEditForm = ({ item, products, productsSelected, warehouseId, setIsEditModal, onCallback, isOnlyQuantityAllowForUpdate = false, isEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const initValues = {
    // masterProductId: item ? item?.masterProduct.id : undefined,
    // DO: item ? item?.DO : undefined,
    // lineNo: item ? item?.lineNo : undefined,
    // quantityTin: item ? item?.quantityTin : undefined,
    // quantityCarttonReceived: item ? item?.quantityCarttonReceived : undefined,
    // quantityTinReceived: item ? item?.quantityTinReceived : undefined,
    // actualQuantityErrorTin: item ? item?.actualQuantityErrorTin : undefined,
    // actualQuantityReceivedCartonPallet: item ? item?.actualQuantityReceivedCartonPallet : undefined,
    // DVT: item ? item?.DVT : undefined,
    // expireDate: item ? item?.expireDate : null,
    // lot: item ? item?.lot : undefined,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
      onCancel={onCancel}
      width={980}
      centered
      footer={null}
      wrapClassName="receipt-product-edit-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="receiptProductEditForm"
        onFinish={(values) => {
          setLoading(true);
          const obj = products.find(x => x.id === values.masterProductId);
          onCallback({ masterProduct: obj, ...values, key: item?.key });
          setLoading(false);
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item name="masterProductId" label={lang.defaultCode}>
              <Select
                placeholder={lang.pleaseSelecteAProduct}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                }
              >
                {products.filter(x => x.warehouseId === warehouseId)?.map(product => {
                  return <Option key={product.id} value={product.id}>{product.nameEN} - {product.shortName} - {product.code3A}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="lineNo"
              label={'Line NO'}
            >
              <Input
                style={{ width: '100%' }} placeholder='Line NO'
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="origDOLine"
              label={lang.origDOLine}
            >
              <Input
                style={{ width: '100%' }} placeholder={lang.origDOLine}
              />
            </Form.Item>
          </Col>
          {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="charge"
                label={'charge'}
              >
                <Input
                  style={{ width: '100%' }} placeholder='charge'
                />
              </Form.Item>
            </Col> */}
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="attributeSetInstance"
              label={lang.attributeSetInstance}
            >
              <Input
                style={{ width: '100%' }} placeholder={lang.attributeSetInstance}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="description"
              label={lang.description}
            >
              <Input
                style={{ width: '100%' }} placeholder={lang.description}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="quantity"
              label={lang.quantity}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="unitPrice"
              label={lang.unitPrice}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="discount"
                label={lang.discount}
              >
                <Input
                  style={{ width: '100%' }} placeholder={lang.discount}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="tax"
                label={lang.taxCode}
              >
                <Input
                  style={{ width: '100%' }} placeholder={lang.taxCode}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="lineAmount"
                label={'lineAmount'}
              >
               <Input
                  style={{ width: '100%' }} placeholder={'lineAmount'}
                />
              </Form.Item>
            </Col> */}
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

ReceiptProductEditForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsSelected: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
  warehouseId: PropTypes.number,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  isEditModal: PropTypes.bool,
};

export default ReceiptProductEditForm;
