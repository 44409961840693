import environment from 'environments/environment';
import request from 'utils/request';

export async function masterproductPackingList(payload) {
  return request(environment.api.productPacking + '/get-all', payload, 'GET');
}

export async function masterproductPackingFindOne(payload) {
  return request(environment.api.productPacking + '/find-one', payload, 'GET');
}

export async function masterproductPackingCreate(payload) {
  return request(environment.api.productPacking + '/create', payload, 'POST');
}

export async function masterproductPackingUpdate(payload) {
  return request(environment.api.productPacking + '/update', payload, 'PUT');
}

export async function importExel(warehouseId, formData) {
  return request(environment.api.masterProductsCombo + `/create/excel/${warehouseId}`, formData, 'POST');
}
