import buildEnv from './buildEnv';

export default {
  ...buildEnv({
    // Import from env params: must write full path (process.env.API_BASE_URL)
    API_BASE_URL: process.env.API_BASE_URL,
    API_APP_ID: process.env.API_APP_ID,
    API_REST_API_KEY: process.env.API_REST_API_KEY,
    NODE_ENV: process.env.NODE_ENV,
    LIVE_QUERY_SERVER_URL: process.env.LIVE_QUERY_SERVER_URL,
    RENEW_SESSION_LENGTH: process.env.RENEW_SESSION_LENGTH
      ? Number(process.env.RENEW_SESSION_LENGTH)
      : 86400,
    MAX_IMAGE_SIZE: process.env.MAX_IMAGE_SIZE ? Number(process.env.MAX_IMAGE_SIZE) : 5000000,
    CUSTOMER_URL: process.env.CUSTOMER_URL,
    isMockData: false,
  }),
};
