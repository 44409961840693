import {
  ZONE_LIST,
  ZONE_LIST_SUCCESS,
  ZONE_LIST_FAIL,
  ZONE_CREATE,
  ZONE_CREATE_FAIL,
  ZONE_CREATE_SUCCESS,
  SHELF_UPDATE,
  SHELF_UPDATE_FAIL,
  SHELF_UPDATE_SUCCESS,
} from './types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ZONE_LIST: case ZONE_LIST_FAIL: case ZONE_LIST_SUCCESS:
    case ZONE_CREATE: case ZONE_CREATE_FAIL: case ZONE_CREATE_SUCCESS:
    case SHELF_UPDATE: case SHELF_UPDATE_SUCCESS: case SHELF_UPDATE_FAIL:
    return {
        state,
      };

    default:
      return state;
  }
};
