import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, message, Modal } from 'antd';
// import { EditOutlined } from '@ant-design/icons';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import './style.less';
import { deliveryConfirm, deliveryOrderDetail, updateDoStatus,deliveryOrderCheckInVoiceDelete } from './delivery-order.service'; //deliveryOrderUpdate
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { productPicking } from '../packaging/packaging.service';
import logog3A from 'assets/logoAKAWMS/logo-3A.png';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const DeliveryOrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [isEditModal, setIsEditModal] = useState(false);
  // const [item, setItem] = useState();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [productLock, setProductLock] = useState([]);
  const [loadingExportPXK, setLoadingExportPXK] = useState(false);
  const [status, setStatus] = useState('');
  // const [code3A, setCode3A] = useState('');
  // const isManager = _get(user, 'roles', '').includes('manager');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isStocker = _get(user, 'roles', '').includes('stocker');

  useEffect(() => {
    setLoading(true);
    deliveryOrderDetail(id)
      .then(result => {
        setSaleorder(result);
        setProductOrder(result?.productDeliveryOrders);
        setStatus(result?.status)
        setLoading(false);
        return result.group && productPicking({warehouseId: result.warehouseId, group: result.group})
      })
      .then(rs => {
        if (status === 'WAITING' || status === 'PICKING' || status === 'CANCELLED'|| status === 'WAITINGCANCEL' || status === 'CONFIRM') {
          setProductLock(rs.data)
          return true
        }
        else  setLoading(false)
      })
      .catch((e) => setLoading(false));
     
  }, [id, status]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'productName',
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.lot,
      dataIndex: 'lot',
      key: 'lot',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: ['masterProduct', 'suppliers'],
      render: (values) => values?.map(value => value.name).join(', '),
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      render: rec => rec?.quantityInCarton || 0,
    },
    {
      title: lang.quantityTin,
      key: 'quantityInTin',
      render: rec => rec?.quantityInTin,
    },
  ];
  const columnsProduct = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.lot,
      dataIndex: 'lot',
      key: 'lot',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      // dataIndex: 'quantityCartton',
      render: record =>{
        const finded = record?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return record?.quantityCartonSubmitArr?.[finded];
        return record?.quantityCartton
      }
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: record =>{
        const finded = record?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return record?.quantityTinSubmitArr?.[finded];
        return record?.quantityTin
      }
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      // dataIndex: 'totalQuantity',
      render: rec => {
        const finded = rec?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return rec?.quantityCartonSubmitArr?.[finded] * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTinSubmitArr?.[finded];
        return rec?.totalQuantity;
      },
    },
    {
      title: `${lang.takeQuantity} (thùng)`,
      key: 'quantityCarttonTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `${lang.takeQuantity} (lon)`,
      key: 'quantityTinTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `Tổng ${lang.takeQuantity} (lon)`,
      key: 'totalQuantityTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index] * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container No',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: 'User Picked',
      key: 'userPicked',
      dataIndex: 'userName',
    },
  ];
  const expandedRowRender = (rec) => {
    const quantityLo = parseInt(rec.quantityInCarton / rec.masterProduct.CTNSPerPalletImport);
    const dataSource = [...Array(2)]?.map((_, index) => {
      const product = { ...rec };
      if (index === 0) {
        product.quantityInCarton = quantityLo * product.masterProduct.CTNSPerPalletImport;
        product.quantityInTin = 0;
      } else {
        product.quantityInCarton = parseInt(rec.quantityInCarton % rec.masterProduct.CTNSPerPalletImport);
      }
      return product;
    });
    return <TableCustom columns={columns} dataSource={dataSource} pagination={false} />;
  };
  const extraButton = [
  saleorder?.status !== "WAITINGCANCEL" && <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    saleorder?.status !== "WAITINGCANCEL" &&  <Button
      key="btn-export"
      type="primary"
      ghost
      onClick={async () => {
        let content = `
        <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
        <div style="display:flex">
          <div style=" margin-top: 20px; line-height: 1; width: 20%;">
            <div style="text-align:center; border-image: initial;">
              <img src="${logog3A}" style=" height: 30%; width: 100%" />
            </div>
          </div>
          <div style="width: 50%;">
              <span>CÔNG TY TNHH DINH DƯỠNG 3A (Việt Nam)</span><br>
              <span>3A Nutrition (vietnam) Co., Ltd</span><br>
              <span>Contec Tower, số 72-74 Nguyễn Thị Minh Khai, P6, Q3, TP.HCM</span><br>
              <span>Tel: (84-8) 3823 8222</span><br>
              <span>Fax: (84-8) 54 255 336</span>
          </div>
          <div style="text-align:left; width: 30%; ">
            <span>Mẫu số</span>: </span><br><br>
            <span>Ngày in: ${new Date().getDate()} - ${new Date().getMonth() + 1} - ${new Date().getFullYear()}</span><br>
          </div>
        </div>
        <div>
          <div style="text-align: center; line-height: 1.2;">
            <div style="font-size: 20px; font-weight:bold; margin-bottom: 10px;">Phiếu giao nhận hàng và cấn trừ tiền chương trình</div>
          </div>
        </div>
        <div style="padding-top:10px; ">
          <p>Đơn vị bán hàng: CÔNG TY TNHH DINH DƯỠNG 3A ABBOTT (Việt Nam)</p>
          <p>Địa chỉ xuất hàng: Lô 6-1A, cụm 6, đường M1, KCN Bình Tân mở rộng, P.Bình Hưng Hòa, Q.Bình Tân, TP.HCM</p>
          <p style="font-size: 17px; font-weight:bold; ">Số PO: 401550</p>
        </div>    
    
        <div style="display: flex;">
          <div style="width: 50%">
            <p style="font-weight: bold; font-size: 20px">Đơn vị mua hàng</p>
          </div>
          <div style="width: 50%">
            <p style="font-weight: bold; font-size: 20px">Thông tin</p>
          </div>
        </div>
    
        <div style="display: flex;">
          <div style="width: 20%">
            <p>Họ và tên:</p>
            <p>Địa chỉ giao hàng:</p>
            <p>Địa chỉ hóa đơn:</p>
            <p>Tuyến đường:</p>
            <p>Loại khách hàng:</p>
            <p>Nhân viên bán hàng:</p>
            <p>Thanh toán:</p>
          </div>
          <div style="width: 50%">
            <p>1006218 SHOP SỮA BÉ YÊU</p>
            <p>31B Nguyễn Du, P.Bến Nghé, Quân 1, TP.HCM</p>
            <p>31B Nguyễn Du, P.Bến Nghé, Quân 1, TP.HCM</p>
            <p>-</p>
            <p>ETH</p>
            <p>12203308 Huynh Thi Thao Quyen</p>
            <p style="font-weight: bold; font-size: 18px">Chuyển khoản</p>
          </div>
          <div style="width: 25%">
            <p>Số phiếu giao hàng:</p>
            <p>Số đơn đặt hàng:</p>
            <p>Ngày đơn hàng:</p>
            <p>Ngày đến hạn giao hàng:</p>
            <p>Loại đơn đặt hàng:</p>
            <p>Số tham chiếu đơn đặt hàng:</p>
            <p>Số điện thoại:</p>
          </div>
          <div style="width: 25%">
            <p>1DO2202-16422</p>
            <p>1SO32202-18329</p>
            <p>25/02/2022</p>
            <p>28/02/2022</p>
            <p>Promotion Order</p>
            <p>M000001137710</p>
            <p>0123456789</p>
          </div>
        </div>
    
        <table style="border: 1px solid #333; width: 100%; border-collapse: collapse;">
          <thead>
            <tr style="text-align:center; font-weight: bold;">
              <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Mã hệ</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên sản phẩm</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Mã sản phẩm</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">SL thùng</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">SL lon/Gói/Pack</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Số lô_Hạn sử dụng</td>
              </tr>
          </thead>
          <tbody>`;
          await productOrder.map((pro, index) => {
            return content += `
            <tr>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + index}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.masterProduct.code3A ? pro.masterProduct.code3A : ''}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.masterProduct.nameEN ? pro.masterProduct.nameEN : ''}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.masterProduct.shortName ? pro.masterProduct.shortName : ''}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantityInCarton ? pro.quantityInCarton : ''}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantityInTin ? pro.quantityInTin : ''}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.lot ? pro.lot : ''}_${pro?.expireDate ? moment(pro?.expireDate, formatDateFull).format(formatDate) : ''}</td>
              </tr>
            `
          })
              
      content += `</tbody>
      <tfoot>
        <tr>
          <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;" colspan="4">Tổng</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${productOrder.reduce((prev, curr) => prev + curr?.quantityInCarton , 0)}</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${productOrder.reduce((prev, curr) => prev + curr?.quantityInTin, 0)}</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
        </tr>
      </tfoot>
      </table>
    
      <div style="display: flex; padding-top: 20px">
        <div style="width: 70%">
          <div style="margin-bottom: 15px">
            <p><b>Tiền hàng chưa chiết khấu theo hóa đơn số: <span>00909787</span></b></p>
            <p><b>Chiết khấu thương mại theo đơn hàng hóa đơn số: <span></span></b></p>
          </div>
          <div style="margin-bottom: 15px">
            <p><b>Chiết khấu thương mại tích lũy từ đơn hàng trước hóa đơn số: <span></span></b></p>
            <p><b>Tiền chương trình được cấn trừ công nợ: <span></span></b></p>
            <p><b>Số tiền còn lại: <span></span></b></p>
          </div>
        </div>
        <div style="width: 30%">
          <div style="margin-bottom: 15px">
            <p>25.508.107,00</p>
            <p>0,00</p>
          </div>
          <div style="margin-bottom: 15px">
            <p>0,00</p>
            <p>0,00</p>
            <p style="font-weight: bold;">25.508.107,00</p>
          </div>
        </div>
      </div>
    
      <div style="margin: 10px 0px 30px 0px;"><b><p>(Phiếu này có giá trị xác nhận việc đã giao hàng, đã giao hóa đơn GTGT bản gốc -</p> <br>
              <p>Liên 2: Giao cho người mua, không có giá trị xác nhận vệc thu tiền)</p></b>
      </div>
    
      <div style="display: flex; padding-top: 20px">
        <div style="width: 33%">
          <p>Khách hàng đã nhận đủ hàng</p>
        </div>
        <div style="width: 33%">
          <p>Khách hàng đã nhận đủ hàng và tiền chương trình</p>
        </div>
        <div style="width: 33%">
          <p>Nhân viên giao hàng</p>
        </div>
      </div>
        
    </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PGNH${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
      }}
    >
      {'Xuất PGNH'}
    </Button>,
    saleorder?.status !== "WAITINGCANCEL" &&  (isStocker || isIdm) && <Button 
    key="btn-export-pxk"
    type="primary"
    ghost
    loading={loadingExportPXK}
    onClick={async () => {
      setLoadingExportPXK(true);
      const date = moment(saleorder?.createDate, formatDateFull).format(formatDate);
      const tottalQuantity = saleorder?.productDeliveryOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0);
      const content = `
    <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
      <div style="display: flex; height: 120px;">
        <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
          <img src="${logog3A}" style=" height: 100%; width: 100%" />
        </div>
        <div style="width: 60%; text-align: center; padding-top: 7vh; ">
          <h1>PHIẾU XUẤT KHO</h1>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;">
      <div style="display: grid; line-height: 1;">
        <p> Khu vực:  <b>Hồ Chí Minh</b></p>
        <p> Từ ngày: <b>${date}</b> </p>
        <p> Số xe: <b>59K1-00005</b> </p>
        <p> NVGH: <b>Nguyen Van A</b> </p>
        <p> Tuyến giao hàng: <b>Hồ Chí Minh</b> </p>
      </div>
      <div style="display: grid; line-height: 1;">
        <p> Printed Time: <b>${moment().format(formatDatetime)}</b> </p>
        <p> Đến ngày: <b>${date}</b> </p>
        <p> Total carton: <b>${tottalQuantity}</b> </p>
        <p> Document No: <b>${saleorder?.documentNo ? saleorder?.documentNo : ''}</b> </p>
        <p> Thứ tự chuyển trong ngày: <b>1</b> </p>
      </div>
    </div>
      <div style="min-height: 400px;">
        <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
          <thead>
            <tr>
              <th><div style="min-height: 50px; display: flex; align-items: center;">No.</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">Product Name</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">Short</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">Carton</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">Tin</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">Lot & Exp Date</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">SO Doc No</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">Invoice</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">Locator</div></th>
            </tr>
          </thead>
          <tbody>
          ${saleorder?.productDeliveryOrders?.map((productOrder, index) => {
            return `<tr style="text-align: center;">
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${index +1}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.masterProduct.nameEN}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.masterProduct.shortName}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder?.quantityInCarton || productOrder.quantityCartton}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.quantityTin}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.lot}_'${productOrder.expireDate}'</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder?.locator?.locator}</div></td>
                    </tr>`;
          }).join(' \n')}
          
            <tr>
              <td colSpan="3" style="text-align:center; padding-top: 10px;">
                <b style="min-height: 30px; display: block;">Total</b>
              </td>
              <td style="font-weight: bold;">${saleorder?.productDeliveryOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0)}</td>
              <td style="font-weight: bold;">${saleorder?.productDeliveryOrders?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0)}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>Nhân viên kiểm tra</b> </p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>Bảo vệ</b> </p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>Nhân viên soạn hàng</b> </p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>Người lập phiếu</b> </p>
        </div>
      </div>
    </div>`;
      var template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        var imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
        pdf.save(`PhieuXuatKho${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
      setLoadingExportPXK(false);
    }}
  >
    {'Xuất PXK'}
  </Button>,
    (saleorder?.status === 'PICKING' || saleorder?.status === 'NEW' ) && !isPicker && <Button
      key="btn-confirm"
      type="primary"
      disabled = {saleorder?.status === 'NEW' && isPicker}
      ghost
      onClick={async() => {
        Modal.confirm({
          title: "Bạn có chắc chắn muốn xác nhận không?",
          async onOk() {
            try {
              if (saleorder?.status === "NEW") {
                setLoading(true)
                await updateDoStatus({ids: [saleorder?.id], status: 'LOCK'})
                deliveryOrderDetail(saleorder?.id)
                .then(result => {
                  setSaleorder(result);
                  setProductOrder(result?.productDeliveryOrders);
                  setLoading(false);
                  message.success("Xác nhận thành công!")
                  navigate(-1);
                })
                .catch((e) => setLoading(false));
              } else {
                setLoading(true)
                await deliveryConfirm({id: saleorder?.id, ids: [saleorder?.id], status: 'CONFIRM', isConfirm: true})
                deliveryOrderDetail(saleorder?.id)
                .then(result => {
                  setSaleorder(result);
                  setProductOrder(result?.productDeliveryOrders);
                  setLoading(false);
                  message.success("Xác nhận thành công!")
                  navigate(-1);
                })
                .catch((e) => setLoading(false));
              }
            } catch (error) {
              message.error(error.message);
              return false;
            }
          },
          onCancel() {},
        });
        
      }}
    >
      {lang.confirm}
    </Button>,

(saleorder?.status === 'WAITINGCANCEL' &&( isStocker || isIdm ) ) && <Button
  key="btn-confirm"
  type="primary"
  ghost
  onClick={async () => {
  Modal.confirm({
    title: "Bạn có chắc muốn hủy DO không?",
    async onOk() {
      setLoading(true);
      try {
        let ids = [saleorder?.id];
        await deliveryOrderCheckInVoiceDelete({ ids });
            deliveryOrderDetail(saleorder?.id)
            .then(result => {
              setSaleorder(result);
              setProductOrder(result?.productDeliveryOrders);
              setLoading(false);
              message.success("Hủy DO thành công!")
              navigate(-1);
            })
            .catch((e) => setLoading(false));
          }                        
       catch (error) {
        setLoading(false);
        message.error(error.message);
      }
    },
    onCancel() {},
  });
}}

  >
  {lang.confirmCancelDO}
  </Button>,

  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/delivery-order',
      breadcrumbName: 'Delivery order',
    },
    {
      path: '',
      breadcrumbName: 'Delivery order detail',
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={'Delivery order detail'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.code}>{saleorder?.code}</Descriptions.Item>
            <Descriptions.Item label={lang.orderCode}>{saleorder?.orderCode}</Descriptions.Item>
            <Descriptions.Item label={lang.company}>{saleorder?.company}</Descriptions.Item>
            <Descriptions.Item label={lang.branch}>{saleorder?.branch}</Descriptions.Item>
            <Descriptions.Item label={lang.orderReference}>{saleorder?.orderReference}</Descriptions.Item>
            <Descriptions.Item label={lang.businessLine}>{saleorder?.bussinessLine}</Descriptions.Item>
            <Descriptions.Item label={lang.abbottPOReference}>{saleorder?.abbottPOReference}</Descriptions.Item>
            <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
            <Descriptions.Item label={lang.dateOrdered}>{moment(saleorder?.dateOrdere).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.giftWarehouse}>{saleorder?.giftWarehouse}</Descriptions.Item>
            <Descriptions.Item label={lang.customerProgram}>{saleorder?.customerProgram}</Descriptions.Item>
            <Descriptions.Item label={lang.subChannel}>{saleorder?.subChannel}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>
              {
                saleorder?.status === 'NEW' ? 'Mới tạo'
                : saleorder?.status === 'LOCK' ? 'Chờ soạn'
                : saleorder?.status === 'WAITING' ? 'Đang soạn'
                : saleorder?.status === 'PICKING' ? 'Đã lấy hàng'
                : saleorder?.status === 'CANCELLED' ? 'Đã Hủy'
                : saleorder?.status === 'WAITINGCANCEL'  ?' Đang Chờ Hủy'
                : 'Hoàn tất xuất hàng'

              }
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={productOrder}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          expandable={{
            expandedRowRender,
            rowExpandable: record => record.quantityInCarton > record.masterProduct.CTNSPerPalletImport,
          }}
          scroll={{ x: 467 }}
        />
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{['WAITING, PICKING, CONFIRM']?.indexOf(saleorder?.status) === -1  ? `${lang.productList} đã lấy` : lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsProduct}
          dataSource={productLock}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 2600 }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default DeliveryOrderDetail;
