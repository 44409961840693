import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Row, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import TableCustom from 'components/TableCustom/table';

const { Option } = Select;
const BookingOrderForm = ({ onCancel, onCallback, listPicking }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  useEffect(() => {
    if (item) {
      setLoading(true);
      const products = listPicking[item?.value]?.reduce((prev, curr) => {
        const productOrders = curr?.productOrders?.map(product => {
          product.orderCode = curr.code;
          product.orderId = curr.id;
          product.group = curr.group;
          return product;
        });
        return [...prev, ...productOrders];
      }, []);
      setItems(products);
      setSelectedRowKeys(products?.map(d => d.id));
      setLoading(false);
    }
  }, [item, listPicking]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      width: '15%',
      render: (record) => {
        return <div
          style={{ cursor: 'pointer' }}
        // onClick={() => console.log('Click: ', record)}
        >
          {record?.orderCode}
        </div>;
      },
    },
    {
      title: lang.productCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
      width: '15%',
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      width: '20%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
    // {
    //   title: lang.pickingquantity,
    //   dataIndex: 'pickingQuantity',
    //   key: 'pickingQuantity',
    //   width: '15%',
    //   render: (value) => value ? value : 0,
    // },
  ];

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={() => {
        onCallback(items);
      }}
      initialValues={{}}
    >
      <Row gutter={24} style={{ marginBottom: 24 }}>
        <Col>
          <h3 style={{ lineHeight: '32px' }}>{`${lang.labelBooking}`}</h3>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 12 }}>
          <Select
            placeholder={lang.placeholderSelectOrder}
            value={item}
            onChange={(value) => {
              setItem(value);
            }}
            labelInValue
            style={{ width: '100%' }}
          >
            {Object.keys(listPicking)?.map(key => {
              return <Option key={key} value={key}>{listPicking[key]?.map(order => order?.code).join()}</Option>;
            })}
          </Select>
        </Col>
      </Row>
      <TableCustom
        rowKey="id"
        rowSelection={rowSelection}
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 600 }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnClose}
            </Button>
            {loading === false ? (
              <Button type="primary" disabled={items.length === 0} htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnDone}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

BookingOrderForm.propTypes = {
  listPicking: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  setProductOrder: PropTypes.array,
};

export default BookingOrderForm;
