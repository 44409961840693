import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import exportFromJSON from 'export-from-json';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Tag, Input } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, ScissorOutlined } from '@ant-design/icons'; //VerticalAlignBottomOutlined
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import './style.less';
import { saleorderDetail, deleteProductOrder, updateProductOrder, saleorderUpdate } from './sale-order.service';
import { formatDate, formatDateFull, formatDatetime, STATUS_INTERNAL_TRANFER, STATUS_OUTBOUND } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import logog3A from 'assets/logoAKAWMS/logo-3A.png';
import { productPicking } from 'pages/dashboard/outbound/packaging/packaging.service';
import ReceiptCreateProductEditForm from 'pages/dashboard/configuration/sale-order/sale-order-create-product-edit.form';
import { deliveryOrderCreate } from 'pages/dashboard/outbound/delivery-order/delivery-order.service';
import { orderUpdateStatus, suggestUnLock } from 'pages/dashboard/outbound/picking/picking.service';
import { productUpdates } from '../storing/storing.service';
import { receiptCreateAPInvoice } from '../request-receipt/apinvoice.service';
import VasProductSplitForm from './vas-product-split.form'
// import { productUpdates } from 'pages/dashboard/inbound/product.service';
// import { useSelector } from 'react-redux';
// import _get from 'lodash/get';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const VasCompletedDetail = () => {
  const { saleorderID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [item, setItem] = useState();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [productLock, setProductLock] = useState([]);
  const [isSplitProductVas, setIsSplitProductVas] = useState(false);
  const [QAcode, setQACode] = useState();
  // const { user } = useSelector((state) => state.auth);
  // const isIdm = _get(user, 'roles', '').includes('idm');
  // const isManager = _get(user, 'roles', '').includes('manager');
  // const isThukho = _get(user, 'roles', '').includes('stocker');
  const [description, setDescription] = useState();
  useEffect(() => {
    setLoading(true);
    saleorderDetail(saleorderID)
      .then(result => {
        setSaleorder(result);
        setDescription(result?.description);
        if (!result.isConfirm) {
          productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
        } else if (result.orderType === 'EXPORT_INTERNAL') {
          productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
        }
        if (result.status !== 'EXPORT') {
          setProductOrder(result?.productOrders);
          setLoading(false);
        } else {
          return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
        }
      })
      .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
      .catch((e) => setLoading(false));
  }, [saleorderID, deleteProduct]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'productName',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => val && `${val?.locatorName} - ${val?.locator}`,
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.SHELF_LIFE_MONTHS,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      render: rec => rec?.quantityCartton || rec?.quantityInCarton || 0,
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: rec => rec?.quantityTin,
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      hideInTable: saleorder?.status !== 'NEW' || saleorder.orderType === 'EXPORT_SALE' || saleorder.orderType === 'EXPORT_INTERNAL',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditModal(true);
              setItem(record);
            }}
          />
          <Button
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: lang.doYouWantToDeleteTheseItems,
                icon: <ExclamationCircleOutlined />,
                onOk: async () => {
                  try {
                    await deleteProductOrder(record.id);
                    message.success(lang.deleteSuccessfully);
                    setDeleteProduct(!deleteProduct);
                    return true;
                  } catch (error) {
                    message.error(error.message);
                    return false;
                  }
                },
                onCancel() {

                },
              });
            }}
          />
        </div>
      ),
    },
  ];
  const columnsProduct = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: `${lang.takeQuantity} (thùng)`,
      key: 'quantityCarttonTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `${lang.takeQuantity} (lon)`,
      key: 'quantityTinTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `Tổng ${lang.takeQuantity} (lon)`,
      key: 'totalQuantityTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index] * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container No',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: lang.Locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '5%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<ScissorOutlined />}
            onClick={() => {
              setQACode(record?.code)
              setIsSplitProductVas(true)
            }}
          />
        </div>
      )
    },
  ];
  const expandedRowRender = (rec) => {
    const quantityLo = parseInt(rec.quantityInCarton / rec.masterProduct.CTNSPerPalletImport);
    const dataSource = [...Array(2)]?.map((_, index) => {
      const product = { ...rec };
      if (index === 0) {
        product.quantityInCarton = quantityLo * product.masterProduct.CTNSPerPalletImport;
        product.quantityTin = 0;
      } else {
        product.quantityInCarton = parseInt(rec.quantityInCarton % rec.masterProduct.CTNSPerPalletImport);
      }
      return product;
    });
    return <TableCustom columns={columns} dataSource={dataSource} pagination={false} />;
  };
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      // ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-save"
      type="primary"
      disabled={!description}
      onClick={async () => {
        try {
          setLoading(true);
          await saleorderUpdate({ id: saleorder?.id, description });
          saleorderDetail(saleorderID)
            .then(result => {
              setSaleorder(result);
              setDescription(result?.description);
              if (!result.isConfirm) {
                productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
              } else if (result.orderType === 'EXPORT_INTERNAL') {
                productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
              }
              if (result.status !== 'EXPORT') {
                setProductOrder(result?.productOrders);
                setLoading(false);
              } else {
                return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
              }
            })
            .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
            .catch((e) => setLoading(false));
          message.success(lang.updateSuccessfully);
          return true;
        } catch (error) {
          message.error(error.message);
          setLoading(false);
          return false;
        }
      }}
    >
      {lang.btnSave}
    </Button>,
    saleorder?.orderType === 'EXPORT_SALE' && <Button
      key="btn-spicking-slip"
      type="primary"
      // ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const content = `
        <div style="display: grid ;line-height: 1.5;width: 950px; font-size: 18px; font-family: 'Times New Roman', Times, serif;">
        <!-- <div style="text-align:right; width: 100%; padding: 10px">
          <p><b style="padding: 10px">Ngày in: 22/08/2022 </b></p>
        </div> -->
        <div style="display: flex; height: 120px;">
          <div style="width: 80%; text-align: center; ">
            <h1>Picking Slip</h1>
          </div>
        </div>
        <div style="display: flex; width: 100%;">
          <div style="width: 25%;">
            <p><b>Số đơn hàng:</b> </p>
            <p><b>Ngày</b>: </p>
            <p><b>Họ tên khách hàng</b>: </p>
            <p><b>Nhân viên bán hàng</b>: </p>
          </div>
          <div style="width: 25%;">
            <p>${saleorder?.documentNo}</p>
            <p>${saleorder?.dateOrdered ? moment(saleorder?.dateOrdered, formatDateFull).format(formatDate) : ''}</p>
            <p>${saleorder?.nameCustomer}</p>
            <p>${saleorder?.userContact}</p>
          </div>
          <div style="width: 25%;">
            <p><b>Delivery Note No:</b> </p>
            <p><b>Order Type</b>: </p>
            <p><b>Order Reference</b>: </p>
            <p><b>Locator</b>: </p>
          </div>
          <div style="width: 25%;">
            <p>${saleorder?.code}</p>
            <p>${saleorder?.orderType}</p>
            <p>${saleorder?.orderReference}</p>
            <p>${saleorder?.locator?.locatorName} - ${saleorder?.locator?.locator}</p>
          </div>
        </div>
        <div>
          <p> <b>Địa chỉ: ${saleorder?.shipAddress}</b> </p>
        </div>
        <div style="min-height: 1000px;">
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style=" background-color: #f5f5f5; ">
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">No</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Code</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Name</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Short</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInCart</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInTin</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lot & Exp Date</div></th>
              </tr>
            </thead>
            <tbody>

            ${productOrder?.map((product, index) => {
          return `<tr style="text-align: center;">
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${index + 1}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.code3A}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.nameEN}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.shortName}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityTin}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">&lt;${product?.lot}&gt;_${product?.expireDate}</div></td>
                      </tr>`;
        }).join(' \n')}
            </tbody>
          </table>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PhieuPickingSlip${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    // disabled={saleorder?.orderType !== 'EXPORT_INTERNAL'}
    >
      {lang.exportSpickingSlip}
    </Button>,
    // saleorder?.orderType === 'EXPORT_INTERNAL' && saleorder?.status === 'EXPORTED' && <Button
    //   key="btn-export"
    //   type="primary"
    //   onClick={() => {
    //     const dataXml = [
    //       {
    //         Invoices: {
    //           Inv: {
    //             key: `${saleorder?.code}`,
    //             Invoice: {
    //               DelieriName: 'Ho Chi Minh',
    //               IRNUMBER: '503/22-XM',
    //               DeliverDate: `${moment(saleorder?.movementDate, formatDateFull).format(formatDate)}`,
    //               DeliverContent: `${saleorder?.description ? saleorder?.description : ''}`,
    //               NoilapYC: 'GIÁM ĐỐC CÔNG TY TNHH DINH DƯỠNG 3A(VIỆT NAM)',
    //               OrgNameOut: 'CÔNG TY TNHH DINH DƯỠNG 3A (VIỆT NAM)',
    //               StoreOut: `${saleorder?.locator?.locatorName} - ${saleorder?.locator?.locator}`,
    //               FromAddress: 'Lô 6-1A, Cụm 6, Đường M1, Khu CN Tân Bình Mở Rộng, P. Bình Hưng Hòa, Q. Bình Tân, Tp.HCM',
    //               OrgNameIn: 'CÔNG TY TNHH DINH DƯỠNG 3A (VIỆT NAM)',
    //               ToAddress: 'Lô 6-1A, Cụm 6, Đường M1, Khu CN Tân Bình Mở Rộng, P. Bình Hưng Hòa, Q. Bình Tân, Tp.HCM',
    //               CusCode: '',
    //               CusName: '',
    //               Buyer: '',
    //               CusAddress: '',
    //               DVQLKHO: '',
    //               DeliverAddress: '',
    //               DeliverTaxCode: '',
    //               DeliverPhone: '',
    //               ComFax: '',
    //               ComBankNo: '',
    //               ComBankName: '',
    //               CurrencyCode: '',
    //               TyGia: '',
    //               VNMInvoiceType: '',
    //               OrderNoOrderNo: '',
    //               DeliverCom: '',
    //               ContractNo: '',
    //               DeliverTruck: `${saleorder?.truckType ? saleorder?.truckType : ''}`,
    //               TripNumber: '',
    //               DeliverNumber: '',
    //               CusTaxCode: '',
    //               PaymentMethod: '',
    //               CusBankNo: '',
    //               BookingNo: '',
    //               Extra: '',
    //               InvoiceDate: `${moment(saleorder?.createDate, formatDateFull).format(formatDate)}`,
    //               TransNumber: '',
    //               PrintDate: '',
    //               Printer: '',
    //               Products: {
    //                 Product: saleorder?.productOrders?.map(val => ({
    //                   ProductName: `${val?.masterProduct?.nameEN}`,
    //                   Code: `${val?.masterProduct?.code3A}`,
    //                   ProdUnit: `${val?.masterProduct?.UOM_VN}`,
    //                   ProdQuantity: `${val?.quantityInCarton}T - ${val?.quantityTin}L`,
    //                   Remarks: '',
    //                   ProdPrice: '',
    //                   Total: '',
    //                   Extra1: '1178413',
    //                   Extra2: `${moment(val?.expireDate, formatDateFull).format(formatDate)}`,
    //                   VATRate: '',
    //                   VATAmount: '',
    //                   Amount: '',
    //                 })),
    //               },
    //               CountTHU: '',
    //               WeightTON: '',
    //               CountItem: '',
    //               SoldVia: '',
    //               InvoiceSigner: '',
    //               Total: '',
    //               DiscountAmount: '',
    //               VATRate: '',
    //               VATAmount: '',
    //               Amount: '',
    //               AmountInWords: '',
    //               PaymentStatus: '',
    //               ResourceCode: '',
    //               GrossValueNon: '',
    //               GrossValue0: '',
    //               GrossValue5: '',
    //               GrossValue10: '',
    //               VatAmountNon: '',
    //               VatAmount0: '',
    //               VatAmount5: '',
    //               VatAmount10: '',
    //               AmountNon: '',
    //               Amount0: '',
    //               Amount5: '',
    //               Amount10: '',
    //             },
    //           },
    //         },
    //       },
    //     ];

    //     const fileName = 'ExportXML';
    //     const fields = [
    //       'Invoices',
    //       'Inv',
    //       'key',
    //       'Invoice',
    //       'DelieriName',
    //       'IRNUMBER',
    //       'DeliverDate',
    //       'DeliverContent',
    //       'NoilapYC',
    //       'OrgNameOut',
    //       'StoreOut',
    //       'FromAddress',
    //       'OrgNameIn',
    //       'ToAddress',
    //       'CusCode',
    //       'CusName',
    //       'Buyer',
    //       'CusAddress',
    //       'DVQLKHO',
    //       'DeliverAddress',
    //       'DeliverTaxCode',
    //       'DeliverPhone',
    //       'ComFax',
    //       'DeliverPhone',
    //       'ComBankNo',
    //       'ComBankName',
    //       'CurrencyCode',
    //       'TyGia',
    //       'VNMInvoiceType',
    //       'OrderNo',
    //       'DeliverCom',
    //       'ContractNo',
    //       'DeliverTruck',
    //       'TripNumber',
    //       'DeliverNumber',
    //       'TripNumber',
    //       'CusTaxCode',
    //       'PaymentMethod',
    //       'CusBankNo',
    //       'BookingNo',
    //       'Extra',
    //       'InvoiceDate',
    //       'TransNumber',
    //       'PrintDate',
    //       'Printer',
    //       'Products',
    //       'Product',
    //       'ProdName',
    //       'Code',
    //       'ProdUnit',
    //       'ProdQuantity',
    //       'Remarks',
    //       'ProdPrice',
    //       'Total',
    //       'Extra1',
    //       'Extra2',
    //       'VATRate',
    //       'Extra2',
    //       'VATAmount',
    //       'Amount',
    //       'CountTHU',
    //       'WeightTON',
    //       'CountItem',
    //       'SoldVia',
    //       'InvoiceSigner',
    //       'Total',
    //       'DiscountAmount',
    //       'VATRate',
    //       'VATAmount',
    //       'Amount',
    //       'AmountInWords',
    //       'PaymentStatus',
    //       'ResourceCode',
    //       'GrossValueNon',
    //       'GrossValue0',
    //       'GrossValue5',
    //       'GrossValue10',
    //       'VatAmountNon',
    //       'VatAmount0',
    //       'VatAmount5',
    //       'VatAmount10',
    //       'AmountNon',
    //       'Amount0',
    //       'Amount5',
    //       'Amount10',
    //     ]; //empty list means "use all"
    //     const exportType = 'xml';
    //     exportFromJSON({ data: dataXml, fileName, fields, exportType });
    //   }}
    // >
    //   {'Xuất XML'}
    //   <VerticalAlignBottomOutlined />
    // </Button>,
    saleorder?.orderType === 'EXPORT_INTERNAL' && <Button
      key="btn-export"
      type="primary"
      // ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const content = `
      <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
        <div style="display: flex; height: 120px;">
          <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
            <img src="${logog3A}" style=" height: 100%; width: 100%" />
          </div>
          <div style="width: 60%; text-align: center; padding-top: 7vh; ">
            <h1>PHIẾU CHUYỂN KHO NỘI BỘ</h1>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid;">
            <p> <b>Số phiếu chuyển kho nội bộ: ${saleorder?.code} </b> </p>
            <p> <b>Số PXKKVCNB: </b></p>
            <p> <b>Diễn dãi:</b></p>
          </div>
          <div style="display: grid;">
            <p> <b>Ngày in: ${moment().format(formatDatetime)} </b> </p>
            <p> <b>Ngày chuyển: ${moment().format(formatDate)}</b> </p>
            <p> <b>Ngày hoàn thành:</b> </p>
          </div>
        </div>
        <div style="min-height: 1000px;">
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style=" background-color: #009ada; ">
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Mã Abbott</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Mã 3A</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">ShortName</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Tên sản phẩm</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Số lô & Hạn dùng</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Từ kho</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Đến kho</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Thùng</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lon</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Hỏng</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Móp</div></th> 
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lỗi</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Thiếu</div></th>
              </tr>
            </thead>
            <tbody>
            ${productLock?.map((product) => {
          // const productfind = saleorder?.productOrders?.find(productOrder => productOrder.masterProduct.id === product.masterProduct.id);
          return `<tr style="text-align: center;">
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.abbottCode16 ? product.masterProduct.abbottCode16 : ''}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.code3A}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.shortName}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.nameEN}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">&lt;${product.lot}&gt;_${moment(product.expireDate, formatDateFull).format(formatDate)}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.locator?.locator}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${`${saleorder?.locator.locator}`}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton || product.quantityCartton}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.quantityTin}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td> 
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      </tr>`;
        }).join(' \n')}
              <tr>
                <td colSpan="6">
                  <b style="min-height: 30px; display: flex;">Tổng</b>
                </td>
                <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                <td style="font-weight: bold;">${productLock?.reduce((prev, curr) => { prev += curr.quantityCartton; return prev; }, 0)}</td>
                <td style="font-weight: bold;">${productLock?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>Nhân viên soạn hàng</b> </p>
            <p> <b>(Ký, Họ tên)</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>Thủ kho</b> </p>
            <p> <b>(Ký, Họ tên)</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>Người lập bảng</b> </p>
            <p> <b>(Ký, Họ tên)</b> </p>
          </div>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PhieuNoiBo${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
      disabled={saleorder?.orderType !== 'EXPORT_INTERNAL'}
    >
      {lang.exportPDF}
    </Button>,
    saleorder?.orderType === 'EXPORT_SALE' && <Button
      key="btn-create"
      type="primary"
      ghost
      onClick={async () => {
        try {
          const payload = Object.assign({}, saleorder);
          payload.orderId = saleorder?.id;
          payload.orderCode = saleorder?.code;
          payload.productDeliverys = saleorder?.productOrders?.map(product => {
            return {
              lineNo: product?.lineNo,
              code3A: product?.code3A,
              branch: product?.branch,
              description: product?.description,
              quantityInCarton: product?.quantityInCarton,
              quantityInTin: product?.quantityTin,
              quantity: product?.quantity,
              lot: product?.lot,
              expireDate: product?.expireDate,
              warehouseId: product?.warehouseId,
            };
          });
          delete payload.id;
          delete payload.createDate;
          delete payload.productOrders;
          await deliveryOrderCreate(payload);
          setDeleteProduct(!deleteProduct);
          message.success(lang.createSuccessfully);
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      disabled={saleorder?.deliveryOrder}
    >
      {'Tạo Delivery Order'}
    </Button>,
    saleorder?.status === 'LOCK' && <Button
      key="btn-unlock"
      type="primary"
      onClick={async () => {
        try {
          setLoading(true);
          await Promise.all([(await suggestUnLock({ orderId: saleorder?.id, warehouseId: saleorder.warehouseId, subChannel: saleorder?.subChannel })),
          orderUpdateStatus({ ids: [saleorder?.id], status: 'NEW' })]);
          saleorderDetail(saleorderID)
            .then(result => {
              setSaleorder(result);
              if (result.status === 'LOCK') {
                productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
              }
              if (result.status !== 'EXPORT') {
                setProductOrder(result?.productOrders);
                setLoading(false);
              } else {
                return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
              }
            })
            .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
            .catch((e) => setLoading(false));
          message.success('Unlock thành công');
          return true;
        } catch (error) {
          message.error(error.message);
          setLoading(false);
          return false;
        }
      }}
    >
      {'Unlock'}
    </Button>,
    (saleorder?.orderType === 'EXPORT_INTERNAL' || saleorder?.orderType === 'EXPORT_BRANCH') && <Button
      key="btn-confirm"
      type="primary"
      onClick={async () => {
        console.log(saleorder?.status);
        Modal.confirm({
          title: 'Bạn có chắc chắn muốn xác nhận không?',
          async onOk() {
            try {
              setLoading(true);
              const idsUpdate = productLock.map((item) => { return item?.id; });
              const checkStatus = productLock.map((status) => { return status?.status; });
              if (checkStatus.indexOf('PICKING') !== -1) {
                await orderUpdateStatus({ ids: [saleorder?.id], status: saleorder?.status === 'EXPORTED' ? 'VAS' : 'EXPORTED' });
                if (saleorder?.typeProductionOrder === 'PICKING')
                  await productUpdates({ ids: idsUpdate, status: 'PACK', type: 3 })
                    .then((result) => {
                      setProductLock(result);
                      setLoading(false);
                    });
                else if(saleorder?.typeProductionOrder === 'IMPORTLABEL') 
                  await productUpdates({ ids: idsUpdate, status: 'VAS', type: 1 })
                  .then((result) => {
                    setProductLock(result);
                    setLoading(false);
                  });
                const dataVas = {
                  receiptDate: moment(),
                  inboundkind: 'INTERNAL',
                  status: 'NEW',
                  warehouseId: saleorder.warehouseId,
                  code: saleorder.code,
                  createDate: saleorder.createDate,
                  locator: saleorder?.locatorCode,
                  receiptProducts: saleorder?.typeProductionOrder === 'PACKING'? saleorder?.productOrders.map((product) => ({
                    quantityCartton: 0,
                    quantityTin: product.quantityPack,
                    code3A: product.code3APack,
                    warehouseId: saleorder.warehouseId,
                    locatorCode: product.locatorCodeTo?.locator,
                    lot: product.lot,
                    expireDate: product.expireDate,
                  })) : saleorder?.productOrders.map((product) => ({
                    quantityCartton: product.quantityInCarton,
                    quantityTin: product.quantity,
                    code3A: product.code3A,
                    warehouseId: product.warehouseId,
                    locatorCode: product.locatorCode,
                    lot: product.lot,
                    expireDate: product.expireDate,
                    masterProduct: product.masterProduct,
                  })),
                };
                await receiptCreateAPInvoice(dataVas);
                message.success('Xác nhận thành công!');
                navigate(-1)
                setLoading(false);
              } else {
                await orderUpdateStatus({ ids: [saleorder?.id], status: saleorder?.status === 'NEW' ? 'CONFIRM' : 'CONFIRM1' });
                saleorderDetail(saleorderID)
                  .then(result => {
                    setSaleorder(result);
                    if (!result.isConfirm) {
                      productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
                    } else if (result.orderType === 'EXPORT_INTERNAL') {
                      productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
                    }
                    if (result.status !== 'EXPORT') {
                      setProductOrder(result?.productOrders);
                      setLoading(false);
                    } else {
                      return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
                    }
                  })
                  .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
                  .catch((e) => setLoading(false));
              }
            } catch (err) {
              message.error(err.message);
            }
          },
          onCancel() { },

        });
      }}
      disabled={saleorder?.status === 'VAS'}
    >
      {lang.btnConfirm}
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    document.location.pathname.indexOf('internal-tranfer') > -1 ? {
      path: '/internal-tranfer',
      breadcrumbName: lang.EXPORT_INTERNAL,
    } : {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '',
      breadcrumbName: document.location.pathname.indexOf('internal-tranfer') > -1 ? `Chi tiết ${lang.EXPORT_INTERNAL}` : lang.saleOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={document.location.pathname.indexOf('internal-tranfer') > -1 ? `Chi tiết ${lang.EXPORT_INTERNAL}` : lang.saleOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 5, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.orderCode}>{saleorder?.code}</Descriptions.Item>
            {saleorder?.orderType === 'EXPORT_SALE' && <>
              <Descriptions.Item label={lang.company}>{saleorder?.company}</Descriptions.Item>
              <Descriptions.Item label={lang.branch}>{saleorder?.branch}</Descriptions.Item>
              <Descriptions.Item label={lang.orderReference}>{saleorder?.orderReference}</Descriptions.Item>
              <Descriptions.Item label={lang.businessLine}>{saleorder?.bussinessLine}</Descriptions.Item>
              <Descriptions.Item label={lang.abbottPOReference}>{saleorder?.abbottPOReference}</Descriptions.Item>
              <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
              <Descriptions.Item label={lang.dateOrdered}>{moment(saleorder?.dateOrdere).format(formatDate)}</Descriptions.Item>
              <Descriptions.Item label={lang.giftWarehouse}>{saleorder?.giftWarehouse}</Descriptions.Item>
              <Descriptions.Item label={lang.customerProgram}>{saleorder?.customerProgram}</Descriptions.Item>
              <Descriptions.Item label={lang.subChannel}>{saleorder?.subChannel}</Descriptions.Item>
            </>
            }
            {saleorder?.orderType !== 'EXPORT_SALE' && <>
              <Descriptions.Item span={saleorder?.orderType === 'EXPORT_BRANCH' ? 2 : 1} label={lang.locatorTo}>{saleorder?.orderType === 'EXPORT_BRANCH' ? `${saleorder?.locatorTransit?.locatorName} - ${saleorder?.locatorTransit?.locator}` : `${saleorder?.locator?.locatorName} - ${saleorder?.locator.locator}`}</Descriptions.Item>
              {saleorder?.orderType === 'EXPORT_BRANCH' && <>
                <Descriptions.Item label={'Lệnh điều động'}>{saleorder?.lDDNo}</Descriptions.Item>
                <Descriptions.Item label={lang.truckType}>{saleorder?.truckType}</Descriptions.Item>
                <Descriptions.Item label={'Trọng tải xe(tấn)'}>{saleorder?.truckWeight}</Descriptions.Item>
              </>}
              <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate ? saleorder?.movementDate : moment()).format(formatDate)}</Descriptions.Item>
              <Descriptions.Item label={lang.tranferReason}>{saleorder?.tranferReason}</Descriptions.Item>
              {saleorder?.orderType === 'EXPORT_BORROW' && <Descriptions.Item label={lang.consignmentOrder}>{saleorder?.typeBorrow}</Descriptions.Item>}
            </>}
            {saleorder?.orderType === 'EXPORT_INTERNAL' &&
              <>
                <Descriptions.Item label={lang.salesChannel}>{saleorder?.channel ? `${saleorder?.channel?.channelName} - ${saleorder?.channel?.channel}` : ''}</Descriptions.Item>
                <Descriptions.Item label={lang.productionOrder}>{saleorder?.internalTranferCode ? saleorder?.internalTranferCode : ''}</Descriptions.Item>
              </>}
            <Descriptions.Item label={lang.status}>
              {saleorder?.orderType === 'EXPORT_INTERNAL' ? STATUS_INTERNAL_TRANFER[saleorder?.status] : STATUS_OUTBOUND[saleorder?.status]}
            </Descriptions.Item>
            <Descriptions.Item label={lang.description}>
              <Input onChange={(val) => setDescription(val.target.value)} value={description}></Input>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={productOrder}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          expandable={{
            expandedRowRender,
            rowExpandable: record => record.quantityInCarton > record.masterProduct.CTNSPerPalletImport,
          }}
          scroll={{ x: 467 }}
        />
        {(!saleorder?.isConfirm || saleorder?.orderType === 'EXPORT_INTERNAL') && <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{!saleorder?.isConfirm ? `${lang.productList} đã Lock` : lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsProduct}
          dataSource={productLock}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 2600 }}
        />}
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={lang.editProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptCreateProductEditForm
          item={item}
          onCallback={async (value) => {
            try {
              await updateProductOrder({ id: value?.id, quantityInCarton: value?.quantityInCarton, quantityTin: value?.quantity });
              message.success(lang.updateSuccessfully);
              setDeleteProduct(!deleteProduct);
              setIsEditModal(false);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          }}
          onCancel={() => setIsEditModal(false)}
        // type={type}
        // locators={locators}
        />
      </Modal>,
      <Modal
        visible={isSplitProductVas}
        title={lang.splitproduct}
        onCancel={() => setIsSplitProductVas(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <VasProductSplitForm
          // isOnlyQuantityAllowForUpdate={true}
          QAcode={QAcode}
          items={productLock}
          onCallback={async (value) => {
            console.log(value)
            try {
              // setLoading(true);
              // receiptDetail({ receiptId: parseInt(receiptId) })
              //   .then(result => {
              //     setReceipt(result);
              //     setItemsReceiptProduct(result?.receiptProduct);
              //     setLoading(false);
              //   })
              //   .catch((e) => setLoading(false));
              setIsSplitProductVas(false);
            } catch (error) {
              message.error(error.message);
            }
          }}
          onCancel={() => setIsSplitProductVas(false)}
        />
      </Modal>
    </Layout>
  );
};

export default VasCompletedDetail;
