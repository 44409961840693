import environment from 'environments/environment';
import request from 'utils/request';

export async function areaList(payload) {
  return request(environment.api.area + '/get-all', payload, 'GET');
}

export async function areaDetail(payload) {
  return request(`${environment.api.area}/get-one/${payload?.areaId}`, {}, 'GET');
}

export async function areaCreate(payload) {
  return request(environment.api.area + '/create', payload, 'POST');
}

export async function areaUpdate(payload) {
  return request(environment.api.area + '/update', payload, 'PUT');
}
export async function areaDelete(id) {
  return request(environment.api.area + `/delete/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.area + `/create/excel/${warehouseId}`, formData, 'POST');
}
