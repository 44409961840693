import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, message as Alert, Modal, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
import Helper from 'utils/helpers';
import { bomCreate, bomUpdate } from './bom.service';
import { MODE } from 'utils/constants';

const { Option } = Select;

const BomEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const initValues = {
    code: item ? item?.code : undefined,
    name: item ? item?.name : undefined,
    DVT: item ? item?.DVT : undefined,
    producerRule: item ? item?.producerRule : undefined,
    cartonRule: item ? item?.cartonRule : undefined,
    grossWeightPerCarton: item ? item?.grossWeightPerCarton : undefined,
    cartonPerPallet: item ? item?.cartonPerPallet : undefined,
    wastage: item ? item?.wastage : undefined,
    stock: item ? item?.stock : undefined,
    status: item ? item?.status : undefined,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);

    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateBom : lang.createBom}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={768}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          values.warehouseId = currentWarehouse?.id;
          if (!item) { // Create
            bomCreate(values)
              .then(() => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            values.id = item?.id;
            bomUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        {/* {item && <>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item
                name="code"
                label={lang.bomCode}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item name="status" label={lang.status}>
                <Select placeholder={lang.status}>
                  <Option value="ENABLE">{lang.ENABLE}</Option>
                  <Option value="DISABLE">{lang.DISABLE}</Option>
                </Select>
              </Form.Item>
            </Col>
            </>} */}
        <Row gutter={24}>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="code"
              label={lang.codeNPL}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.requiredDivisonName,
            //   },
            //   {
            //     max: 100,
            //     message: lang.messageLong,
            //   },
            // ]}
            >
              <Input style={{ width: '100%' }} placeholder={lang.codeNPL} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label={lang.caseUOM}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.requiredDivisonName,
            //   },
            //   {
            //     max: 100,
            //     message: lang.messageLong,
            //   },
            // ]}
            >
              <Input style={{ width: '100%' }} placeholder={lang.caseUOM} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }}>
            <Form.Item
              name="DVT"
              label={lang.DVT}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <Input placeholder={lang.DVT} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }}>
            <Form.Item
              name="producerRule"
              label={lang.producerRule}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <Input placeholder={lang.producerRule} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }}>
            <Form.Item
              name="cartonRule"
              label={lang.cartonRule}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <Input placeholder={lang.cartonRule} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="grossWeightPerCarton"
              label={lang.grossWeightPerCarton}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.requiredDivisonName,
            //   },
            //   {
            //     max: 100,
            //     message: lang.messageLong,
            //   },
            // ]}
            >
              <Input style={{ width: '100%' }} placeholder={lang.grossWeightPerCarton} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }}>
            <Form.Item
              name="cartonPerPallet"
              label={lang.cartonPerPallet}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <Input style={{ width: '100%' }} placeholder={lang.cartonPerPallet} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }}>
            <Form.Item
              name="wastage"
              label={lang.wastage}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.wastage} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 8 }}>
            <Form.Item
              name="stock"
              label={lang.totalQuantity}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.totalQuantity} />
            </Form.Item>
          </Col>
          {item && <Col sm={{ span: 8 }}>
            <Form.Item
              name="status"
              label={lang.status}
            >
              <Select placeholder={lang.status}>
                <Option key={'ENABLE'} value={'ENABLE'}>ENABLE</Option>
                <Option key={'DISABLE'} value={'DISABLE'}>DISABLE</Option>
              </Select>
            </Form.Item>
          </Col>}
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

BomEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default BomEditForm;
