import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Select, message, Modal, Tag } from 'antd'; //Tag
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatDate, formatDateAPi } from 'utils/constants';
import './style.less';
import TableCustom from 'components/TableCustom/table';
import { apInvoiceDetail, updateAPInvoice } from './apinvoice.service';
import ApInvoiceForm from './apinvoice.edit';
import jsPdf from 'jspdf';
import logog3A from 'assets/logoAKAWMS/logo-3A.png';
import html2canvas from 'html2canvas';
import { warehouseList } from 'pages/admin/warehouse/warehouse.service';
// import Modal from 'antd/lib/modal/Modal';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const APInvoiceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const [item, setItem] = useState([]);
  const [items, setItems] = useState([]);
  const [apInvoice, setApInvoice] = useState();
  const { locale } = useSelector((state) => state.i18n);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys([...selectedRowKeys]);
  };
  const [warehouse, setWarehouse] = useState();
  const [listwarehouse, setListwarehouse] = useState([]);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    warehouseList().then(value => {
      if (value) {
        setListwarehouse(value.data);
      }
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    apInvoiceDetail(id)
      .then(result => {
        setApInvoice(result);
        // const listReceipts = result.reciepts;
        // let totalCartons = 0;
        // let totalTins = 0;
        // // eslint-disable-next-line no-unused-expressions
        // listReceipts?.map(items => {
        //   // eslint-disable-next-line no-unused-expressions
        //   items?.receiptProduct.map(item => {
        //      totalCartons += item.quantityCartton;
        //      totalTins += item.quantityTin;
        //   });
        // });
        // console.log(totalCartons);
        // console.log(totalTins);
        if (result?.warehouseId !== 1) { setWarehouse(result?.warehouseId); }
        result.apInvoiceProduct.map((value) => {
          value.quantityCarttonLeft = value.quantityCartton - value.quantityCarttonTaken;
          value.quantityLeft = value.quantityTin - value.quantityTaken;
          value.boothcode = result?.reciepts?.map(receipt => receipt?.receiptProduct?.[0]?.container);
          return value;
        });
        setItems(result?.apInvoiceProduct);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [id]);
  const warehouseDisplay = listwarehouse?.filter((value) => {
    return value?.id > 1;
  });
  function getWeek(target) {
    const dayNr = (target.getDay() + 6) % 7;
    const firstThursday = target.valueOf();
    target.setDate(target.getDate() - dayNr + 3);
    target.setMonth(0, 1);
    if (target.getDay() !== 4) {
      target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - target) / 604800000); // 604800000 = 7 * 24 * 3600 * 1000
  }

  const columns = [
    {
      title: lang.defaultCode,
      key: 'code',
      dataIndex: 'masterProduct',
      render: (val) => val?.code3A,
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
      width: '10%',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '15%',
      render: record => record?.nameEN,
    },
    {
      title: 'PO Reference',
      key: 'poReference',
      dataIndex: 'poReference',
    },
    {
      title: 'Tổng SL lon(ERP)',
      key: 'quantityTin',
      dataIndex: 'quantityTin',
      // render: (record) => record?.quantityCartton === 0 ? record?.quantityTin : record?.quantityCartton * parseInt(record?.masterProduct?.tinPerCarton) + record?.quantityTin
    },
    {
      title: 'SL thùng quy đổi(ERP)',
      key: 'quantityCartton',
      // dataIndex: 'quantityCartton',
      render: (record) => Math.floor((record?.quantityTin) / parseInt(record?.masterProduct?.tinPerCarton)),
    },
    {
      title: 'SL lon lẻ sau khi đã quy đổi ra thùng(ERP)',
      key: 'quantityTinss',
      // dataIndex: 'quantityCartton',
      render: (record) => record?.quantityTin % parseInt(record?.masterProduct?.tinPerCarton),
    },
    {
      title: lang.quantityCarttonTaken,
      key: 'quantityCarttonTaken',
      // dataIndex: 'quantityCarttonTaken',
      // render: (record) => {
      //   const cartons = Math.floor((record?.quantityTin) / parseInt(record?.masterProduct?.tinPerCarton));
      //   if (cartons > 0) {
      //     const tinsOdd = record?.quantityTin % cartons;
      //     return Math.floor((record?.quantityTaken - tinsOdd) / parseInt(record?.masterProduct?.tinPerCarton));
      //   } else {
      //     return 0
      //   }
      // },
      render: record => Math.floor((record?.quantityTaken) / parseInt(record?.masterProduct?.tinPerCarton))
    },
    {
      title: lang.quantityTaken,
      key: 'quantityTaken',
      dataIndex: 'quantityTaken',
      // render: record => record?.quantityTaken
    },
    {
      title: lang.quantityCarttonLeft,
      key: 'quantityCarttonLeft',
      // dataIndex: 'quantityCarttonLeft',
      render: (record) => Math.floor((record?.quantityTin) / parseInt(record?.masterProduct?.tinPerCarton)) - Math.floor((record?.quantityTaken) / parseInt(record?.masterProduct?.tinPerCarton)),
    },
    {
      title: lang.quantityLeft,
      key: 'quantityLeft',
      // dataIndex: 'quantityLeft',
      render: (record) => record?.quantityTaken ? record?.quantityTin - (record?.quantityTaken + record?.quantityCarttonTaken * parseInt(record?.masterProduct?.tinPerCarton)) : record?.quantityTin - (record?.quantityCarttonTaken * parseInt(record?.masterProduct?.tinPerCarton)),
      // record?.quantityCartton === 0 ? (record?.quantityTin - record?.quantityTaken) % parseInt(record?.masterProduct?.tinPerCarton) : (record?.quantityCartton - record?.quantityCarttonTaken) % parseInt(record?.masterProduct?.tinPerCarton)
    },
    {
      title: lang.shipment,
      key: 'shipment',
      dataIndex: 'shipmentNo',
    },
    {
      title: lang.contractNo,
      key: 'contractNo',
      dataIndex: 'contractNo',
    },
    {
      title: locale === 'vi' ? lang.UOM_VN : lang.UOM_EN,
      key: 'UOM',
      dataIndex: 'masterProduct',
      render: record => locale === 'vi' ? record?.UOM_VN : record?.UOM_EN,
    },
    {
      title: lang.expireDate,
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: value => moment(value, formatDateAPi).format(formatDate),
    },
    // {
    //   title: lang.TYPE_OF_PRODUCT,
    //   dataIndex: ['masterProduct', 'typeOfProduct'],
    //   key: 'typeOfProduct',
    // },
    // {
    //   title: lang.five_KEYBRAND,
    //   dataIndex: ['masterProduct', 'keyBrand5'],
    //   key: 'keyBrand5',
    // },
    // {
    //   title: lang.SIZE,
    //   dataIndex: ['masterProduct', 'netWeightGramPerTins'],
    //   key: 'netWeightGramPerTins',
    // },
    {
      title: lang.description,
      dataIndex: 'description',
      key: 'description',
    },
    // {
    //   title: lang.status,
    //   key: 'reciepts',
    //   dataIndex: ['masterProduct', 'status'],
    //   width: '10%',
    //   render: (record) => {
    //     return (
    //       <Tag>{lang[record]}</Tag>
    //     );
    //   },
    // },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/apinvoice');
      }}
    >
      {lang.btnCancel}
    </Button>,
    apInvoice?.reciepts.length > 0 && <Button
      key="btn-export"
      type="primary"
      ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const content = `
  <div style="display: grid ;line-height: 1;font-size: 16px; font-family: Times New Roman, Times, serif; padding: 0 5vw; min-width: 1920px; min-height: 876px">
    <div style="display: flex;">
      <div style="text-align:center; width: 20%; border-image: initial;">
        <img src="${logog3A}" style=" height: 100%; width: 100%" />
      </div>
      <div style="width: 35%; padding-top: 120px;">
          <div>
            <span>
              Công Ty TNHH Dinh Dưỡng 3A (VIỆT NAM)
            </span>
          </div>
          <div>
            <span>
              3A Nutrition (VietNam) Co., Ltd
            </span>
          </div>
          <div>
            <span>
              No: 72-74 Nguyen Thi Minh Khai St, Ward 6, Dist 3, Ho Chi Minh , VietNam
            </span>
          </div>
          <div>
            <span>
              Tel: (84.8) 3823 8222
            </span>
          </div>
      </div>
      <div style="width: 35%; padding-top: 120px;">
          <div>
            <span>
              Trung Tâm Phân Phối Distribution Center
            </span>
          </div>
          <div>
            <span>
              18 Luy Ban Bich st, Tan Thoi Hoa Ward, Tan Phu Dist, Ho Chi Minh City, Vietnam
            </span>
          </div>
          <div>
            <span>
              Tel: (84.8) 3961 2626
            </span>
          </div>
          <div>
            <span>
              Fax: (84.8) 3961 2626
            </span>
          </div>
      </div>
      <div style="width: 15%; padding-top: 120px;">
          <div style="border: 1px solid;">
            <div><h4>Code: P-85-01-F2</h4></div>
            <div>Isue date: 02/04/2016</div>
            <div>No: 15N-03/SC-22</div>
          </div>
      </div>
    </div>
    <div style="padding-left: 20%; line-height: 2em;">
        <div style="font-weight: bold;"><span style="font-size: 2.17em; font-weight: bold;">GOODS RECEIVE ADVICE</span></div>
        <div><span style="font-size: 2.17em; font-weight: bold;">(PHIẾU BÁO NHẬP HÀNG)</span></div>
        <div><span style="font-size: 2.17em; font-weight: bold;">WH: ${items[0]?.warehouse?.address}</span></div>
        <div><span style="font-size: 2.17em; font-weight: bold;">Week: ${getWeek(new Date(apInvoice.createDate))}</span></div>
        <div style="display: flex; justify-content: space-between;">
          <span style="font-size: 2.17em; font-weight: bold;">Date: ${moment(apInvoice.createDate).format(formatDate)}</span>
          <span>Unit: cartons</span>
        </div>
    </div>
    <div style="padding: 2vh; width: 100%;">
      <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
        <thead>
          <tr>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Products</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Container No.</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Shipment</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Order No</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Contract</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">AP Invoice</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Q.Test(Tins)</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Quantity(cartons)</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Lot No</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">MFG date</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">EXP date</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Vendor Abbott</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Invoice No.</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">Short Name</div></th>
        </thead>
        <tbody style="font-weight: 600;">
        ${items?.map((product) => {
          return `<tr style="text-align: center;">
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.nameEN}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product.boothcode}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.shipmentNo}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.orderNo}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.contractNo}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${apInvoice?.id}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityTin}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityCartton}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.lot}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.mfgDate ? moment(product?.mfgDate).format(formatDate) : ''}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.expiryDate ? moment(product?.expiryDate).format(formatDate) : ''}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${apInvoice?.vendorInvoiceNo}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.invoice}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.shortName}</div></td>
                    </tr>`;
        }).join(' \n')}
          <tr>
            <td colSpan="2" style="text-align: center; padding: 10px">
                <b style="min-height: 30px;">Supply Chain Staff</b>
            </td>
            <td colSpan="6" style="text-align: center; padding: 10px">
                <b style="min-height: 30px;">Logistic Staff</b>
            </td>
            <td colSpan="4" style="text-align: center; padding: 10px">
              <b style="min-height: 30px;">WH Staff</b>
            </td>
            <td colSpan="2" style="text-align: center;">
              <b style="min-height: 30px;"></b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; flex-direction: row-reverse; padding: 2vh; width: 100%;">
       <div style="justify-content: center; margin-left: -20px;font-weight: bold;padding: 70px 0px;">
        <span style=" font-size: 26px;">3A Nutrition</span>
        <div style="font-size: 12px;">Delivering A Better Future</div>
       </div>
      <img src="${logog3A}" style=" height: 100%; width: 10%" />
    </div>
  </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1920 }).then((canvas) => {
          // let imgWidth = 400;
          // let imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          const imgProps = pdf.getImageProperties(imgData);
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.save(`PhieuNhapKho${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    >
      {lang.exportPDF}
    </Button>,
    (currentWarehouse?.id === 1 && !items?.some(item => item.quantityCarttonTaken > 0 || item?.quantityTaken > 0)) && <Button
      key="btn-save"
      type="primary"
      ghost
      // disabled={currentWarehouse?.id === 1 && apInvoice?.warehouseId !== currentWarehouse.id}
      onClick={() => {
        try {
          if (!warehouse) {
            throw new Error('Vui lòng chọn kho!');
          }
          if (apInvoice?.reciepts?.length > 0) {
            Modal.warning({
              title:'Không thể chọn lại kho',
              content:'ApInvoive đã tạo phiếu nhập kho!'
            });
          } else {
            const payload = { ...apInvoice };
            payload.warehouseId = warehouse;
            payload.invoiceProducts = apInvoice.apInvoiceProduct;
            payload.status = 'ENABLE';
            delete payload.apInvoiceProduct;
            updateAPInvoice(payload).then(() => {
              message.success(lang.updateSuccessfully);
              navigate('/dashboard/inbound/apinvoice');
            });
          }
        } catch (error) {
          message.error(error.message);
        }
      }}
    >
      {lang.btnSave}
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/receipt',
      breadcrumbName: lang.requestReceipt,
    },
    {
      path: '',
      breadcrumbName: lang.apInvoiceDetail,
    },
  ];

  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.apInvoiceDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions className="descriptions-class" column={{ xl: 4, md: 3, sm: 2, xs: 1 }}>
            <Descriptions.Item label={'Mã APInvoice'}>{apInvoice?.vendorInvoiceNo}</Descriptions.Item>
            <Descriptions.Item label={'Công ty'}>{apInvoice?.company}</Descriptions.Item>
            {/* <Descriptions.Item label={'Chi nhánh'}>{apInvoice?.branch}</Descriptions.Item> */}
            {/* <Descriptions.Item label={'Lô hàng'}>{apInvoice?.shipmentNo}</Descriptions.Item> */}
            <Descriptions.Item label={'Shipment NO'}>{apInvoice?.shipmentNo}</Descriptions.Item>
            <Descriptions.Item label={'Business Partner'}>{apInvoice?.businessPartner}</Descriptions.Item>
            {/* <Descriptions.Item label={'Địa chỉ người bán'}>{apInvoice?.partnerLocation}</Descriptions.Item> */}
            {/* <Descriptions.Item label={'PO Reference'}>{apInvoice?.apInvoiceProduct[0]?.pOReference}</Descriptions.Item> */}
            {/* <Descriptions.Item label='Invoice Type'>{apInvoice?.invoiceType}</Descriptions.Item> */}
            {/* <Descriptions.Item label='Số hóa đơn'>{apInvoice?.physicalInvoiceNo}</Descriptions.Item> */}
            {
              currentWarehouse.id === 1
                ? <Descriptions.Item label={'Trạng thái'}><Tag>{apInvoice?.warehouseId === currentWarehouse.id ? 'Chưa Điều Phối' : 'Đã Điều Phối'}</Tag></Descriptions.Item>
                : apInvoice?.status === 'ENABLE' ? <Descriptions.Item label={'Trạng thái'}><Tag>{'Chưa Nhập Hàng'}</Tag></Descriptions.Item> : apInvoice?.status === 'INPROGRESS' ? <Descriptions.Item label={'Trạng thái'}><Tag>{'Chưa Nhập Xong'}</Tag></Descriptions.Item> : apInvoice?.status === 'DISABLE' ? <Descriptions.Item label={'Trạng thái'}><Tag>{'Đã Hủy'}</Tag></Descriptions.Item> : <Descriptions.Item label={'Trạng thái'}><Tag>{'Đã Nhập Đủ'}</Tag></Descriptions.Item>
            }
            {(currentWarehouse?.id === 1) && <Descriptions.Item label={lang.warehouse}><Select
              style={{ width: '70%' }}
              name='warehouseId'
              label={null}
              options={
                warehouseDisplay?.map(warehouse => ({
                  value: warehouse.id,
                  label: warehouse.name,
                  key: warehouse.id,
                  style: { width: '200%' },
                }))}
              value={warehouse}
              placeholder={lang.warehouse}
              onChange={val => setWarehouse(val)}
            /></Descriptions.Item>}
            {/* {apInvoice?.warehouseId !== 1 && <Descriptions.Item label={lang.warehouse}>{listwarehouse?.find(warehouse => warehouse.id === apInvoice?.warehouseId)?.name}</Descriptions.Item>}
            <Descriptions.Item label={'Trạng thái PNK'}>{apInvoice?.reciepts[0]?.status === 'RECEIVE' ? <Tag>{'ĐÃ XÁC NHẬN'}</Tag> : <Tag>{'CHƯA XÁC NHẬN'}</Tag>}</Descriptions.Item> */}
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {currentWarehouse?.id !== 1 && <Button
                type="primary"
                onClick={() => {
                  if (apInvoice?.status === 'COMPLETED') {
                    Modal.error({
                      title: 'Hàng đã nhập đủ, vui lòng kiểm tra lại!',
                      icon: <ExclamationCircleOutlined />,
                    });
                  } else {
                  const filtersRole = items?.filter(item => selectedRowKeys.indexOf(item.id) > -1 && item?.masterProduct?.CTNSPerPalletImport !== null);
                  const nonFiltersRole = items?.filter(item => selectedRowKeys.indexOf(item.id) > -1 && item?.masterProduct?.CTNSPerPalletImport === null);
                  if (nonFiltersRole.length > 0) {
                    Modal.confirm({
                      title: `Sản phẩm có mã ${nonFiltersRole?.map(r => r.masterProduct.code3A).toString()} chưa có tiêu chuẩn nhập khẩu - Số thùng/ pallet - Dưới sàn`,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        if (filtersRole.length > 0) {
                          setItem(filtersRole);
                          setIsEditModal(true);
                        }
                      },
                      onCancel() { },
                    });
                  } else {
                    setItem(filtersRole);
                    setIsEditModal(true);
                  }
                  }
                }}
                disabled={selectedRowKeys.length === 0}
              >
                {'Tạo phiếu nhập kho'}
              </Button>}
            </Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={false}
          scroll={{ x: 2500 }}
          rowSelection={currentWarehouse?.id !== 1 && rowSelection}
        />
      </Layout.Content>
      <ApInvoiceForm
        item={item}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
        apInvoice={apInvoice}
        onCallBack={() => {
          setLoading(true);
          apInvoiceDetail(id)
            .then(result => {
              setApInvoice(result);
              result.apInvoiceProduct.map((value) => {
                value.quantityCarttonLeft = value.quantityCartton - value.quantityCarttonTaken;
                value.quantityLeft = value.quantityTin - value.quantityTaken;
                value.boothcode = result?.reciepts?.map(receipt => receipt?.receiptProduct?.[0]?.container);
                return value;
              });
              setItems(result?.apInvoiceProduct);
              setSelectedRowKeys([])
              setLoading(false);
            })
            .catch((e) => setLoading(false));
        }}
      />
    </Layout>
  );
};

export default APInvoiceDetail;
