// Section Import Librari
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; //Link, useNavigate,
import { Layout, PageHeader, Descriptions, Card, Row, Col, message } from 'antd';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { productionDetail } from './production.service';
import moment from 'moment-timezone';

//Section Import Internal
import TableCustom from 'components/TableCustom/table';
import { formatDate, formatDateFull } from 'utils/constants';

const ProductionDetail = () => {
// Section Difine Vảiable
const { t } = useTranslation();
const lang = getLanguages(t);
const {id} = useParams();
const [pageSize, SetPageSize] = useState(10);
const [currentPage, setCurrentPage] = useState(1);
const [loading, setLoading] = useState(false);
const [productionGetOne, setProductionGetOne] = useState([])
const [productionProductPack, setProductionProductPack] = useState([])
const [production, setProduction] = useState()
// const [selectedRowKeys, setSelectedRowKeys] = useState([])

// Section Define UseEffect call API
useEffect(()=> {
    setLoading(true)
    productionDetail(id)
        .then(result => {
            setProductionGetOne(result?.productionProductLine);
            setProductionProductPack(result?.productionProductPack);
            setProduction(result);
            setLoading(false)
        })
        .catch(err => {
            message.error(err);
            setLoading(false)
        })
}, [id])

    const columns = [
        {
            title: 'Mã Production',
            dataIndex: 'documentNo',
            key: 'documentNo',
        },

        {
            title: 'Mã',
            dataIndex: 'code3A',
            key: 'code3A',
        },

        {
            title: lang.SHORTNAME,
            dataIndex: ['masterProduct', 'shortName'],
            key: 'name',
        },

        {
            title: lang.productName,
            dataIndex: ['masterProduct', 'nameEN'],
            key: 'name',
        },

        {
            title: lang.Locator,
            key: 'locatorCode',
            dataIndex: 'locatorCode',
        },

        {
            title: lang.lot,
            key: 'lot',
            dataIndex: 'lot',
        },

        {
            title: lang.expireDate,
            key: 'expireDate',
            dataIndex: 'expireDate',
            render: (val) => {
                return moment(val, formatDateFull).format(formatDate)
            }
        },

        {
            title: lang.quantity,
            key: 'productionQuantity',
            dataIndex: 'productionQuantity',
        },

    ]

    const expandedRowRender = (rec) => {
        const columExpanded = [
            {
                title: 'Mã',
                dataIndex: 'codeProduct',
                key: 'codeProduct',
            },
    
            {
                title: lang.productName,
                dataIndex: 'nameProduct',
                key: 'name',
            },
    
            {
                title: lang.Locator,
                key: 'locatorCode',
                dataIndex: 'locatorCode',
                // render: record => record?.locatorCode ? record?.locatorCode : ''
            },

            {
                title: lang.lot,
                key: 'lot',
                dataIndex: 'lot',
            },
    
            {
                title: lang.expireDate,
                key: 'expireDate',
                dataIndex: 'expireDate',
                render: (val) => {
                    return moment(val, formatDateFull).format(formatDate)
                }
            },
    
            {
                title: lang.quantity,
                key: 'movementQuantity',
                dataIndex: 'movementQuantity',
            },
        ]
        return <TableCustom columns={columExpanded} dataSource={productionGetOne} pagination={false} scroll={{ x: 1200 }} />;
    }

    const extraButton = [

    ]

    return (
        <Layout>
            <PageHeader 
             ghost={false}
             title={'Chi tiết Production'}
             extra={extraButton}
             className="customPageHeader"
            
            />

            <Layout.Content>
            <Card size="small" className="cardSearch">
                <Descriptions column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 3, xs: 2 }} >
                    <Descriptions.Item label={'Mã Production'}>{production?.documentNo}</Descriptions.Item>
                    {/* <Descriptions.Item span={2} label={`${lang.Locator} (Transit)`}></Descriptions.Item> */}
                    {/* <Descriptions.Item label={lang.truckType}></Descriptions.Item>
                    <Descriptions.Item label={'Trọng tải xe(tấn)'}></Descriptions.Item> */}
                    <Descriptions.Item label={lang.movementDate}>{moment(production?.movementDate, formatDateFull).format(formatDate)}</Descriptions.Item>
                    <Descriptions.Item label={lang.tranferReason}>{production?.description}</Descriptions.Item>
                    <Descriptions.Item label={lang.status}>{
                    production?.status === 'NEW' ? 'Mới tạo'
                    // : production?.status === 'PICKING' ? 'Đã tạo nghiệp vụ'
                    : ''
                    }</Descriptions.Item>
                    <Descriptions.Item label={lang.description}></Descriptions.Item>
                </Descriptions>
            </Card>

            <TableCustom
                title={() => <Row>
                    <Col span={12}><h3>{'Danh sách sản phẩm Production'}</h3></Col>
                </Row>}
                rowKey="id"
                loading={loading}
                bordered
                columns={columns}
                dataSource={productionProductPack}
                expandable={{expandedRowRender}}
                onChange={(pagination, filters, sorter) => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setCurrentPage(pagination.current);
                }}
                pagination={{
                    showSizeChanger: true,
                    total: productionProductPack?.length,
                    onShowSizeChange: (current, size) => {
                    SetPageSize(size);
                    },
                    pageSize: pageSize,
                    current: currentPage,
                }}
                scroll={{ x: 467 }}
                // rowSelection = {{
                //     onChange: (_, selectedRows) => {
                //     setSelectedRowKeys(selectedRows)
                //     },
                //     getCheckboxProps: (record) => ({
                //     disabled: (record?.locatorCode === null)
                //     })
                // }}
                />
            </Layout.Content>
        </Layout>
    )
}

export default ProductionDetail