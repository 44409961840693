import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import { PrinterOutlined } from '@ant-design/icons';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message } from 'antd';
import ProTableCusTom from 'components/TableCustom/Protable';
import './style.less';
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import moment from 'moment-timezone';
// import Helper from 'utils/helpers';
// import TableCustom from 'components/TableCustom/table';
import { physicalInventoryDetail } from './physical-inventory.service';
import ReceiptProductsPrint from '../../inbound/receipt/receipt-products-print';
import { productListInStored } from '../../inbound/product.service';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PhysicalInventoryDetail = () => {
  const { id } = useParams();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [productsStored, setProductsStored] = useState([]);
  const [item, setItem] = useState([]);
  const [isclose, setIsClose] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    setLoading(true);
    physicalInventoryDetail(id)
      .then(result => {
        setSaleorder(result);
        setProductOrder(result?.physicalProduct);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [id]);
  useEffect(() => {
    setLoading(true);
    productListInStored({  warehouseId: currentWarehouse.id })
    .then(result => {
      setProductsStored(result.data);
      // setTotalItem(result.totalItem);
      setLoading(false);
    })
    .catch((e) => setLoading(false));
  }, [currentWarehouse]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.SHORTNAME,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'nameEN',
    },
    {
      title: 'Code3A',
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.Locator,
      dataIndex: ['locator', 'locator'],
      key: 'locatorId',
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: 'expireDate',
      render: val => moment(val).format(formatDate),
    },
    {
      title: lang.quantity,
      key: 'qtyCount',
      dataIndex: 'qtyCount',
    },
    {
      title: 'Qty Scrapped',
      dataIndex: 'qtyScrapped',
      key: 'carton',
    },
    {
      title: 'Quantity book',
      dataIndex: 'quantityBook',
      key: 'quantityTin',
    },
    // {
    //   title: 'In QACode',
    //   key: 'quantity',
    //   render: record => (
    //     <Button
    //         type="link"
    //         size="small"
    //         disabled={record?.printQAcode === null}
    //         icon={<PrinterOutlined />}
    //         onClick={() => {
    //         const findProductInStored = productsStored.filter(result => result?.masterProduct?.id === record?.masterProduct.id && result?.locator.id === record?.locator.id && result?.lot === record?.lot && result.expireDate === record?.expireDate && result?.totalQuantity === -(record?.qtyScrapped));
    //           setItem(findProductInStored);
    //           setIsPrintModal(true);
    //         }}
    //       />
    //   )
    // },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {'Back'}
    </Button>,
    saleorder?.documentType === 'Stocktake Adjustment' && <Button
    key="btn-export-NB"
    type="primary"
    ghost
    loading={loadingExport}
    onClick={async () => {
      setLoadingExport(true);
      let content = `
      <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
      <div style="display:flex">
        <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 80%; ">
          <div>
            <p style="font-weight: bold; font-size: 20px;">Stocktake Adjusment:Document No 1HH1908 - 10718</p>
            <p>Warehouse HCM Suspense</p>
            <p>Movement date: ${new Date().getDate()} - ${new Date().getMonth() + 1} - ${new Date().getFullYear()}</p>
            <p>Doc Status: Completed</p>
          </div>  
        </div>
        <div style="width: 20%; margin-top: 20px; padding: 10px 0; "></div>
      </div>
      <div style="padding: 50px 0px 30px 0px;">
        <p style="font-weight: bold; font-size: 18px;">NHAP HANG THUA KHO WDC (Dua vao bien ban kiem kho T06/2019)</p>
      </div>
  
      <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
        <thead>
          <tr style="text-align:center; font-weight: bold;">
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Line No</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Locator</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Product</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Attribute Set Instance</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px;">Qty</td>
            </tr>
        </thead>
        <tbody>
        ${productOrder.map(pro => {
          return `<tr>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${pro?.lineNo ? pro?.lineNo : ''}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro?.locator ? pro?.locator.locatorName : ''}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro?.masterProduct?.nameEN}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">&lt;${pro.lot}&gt;_${moment(pro?.expireDate, formatDateFull).format(formatDate)}</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${-pro?.qtyScrapped}</td>
                </tr>`
      }).join(' \n')}
        </tbody>
      </table>
  </div>`;
      var template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        var imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
        pdf.save(`StockAdjusment${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
      setLoadingExport(false);
    }}
  >
    {'Xuất Stocktake Adjustment'}
  </Button>,
   saleorder?.documentType === 'Physical Inventory' && <Button
   key="btn-export-NB"
   type="primary"
   ghost
   loading={loadingExport}
   onClick={async () => {
     setLoadingExport(true);
     let content = `
     <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
     <div style="display:flex">
       <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 80%; ">
         <div>
           <p style="font-weight: bold; font-size: 20px;">Lot Adjusment:Document No 1HH2302 - 10113</p>
           <p>Warehouse ${productOrder[0]?.locator?.locatorName}</p>
           <p>Movement date: ${new Date().getDate()} - ${new Date().getMonth() + 1} - ${new Date().getFullYear()}</p>
           <p>Doc Status: Completed</p>
         </div>  
       </div>
       <div style="width: 20%; margin-top: 20px; padding: 10px 0; "></div>
     </div>
     <div style="padding: 50px 0px 30px 0px;">
       <p style="font-weight: bold; font-size: 18px;">ĐIỀU CHỈNH HÀNG HẾT DATE (Hoang CS 8/2)</p>
     </div>
 
     <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
       <thead>
         <tr style="text-align:center; font-weight: bold;">
             <td style="width: auto; border: 1px solid #333; padding: 5px;">Line No</td>
             <td style="width: auto; border: 1px solid #333; padding: 5px;">Locator</td>
             <td style="width: auto; border: 1px solid #333; padding: 5px;">Product</td>
             <td style="width: auto; border: 1px solid #333; padding: 5px;">Attribute Set Instance</td>
             <td style="width: auto; border: 1px solid #333; padding: 5px;">Qty</td>
           </tr>
       </thead>
       <tbody>
       ${productOrder.map(pro => {
         return `<tr>
                 <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${pro?.lineNo ? pro?.lineNo : ''}</td>
                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro?.locator ? pro?.locator.locatorName : ''}</td>
                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro?.masterProduct?.nameEN}</td>
                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">&lt;${pro.lot}&gt;_${moment(pro?.expireDate, formatDateFull).format(formatDate)}</td>
                 <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${-pro?.qtyScrapped}</td>
               </tr>`
     }).join(' \n')}
       </tbody>
     </table>
 </div>`;
     var template = document.createElement('div');
     template.innerHTML = content;
     document.body.appendChild(template);
     await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
       const imgWidth = 208;
       const imgHeight = canvas.height * imgWidth / canvas.width;
       var imgData = canvas.toDataURL('image/png');
       // eslint-disable-next-line new-cap
       const pdf = new jsPdf('p', 'mm', 'a4');
       pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
       pdf.save(`PhysicalInventory${moment().format('DDMMYYYY')}.pdf`);
     });
     document.body.removeChild(template);
     setLoadingExport(false);
   }}
 >
   {'Xuất Physical Inventory'}
 </Button>,
  
    // <Button
    //   key="btn-export"
    //   type="primary"
    //   ghost
    //   onClick={async () => {
    //     try {
    //       await physicalInventoryConfirm(saleorder?.id);
    //       message.success(lang.confirmSuccessfully);
    //       navigate(-1);
    //       return true;
    //     } catch (error) {
    //       message.error(error.message);
    //       return false;
    //     }
    //   }}
    //   disabled={saleorder?.status === 'CONFIRM'}
    // >
    //   {lang.confirm}
    // </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/inventory',
      breadcrumbName: lang.inventory,
    },
    {
      path: '/inventory/inventorymanagement',
      breadcrumbName: 'Physical Inventory',
    },
    {
      path: '/',
      breadcrumbName: 'Physical Inventory Detail',
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={'Chi tiết Physical Inventory'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.code}>{saleorder?.code}</Descriptions.Item>
            <Descriptions.Item label={lang.company}>{saleorder?.company}</Descriptions.Item>
            <Descriptions.Item label={lang.branch}>{saleorder?.branch}</Descriptions.Item>
            <Descriptions.Item label={lang.businessLine}>{saleorder?.businessLine}</Descriptions.Item>
            {/* <Descriptions.Item label={lang.documentNo}>{saleorder?.documentNo}</Descriptions.Item> */}
            <Descriptions.Item label={lang.documentType}>{saleorder?.documentType}</Descriptions.Item>
            <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
            <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate).format(formatDatetime)}</Descriptions.Item>
            <Descriptions.Item label={'perpetualInventory'}>{saleorder?.perpetualInventory}</Descriptions.Item>
            {/* <Descriptions.Item label={lang.status}>{
            saleorder?.status === 'ENABLE' ? 'Mới tạo' :
            saleorder?.status === 'CONFIRM' ? 'Đã xác nhận' :
            ''
            }</Descriptions.Item> */}
          </Descriptions>
        </Card>
        <ProTableCusTom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={productOrder}
          toolBarRender={() => [
            <Button
            type={'primary'}
            disabled={selectedRowKeys?.length === 0}
            style={{ width: 110, borderRadius: 4 }}
            onClick={ async () => {
              let dataPrint = [];
              const dataPrintError = [];
              await selectedRowKeys.map(productPrint => {
                 const findProductInStored = productsStored.filter(result => productPrint.id === result.physicalProductId);
                if (findProductInStored) {
                  dataPrint = dataPrint.concat(findProductInStored);

                } else {
                  dataPrintError.push(productPrint?.masterProduct?.code3A)
                }
                return true
              });
              if (dataPrintError?.length > 0) {
                message.error(`Không tìm thấy sản phẩm có mã ${dataPrintError.toString()} trong kho`)
              } else {
                setItem(dataPrint);
                setIsPrintModal(true);
              }
       
            }}
            >
              {lang.print}
            </Button>
          ]}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
          rowSelection={{
            onChange: (_, selectedRows) => {
            setSelectedRowKeys(selectedRows);
          },
            getCheckboxProps: (record) => ({
              disabled: record?.printQAcode === "NO" || record?.qtyCount < 0, // Column configuration not to be checked
            }),
          }}
        />
      </Layout.Content>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => setIsPrintModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={item}
          physicalInventory={true}
          // onCallback={(values) => {
          //   Helper.dowloadXLSX(values?.map(item => {
          //     return {
          //       code: item.rack.code,
          //       name: item.masterProduct.name,
          //       receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
          //     };
          //   }), 'productCode');
          //   setIsPrintModal(false);
          //   if (isclose) {
          //     navigate('/dashboard/inventory/physicalinventory/create');
          //     setIsClose(false);
          //   }
          // }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inventory/physicalinventory/create');
              setIsClose(false);
            }
          }}
          onCancel={() => {
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inventory/physicalinventory/create');
              setIsClose(false);
            }
          }}
        />
      </Modal>
    </Layout>
  );
};

export default PhysicalInventoryDetail;
