import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, message as Alert, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import Helper from 'utils/helpers';
import { areaCreate, areaUpdate } from './area.service';
import { MODE } from 'utils/constants';

const { Option } = Select;

const AreaEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const initValues = {
    code: item ? item?.warehouseID : undefined,
    name: item ? item?.warehouseName : undefined,
    status: item ? item?.status : undefined,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.areaUpdate : lang.areaCreate}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={570}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          values.warehouseId = 1;
          if (!item) { // Create
            areaCreate(values)
              .then(() => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            values.id = item?.id;
            areaUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="code"
              label={lang.areaCode}
              rules={[
                {
                  required: true,
                  message: lang.requiredDivisonName,
                },
                {
                  max: 100,
                  message: lang.messageLong,
                },
              ]}
            >
              <Input placeholder={lang.areaCode} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="name"
              label={lang.areaName}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <Input placeholder={lang.areaName} />
            </Form.Item>
          </Col>
          {item && <Col span={24}>
            <Form.Item name="status" label={lang.status}>
              <Select placeholder={lang.status}>
                <Option value="ENABLE">{lang.ENABLE}</Option>
                <Option value="DISABLE">{lang.DISABLE}</Option>
              </Select>
            </Form.Item>
          </Col>}
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

AreaEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default AreaEditForm;
