import { combineReducers } from 'redux';
import i18n from 'providers/I18nProvider/reducer';

import auth from 'providers/AuthProvider/reducer';
import outbound from 'providers/OutboundProvider/reducer';
import location from 'providers/LocationProvider/reducer';
import inbound from 'providers/InboundProvider/reducer';
import inventory from 'providers/InventoryProvider/reducer';

export default combineReducers({
  i18n,
  auth,
  outbound,
  location,
  inbound,
  inventory,
});
