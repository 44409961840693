import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Input, Form, Tag, Modal, message, Select } from 'antd';

import { deliveryOrderCheckInVoiceDelete, deliveryOrderList, updateDoStatus, orderCheck, groupDeliveryOrder, unGroupDeliveryOrder } from './delivery-order.service';
import { DeleteOutlined, SearchOutlined, ExclamationCircleOutlined, ScissorOutlined } from '@ant-design/icons';
import './style.less';
import { defaultPage, formatDate, formatDateFull, STATUS_OUTBOUND } from 'utils/constants';
import { bookingOrderSet } from 'providers/OutboundProvider/actions';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { pickBy } from 'lodash';
import _get from 'lodash/get';
import TableCustom from 'components/TableCustom/table';
import { userList } from 'pages/admin/user/user.service';
import { warehouseListAll } from 'pages/admin/warehouse/warehouse.service';

const { Option } = Select;

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const DeliveryOrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);
  const [pickers, setPickers] = useState([]);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [warehouses, setWarehouses] = useState([]);
  const isManager = _get(user, 'roles', '').includes('manager');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isOutboundStaff = _get(user, 'roles', '').includes('outboundStaff');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');

  //Pagination
  const [params, setParams] = useState({ status: isPicker ? 'LOCK' || 'WAITING' || 'PICKING' : 'NEW', ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  const [userId, setUserId] = useState([]);
  const [status, setStatus] = useState(isPicker ? 'LOCK' : 'NEW');

  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysUnGroup, setSelectedRowKeysUnGroup] = useState([]);

  const onSelectChangeUnGroup = selectedRowKeysUnGroup => {
    setSelectedRowKeysUnGroup(selectedRowKeysUnGroup);
  };

  const rowSelectionUnGroup = {
    selectedRowKeysUnGroup,
    onChange: onSelectChangeUnGroup,
  };

  const getUsers = () => {
    setLoading(true);

    userList()
      .then(result => {
        const rsfilter = result?.map(rs => {
          const role = rs.roles?.filter(role => role.name !== 'default-roles-akawms-dev' && role.name === "forkliftDriver");
          rs.roles = role;
          return rs
        });
        setPickers(rsfilter);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    warehouseListAll()
      .then(result => {
        setWarehouses(result.data);
        if (isStocker || isOutboundStaff) {
          getUsers();
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse, isStocker, isOutboundStaff]);
  useEffect(() => {
    setLoading(true);
    deliveryOrderList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
        if (isPicker) {
          const findByUserId = result.data.filter(item => item?.userId === null && item?.assignAll === true)
          const findDo = result.data.filter(item => {
            const idString = JSON.stringify(item?.userId);
            if (idString.indexOf(user?.userInfo?.sub) !== -1) {
              return item;
            }
            return '';
          });

          //TH vừa có đơn k asign và vừa có đơn asign
          if (findByUserId?.length > 0) {
            const result = findByUserId.concat(findDo)
            setItems(result)
          } 
          // TH lọc ra những đơn đã asign
          else if (findDo.length > 0) {
            setItems(findDo)
          } else {
            setItems([])
          }
        } else {
          setItems(result.data);
        }
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse, isPicker, user.userInfo.sub]);

  const columns = [
    {
      title: lang.code,
      key: 'code',
      dataIndex: 'code',
      render: (val, rec) => {
        if (rec?.groupDeliveryOrder.length > 0) {
          const groupCodeDo = rec?.groupDeliveryOrder.map((doCode, index) => index === rec?.groupDeliveryOrder.length -1 ?  doCode.code : doCode.code + " , ");
          if (groupCodeDo) {
            return <Button
            key="btn-detail"
            type="link"
            onClick={() => navigate(`/dashboard/outbound/delivery-order/${rec.id}`)}
            size='small'
          >
            {groupCodeDo}
          </Button>
          }
        } else {
          return <Button
            key="btn-detail"
            type="link"
            onClick={() => navigate(`/dashboard/outbound/delivery-order/${rec.id}`)}
            size='small'
          >
            {val}
          </Button>
        }
        
      }
    },
    {
      title: lang.invoiceNo,
      key: 'invoicePrintNo',
      dataIndex: 'invoicePrintNo',
    },
    {
      title: lang.company,
      key: 'company',
      dataIndex: 'company',
    },
    {
      title: lang.branch,
      dataIndex: 'branch',
      key: 'branch',
    },
    {
      title: lang.orderCode,
      dataIndex: 'orderCode',
      key: 'orderCode',
      // render: (record) => {
      //   console.log(record)
      // }
    },
    {
      title: lang.orderReference,
      key: 'orderReference',
      dataIndex: 'orderReference',
    },
    {
      title: lang.businessLine,
      dataIndex: 'bussinessLine',
      key: 'businessLine',
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      render: (record) => moment(record, formatDateFull).format(formatDate),
    },
    {
      title: lang.status,
      dataIndex: 'status',
      key: 'status',
      render: val => {
        if (val === 'NEW') {
          return <Tag>{'Mới tạo'}</Tag>
        } else if (val === 'LOCK') {
          return <Tag>{'Chờ Soạn'}</Tag>
        } else if (val === 'WAITING') { 
          return <Tag>{'Đang Soạn'}</Tag>
        } else if (val === 'PICKING') {
          return <Tag>{'Đã Lấy Hàng'}</Tag>
        } else if (val === 'WAITINGCANCEL') {
          return <Tag>{'Chờ Hủy DO'}</Tag>  
        } else if (val === 'CANCELLED') {
          return <Tag>{'Đã Hủy DO'}</Tag> 
        } 
        else return <Tag>{'Hoàn Tất Xuất Hàng'}</Tag>
      },
    },
    {
      title: 'User asignned',
      dataIndex: 'userId',
      key: 'userId',
      hideInTable: isManager || isIdm || isPicker || params?.status === 'NEW',
      render: (value, record) => {
        const idString = JSON.stringify(value)
        if (!(record?.userIdPicked)) {
          const data = pickers.map((item) => item);
          const findUser = data.filter(user => user?.roles.length > 0)
          const checkUser = findUser.map(itemUser => {
            if ( idString.indexOf(itemUser.id) !== -1 ) {
              return itemUser
            }
            return '';
          })
          const listId = checkUser.filter(data => data !== '');
          return listId.map((i, index) =>index === listId.length-1 ? i.username  : i.username + ' , ' );
        }  
      },
    },
    {
      title: 'User Picked',
      dataIndex: 'userIdPicked',
      key: 'userIdPicked',
      hideInTable: isManager || isIdm || isPicker || params?.status === 'NEW',
      render: (value) => {
        const data = pickers.map((item) => item);
        const findUser = data.filter(user => user?.roles.length > 0)
        const checkUser = findUser.map(itemUser => {
          if (itemUser.id === value  ) {
            return itemUser
          }
          return '';
        })
       for(let name of checkUser){
        if (name){
          return <Tag>{name.username}</Tag>
        }
       }
         
      },
    },
  ];

  const columnsExpand = [
    {
      title: lang.code,
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.invoiceNo,
      key: 'invoicePrintNo',
      dataIndex: 'invoicePrintNo',
    },
    {
      title: lang.company,
      key: 'company',
      dataIndex: 'company',
    },
    {
      title: lang.branch,
      dataIndex: 'branch',
      key: 'branch',
    },
    {
      title: lang.orderCode,
      dataIndex: 'orderCode',
      key: 'orderCode',
    },
    {
      title: lang.orderReference,
      key: 'orderReference',
      dataIndex: 'orderReference',
    },
    {
      title: lang.businessLine,
      dataIndex: 'bussinessLine',
      key: 'businessLine',
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      render: (record) => moment(record, formatDateFull).format(formatDate),
    },
    // {
    //   title: lang.status,
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: val => {
    //     if (val === 'GROUP_DELIVERY_ORDER') {
    //       return <Tag>{'GROUP_DO'}</Tag>
    //     }
    //   }
    // }
    
  ];

  const expandedRowRender = (rec) => {
    if (rec?.groupDeliveryOrder?.length > 0) {
      const dataSource = rec?.groupDeliveryOrder?.map(producDo => producDo);
      return <TableCustom columns={columnsExpand} dataSource={[...dataSource]} pagination={false} rowSelection={rowSelectionUnGroup} rowKey="id" />;//
    }
  }
  const extraButton = [
    selectedRowKeys.length > 0 && params?.status === 'NEW' && isStocker && !isPicker && (
      <Button
        type="primary"
        onClick={() => {
          Modal.confirm({
            title: 'Bạn chắc chắn muốn gom nhóm?',
            icon: <ExclamationCircleOutlined />,
            async onOk() {
              setLoading(true);
              await groupDeliveryOrder({ids: selectedRowKeys.map(doId => doId.id)})
                .then(() => {
                  setSelectedRowKeys([])
                  Modal.success({
                    title: 'Gom đơn thành công!',
                    icon: <ScissorOutlined />
                  });
                })
                .catch((e) => message.error(e.message));
              await deliveryOrderList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
                .then(result => {
                  setItems(result.data);
                  setTotalItems(result.totalItem);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              },
            onCancel() {},
          });
        }}
        style={{ marginLeft: 8 }}
      >
        {'GroupPick'}
      </Button>
    ),
     params?.status === 'NEW' && selectedRowKeysUnGroup?.length > 0 && <Button
    type='primary'
    onClick={() => {
      Modal.confirm({
        title:'Bạn chắc chắn muốn UnGroup?',
        icon: <ScissorOutlined />,
        async onOk() {
          setLoading(true);
          await unGroupDeliveryOrder({ids: selectedRowKeysUnGroup})
            .then(() => {
              setSelectedRowKeysUnGroup([])
              Modal.success({
                title: 'UnGroup thành công!',
                icon: <ScissorOutlined />
              });
            })
            .catch((e) => message.error(e.message));
          await deliveryOrderList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
            .then(async result => {
              // await result.data.map(async item => {
              //   const newOrderCode = []
              //   const newOrderReference = []
              //   const newBussinessLine = []
              //   await item.groupDeliveryOrder.map(itemCode => {
              //     newOrderCode.push(itemCode.orderCode)
              //     newOrderReference.push(itemCode.orderReference)
              //     newBussinessLine.push(itemCode.bussinessLine)
              //     return true
              //   });
              //   if (item?.groupDeliveryOrder?.length > 0) {
              //     item.orderCode = newOrderCode.toString()
              //     item.orderReference = newOrderReference.toString()
              //     item.bussinessLine = newBussinessLine.toString()
              //   } else {
              //     setItems(result.data);
              //   }
              //   return true
              // })
              setItems(result.data);
              setTotalItems(result.totalItem);
              setLoading(false);
            })
            .catch((e) => setLoading(false));
        },
        onCancel() {}
      })
    }}
    >
      {'UnGroupPick'}
    </Button>,
    params?.status === 'NEW'  && !isPicker &&  selectedRowKeys?.length > 0 && <Button
    key="btn-confirm"
    type="primary"
    disabled = {isPicker}
    // ghost
    onClick={async() => {
      Modal.confirm({
        title: "Bạn có chắc chắn muốn xác nhận không?",
        async onOk() {
          try {
            if (params?.status === "NEW") {
              setLoading(true)
              await updateDoStatus({ids: selectedRowKeys.map(idDo => idDo?.id), status: 'LOCK'})
              deliveryOrderList({...params, ...sortedInfo, warehouseId: currentWarehouse?.id})
              .then(result => {
                setItems(result.data);
                setSelectedRowKeys([])
                message.success("Xác nhận thành công!")
                setLoading(false);
              })
              .catch((e) => setLoading(false));
            }
          } catch (error) {
            message.error(error.message);
            return false;
          }
        },
        onCancel() {},
      });
      
    }}
  >
    {lang.confirm}
  </Button>,

    (isIdm ||isStocker) && params?.status === 'WAITINGCANCEL'  && selectedRowKeys?.length > 0 && (
      <Button
        key="btn-confirm"
        type="primary"
        disabled={isPicker}
        // ghost
        onClick={async () => {
          Modal.confirm({
            title: lang.confirmCancelDO,
            icon: <ExclamationCircleOutlined />,
            async onOk() {
              setLoading(true);
        
              const ids = [];
              for (const item of selectedRowKeys) {
                ids.push(item.id);
              }
        
              try {
                await deliveryOrderCheckInVoiceDelete({ ids })
                await deliveryOrderList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
                  .then(result => {
                    setItems(result.data);
                    setTotalItems(result.totalItem);  
                  });                         
                setSelectedRowKeys([]);
                setLoading(false);
        
                Modal.success({
                  title: lang.deleteSuccessfully,
                  icon: <ScissorOutlined />
                });
        
              } catch (error) {
                setLoading(false);
                message.error(error.message);
              }
            },
            onCancel() {},
          });
        }}
      >
        {lang.confirmCancelDO}
      </Button>),
    

   params?.status === 'LOCK' && isStocker && <Button
    disabled={selectedRowKeys.length === 0 }
    key="btn-complete"
    type='primary'
    onClick={async () => {
      Modal.confirm({
        title: "Bạn có chắc chắn muốn Assign?",
        async onOk() {
          try {     
            setLoading(true)
            await updateDoStatus({ids: selectedRowKeys?.map(order => order?.id), userId: userId, assignAll: true})
            deliveryOrderList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
              .then(result => {
                setItems(result.data);
                setSelectedRowKeys([])
              })
              .catch((e) => setLoading(false));
                message.success('Assign thành công!')
                setLoading(false)
                return true
              } catch (error) {
                message.error(error.message);
                setLoading(false)
                return false;
              }
            },
          onCancel() {},
      });
    }}
    >
      {'Assign Staff'}
    </Button>,
   (params?.status === 'WAITING' || params?.status === 'LOCK') && isPicker && <Button
    disabled={selectedRowKeys.length === 0 || selectedRowKeys.length > 1 }
    key="btn-complete"
    type='primary'
    onClick={async () => {
      try {
        if (selectedRowKeys.length > 0 ) {
          await orderCheck({ids: selectedRowKeys?.map(orderId => orderId.id), userId: `${user?.userInfo?.sub}`});
          dispatch(bookingOrderSet({
            pickingOrder: selectedRowKeys,
            pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
              // let dataProductDO = await deliveryOrderDetail(curr?.id).then(result => result?.productDeliveryOrders).catch(err => message.error(err))
              const productOrders = curr?.productDeliveryOrders?.map((product) => {
                product.orderCode = curr.code;
                product.orderId = curr.id;
                product.pickedQuantityCartton = 0;
                product.pickedQuantityTin = 0;
                product.groupOrder = curr.group;
                product.saleChannel = curr?.subChannel;
                product.orderType = 'EXPORT_SALE';
                product.locator = product?.locator;
                product.warehouse = curr.warehouse;
                return product;
              });
              // console.log(productOrders)
              return [...prev, ...productOrders];
            }, [])
          }))
          return navigate(`picking/scan`)
        }
      } catch (error) {
        const splitMessage = error?.message?.split(/\s(.*)/s);
          message.error(splitMessage[0] + ' ' + lang.messeagePicking);
      }
    }}
    >
      {lang.picking}
    </Button>
  ]

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/delivery-order',
      breadcrumbName: 'Delivery order',
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={'Delivery order'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            setSelectedRowKeys([]);
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item label={lang.status} name="status" initialValue={isPicker ? 'LOCK' : 'NEW'}>
                  <Select placeholder={lang.status} defaultValue={status} onChange={(value) => setStatus(value)} showSearch allowClear >
                    {!isPicker && <Option key={'NEW'} value={'NEW'}>{STATUS_OUTBOUND.NEW}</Option>}
                    <Option key={'LOCK'} value={'LOCK'}>{STATUS_OUTBOUND.LOCK}</Option>
                    <Option key={'WAITING'} value={'WAITING'}>{STATUS_OUTBOUND.WAITING}</Option>
                    <Option key={'PICKING'} value={'PICKING'}>{STATUS_OUTBOUND.PICKING}</Option>
                    <Option key={'CONFIRM'} value={'CONFIRM'}>{STATUS_OUTBOUND.CONFIRM}</Option>
                    <Option key={'WAITINGCANCEL'} value={'WAITINGCANCEL'}>{STATUS_OUTBOUND.WAITINGCANCEL}</Option>
                    <Option key={'CANCELLED'} value={'CANCELLED'}>{STATUS_OUTBOUND.CANCELLED}</Option>
                  </Select>
                </Form.Item>
              </Col>
              {
                params?.status === 'LOCK' && !isPicker && !isManager && !isIdm &&
                <Col xxl={4} md={6} sm={12}>
                  <Form.Item label='Chọn nhân viên' name="asignStaff">
                    <Select placeholder='Chọn nhân viên' 
                    mode="multiple"
                    showSearch 
                    allowClear
                    onChange={(value) => {
                      return (setUserId(value))
                    }}
                    >
                      { 
                        pickers?.map((pickerAsignned, index) => {
                          if (pickerAsignned.roles.length > 0) {
                            const findWarehouse = warehouses?.find(warehouse => warehouse?.id === currentWarehouse?.id)
                            if (findWarehouse) {
                              const listUserId = findWarehouse?.userIds
                              const checkUser = listUserId.includes(pickerAsignned?.id)
                              if (checkUser) {
                                return <Option key={index} value={pickerAsignned?.id}>{pickerAsignned.username}</Option>
                              }
                            }
                          }
                          return '';
                        })
                      }
                    </Select>
                  </Form.Item>
              </Col>
              }
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
                <Form.Item name="orderCode">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"                   
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{'Danh sách Delivery order'}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && !isPicker && params?.status !== 'WAITINGCANCEL' && params?.status !== 'CANCELLED' && (
                <Button
                  type="primary"
                  onClick={async () => {
                    Modal.confirm({
                      title: lang.confirmCancelDO,
                      icon: <ExclamationCircleOutlined />,
                      async onOk() {
                        setLoading(true);
                  
                        const ids = [];
                        for (const item of selectedRowKeys) {
                          ids.push(item.id);
                        }
                  
                        try {
                          await deliveryOrderCheckInVoiceDelete({ ids })
                          await deliveryOrderList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
                            .then(result => {
                              setItems(result.data);
                              console.log(result.data)
                              setTotalItems(result.totalItem);
                            });                         
                          setSelectedRowKeys([]);
                          setLoading(false);
                  
                          Modal.success({
                            title: lang.deleteSuccessfully,
                            icon: <ScissorOutlined />
                          });
                  
                        } catch (error) {
                          setLoading(false);
                          message.error(error.message);
                        }
                      },
                      onCancel() {},
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.cancelDO}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          rowSelection={{
            onChange: (_, selectedRows) => {
            setSelectedRowKeys(selectedRows);
          },
            // getCheckboxProps: (record) => ({
            //   disabled: (record?.assignAll === true && record?.userId === null), // || record?.groupDeliveryOrder.length > 0
            //   name: record.status,
            // }),
          }}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            showSizeChanger: true,
            total: totalItems,
            pageSize: params.limit,
            current: params.current,
          }}
          expandable={params?.status === 'NEW' && {
            expandedRowRender,
          }}
          // rowSelection={rowSelection}
        />
      </Layout.Content>

    </Layout>
  );
};

export default DeliveryOrderList;
