import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Modal, Row, Col } from 'antd';
// import { LoginOutlined } from '@ant-design/icons';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import ReceiptProductsPrint from '../../inbound/receipt/receipt-products-print';
import { formatDate, formatDateFull } from 'utils/constants';
import moment from 'moment-timezone';
import Helper from 'utils/helpers';
// import { useSelector } from 'react-redux';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const SplitScanContainer = () => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [itemPrint, setItemPrint] = useState();
  const onCallbackPrint = (val) => {
    setItemPrint(val);
    setIsPrintModal(true);
  };
  // const { scanSplitProductCode } = useSelector((state) => state.outbound);
  // const extraButton = [
  //   <Button
  //     key="btn-complete"
  //     type="primary"
  //     onClick={async () => {
  //         navigate('complete');
  //     }}
  //     disabled={!(scanSplitProductCode?.length > 0)}
  //   >
  //     {lang.btnSubmit}
  //     <LoginOutlined />
  //   </Button>,
  // ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/split-product',
      breadcrumbName: lang.splitproduct,
    },
    {
      path: '/scan',
      breadcrumbName: lang.scan,
    },
  ];

  return (
    <Layout className="storing-scan">
      <PageHeader
        ghost={false}
        title={lang.splitproduct}
        // extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ScanProductForm type={'SPLIT'} onCallbackPrint={onCallbackPrint} />
          </Col>
        </Row>
      </Layout.Content>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => { setIsPrintModal(false); }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={[itemPrint]}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            // setIsEffect(!isEffect);
          }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            // setIsEffect(!isEffect);
          }}
          onCancel={() => {
            setIsPrintModal(false);
            // setIsEffect(!isEffect);
          }}
        />
      </Modal>
    </Layout>
  );
};

export default SplitScanContainer;
