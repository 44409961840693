import moment from 'moment-timezone';
import environment from 'environments/environment';
import request from 'utils/request';

const productList = [
    {
        year: 2021,
        data: [
            {
                month: 'January',
                value: 1998,
            },
            {
                month: 'February',
                value: 1850,
            },
            {
                month: 'March',
                value: 1720,
            },
            {
                month: 'April',
                value: 1818,
            },
            {
                month: 'May',
                value: 1920,
            },
            {
                month: 'June',
                value: 1802,
            },
            {
                month: 'July',
                value: 1945,
            },
            {
                month: 'August',
                value: 1856,
            },
            {
                month: 'September',
                value: 2107,
            },
            {
                month: 'October',
                value: 2140,
            },
            {
                month: 'November',
                value: 2107,
            },
            {
                month: 'December',
                value: 2140,
            },
        ],
    },
    {
        year: 2020,
        data: [
            {
                month: 'January',
                value: 150,
            },
            {
                month: 'February',
                value: 5454,
            },
            {
                month: 'March',
                value: 1578,
            },
            {
                month: 'April',
                value: 1818,
            },
            {
                month: 'May',
                value: 1920,
            },
            {
                month: 'June',
                value: 1802,
            },
            {
                month: 'July',
                value: 1945,
            },
            {
                month: 'August',
                value: 1856,
            },
            {
                month: 'September',
                value: 2107,
            },
            {
                month: 'October',
                value: 3658,
            },
            {
                month: 'November',
                value: 2107,
            },
            {
                month: 'December',
                value: 4567,
            },
        ],
    },
];
const productCategory = [
    { id: 1, productCategory: 'Home Care', value: 14500 },
    { id: 2, productCategory: 'Electronic', value: 34000 },
    { id: 3, productCategory: 'Fashion', value: 26000 },
];
export async function fetchProductCateGory(dataFilter) {
    return productCategory;
}
export async function fetchProduct(dataFilter) {
    const { year } = dataFilter;
    const getyear = moment(year).format('YYYY');
    return productList.filter(product => product.year.toString() === getyear)[0];
}
export async function fetchReports(payload) {
    return request(environment.api.reports, payload, 'GET');
}
export async function productMasterList(payload) {
    return request(environment.api.productList, payload, 'GET');
}
