import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, message as Alert, Modal, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
// import Helper from 'utils/helpers';
import { salesChannelCreate, salesChannelUpdate } from './sale-channel.service';
import { MODE } from 'utils/constants';

const SalesChannelEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    ...item,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const onCancel = () => {
    // const valueForm = form.getFieldsValue();
    // const isDefined = Object.values(valueForm).some(value => value !== undefined);
    // const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);

    // if (!isDefined || isUnChange) {
    //   setIsEditModal(false);
    //   return true;
    // }
    // Helper.conFirm(setIsEditModal);
    setIsEditModal(false);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateSalesChannel : lang.createSalesChannel}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={960}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          values.warehouseId = currentWarehouse?.id;
          if (!item) { // Create
            salesChannelCreate(values)
              .then(() => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            values.id = item?.id;
            salesChannelUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={[16, 16]}>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="distributorCode"
              label='Mã NPP'
            >
              <Input style={{ width: '100%' }} placeholder='Mã NPP' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="distributorName"
              label='Tên NPP'
            >
              <Input style={{ width: '100%' }} placeholder='Tên NPP' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="customerCode"
              label='Mã khách hàng'
            >
              <Input style={{ width: '100%' }} placeholder='Mã khách hàng' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="storeName"
              label='Tên cửa hàng'
            >
              <InputNumber style={{ width: '100%' }} placeholder='Tên cửa hàng' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="registerName"
              label='Tên ĐKKD'
            >
              <Input style={{ width: '100%' }} placeholder='Tên ĐKKD' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="customerTypeCode"
              label='Mã loại KH'
            >
              <Input style={{ width: '100%' }} placeholder='Mã loại KH' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="customerTypeName"
              label='Tên loại KH'
            >
              <Input style={{ width: '100%' }} placeholder='Tên loại KH' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="status"
              label={lang.status}
            >
              <Input style={{ width: '100%' }} placeholder={lang.status} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="street"
              label='Đường'
            >
              <Input style={{ width: '100%' }} placeholder='Đường' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="ward"
              label='Phường/Xã'
            >
              <Input style={{ width: '100%' }} placeholder='Phường/Xã' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="wardCode"
              label='Mã phường/xã'
            >
              <Input style={{ width: '100%' }} placeholder='Mã phường/xã' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="district"
              label='Quận/Huyện'
            >
              <Input style={{ width: '100%' }} placeholder='Quận/Huyện' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="province"
              label='Tỉnh/Thành phố'
            >
              <InputNumber style={{ width: '100%' }} placeholder='Tỉnh/Thành phố' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="homeInvoiceNo"
              label='Số nhà ghi hóa đơn'
            >
              <Input style={{ width: '100%' }} placeholder='Số nhà ghi hóa đơn' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="streetNameInvoice"
              label='Tên đường ghi hóa đơn'
            >
              <Input style={{ width: '100%' }} placeholder='Tên đường ghi hóa đơn' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="provinceInvoice"
              label='Tỉnh/TP ghi hóa đơn'
            >
              <Input style={{ width: '100%' }} placeholder='Tỉnh/TP ghi hóa đơn' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="districtInvoice"
              label='Quận/Huyện ghi hóa đơn'
            >
              <Input style={{ width: '100%' }} placeholder='Quận/Huyện ghi hóa đơn' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="wardCodeInvoice"
              label='Mã phường/xã ghi hóa đơn'
            >
              <Input style={{ width: '100%' }} placeholder='Mã phường/xã ghi hóa đơn' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="country"
              label='Quốc gia'
            >
              <Input style={{ width: '100%' }} placeholder='Quốc gia' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="geoLocation"
              label='Geo-Location'
            >
              <Input style={{ width: '100%' }} placeholder='Geo-Location' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="dateOfBirth"
              label='Ngày sinh'
            >
              <Input style={{ width: '100%' }} placeholder='Ngày sinh' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="channel"
              label='Channel'
            >
              <InputNumber style={{ width: '100%' }} placeholder='Channel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="channelName"
              label='Tên Channel'
            >
              <InputNumber style={{ width: '100%' }} placeholder='Tên Channel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="subChannel"
              label='Sub Channel'
            >
              <Input style={{ width: '100%' }} placeholder='Sub Channel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="subChannelName"
              label='Tên SubChannel'
            >
              <Input style={{ width: '100%' }} placeholder='Tên SubChannel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="nielsenChannel"
              label='Nielsen Channel'
            >
              <Input style={{ width: '100%' }} placeholder='Tên SubChannel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="nielsenChannelName"
              label='Tên Nielsen Channel'
            >
              <InputNumber style={{ width: '100%' }} placeholder='Tên Nielsen Channel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="payment"
              label='Payment Method'
            >
              <Input style={{ width: '100%' }} placeholder='Payment Method' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="standardDelivery"
              label='Standard delivery time'
            >
              <Input style={{ width: '100%' }} placeholder='Standard delivery time' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="contractNo"
              label='Contract No'
            >
              <Input style={{ width: '100%' }} placeholder='Contract No' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="shelfLife"
              label={lang.shelfLife}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.shelfLife} />
            </Form.Item>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

SalesChannelEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default SalesChannelEditForm;
