import request from 'utils/request';
import environment from 'environment';
export async function deliveryOrderList(payload) {
  return request(environment.api.deliveryOrder + '/get-all', payload, 'GET');
}

export async function deliveryOrderDetail(id) {
  return request(environment.api.deliveryOrder + `/get-one/${id}`, {}, 'GET');
}

export async function deliveryOrderCreate (payload) {
  return request(environment.api.deliveryOrder + `/create`, payload, 'POST');
}

export async function deliveryOrderUpdate (payload) {
  return request(environment.api.deliveryOrder + `/update`, payload, 'PUT');
}

export async function deliveryOrderDelete (id) {
  return request(environment.api.deliveryOrder + `/delete/${id}`, {}, 'DELETE');
}

export async function deliveryOrderCheckInVoiceDelete (payload) {
  return request(environment.api.deliveryOrder + `/check-invoice-cancelDO`, payload, 'POST');
}

export async function locatorList(payload) {
  return request(environment.api.locator + '/get-all', payload, 'GET');
}

export async function updateDoStatus(payload) {
  return request(environment.api.deliveryOrder + '/updates', payload, 'POST');
}

export async function orderCheck(payload) {
  return request(environment.api.deliveryOrder + '/check/picking', payload, 'POST');
}

export async function deliveryConfirm(payload) {
  return request(environment.api.deliveryOrder + '/confirm', payload, 'POST');
}

export async function groupDeliveryOrder(payload) {
  return request(environment.api.deliveryOrder + '/group-delivery-order', payload, 'POST');
}

export async function unGroupDeliveryOrder(payload) {
  return request(environment.api.deliveryOrder + '/un-group-delivery-order', payload, 'POST');
}
