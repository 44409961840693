import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, DatePicker, Divider, message } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import { formatDate, formatDateFull } from 'utils/constants';
import moment from 'moment';

const ReceiptSplitForm = ({ items, onCancel, onCallback}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [checkQuantity, setCheckQuantity] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    receiptProducts: items.map(productSplit => ({
      quantityCartton:  items ? 0 : undefined ,
      quantityTin: items ? 0 : undefined,
      lot: productSplit.lot ? productSplit?.lot : undefined,
      expireDate:moment(productSplit.expireDate,formatDateFull),
      shortName: productSplit?.masterProduct?.shortName,
      code3A: productSplit?.masterProduct?.code3A,
      name: productSplit?.masterProduct?.nameEN,
      masterProductId: productSplit?.masterProduct.id,
      warehouseId: productSplit?.warehouseId,
      ReceiptProductId: productSplit?.id,
    }))
  }

  return (
    <Form
      colon={false}
      form={form}
      requiredMark={false}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        const productCode = [];
        await values?.receiptProducts.map( (item) => {
          const dataSelected = items?.map((pro) => {
            if (item?.quantityCartton === pro?.quantityCartton && item?.quantityTin === pro?.quantityTin) {
              productCode.push(pro?.masterProduct?.code3A)
            } 
            return '';
          })
          return dataSelected;
        })
        if (productCode?.length > 0) {
          message.warning(`Bạn không thể lấy hết số lượng thùng và lon của sản phẩm có mã ${productCode}`)
        } else {
          setLoading(true)
          onCallback(values);
        }
       

      }}
      initialValues={initValues}
    >
      <Row gutter={24} type="flex">
      <Form.List name="receiptProducts">
        {
          (fields) => (
            <>
            {
              fields.map(({name}) => (
                <>
                  <Row gutter={[16, 16]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item label={'Mã sản phẩm'} name={[name, 'code3A']}>
                        <Input disabled value={items[0]?.masterProduct?.code3A} />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item label={lang.SHORTNAME} name={[name, 'shortName']}>
                        <Input disabled value={items[0]?.masterProduct?.shortName} />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item
                        label={lang.productName}
                        name={[name, 'name']}
                      >
                        <Input disabled value={items[0]?.masterProduct?.nameEN} />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item label={'Lot'} name={[name, 'lot']}>
                        <Input value={items?.lot} />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item name={[name, 'expireDate']} label={lang.expireDate}>
                        <DatePicker
                          style={{ width: '100%' }}
                          format={formatDate}
                        />

                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item name={[name, 'quantityCartton']} label={`${lang.quantityCartton}(<=${items[name]?.quantityCartton - items[name]?.quantityCarttonReceived})`}
                        rules={[
                          {
                            required: true,
                            message: 'Số lượng thùng cần phải nhập',
                            // eslint-disable-next-line prefer-promise-reject-errors
                          },
                          {
                            validator: (_, value) => value ? (value <= (items[name]?.quantityCartton - items[name]?.quantityCarttonReceived) ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng thùng hiện có')) : Promise.resolve(),
                          },
                        ]}
                      >
                        <InputNumber style={{ width: '100%' }} min={0} defaultValue={0} placeholder={lang.quantityCartton}/>
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item shouldUpdate>
                        {(form) => {
                          const totalQuantity = (items[name]?.quantityCartton - form.getFieldValue(['receiptProducts', name, 'quantityCartton']) - items[name]?.quantityCarttonReceived) * parseInt(items[name]?.masterProduct?.tinPerCarton) + items[name]?.quantityTin - items[name]?.quantityTinReceived;
                        
                          return <Form.Item
                            name={[name, 'quantityTin']}
                            label={totalQuantity > 0 ? `${lang.quantityTin}(<=${totalQuantity})` : `${lang.quantityTin}(=${totalQuantity})`}
                            rules={[
                              {
                                required: true,
                                message: 'Số lượng lon cần phải nhập',
                                // eslint-disable-next-line prefer-promise-reject-errors
                              },
                              {
                                validator: (_, value) => value ? (value <= totalQuantity ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng lon tồn kho')) : Promise.resolve(),
                              }
                            ]}
                          >
                            <InputNumber disabled={totalQuantity === 0} defaultValue={0} style={{ width: '100%' }} min={0} placeholder={lang.quantityTin} value={totalQuantity} />
                          </Form.Item>;
                        }}
                      </Form.Item>
                    </Col>
                    {name < items?.length - 1 && <Divider/>}
                  </Row>
                </>
              ))
            }
            </>
          )
        }
      </Form.List>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptSplitForm.propTypes = {
  items: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
};

export default ReceiptSplitForm;
