import {
  REALLOCATION_SCAN,
} from './types';

const INITIAL_STATE = {
  scanReallocationProductCode: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //Scan
    case REALLOCATION_SCAN:
      return {
        ...state,
        scanReallocationProductCode: action.payload,
    };
    default:
      return state;
  }
};
