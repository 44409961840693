import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Image, message, Card, Row, Col } from 'antd';

import { productDetail } from './product.service';

import './style.less';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ProductDetail = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [product, setProduct] = useState();

  useEffect(() => {
    productDetail(productId)
      .then(result => {
        setProduct(result);
      })
      .catch((e) => message.error(e.message));
  }, [productId]);

  const extraButton = [
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        navigate('/dashboard/configuration/product');
      }}
    >
      {lang.backtoProductList}
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/product',
      breadcrumbName: lang.product,
    },
  ];
  return (
    <Layout className="product-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.productDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={16}>
            <Col md={5} xl={4} sm={10}>
              <Image src={product?.image} style={{ objectFit: 'contain', height: 150 }} />
            </Col>
            <Col md={19} xl={20} sm={14}>
              <Descriptions title={lang.productInfo} column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}>
                <Descriptions.Item label={lang.productCode}>{product?.code}</Descriptions.Item>
                <Descriptions.Item label={lang.productName}>{product?.name}</Descriptions.Item>
                <Descriptions.Item label={lang.productCategory}>{product?.productCategory?.name}</Descriptions.Item>
                <Descriptions.Item label={lang.supplier}>{product?.suppliers[0]?.name}</Descriptions.Item>
                <Descriptions.Item label={lang.capacity}>{product?.capacity}</Descriptions.Item>
                <Descriptions.Item label={lang.storageTime}>{product?.stogareTime}</Descriptions.Item>
                <Descriptions.Item label={lang.status}>{lang[product?.status]}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>

      </Layout.Content>

    </Layout>
  );
};

export default ProductDetail;
