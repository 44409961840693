import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Select, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import { useSelector } from 'react-redux';
import { ProFormSelect } from '@ant-design/pro-form';
// import _ from 'lodash';
import _get from 'lodash/get';
import { locatorGiftList } from './production-order.service';
import { bomGiftList } from 'pages/dashboard/inventory/giftinventory/inventory-gift.service';

const { Option } = Select;


const ReceiptProductEditForm = ({ item, onCancel, onCallback, type }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [productFind, setProductFind] = useState();
  const [locators, setLocators] = useState();
  const isIdm = _get(user, 'roles', '').includes('idm');
  const [sortedInfo] = useState({
    sortDirection: 'desc',
    sortBy: 'createDate',
  });
  useEffect(() => {
    setLoading(true);
    bomGiftList({...sortedInfo, warehouseId: currentWarehouse.id, isAvailable: true })
      .then(result => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [sortedInfo, currentWarehouse, item]);

  useEffect(() => {
    locatorGiftList({ warehouseId: currentWarehouse?.id, isAvailable: true, bomGiftId: item?.bomGift?.id }).then(val => {
      setLocators(val.data);
      setLoading(false);
    });
  }, [currentWarehouse, item]);
  
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        try {
          if (values.quantity === 0) {
            throw new Error('Số lượng là bắt buộc');
          }
          setLoading(true);
          onCallback({
            ...productFind,
            ...values,
            type,
            id: item?.id,
          });
        } catch (error) {
          message.error(error.message);
        }
      }}
      initialValues={{
        productCode: item ? item?.bomGift?.name : undefined,
        quantity: item ? item?.quantity : 0,
        locatorId: item ? item?.locatorCode : undefined,

      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.product}
           
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              placeholder={lang.placeholderSelectProduct}
              disabled={item}
              onChange={(value) => {
                const itemFind = products.find(product => product.id === value);
                setProductFind(itemFind);
                form.setFieldsValue({ locatorId: undefined, quantity: 0 });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
            >
              {products?.map(item => (
                <Option key={item.id} value={item.id}>{item.name} - {item.code}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
          {
            isIdm && <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <ProFormSelect style={{ fontWeight: 600 }}
              name='locatorId'
              disabled={item?.locatorCode}
              label={lang.Locator}
              rules={[
                {
                  required: true,
                  message: lang.requiredLocator,
                },
              ]}
              options={  
                item ? locators?.map(locator => ({
                  label: `${locator?.locator?.locator} - ${locator?.locator?.locatorName}`,
                  value: item?.locatorCode === null ? locator?.locator?.locator : locator?.locator?.id,
                  locatorCode: locator?.locators?.locator,
                  id: locator.id,
                })) :
                productFind?.masterProductsGiftLocators.map(locator => ({
                  label: `${locator?.locator?.locator} - ${locator?.locator?.locatorName}`,
                  value: locator?.locators?.id,
                  locatorCode: locator?.locator?.locator,
                  id: locator.id,
                }))
            }
              placeholder={lang.Locator}
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              showSearch
            />
          </Col>
          }
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
        <Form.Item shouldUpdate>
            {(form) => {
              if (productFind) {
                let totalQuantity = productFind.masterProductsGiftLocators.reduce((prev,cur) => prev + cur?.quantityForSale, 0);
                if (form.getFieldValue('locatorId')) {
                  totalQuantity = productFind?.masterProductsGiftLocators?.find(locator => locator?.locator?.id === form.getFieldValue('locatorId'))?.quantityForSale;
                }
                return <Form.Item
                  name="quantity"
                  label={totalQuantity > 0 ? `${'Số lượng khả dụng'}(<=${totalQuantity})` : `${'Số lượngùng khả dụng'}(=${totalQuantity})`}
                  rules={[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => value ? (value <= totalQuantity ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng tồn kho')) : Promise.resolve(),
                    },
                  ]}
                >
                  <InputNumber disabled={totalQuantity <= 0} style={{ width: '100%' }} min={0} placeholder={lang.placeholderQuantity} />
                </Form.Item>;
              } else if (locators && item) {
                let totalQuantity = locators.reduce((prev,cur) => prev + cur?.quantityForSale, 0);
                // console.log(locators)
                // console.log(form.getFieldValue('locatorId'))
                // console.log(item)
                if (form.getFieldValue('locatorId')) {
                  totalQuantity = item?.locatorCode !== null ? locators?.find(locator => locator?.locator?.locator === form.getFieldValue('locatorId'))?.quantityForSale : locators?.find(locator => locator?.locator?.locator === form.getFieldValue('locatorId'))?.quantityForSale
                }
                return <Form.Item
                  name="quantity"
                  label={totalQuantity > 0 ? `${'Số lượng khả dụng'} (<=${totalQuantity})` : `${'Số lượng khả dụng'} (=${totalQuantity})`}
                  rules={[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => value ? (value <= totalQuantity ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng tồn kho')) : Promise.resolve(),
                    },
                  ]}
                >
                  <InputNumber disabled={totalQuantity <= 0} style={{ width: '100%' }} min={0} placeholder={lang.placeholderQuantity} />
                </Form.Item>;
              }
              return <Form.Item
                name="quantity"
                label={'Số lượng khả dụng'}
              >
                <InputNumber  style={{ width: '100%' }} min={0} max={productFind?.capacity} placeholder={'Số lượng khả dụng'} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
      </Row>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  type: PropTypes.string,
  locators: PropTypes.array,
  channel: PropTypes.object,
};

export default ReceiptProductEditForm;
